import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { ScheduleParams } from "../model/schedule";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/schedule`;

@Injectable()
export class ScheduleService {
  constructor(private http: JsonHttp) {}

  getList(calendarId: string) {
    let params: string = `${url}?calendarId=${calendarId}`;
    //console.log(params);
    return this.http.get(params);
  }

  create(params: ScheduleParams) {
    return this.http.post(url, params);
  }

  update(params: ScheduleParams) {
    return this.http.put(url, params);
  }

  delete(params: ScheduleParams) {
    return this.http.delete(url, params);
  }

  getHomeList(calendarId: string) {
    let params: string = `${url}/home?calendarId=${calendarId}`;
    //console.log(params);
    return this.http.get(params);
  }
}
