import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import { Angular2Csv } from "angular2-csv";
import { Profile } from "app/model/profile";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import * as Collections from "typescript-collections";
import { Admission, AdmissionSearchParams } from "../../model/admission";
import { CodeCommon } from "../../model/code-common";
import { Page } from "../../model/page";
import { AdmissionService } from "../../services/admission.service";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";

const fixedSize = 10000;
@Component({
  selector: "app-admission",
  providers: [AdmissionService],
  templateUrl: "./admission.component.html",
  styleUrls: ["./admission.component.css"],
})
export class AdmissionComponent implements OnInit {
  public page = new Page();
  public rows = new Array<Admission>();
  public userId: number = 0;

  public searchParams = new AdmissionSearchParams();

  datePipe = new DatePipe("en-US");

  codeCommon: CodeCommon;
  filterList: any[] = [];

  selected = [];

  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  @ViewChild("dataTable") public dataTable: DatatableComponent;

  authority: string;
  name: string;
  company: string;

  isSearchEp: boolean = true;

  action: string;
  idx: string;
  selectedEp: string;

  loadingIndicator: boolean = false;
  timeout: any;

  mySet = new Collections.Set<number>();
  mySet2 = new Collections.Set<string>();
  //mySet3            = new Collections.Set<number>();
  studentCount: number = 0;
  schoolCount: number = 0;

  displaySearchValue: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private codeService: CodeService,
    private admissionService: AdmissionService
  ) {
    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;

        if (
          this.authority == "admin" ||
          this.authority == "accountant" ||
          this.authority == "manager"
        ) {
          this.isSearchEp = true;
        } else {
          this.isSearchEp = false;
        }
      });

    this.searchParams.searchKey = "Name";
    this.searchParams.searchValue = "";

    this.route.queryParams.subscribe((params) => {
      this.action = params["action"];
      this.idx = params["idx"];
      this.selectedEp = params["ep"];

      //codeService.getCommon(this);
      codeService.getEachCommon(this, "3");
    });
  }

  setSearchParams(searchParams) {
    
    if (searchParams != undefined) {
      this.searchParams = searchParams;
    } else {
      this.searchParams.searchKey = "Name";
      this.searchParams.searchValue = "";
      this.searchParams.fromNextCrm = "";
      this.searchParams.toNextCrm = "";
      this.searchParams.fromLastCrm = "";
      this.searchParams.toLastCrm = "";
      this.searchParams.fromHoldingDate = "";
      this.searchParams.toHoldingDate = "";
      this.searchParams.fromIntakeDate = "";
      this.searchParams.toIntakeDate = "";
      this.searchParams.fromCourseEndDate = "";
      this.searchParams.toCourseEndDate = "";
    }
    //console.log('noga!!! searchParams; ', this.searchParams);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    //240403
    this.codeCommon = this.codeService.getOrg();
    if (codeCommon && codeCommon.adminEpList) {
      // 'codeCommon'에서 'adminEpList'를 가져와서 현재 'codeCommon' 객체의 'adminEpList'에 할당합니다.
      this.codeCommon.adminEpList = codeCommon.adminEpList;
      this.codeCommon.adminEpList.forEach(x => x.checked = false);

    } else {
      // 필요한 경우, 'adminEpList'가 없거나 'codeCommon'이 undefined일 때의 처리를 추가할 수 있습니다.
      console.log('No adminEpList available or codeCommon is undefined');
    }
    //this.codeCommon = codeCommon; //240403

    
    //console.log('noga!!! setCodeList idx; ', this.idx);
    if (this.action == "home") {
      
      if (this.idx !== undefined && this.idx != "") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (this.idx == "2") {
            if (x.code == "1_RO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          } else if (this.idx == "3") {
            if (x.code == "6_UO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          } else if (
            this.idx == "4" ||
            this.idx == "5" ||
            this.idx == "6" ||
            this.idx == "6"
          ) {            
            if (
              x.code == "Enrolled" ||
              x.code == "Rejected" ||
              x.code == "wd"
            ) {
              x.checked = false;
            } else {
              x.checked = true;
            }
          }
        });
        
        if (this.idx == "5") {
          //console.log('noga!!! this.idx == 5 ');
          this.searchParams.fromNextCrm1 = Utils.formatStrDate("today");
          this.searchParams.toNextCrm1 = Utils.formatStrDate("today");
        } else if (this.idx == "6") {
          //console.log('noga!!! this.idx == 6 ');
          this.searchParams.fromNextCrm1 = Utils.getNgbDate(1);
          this.searchParams.toNextCrm1 = Utils.getNgbDate(5);
        } else if (this.idx == "7") {
          //console.log('noga!!! this.idx == 7 ');
          this.searchParams.fromNextCrm1 = undefined;
          this.searchParams.toNextCrm1 = Utils.getNgbDate(-6);
        }
      }

      if (this.selectedEp != undefined) {
        this.codeCommon.adminEpList.forEach((x) => {
          if (x.code == this.selectedEp) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }
    } else {
      if (!this.codeCommon.adminStatusList.some((x) => x.checked == true)) {
        this.setDefaultFilter();
      }

      this.setDefaultAdminEP();
    }

    // dashboard starts //

    if (this.action == "home") {
      this.codeCommon.memberCategoryList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.stageList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.epList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.adminEpList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.adminAgentList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.schoolTypeList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.schoolNewList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.courseList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.adminStatusList.forEach((x) => {
        x.checked = false;
      });

      this.searchParams.fromLastCrm1 = undefined;
      this.searchParams.toLastCrm1 = undefined;

      this.searchParams.fromIntakeDate1 = undefined;
      this.searchParams.toIntakeDate1 = undefined;

      this.searchParams.fromCourseEndDate1 = undefined;
      this.searchParams.toCourseEndDate1 = undefined;

      this.searchParams.fromNextCrm1 = undefined;
      this.searchParams.toNextCrm1 = undefined;

      this.searchParams.fromHoldingDate1 = undefined;
      this.searchParams.toHoldingDate1 = undefined;

      if (
        this.idx == "todaycrm0" ||
        this.idx == "todaycrm1" ||
        this.idx == "todaycrm2" ||
        this.idx == "todaycrm3" ||
        this.idx == "todaycrm4" ||
        this.idx == "todaycrm5"
      ) {
        if (this.selectedEp != "All") {
          
          if (this.idx != "todaycrm0" && this.idx != "todaycrm2" && this.idx != "todaycrm3") { //0224
            var selectedEps = this.selectedEp.split(",");

            this.codeCommon.adminEpList.forEach((x) => {
              x.checked = false;
            });

            this.codeCommon.adminEpList.forEach((x) => {
              selectedEps.forEach(function (value) {
                //console.log(value);
                if (x.code == value) {
                  x.checked = true;
                }
              });
            });

            /*
            this.codeCommon.adminEpList.forEach(x => {
              if(x.code == this.selectedEp) {
                x.checked = true;
              } else {
                x.checked = false;
              }
            });
            */
          }
        }

        if (this.idx == "todaycrm4") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "6_UO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "todaycrm5") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "OnGoing") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        //0117 data fixed
        } else if (this.idx == "todaycrm3") {

          //0224
          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
          //0224
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "1_RO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-4);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        }
        else if (this.idx == "todaycrm2") {
          //0224
          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });

          //0224
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "1_RO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-4);
        }
        //0117 data fixed
      }
      //0224
      if (this.idx == "todaycrm7") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "Admin Holding") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          this.searchParams.fromHoldingDate1 = undefined;
          this.searchParams.toHoldingDate1 = Utils.formatStrDate("today");
        }
      }
      //0224
      if (this.idx == "todaycrm6") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "Admin Holding") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });


      }
      console.log('noga!!! idx; ', this.idx);
      //0226
      if (this.idx == "epcrm0") {

        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code != "wd" &&
            x.code != "Cancelled" &&
            x.code != "Rejected" &&
            x.code != "Enrolled"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        var selectedEps = this.selectedEp.split(",");
        console.log("selectedEps",selectedEps);
        this.codeCommon.adminEpList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });
        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            //console.log(value);
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

      }

      if (this.idx == "admincrm0") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code != "wd" &&
            x.code != "Cancelled" &&
            x.code != "Rejected" &&
            x.code != "Enrolled"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        var selectedEps = this.selectedEp.split(",");

        this.codeCommon.adminEpList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.adminEpList.forEach((x) => {
          selectedEps.forEach(function (value) {
            console.log(value);
            if (x.code == value) {
              x.checked = true;
            }
          });
        });
      }

      if (this.idx == "epcrm1" || this.idx == "admincrm1") {
        if (this.selectedEp != "All") {
          if (this.idx == "epcrm1") {

            var selectedEps = this.selectedEp.split(",");
            console.log("selectedEps",selectedEps);
            this.codeCommon.adminEpList.forEach((x) => {
              x.checked = false;
            });
    
            this.codeCommon.epList.forEach((x) => {
              x.checked = false;
            });
            this.codeCommon.epList.forEach((x) => {
              selectedEps.forEach(function (value) {
                //console.log(value);
                if (x.code == value) {
                  x.checked = true;
                }
              });
            });
    
          } else if (this.idx == "admincrm1") {
            this.codeCommon.epList.forEach((x) => {
              x.checked = false;
            });
    
            var selectedEps = this.selectedEp.split(",");
    
            this.codeCommon.adminEpList.forEach((x) => {
              x.checked = false;
            });
    
            this.codeCommon.adminEpList.forEach((x) => {
              selectedEps.forEach(function (value) {
                console.log(value);
                if (x.code == value) {
                  x.checked = true;
                }
              });
            });
          } 

        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "2_AS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
        this.searchParams.toLastCrm1 =  undefined;
      }
      if (this.idx == "epcrm2" || this.idx == "admincrm2") {

        if (this.idx == "epcrm2") {


          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
  
        } else if (this.idx == "admincrm2") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
  
          var selectedEps = this.selectedEp.split(",");
  
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        } 
        //0224
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "1_RO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-4);
        this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
      } //epcrm2 admincrm2

      if (this.idx == "epcrm3" || this.idx == "admincrm3") {
        //0224
        if (this.idx == "epcrm3") {
          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
  
        } else if (this.idx == "admincrm3") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
  
          var selectedEps = this.selectedEp.split(",");
  
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        } 
        

        //0224
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "1_RO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = undefined;
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-4);
      }


      if (this.idx == "epcrm4" || this.idx == "admincrm4") {
        //0224
        if (this.idx == "epcrm4") {
          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
  
        } else if (this.idx == "admincrm4") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
  
          var selectedEps = this.selectedEp.split(",");
  
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        } 
        

        //0224
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "Admin Holding") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromHoldingDate1 = undefined;
        this.searchParams.toHoldingDate1 = Utils.formatStrDate("today");
        
      }

      if (this.idx == "epcrm5" || this.idx == "admincrm5") {
        //0224
        if (this.idx == "epcrm5") {
          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
  
        } else if (this.idx == "admincrm5") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
  
          var selectedEps = this.selectedEp.split(",");
  
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
  
          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        } 
        
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code == "Defer" ||
            x.code == "OnGoing" ||
            x.code == "2_AS" ||
            x.code == "3_PS" ||
            x.code == "4_CO" ||
            x.code == "6_UO"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        this.searchParams.fromIntakeDate1 = undefined; //Utils.formatStrDate("2025-01-01"); //=
        this.searchParams.toIntakeDate1 = Utils.formatStrDate("today");

      }

      //0226
      if (
        this.idx == "next5crm0" ||
        this.idx == "next5crm1" ||
        this.idx == "next5crm2" ||
        this.idx == "next5crm3" ||
        this.idx == "next5crm4" ||
        this.idx == "next5crm5" ||
        this.idx == "next5crm6" ||
        this.idx == "next5crm7" ||
        this.idx == "next5crm8" ||
        this.idx == "next5crm9"
      ) {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code != "wd" &&
            x.code != "Cancelled" &&
            x.code != "Rejected" &&
            x.code != "Enrolled"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        var thisEP = this.selectedEp.split(",");

        if (this.idx == "next5crm0") {
          //
        } else if (this.idx == "next5crm1") {
          thisEP = [];
          thisEP[0] = "Liz";
        } else if (this.idx == "next5crm2") {
          thisEP = [];
          thisEP[0] = "Ella";
        } else if (this.idx == "next5crm3") {
          thisEP = [];
          thisEP[0] = "Sharon";
        } else if (this.idx == "next5crm4") {
          thisEP = [];
          thisEP[0] = "Sugar";
        } else if (this.idx == "next5crm5") {
          thisEP = [];
          thisEP[0] = "Jemma";
        } else if (this.idx == "next5crm6") {
          thisEP = [];
          thisEP[0] = "Fred";
        } else if (this.idx == "next5crm7") {
          thisEP = [];
          thisEP[0] = "Kristy";
        }

        
        if (this.idx == "next5crm0") {


          var selectedEps = this.selectedEp.split(",");
          console.log("selectedEps",selectedEps);
          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });

        }

        if (this.idx == "next5crm1") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });

          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        //All EP but Chosen Admin EP == selectedEP
        if (this.idx == "next5crm8") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });

          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        //All EP and All Admin EP
        if (this.idx == "next5crm9") {
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
        }
      }

      if (this.idx == "arrivingm0") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code == "1_RO" ||
            x.code == "2_AS" ||
            x.code == "3_PS" ||
            x.code == "4_CO" ||
            x.code == "6_UO" ||
            x.code == "Admin Holding" ||
            x.code == "Defer" ||
            x.code == "OnGoing"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromNextCrm1 = Utils.formatStrDate("today");
        this.searchParams.toNextCrm1 = Utils.formatStrDate("today");
      }

      if (this.idx == "arrivingm1") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code == "1_RO" ||
            x.code == "2_AS" ||
            x.code == "3_PS" ||
            x.code == "4_CO" ||
            x.code == "6_UO" ||
            x.code == "Admin Holding" ||
            x.code == "Defer" ||
            x.code == "OnGoing"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromNextCrm1 = Utils.getNgbDate(1);
        this.searchParams.toNextCrm1 = Utils.getNgbDate(5);
      }

      if (this.idx == "arrivingm2") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code == "1_RO" ||
            x.code == "2_AS" ||
            x.code == "3_PS" ||
            x.code == "4_CO" ||
            x.code == "6_UO" ||
            x.code == "Admin Holding" ||
            x.code == "Defer" ||
            x.code == "OnGoing"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromNextCrm1 = undefined;
        this.searchParams.toNextCrm1 = Utils.getNgbDate(-1);
      }

      if (this.idx == "myNumber4" || this.idx == "totalNumber4") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });

          if (this.idx == "myNumber4") {
            this.codeCommon.epList.forEach((x) => {
              selectedEps.forEach(function (value) {
                //console.log(value);
                if (x.code == value) {
                  x.checked = true;
                }
              });
            });
          }
        }

        this.codeCommon.adminEpList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "수속멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        this.searchParams.fromIntakeDate1 = Utils.formatStrDate(
          Utils.formatStrDate("today").year + "-01-01"
        );
        this.searchParams.toIntakeDate1 = Utils.formatStrDate(
          Utils.formatStrDate("today").year + "-12-31"
        );
      }

      if (
        this.idx == "todaydone0" ||
        this.idx == "todaydone1" ||
        this.idx == "todaydone2" ||
        this.idx == "todaydone3" ||
        this.idx == "todaydone4" ||
        this.idx == "todaydone5" ||
        this.idx == "todaydone5b"
      ) {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromLastCrm1 = Utils.formatStrDate("today");
        this.searchParams.toLastCrm1 = Utils.formatStrDate("today");

        if (this.idx == "todaydone0") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "1_RO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }

        if (this.idx == "todaydone1") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "2_AS") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }

        if (this.idx == "todaydone2") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "3_PS") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }

        if (this.idx == "todaydone3") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "4_CO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }

        if (this.idx == "todaydone4") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "6_UO") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }

        if (this.idx == "todaydone5") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "wd") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }

        if (this.idx == "todaydone5b") {
          this.codeCommon.adminStatusList.forEach((x) => {
            if (x.code == "Enrolled") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }
      }

      if (
        this.idx == "todaydone6" ||
        this.idx == "todaydone7" ||
        this.idx == "todaydone8"
      ) {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "1_RO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "todaydone6") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-3);
          this.searchParams.toLastCrm1 = undefined;
        }

        if (this.idx == "todaydone7") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-10);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-4);
        }

        if (this.idx == "todaydone8") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-10);
        }
      }

      if (this.idx == "intakepassedall") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code == "Defer" ||
            x.code == "OnGoing" ||
            x.code == "2_AS" ||
            x.code == "3_PS" ||
            x.code == "4_CO" ||
            x.code == "6_UO"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromIntakeDate1 = undefined;
        this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
      }

      if (this.idx == "defer0") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "Defer") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromIntakeDate1 = undefined;
        this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
      }

      if (this.idx == "ongoing0") {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "OnGoing") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        this.searchParams.fromIntakeDate1 = undefined;
        this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
      }
      
      if (
        this.idx == "lapsedrighttotal60" ||
        this.idx == "lapsedrighttotal0" ||
        this.idx == "lapsedrighttotal1" ||
        this.idx == "lapsedrighttotal2" ||
        this.idx == "lapsedrighttotal3" ||
        this.idx == "lapsedrighttotal4"
      ) {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (
            x.code == "2_AS" ||
            x.code == "3_PS" ||
            x.code == "4_CO" ||
            x.code == "6_UO"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          this.codeCommon.adminEpList.forEach((x) => {
            if (x.code == this.selectedEp) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }
       
        if (this.idx == "lapsedrighttotal60") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        } else if (this.idx == "lapsedrighttotal0") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        } else if (this.idx == "lapsedrighttotal1") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
        } else if (this.idx == "lapsedrighttotal2") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-90);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        } else if (this.idx == "lapsedrighttotal3") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-91);
        } else if (this.idx == "lapsedrighttotal4") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        } 
      }

      //0120
      if (this.idx == "lapsedappsub0") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.stageList.forEach((x) => {   
          console.log("현재 데이터:", x);       
          if ( x.code == "신규 멤버") {
            x.checked = true;
            //console.log("noga!!!!!!!!!!!! new mem");
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
      }
      if (this.idx == "lapsedappsub1") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "신규 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = undefined;
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
      }
      //0120

      //0123
      if (this.idx == "lapsedappsub2") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "2_AS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {   
          console.log("현재 데이터:", x);       
          if ( x.code == "신규 멤버") {
            x.checked = true;
            //console.log("noga!!!!!!!!!!!! new mem");
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-30);
      }
      if (this.idx == "lapsedappsub3") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "2_AS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "신규 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = undefined;
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-60);
      }
      if (this.idx == "lapsedappsub4") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });
          //0224
          this.codeCommon.epList.forEach((x) => {
            x.checked = false;
          });
          this.codeCommon.epList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
          /*
          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
          */
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "2_AS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "신규 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
        this.searchParams.toLastCrm1 =  undefined;
      }
      if (this.idx == "lapsedpendingdoc2") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "3_PS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
      }
      if (this.idx == "lapsedpendingdoc3") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "3_PS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = undefined;
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
      }
      if (this.idx == "lapsedconoffer0") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "4_CO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {   
          console.log("현재 데이터:", x);       
          if ( x.code == "신규 멤버") {
            x.checked = true;
            //console.log("noga!!!!!!!!!!!! new mem");
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
      }
      if (this.idx == "lapsedconoffer1") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "4_CO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {   
          console.log("현재 데이터:", x);       
          if ( x.code == "신규 멤버") {
            x.checked = true;
            //console.log("noga!!!!!!!!!!!! new mem");
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = undefined;
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
      }
      if (this.idx == "lapsedunconoffer0") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "6_UO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {   
          console.log("현재 데이터:", x);       
          if ( x.code == "신규 멤버") {
            x.checked = true;
            //console.log("noga!!!!!!!!!!!! new mem");
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
      }
      if (this.idx == "lapsedunconoffer1") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "6_UO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        /*0123
        this.codeCommon.stageList.forEach((x) => {   
          console.log("현재 데이터:", x);       
          if ( x.code == "신규 멤버") {
            x.checked = true;
            //console.log("noga!!!!!!!!!!!! new mem");
          } else {
            x.checked = false;
          }
        });*/
        this.searchParams.fromLastCrm1 = undefined;
        this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
      }
      //0123

      if (
        this.idx == "lapsedappsub60" ||
        //this.idx == "lapsedappsub0" ||
        //this.idx == "lapsedappsub1" ||
        //this.idx == "lapsedappsub2" ||
        //this.idx == "lapsedappsub3" ||
        //this.idx == "lapsedappsub4" ||
        this.idx == "lapsedappsub5" ||
        this.idx == "lapsedappsub6"
      ) {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "2_AS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        //console.log('noga!!!!1 this.codeCommon.adminStatusList:'+this.codeCommon.adminStatusList);
        //console.log('noga!!!!1 this.selectedEp:'+this.selectedEp);
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        //console.log('noga!!!!1 selectedEps:'+selectedEps);

        if (this.idx == "lapsedappsub60") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        //} else if (this.idx == "lapsedappsub0") {
        //  this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        //} else if (this.idx == "lapsedappsub1") {
        //  this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
        //} else if (this.idx == "lapsedappsub2") {
        //  this.searchParams.fromLastCrm1 = Utils.getNgbDate(-90);
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        //} else if (this.idx == "lapsedappsub3") {
        //  this.searchParams.fromLastCrm1 = undefined;
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(-91);
        //} else if (this.idx == "lapsedappsub4") {
        //  this.searchParams.fromLastCrm1 = undefined;
        //  this.searchParams.toLastCrm1 = undefined;
        } else if (this.idx == "lapsedappsub5") {
          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
        } else if (this.idx == "lapsedappsub6") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        }
      }
      /*0124
      if (this.idx == "lapsedpendingdoc0") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.stageList.forEach((x) => {
          //console.log("noga!!!!!!!!!!!!enter");
          if (x.code == "상담 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        //this.searchParams.fromLastCrm1 = Utils.getNgbDate(-4);
        //this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        //console.log("end noga!!!!111111111");
      }
      if (this.idx == "lapsedpendingdoc1") {
        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "상담 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
        this.searchParams.fromLastCrm1 = Utils.getNgbDate(-61); //undefined;
          this.searchParams.toLastCrm1 = undefined;
          //console.log("end noga!!!!222222222222");
      }
      0124 */
      if (
        this.idx == "lapsedpendingdoc60" ||
        //this.idx == "lapsedpendingdoc0" ||
        //this.idx == "lapsedpendingdoc1" ||
        //this.idx == "lapsedpendingdoc2" ||
        //this.idx == "lapsedpendingdoc3" ||
        this.idx == "lapsedpendingdoc4" ||
        this.idx == "lapsedpendingdoc5" ||
        this.idx == "lapsedpendingdoc6"
      ) {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "3_PS") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        if (this.idx == "lapsedpendingdoc60") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        //} else if (this.idx == "lapsedpendingdoc0") {
        //  this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        //} else if (this.idx == "lapsedpendingdoc1") {
        //  this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
        //} else if (this.idx == "lapsedpendingdoc2") {
        //  this.searchParams.fromLastCrm1 = Utils.getNgbDate(-90);
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        //} else if (this.idx == "lapsedpendingdoc3") {
        //  this.searchParams.fromLastCrm1 = undefined;
        //  this.searchParams.toLastCrm1 = Utils.getNgbDate(-91);
        } else if (this.idx == "lapsedpendingdoc4") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        } else if (this.idx == "lapsedpendingdoc5") {
          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
        } else if (this.idx == "lapsedpendingdoc6") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        }
      }

      if (
        this.idx == "lapsedconoffer60" ||
        /*this.idx == "lapsedconoffer0" ||
        this.idx == "lapsedconoffer1" ||*/
        this.idx == "lapsedconoffer2" ||
        this.idx == "lapsedconoffer3" ||
        this.idx == "lapsedconoffer4" ||
        this.idx == "lapsedconoffer5" ||
        this.idx == "lapsedconoffer0"
      ) {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "4_CO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        if (this.idx == "lapsedconoffer60") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        /*} else if (this.idx == "lapsedconoffer0") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        } else if (this.idx == "lapsedconoffer1") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
          */
        } else if (this.idx == "lapsedconoffer2") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-90);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        } else if (this.idx == "lapsedconoffer3") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-91);
        } else if (this.idx == "lapsedconoffer4") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        } else if (this.idx == "lapsedconoffer5") {
          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
        } /*else if (this.idx == "lapsedconoffer6") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        }*/
      }

      if (
        this.idx == "lapsedunconoffer60" ||
        /*this.idx == "lapsedunconoffer0" ||
        this.idx == "lapsedunconoffer1" ||*/
        this.idx == "lapsedunconoffer2" ||
        this.idx == "lapsedunconoffer3" ||
        this.idx == "lapsedunconoffer4" ||
        this.idx == "lapsedunconoffer5" ||
        this.idx == "lapsedunconoffer6"
      ) {
        this.codeCommon.adminStatusList.forEach((x) => {
          if (x.code == "6_UO") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.selectedEp != "All") {
          var selectedEps = this.selectedEp.split(",");

          this.codeCommon.adminEpList.forEach((x) => {
            x.checked = false;
          });

          this.codeCommon.adminEpList.forEach((x) => {
            selectedEps.forEach(function (value) {
              //console.log(value);
              if (x.code == value) {
                x.checked = true;
              }
            });
          });
        }

        if (this.idx == "lapsedunconoffer60") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        /*} else if (this.idx == "lapsedunconoffer0") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(0);
        } else if (this.idx == "lapsedunconoffer1") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-60);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
          */
        } else if (this.idx == "lapsedunconoffer2") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-90);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-61);
        } else if (this.idx == "lapsedunconoffer3") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-91);
        } else if (this.idx == "lapsedunconoffer4") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        } else if (this.idx == "lapsedunconoffer5") {
          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-1);
        } else if (this.idx == "lapsedunconoffer6") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = undefined;
        }
      }
    }

    // dashboard ends //

    this.searchAdmission();
  }

  setDefaultFilter() {
    this.codeCommon.adminStatusList.forEach((x) => {
      if (
        x.code == "1_RO" ||
        x.code == "2_AS" ||
        x.code == "3_PS" ||
        x.code == "4_CO" ||
        x.code == "6_UO" ||
        x.code == "Admin Holding"
      ) {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  setDefaultAdminEP() {
    if (this.authority == "admin" || this.authority == "accountant") {
      this.codeCommon.adminEpList.forEach((x) => {
        if (x.code == this.name) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    }
  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    //console.log("pageInfo.offset : " + pageInfo.offset);

    this.rows = new Array<Admission>();
    this.page = new Page();
    this.page.pageNumber = pageInfo.offset;
    this.page.size = fixedSize;

    this.search();
  }

  searchAdmission() {
    this.rows = new Array<Admission>();
    this.page = new Page();

    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    if (this.searchParams.fromNextCrm1 != undefined)
      this.searchParams.fromNextCrm = Utils.formatDateStr(
        this.searchParams.fromNextCrm1
      );
    else if (this.action == "home" && this.idx == "7")
      this.searchParams.fromNextCrm = "2000-01-01";
    else this.searchParams.fromNextCrm = "";
    if (this.searchParams.toNextCrm1 != undefined)
      this.searchParams.toNextCrm = Utils.formatDateStr(
        this.searchParams.toNextCrm1
      );
    else this.searchParams.toNextCrm = "";
    if (this.searchParams.fromIntakeDate1 != undefined)
      this.searchParams.fromIntakeDate = Utils.formatDateStr(
        this.searchParams.fromIntakeDate1
      );
    else this.searchParams.fromIntakeDate = "";
    if (this.searchParams.toIntakeDate1 != undefined)
      this.searchParams.toIntakeDate = Utils.formatDateStr(
        this.searchParams.toIntakeDate1
      );
    else this.searchParams.toIntakeDate = "";
    if (this.searchParams.fromLastCrm1 != undefined)
      this.searchParams.fromLastCrm = Utils.formatDateStr(
        this.searchParams.fromLastCrm1
      );
    else this.searchParams.fromLastCrm = "";
    if (this.searchParams.toLastCrm1 != undefined)
      this.searchParams.toLastCrm = Utils.formatDateStr(
        this.searchParams.toLastCrm1
      );
    else this.searchParams.toLastCrm = "";
    if (this.searchParams.fromCourseEndDate1 != undefined)
      this.searchParams.fromCourseEndDate = Utils.formatDateStr(
        this.searchParams.fromCourseEndDate1
      );
    else this.searchParams.fromCourseEndDate = "";
    if (this.searchParams.toCourseEndDate1 != undefined)
      this.searchParams.toCourseEndDate = Utils.formatDateStr(
        this.searchParams.toCourseEndDate1
      );
    else this.searchParams.toCourseEndDate = "";
    if (this.searchParams.fromHoldingDate1 != undefined)
      this.searchParams.fromHoldingDate = Utils.formatDateStr(
        this.searchParams.fromHoldingDate1
      );
    else this.searchParams.fromHoldingDate = "";
    if (this.searchParams.toHoldingDate1 != undefined)
      this.searchParams.toHoldingDate = Utils.formatDateStr(
        this.searchParams.toHoldingDate1
      );
    else this.searchParams.toHoldingDate = "";

    this.search();
  }

  search() {    
    this.searchParams.ciMemberCategory = this.codeCommon.memberCategoryList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciStage = this.codeCommon.stageList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.cpSubAgent = this.codeCommon.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    //this.searchParams.cpEp                = this.epList.filter(opt => opt.checked).map(opt => opt.code);

    
    this.searchParams.adminEp = (this.codeCommon.adminEpList || [])
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    
    

    this.searchParams.adminAgent = this.codeCommon.adminAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.schoolType = this.codeCommon.schoolTypeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.school = this.codeCommon.schoolNameListIncFalse
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.baseCourse1 = this.codeCommon.courseList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);     
    this.searchParams.adminStatus = this.codeCommon.adminStatusList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

    this.displaySearchValue = "";
    if (this.searchParams.ciMemberCategory.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " M Category in " +
        JSON.stringify(
          this.codeCommon.memberCategoryList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciStage.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Stage in " +
        JSON.stringify(
          this.codeCommon.stageList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.adminEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Admin EP in " +
        JSON.stringify(
          this.codeCommon.adminEpList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.adminAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Admin Agent in " +
        JSON.stringify(
          this.codeCommon.adminAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.schoolType.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School Type in " +
        JSON.stringify(
          this.codeCommon.schoolTypeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.school.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School in " +
        JSON.stringify(
          this.codeCommon.schoolNameListIncFalse
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.baseCourse1.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Course in " +
        JSON.stringify(
          this.codeCommon.courseList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.cpSubAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Sub Agent in " +
        JSON.stringify(
          this.codeCommon.subAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    if (this.searchParams.adminStatus.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Status in " +
        JSON.stringify(
          this.codeCommon.adminStatusList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    if (
      this.authority == "admin" ||
      this.authority == "accountant" ||
      this.authority == "manager"
    ) {
      this.searchParams.cpEp = this.codeCommon.epList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    } else {
      this.searchParams.cpEp = this.codeCommon.epList
        .filter((opt) => opt.value == this.name)
        .map((opt) => opt.code);
    }

    if (this.searchParams.cpEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " EP in " +
        JSON.stringify(
          this.codeCommon.epList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    this.loadingIndicator = true;
    this.studentCount = 0;
    this.schoolCount = 0;
    this.selected = [];
    //console.log('noga777 this.codeCommon.adminEpList:'+ this.codeCommon.adminEpList.filter((item) => item.checked).map((item) => item.value).join(","));
    //console.log("noga 333333333333333 this.searchParams : ", this.searchParams);
    this.admissionService
      .searchAdmission(this.searchParams, this.page)
      .subscribe(
        (data) => {
          this.mySet = new Collections.Set<number>();
          this.mySet2 = new Collections.Set<string>();
          // this.mySet3             = new Collections.Set<number>();
                    
          data.content.forEach((admission) => {
            //if(!this.mySet3.contains(admission.id)) {
            this.rows.push(admission);
            //}
            this.mySet.add(admission.basicId);
            this.mySet2.add(admission.basicId + "|" + admission.school);

            //this.mySet3.add(admission.id);
          });

          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;

          this.rows = [...this.rows];          //this.loadingIndicator   = false;
          setTimeout(() => {
            this.loadingIndicator = false;
          }, 1500);
          this.studentCount = this.mySet.size();
          this.schoolCount = this.mySet2.size();
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          } else {
            setTimeout(() => {
              this.loadingIndicator = false;
            }, 1500);
          }
        }
      );
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(obj) {
    if (obj.type === "click" && obj.event.altKey) {
      window.open(
        `${window.location.origin}/admissionDetail/${obj.row.basicId}`,
        "_blank"
      );
    } else if (obj.type === "dblclick") {
      //console.log('Activate Event', obj);
      //console.log('Type', obj.type);
      //console.log('basicId', obj.row.basicId);
      this.codeService.keepSearchParams("3", this.searchParams);
      this.router.navigate(["/admissionDetail", obj.row.basicId, obj.row.id]);
    }
  }

  onScroll(obj) {
    //console.log("onScroll : " + JSON.stringify(obj));
  }

  onFilter(column) {
    this.selectedColumn = column;
    if (column == "M Category") {
      this.filterList = this.codeCommon.memberCategoryList;
    } else if (column == "Stage") {
      this.filterList = this.codeCommon.stageList;
    } else if (column == "SubAgent") {
      //this.filterList = this.codeCommon.subAgentList;
      this.filterList = this.codeService.codeCommon.subAgentList.filter(
        (code) => this.rows.some((x) => x.cpSubAgent == code.code)
      );
    } else if (column == "EP") {
      this.filterList = this.codeCommon.epList;
    } else if (column == "Admin EP") {
      this.filterList = this.codeCommon.adminEpList;
    } else if (column == "Admin Agent") {
      this.filterList = this.codeCommon.adminAgentList;
    } else if (column == "School Type") {
      this.filterList = this.codeCommon.schoolTypeList;
    } else if (column == "School") {
      //this.filterList = this.codeCommon.schoolNameList;

      this.filterList =
        this.codeService.codeCommon.schoolNameListIncFalse.filter((code) =>
          this.rows.some((x) => x.school == code.code)
        );
    } else if (column == "Course") {
      //this.filterList = this.codeCommon.courseList;

      this.filterList = this.codeService.codeCommon.courseList.filter((code) =>
        this.rows.some((x) => x.baseCourse1 == code.code)
      );
    } else if (column == "Adm Status") {
      this.filterList = this.codeCommon.adminStatusList;
    } else {
      this.filterList = [];
    }

    if (
      Array.isArray(this.filterList) && 
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
      }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "M Category") {
      this.codeCommon.memberCategoryList = this.filterList;
    } else if (this.selectedColumn == "Stage") {
      this.codeCommon.stageList = this.filterList;
    } else if (this.selectedColumn == "SubName") {
      //this.codeCommon.subAgentList = this.filterList;

      this.codeCommon.subAgentList.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "EP") {
      this.codeCommon.epList = this.filterList;
    } else if (this.selectedColumn == "Admin EP") {
      this.codeCommon.adminEpList = this.filterList;
    } else if (this.selectedColumn == "Admin Agent") {
      this.codeCommon.adminAgentList = this.filterList;
    } else if (this.selectedColumn == "School Type") {
      this.codeCommon.schoolTypeList = this.filterList;
    } else if (this.selectedColumn == "School") {
      //this.codeCommon.schoolNameList = this.filterList;

      this.codeCommon.schoolNameListIncFalse.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "Course") {
      //this.codeCommon.courseList = this.filterList;

      this.codeCommon.courseList.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "Adm Status") {
      this.codeCommon.adminStatusList = this.filterList;
    }

    this.filterModal.hide();
    this.searchAdmission();
  }

  onPage(event) {}

  setDefaultParams() {
    this.searchParams = new AdmissionSearchParams();
    this.codeCommon = this.codeService.getOrg();
    
    this.setDefaultFilter();
    this.setDefaultAdminEP();
    this.searchAdmission();
  }

  exportAsCSV() {
    const columns: TableColumn[] = this.dataTable._internalColumns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "admission",
      useBom: true,
    };
    return new Angular2Csv(rows, "admission", options);
  }

  toggleExpandRow(row) {
    row.expanded = !row.expanded;
    this.dataTable.rowDetail.toggleExpandRow(row);
  }
}
