import { NgModule        } from '@angular/core';
import { RouterModule    } from '@angular/router';
import { BrowserModule   } from '@angular/platform-browser';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule
  ],
  declarations: [
    HeaderComponent,
  ],
  exports: [
    HeaderComponent,
  ]
})
export class HeaderModule {
}
