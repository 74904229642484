export class AccountingMemo {
  id?:number;
  basicId?:number;
  adminId?:number;
  crmMethod?:string;
  crmType?:string;
  memo?:string;
  ep?:string;
  emailAlarm?:string;
  createDate?:string;
  saveDate?:string;  
  createUser?:string;
  saveUser?:string;
  recSchoolType?:string;
  recSchool?:string;
  recCourse?:string;
  recMajor?:string;
  recStartDate?:string;
  recStatus?:string;
  adminEp?:string;  


  crmMethodName?:string;  
  epName?:string;  
  adminEpName?:string;  
  mmAlarmDate?:string;
  
  keyMemo?:boolean;
  adminStatus?:string;  
  
  isSendToAdminByEmail?:boolean;
  accountingNote?:string;
  accEp?:string;  
  
  isVisibleToAgent?:boolean;
}

export class AccountingMemoParams extends AccountingMemo {
  
}
