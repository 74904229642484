import { SchoolUpdate } from "./school-update";
import { SchoolGdlinks } from "./school-gdlinks";
import { SchoolFiles     } from './school-files';

export class School {
    id: number;
    name?: string;
    address?: string;
    location?: string;
    region?: string | string[];
    phone?: string;
    fax?: string;
    email?: string;
    website?: string;
    display?: boolean;
    schoolInfo?: string;
    schoolContent?: string;
    generalManagerInfo?: string;
    admissionStaffInfo?: string;
    invoiceStaffInfo?: string;
    adminType?: string;
    keyAdminInfo?: string;
    applicationFee?: string;
    internalPortal?: string;
    adminNote?: string;
    agentChange?: string;
    agentChangeYN?: boolean;
    territoryRestrict?: string;
    territoryRestrictYN?: boolean;
    under18?: string;
    under18YN?: boolean;
    crmRate?: number;
    accountEmail?: string;
    commissionRate?: string;
    commissionClaim?: string;
    createDate?: string;
    createUser?: string;
    updateDate?: string;
    updateUser?: string;
    courseType?: string;
    courseTypes?: string;
    code?: string;
    schoolUpdateList?: SchoolUpdate[] = [];
    schoolGdlinksList?: SchoolGdlinks[] = [];
    
    schoolFilesParams = new Array<SchoolFiles>();
}

export class SchoolParams extends School {
}


