import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { PageRequest, UserParams } from "../model/dto";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/user`;
const defaultPageRequest: PageRequest = { page: 1, size: 5 };

@Injectable()
export class UserService {
  constructor(private http: JsonHttp) {}

  getAll(searchParams) {
    let params: string = `${url}?`;

    searchParams.isActive.forEach(
      (code) => (params = params + "&isActive=" + code)
    );

    return this.http.get(params);
  }

  getMembers(searchParams) {
    let params: string = `${url}?type=member`;

    searchParams.isActive.forEach(
      (code) => (params = params + "&isActive=" + code)
    );

    return this.http.get(params);
  }

  getSelf() {
    return this.http.get(`${url}/self`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: UserParams) {
    return this.http.post(url, params);
  }

  update(params: UserParams) {
    return this.http.put(url, params);
  }

  delete(params: UserParams) {
    return this.http.delete(url, params);
  }

  getView(name) {
    return this.http.get(`${url}/view?name=${name}`);
  }

  //    private helper methods

  //    private jwt() {
  //        // create authorization header with jwt token
  //        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  //        if (currentUser && currentUser.token) {
  //            let headers = new Headers({ 'Authorization': 'Bearer ' + currentUser.token });
  //            return new RequestOptions({ headers: headers });
  //        }
  //    }
}
