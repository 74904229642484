import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { UserTargetParams } from "../model/user-target";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/usertarget`;

@Injectable()
export class UserTargetService {
  constructor(private http: JsonHttp) {}

  search(searchParams, page) {
    let params: string = `${url}?name=${searchParams.name}&year=${searchParams.year}&page=${page.pageNumber}&size=${page.size}`;

    ////console.log(params);
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: UserTargetParams) {
    return this.http.post(url, params);
  }

  update(params: UserTargetParams) {
    return this.http.put(url, params);
  }

  delete(params: UserTargetParams) {
    return this.http.delete(url, params);
  }
}
