import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopupModule } from 'ng2-opd-popup';
import { DetailMenuModule } from '../detail/detail-menu.module';
import { RestrictInputDirectveModule } from '../../shared/directive/restrict-input-directive.module';
import { SchoolComponent } from './school.component';
import { PipesModule } from '../../shared/pipe/pipe.module';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { InformationBoardModule } from '../information/information-board.module';
import { FileUploadModule } from 'ng2-file-upload/file-upload/file-upload.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    NgxDatatableModule,
    ModalModule.forRoot(),

    NgbModule,
    PopupModule.forRoot(),
    DetailMenuModule,
    RestrictInputDirectveModule,
    PipesModule,
    MultiselectDropdownModule,
    InformationBoardModule,
    FileUploadModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  declarations: [
    SchoolComponent
  ],
  exports: [
    SchoolComponent
  ]
})
export class SchoolModule {



}
