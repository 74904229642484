import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { FollowupParams } from "../model/followup";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/followup`;

@Injectable()
export class FollowupService {
  constructor(private http: JsonHttp) {}

  searchFollowupNew(searchParams, page) {
    let params: string = `${url}/new?searchKey=${searchParams.searchKey1}&searchValue=${searchParams.searchValue1}&fromRegDate=${searchParams.fromRegDate1}&toRegDate=${searchParams.toRegDate1}&fromNextCrm=${searchParams.fromNextCrm1}&toNextCrm=${searchParams.toNextCrm1}&whyMember=${searchParams.whyMember}&page=${page.pageNumber}&size=${page.size}`;

    searchParams.ciSubAgent.forEach(
      (code) => (params = params + "&ciSubAgent=" + code)
    );
    searchParams.ciMemberField.forEach(
      (code) => (params = params + "&ciMemberField=" + code)
    );
    searchParams.ciMemberCategory.forEach(
      (code) => (params = params + "&ciMemberCategory=" + code)
    );
    searchParams.ciEp.forEach((code) => (params = params + "&ciEp=" + code));
    searchParams.ciStage.forEach(
      (code) => (params = params + "&ciStage=" + code)
    );
    searchParams.mmAlarmLevel.forEach(
      (code) => (params = params + "&mmAlarmLevel=" + code)
    );
    searchParams.school.forEach(
      (code) => (params = params + "&school=" + code)
    );

    //console.log(params);

    return this.http.get(params);
  }

  searchFollowupEnrolled(searchParams, page) {
    let params: string = `${url}/enrolled?searchKey=${searchParams.searchKey2}&searchValue=${searchParams.searchValue2}&fromRegDate=${searchParams.fromRegDate2}&toRegDate=${searchParams.toRegDate2}&fromNextCrm=${searchParams.fromNextCrm2}&toNextCrm=${searchParams.toNextCrm2}&lapsed=${searchParams.lapsed}&fromLapsed=${searchParams.fromLapsed}&toLapsed=${searchParams.toLapsed}&page=${page.pageNumber}&size=${page.size}`;

    searchParams.ciSubAgent.forEach(
      (code) => (params = params + "&ciSubAgent=" + code)
    );
    searchParams.ciMemberField.forEach(
      (code) => (params = params + "&ciMemberField=" + code)
    );
    searchParams.ciMemberCategory.forEach(
      (code) => (params = params + "&ciMemberCategory=" + code)
    );
    searchParams.ciEp.forEach((code) => (params = params + "&ciEp=" + code));
    searchParams.ciStage.forEach(
      (code) => (params = params + "&ciStage=" + code)
    );
    searchParams.mmAlarmLevel.forEach(
      (code) => (params = params + "&mmAlarmLevel=" + code)
    );
    searchParams.school.forEach(
      (code) => (params = params + "&school=" + code)
    );

    //console.log(params);

    return this.http.get(params);
  }

  getList(id: number) {
    return this.http.get(`${url}/basic/${id}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: FollowupParams) {
    return this.http.post(url, params);
  }

  update(params: FollowupParams) {
    return this.http.put(url, params);
  }

  delete(params: FollowupParams) {
    return this.http.delete(url, params);
  }
}
