import { NgbDateStruct          } from '@ng-bootstrap/ng-bootstrap';

export class FollowupSearchParams {
    public searchKey1?      :string = "Name";
    public searchValue1?    :string = "";
    public whyMember?       :string = "";    
    public fromRegDate1?    :string = "";    
    public toRegDate1?      :string = "";  
    public fromNextCrm1?    :string = "";  
    public toNextCrm1?      :string = "";  
    
    public searchKey2?      :string = "Name";
    public searchValue2?    :string = "";  
    public fromRegDate2?    :string = "";    
    public toRegDate2?      :string = "";  
    public fromNextCrm2?    :string = "";  
    public toNextCrm2?      :string = "";     
    public lapsed?          :string = "";  
    public fromLapsed?      :string = "";  
    public toLapsed?        :string = "";
    
    public ciSubAgent       :any[];
    public ciMemberField    :any[];
    public ciMemberCategory :any[];
    public ciEp             :any[];
    public ciStage          :any[];
    public mmAlarmLevel     :any[];
    public school           :any[];    
    
  
    public fromRegDate11?    :NgbDateStruct;    
    public toRegDate11?      :NgbDateStruct;  
    public fromNextCrm11?    :NgbDateStruct;  
    public toNextCrm11?      :NgbDateStruct; 
    
    public fromRegDate21?    :NgbDateStruct;    
    public toRegDate21?      :NgbDateStruct;  
    public fromNextCrm21?    :NgbDateStruct;  
    public toNextCrm21?      :NgbDateStruct;     

}

export class Followup {
    id:string|number;   
    name?:string;
    mdNameOrg?:string;
    mdNameEng?:string;
    mdNameEngFamily?:string;
    csGrade?:string;
    csGradeName?:string;    
    vsCurrentStatus?:string;
    vsCurrentStatusName?:string;    
    ciSubAgent?:string;
    ciSubAgentName?:string;    
    mdEMail?:string;
    ciMemberField?:string;
    ciMemberFieldName?:string;      
    ciMemberCategory?:string;
    ciMemberCategoryName?:string;    
    ciEp?:string;
    ciEpName?:string;    
    ciPriority?:string;
    ciPriorityName?:string;    
    ciStage?:string;
    ciStageName?:string;
    mmAlarmLevel?:string;
    mmAlarmLevelName?:string; 
    mmAlarmDate?:string;
    lapsed?:number;
    crmMethod?:string;
    crmMethodName?:string;
    mmLastcrmDate?:string;
    createDate?:string;
    globalNumber?:string;
    mdNzMobile?:string;
    memo?:string;
    fileCount?:number;
    mdKorMobile?:string;
    school?:string;
    schoolName?:string;
    endDate?:string;
    
    memberType?:string;
    changedEp?:string;
    memo1?:string;
}

export class FollowupParams extends Followup {
}


