import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AdmissionParams } from "../model/admission";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/admission`;

@Injectable()
export class AdmissionService {
  constructor(private http: JsonHttp) {}

  searchAdmission(searchParams, page) {
    let params: string = `${url}?searchKey=${searchParams.searchKey}&searchValue=${searchParams.searchValue}&fromHoldingDate=${searchParams.fromHoldingDate}&toHoldingDate=${searchParams.toHoldingDate}&fromNextCrm=${searchParams.fromNextCrm}&toNextCrm=${searchParams.toNextCrm}&fromLastCrm=${searchParams.fromLastCrm}&toLastCrm=${searchParams.toLastCrm}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCourseEndDate=${searchParams.fromCourseEndDate}&toCourseEndDate=${searchParams.toCourseEndDate}&page=${page.pageNumber}&size=${page.size}`;

    searchParams.ciMemberCategory.forEach(
      (code) => (params = params + "&ciMemberCategory=" + code)
    );
    searchParams.ciStage.forEach(
      (code) => (params = params + "&ciStage=" + code)
    );
    searchParams.cpSubAgent.forEach(
      (code) => (params = params + "&cpSubAgent=" + code)
    );
    searchParams.cpEp.forEach((code) => (params = params + "&cpEp=" + code));
    searchParams.adminEp.forEach(
      (code) => (params = params + "&adminEp=" + code)
    );
    searchParams.adminAgent.forEach(
      (code) => (params = params + "&adminAgent=" + code)
    );
    searchParams.schoolType.forEach(
      (code) => (params = params + "&schoolType=" + code)
    );
    searchParams.school.forEach(
      (code) => (params = params + "&school=" + code)
    );
    searchParams.baseCourse1.forEach(
      (code) => (params = params + "&baseCourse1=" + code)
    );
    searchParams.adminStatus.forEach(
      (code) => (params = params + "&adminStatus=" + code)
    );

    //console.log(params);
    return this.http.get(params);
  }

  getList(id: number) {
    return this.http.get(`${url}/basic/${id}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: AdmissionParams) {
    return this.http.post(url, params);
  }

  update(params: AdmissionParams) {
    return this.http.put(url, params);
  }

  updateStatus(status) {
    return this.http.put(`${url}/adminStatus`, status);
  }

  delete(params: AdmissionParams) {
    return this.http.delete(url, params);
  }
}
