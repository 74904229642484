import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Profile } from "app/model/profile";
import * as _ from "lodash";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { ApplicationSearchParams } from "../../model/application";
import { CodeCommon } from "../../model/code-common";
import { Counselling } from "../../model/counselling";
import { Page } from "../../model/page";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { CounsellingService } from "../../services/counselling.service";

@Component({
  selector: "app-counselling",
  providers: [CounsellingService],
  templateUrl: "./counselling.component.html",
})
export class CounsellingComponent implements OnInit, OnChanges {
  lodash = _;

  @Input() parentData;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  public pageSize = 20;
  public page = new Page();
  public rows = new Array<Counselling>();
  public userId: number = 0;
  public searchParams = new ApplicationSearchParams();
  public selectedRow = new Counselling();

  @ViewChild("Modal") public modal: ModalDirective;

  selectedOffSet: number = 0;
  authority: string;
  name: string;
  company: string;
  inboxTab: boolean = true;
  sentTab: boolean = false;
  addEventClickCount: number;

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private counsellingService: CounsellingService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    this.searchParams.realType = "TA";
    this.searchParams.kname = "";
    this.searchParams.email = "";
    this.searchParams.answered = this.sentTab;

    codeService.getCommon(this);
    this.search();
  }

  ngOnChanges(changes: SimpleChanges) {}

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage ===
      "Either Name/Phone No or Email is inconsistent. Pls update the existing details."
    ) {
      this.addEventClickCount = 1;
    } else if (
      this.popupMessage ===
      "Either Name/Phone No or Email is Still inconsistent, but I will keep the existing Name/Phone No/Email."
    ) {
      this.addEventClickCount = 0;
    }
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.search();
  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
    this.addEventClickCount = 0;
  }

  optionChange(varX: string) {
    this.searchParams.realType = varX;
    this.selectedOffSet = 0;
    this.search();
  }

  searchResetPage() {
    this.searchParams.realType = "TA";
    this.selectedOffSet = 0;
    this.search();
  }

  setPage(pageInfo) {
    this.selectedOffSet = pageInfo.offset;
    this.search(pageInfo.offset);
  }


  onDelete() {
    if (this.selectedRow == undefined || this.selectedRow.id == undefined)
      return;

    this.counsellingService.delete(this.selectedRow).subscribe(
      (data) => {
        console.log("delete suessful");
        this.search(this.selectedOffSet);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelect({ selected }) {
    //console.log('Select Application', selected, this.selected);
    this.addEventClickCount = 0;
  }

  onActivate(obj) {
    if (obj.type === "click") {
      this.selectedRow = obj.row;
    } else if (obj.type == "dblclick") {
      this.selectedRow = obj.row;
      this.modal.show();
    }
  }

  hideModal() {
    this.modal.hide();
  }

  answer() {
    let values = _.cloneDeep(this.selectedRow);

    values.info = JSON.stringify(values.info);

    this.counsellingService.update(values).subscribe(
      (data) => {
        console.log("update suessful");
        this.modal.hide();
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }



  changeTab(toggle){
    this.selectedOffSet = 0;
    this.searchParams.answered = toggle;
    this.search();
  }





    search(pageNumber = this.selectedOffSet) {
      this.rows = new Array<Counselling>();
      this.page = new Page();
      this.page.pageNumber = pageNumber;
      this.page.size = this.pageSize;

      this.counsellingService.search(this.searchParams, this.page).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));

          this.rows = _.map(data.content, (d) => {
            d.info = JSON.parse(d.info);
            return d;
          });

          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
    }

}
