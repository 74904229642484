export class PersonalFilesSearchParams {
  public realType?: string;
  public kname?: string;
  public email?: string;
}

export class PersonalFiles {
  id: string | number;
  filename?: string;
  attachFile?: any[];
  createDate?: string;
  mode?: string;
  kind?: string;
  bucket?: string;
  filtered?: boolean;
  isShare?: boolean;
}

export class PersonalFilesParams {
  basicId?: number;
  basicFolderName?: string;
  adminList?= new Array<string>();
  personalFilesList = new Array<PersonalFiles>();
}
