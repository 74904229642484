import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
    name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string): any[] {
        if (!items) return [];
        if (_.isEmpty(value)) return items;

        return items.filter(it => _.lowerCase(<string>_.get(it, field)).indexOf(_.lowerCase(value)) > -1);
    }
}
