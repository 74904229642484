import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SchoolParams } from "../model/school";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/school`;

@Injectable()
export class SchoolService {
  constructor(private http: JsonHttp) {}

  getAll() {
    return this.http.get(url);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: SchoolParams) {
    return this.http.post(url, params);
  }

  update(params: SchoolParams) {
    return this.http.put(url, params);
  }

  delete(params: SchoolParams) {
    return this.http.delete(url, params);
  }
}
