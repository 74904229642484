export class BasicHistoryComment {
    id?:string|number;   
    basicId?:string|number;
    commentBody?:string;
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
}

export class BasicHistoryCommentParams extends BasicHistoryComment {
}
