import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import { Angular2Csv } from "angular2-csv";
import { Profile } from "app/model/profile";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import * as Collections from "typescript-collections";
import { CodeCommon } from "../../model/code-common";
import { Followup, FollowupSearchParams } from "../../model/followup";
import { Page } from "../../model/page";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { FollowupService } from "../../services/followup.service";
import Utils from "../../shared/utils";

const fixedSize = 15;
@Component({
  selector: "app-followup",
  providers: [FollowupService],
  templateUrl: "./followup.component.html",
})
export class FollowupComponent implements OnInit {
  public newPage = new Page();
  public enrolledPage = new Page();
  public newRows = new Array<Followup>();
  public enrolledRows = new Array<Followup>();
  public userId: number = 0;

  public searchParamsNew = new FollowupSearchParams();
  public searchParamsEnrolled = new FollowupSearchParams();

  datePipe = new DatePipe("en-US");

  codeCommonNew: CodeCommon;
  filterListNew: any[] = [];

  selectedAllNew: boolean = false;
  selectedColumnNew: string;
  @ViewChild("filterModalNew") public filterModalNew: ModalDirective;

  codeCommonEnrolled: CodeCommon;
  filterListEnrolled: any[] = [];

  selectedAllEnrolled: boolean = false;
  selectedColumnEnrolled: string;
  @ViewChild("filterModalEnrolled") public filterModalEnrolled: ModalDirective;

  @ViewChild("dataTableNew") public dataTableNew: DatatableComponent;
  @ViewChild("dataTableEnrolled") public dataTableEnrolled: DatatableComponent;

  authority: string;
  name: string;
  company: string;

  action: string;
  idx: string;
  tab: string;

  newTab: boolean = true;
  enrolledTab: boolean = false;

  loadingIndicator1: boolean = false;
  mySet1 = new Collections.Set<number>();
  studentCount1: number = 0;

  loadingIndicator2: boolean = false;
  mySet2 = new Collections.Set<number>();
  studentCount2: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private codeService: CodeService,
    private followupService: FollowupService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.newPage.pageNumber = 0;
    this.newPage.size = fixedSize;
    this.enrolledPage.pageNumber = 0;
    this.enrolledPage.size = fixedSize;

    this.route.queryParams.subscribe((params) => {
      this.action = params["action"];
      this.idx = params["idx"];
      this.tab = params["tab"];

      if (this.tab == "enrolled") {
        this.newTab = false;
        this.enrolledTab = true;
      } else {
        this.newTab = true;
        this.enrolledTab = false;
      }
      //codeService.getCommon(this);
      codeService.getEachCommon(this, "2");
    });
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.codeCommonNew = codeCommon;
    this.codeCommonEnrolled = codeCommon;

    this.search();
  }

  setSearchParams(searchParams) {
    //console.log("searchParams : " + searchParams);
  }

  setDefaultFilterNew() {
    this.codeCommonNew.stageList.forEach((x) => {
      if (x.code == "신규 멤버" || x.code == "상담 멤버") {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  setDefaultFilterEnrolled() {
    this.codeCommonEnrolled.stageList.forEach((x) => {
      if (x.code == "수속멤버") {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
  }

  search() {
    this.setDefaultFilterNew();
    this.setDefaultFilterEnrolled();

    this.searchFollowupNew();
    this.searchFollowupEnrolled();
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPageNew(pageInfo) {
    this.newRows = new Array<Followup>();
    this.newPage = new Page();
    this.newPage.pageNumber = pageInfo.offset;
    this.newPage.size = fixedSize;

    this.searchNew();
  }

  searchFollowupNew() {
    this.newRows = new Array<Followup>();
    this.newPage = new Page();

    this.newPage.pageNumber = 0;
    this.newPage.size = fixedSize;

    if (this.searchParamsNew.fromRegDate11 != undefined)
      this.searchParamsNew.fromRegDate1 = Utils.formatDateStr(
        this.searchParamsNew.fromRegDate11
      );
    else this.searchParamsNew.fromRegDate1 = "";
    if (this.searchParamsNew.toRegDate11 != undefined)
      this.searchParamsNew.toRegDate1 = Utils.formatDateStr(
        this.searchParamsNew.toRegDate11
      );
    else this.searchParamsNew.toRegDate1 = "";
    if (this.searchParamsNew.fromNextCrm11 != undefined)
      this.searchParamsNew.fromNextCrm1 = Utils.formatDateStr(
        this.searchParamsNew.fromNextCrm11
      );
    else this.searchParamsNew.fromNextCrm1 = "";
    if (this.searchParamsNew.toNextCrm11 != undefined)
      this.searchParamsNew.toNextCrm1 = Utils.formatDateStr(
        this.searchParamsNew.toNextCrm11
      );
    else this.searchParamsNew.toNextCrm1 = "";

    this.searchNew();
  }

  searchNew() {
    this.searchParamsNew.ciSubAgent = this.codeCommonNew.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsNew.ciMemberField = this.codeCommonNew.studyFieldList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsNew.ciMemberCategory =
      this.codeCommonNew.memberCategoryList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    this.searchParamsNew.ciEp = this.codeCommonNew.epList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsNew.ciStage = this.codeCommonNew.stageList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsNew.mmAlarmLevel = this.codeCommonNew.alarmLevelList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsNew.school = this.codeCommonNew.schoolNameList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

    this.loadingIndicator1 = true;
    this.followupService
      .searchFollowupNew(this.searchParamsNew, this.newPage)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.content : " + data.content.length);

          data.content.forEach((followup) => {
            //{{value.length > 200 ? value.substring(0,200) : value}}
            if (followup.memo != undefined) {
              followup.memo1 =
                followup.memo.length > 200
                  ? followup.memo.substring(0, 200)
                  : followup.memo;
            }

            if (
              followup.mdNzMobile != null &&
              followup.mdNzMobile.length > 1 &&
              followup.mdNzMobile.substr(0, 1) != "0"
            ) {
              followup.mdNzMobile = "0" + followup.mdNzMobile;
            }

            this.newRows.push(followup);
          });

          setTimeout(() => {
            this.loadingIndicator1 = false;
          }, 1500);
          //this.page.size = data.size;
          this.newPage.totalElements = data.totalElements;
          this.newPage.totalPages = data.totalPages;
          this.newPage.pageNumber = data.number;
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          } else {
            setTimeout(() => {
              this.loadingIndicator1 = false;
            }, 1500);
          }
        }
      );
  }

  onSelectNew({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onActivateNew(obj) {
    if (obj.type === "dblclick") {
      this.router.navigate(["/followupDetail", obj.row.id]);
    }
  }

  setPageEnrolled(pageInfo) {
    this.enrolledRows = new Array<Followup>();
    this.enrolledPage = new Page();
    this.enrolledPage.pageNumber = pageInfo.offset;
    this.enrolledPage.size = fixedSize;

    this.searchEnrolled();
  }

  searchFollowupEnrolled() {
    this.enrolledRows = new Array<Followup>();
    this.enrolledPage = new Page();

    this.enrolledPage.pageNumber = 0;
    this.enrolledPage.size = fixedSize;

    if (this.searchParamsEnrolled.fromRegDate21 != undefined)
      this.searchParamsEnrolled.fromRegDate2 = Utils.formatDateStr(
        this.searchParamsEnrolled.fromRegDate21
      );
    else this.searchParamsEnrolled.fromRegDate2 = "";
    if (this.searchParamsEnrolled.toRegDate21 != undefined)
      this.searchParamsEnrolled.toRegDate2 = Utils.formatDateStr(
        this.searchParamsEnrolled.toRegDate21
      );
    else this.searchParamsEnrolled.toRegDate2 = "";
    if (this.searchParamsEnrolled.fromNextCrm21 != undefined)
      this.searchParamsEnrolled.fromNextCrm2 = Utils.formatDateStr(
        this.searchParamsEnrolled.fromNextCrm21
      );
    else this.searchParamsEnrolled.fromNextCrm2 = "";
    if (this.searchParamsEnrolled.toNextCrm21 != undefined)
      this.searchParamsEnrolled.toNextCrm2 = Utils.formatDateStr(
        this.searchParamsEnrolled.toNextCrm21
      );
    else this.searchParamsEnrolled.toNextCrm2 = "";

    this.searchEnrolled();
  }

  searchEnrolled() {
    this.searchParamsEnrolled.ciSubAgent = this.codeCommonEnrolled.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsEnrolled.ciMemberField =
      this.codeCommonEnrolled.studyFieldList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    this.searchParamsEnrolled.ciMemberCategory =
      this.codeCommonEnrolled.memberCategoryList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    this.searchParamsEnrolled.ciEp = this.codeCommonEnrolled.epList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsEnrolled.ciStage = this.codeCommonEnrolled.stageList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParamsEnrolled.mmAlarmLevel =
      this.codeCommonEnrolled.alarmLevelList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    this.searchParamsEnrolled.school = this.codeCommonEnrolled.schoolNameList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

    this.loadingIndicator2 = true;
    this.followupService
      .searchFollowupEnrolled(this.searchParamsEnrolled, this.enrolledPage)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          console.log("data.content : " + data.content.length);

          data.content.forEach((followup) => {
            if (followup.memo != undefined) {
              followup.memo1 =
                followup.memo.length > 200
                  ? followup.memo.substring(0, 200)
                  : followup.memo;
            }

            if (
              followup.mdNzMobile != null &&
              followup.mdNzMobile.length > 1 &&
              followup.mdNzMobile.substr(0, 1) != "0"
            ) {
              followup.mdNzMobile = "0" + followup.mdNzMobile;
            }

            this.enrolledRows.push(followup);
          });

          setTimeout(() => {
            this.loadingIndicator2 = false;
          }, 1500);
          //this.page.size = data.size;
          this.enrolledPage.totalElements = data.totalElements;
          this.enrolledPage.totalPages = data.totalPages;
          this.enrolledPage.pageNumber = data.number;
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          } else {
            setTimeout(() => {
              this.loadingIndicator2 = false;
            }, 1500);
          }
        }
      );
  }

  onSelectEnrolled({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onActivateEnrolled(obj) {
    if (obj.type === "dblclick") {
      this.router.navigate(["/followupDetail", obj.row.id]);
    }
  }

  onFilterNew(column) {
    this.selectedColumnNew = column;
    if (column == "SubName") {
      this.filterListNew = this.codeCommonNew.subAgentList;
    } else if (column == "Study Field") {
      this.filterListNew = this.codeCommonNew.studyFieldList;
    } else if (column == "M Category") {
      this.filterListNew = this.codeCommonNew.memberCategoryList;
    } else if (column == "EP") {
      this.filterListNew = this.codeCommonNew.epList;
    } else if (column == "Stage") {
      this.filterListNew = this.codeCommonNew.stageList;
    } else if (column == "Alarm_LV") {
      this.filterListNew = this.codeCommonNew.alarmLevelList;
    } else if (column == "Enrolled School") {
      this.filterListNew = this.codeCommonNew.schoolNameList;
    } else {
      this.filterListNew = [];
    }

    if (
      this.filterListNew.some(
        (x) => x.checked == undefined || x.checked == false
      )
    ) {
      this.selectedAllNew = false;
    } else {
      this.selectedAllNew = true;
    }

    this.filterModalNew.show();
  }

  selectAllNew() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterListNew.length; i++) {
      this.filterListNew[i].checked = this.selectedAllNew;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelectedNew() {
    this.selectedAllNew = this.filterListNew.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilterNew() {
    this.filterModalNew.hide();
  }

  onOkFilterNew() {
    if (this.selectedColumnNew == "SubName") {
      this.codeCommonNew.subAgentList = this.filterListNew;
    } else if (this.selectedColumnNew == "Study Field") {
      this.codeCommonNew.studyFieldList = this.filterListNew;
    } else if (this.selectedColumnNew == "M Category") {
      this.codeCommonNew.memberCategoryList = this.filterListNew;
    } else if (this.selectedColumnNew == "EP") {
      this.codeCommonNew.epList = this.filterListNew;
    } else if (this.selectedColumnNew == "Stage") {
      this.codeCommonNew.stageList = this.filterListNew;
    } else if (this.selectedColumnNew == "Alarm_LV") {
      this.codeCommonNew.alarmLevelList = this.filterListNew;
    } else if (this.selectedColumnNew == "Enrolled School") {
      this.codeCommonNew.schoolNameList = this.filterListNew;
    }

    this.filterModalNew.hide();
    this.searchFollowupNew();
  }

  onFilterEnrolled(column) {
    this.selectedAllEnrolled = false;

    this.selectedColumnEnrolled = column;
    if (column == "SubName") {
      this.filterListEnrolled = this.codeCommonEnrolled.subAgentList;
    } else if (column == "Study Field") {
      this.filterListEnrolled = this.codeCommonEnrolled.studyFieldList;
    } else if (column == "M Category") {
      this.filterListEnrolled = this.codeCommonEnrolled.memberCategoryList;
    } else if (column == "EP") {
      this.filterListEnrolled = this.codeCommonEnrolled.epList;
    } else if (column == "Stage") {
      this.filterListEnrolled = this.codeCommonEnrolled.stageList;
    } else if (column == "Alarm_LV") {
      this.filterListEnrolled = this.codeCommonEnrolled.alarmLevelList;
    } else if (column == "Enrolled School") {
      this.filterListEnrolled = this.codeCommonEnrolled.schoolNameList;
    } else {
      this.filterListEnrolled = [];
    }

    if (
      this.filterListEnrolled.some(
        (x) => x.checked == undefined || x.checked == false
      )
    ) {
      this.selectedAllEnrolled = false;
    } else {
      this.selectedAllEnrolled = true;
    }

    this.filterModalEnrolled.show();
  }

  selectAllEnrolled() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterListEnrolled.length; i++) {
      this.filterListEnrolled[i].checked = this.selectedAllEnrolled;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelectedEnrolled() {
    this.selectedAllEnrolled = this.filterListEnrolled.every(function (
      item: any
    ) {
      return item.checked == true;
    });
  }

  onCloseFilterEnrolled() {
    this.filterModalEnrolled.hide();
  }

  onOkFilterEnrolled() {
    if (this.selectedColumnEnrolled == "SubName") {
      this.codeCommonEnrolled.subAgentList = this.filterListEnrolled;
    } else if (this.selectedColumnEnrolled == "Study Field") {
      this.codeCommonEnrolled.studyFieldList = this.filterListEnrolled;
    } else if (this.selectedColumnEnrolled == "M Category") {
      this.codeCommonEnrolled.memberCategoryList = this.filterListEnrolled;
    } else if (this.selectedColumnEnrolled == "EP") {
      this.codeCommonEnrolled.epList = this.filterListEnrolled;
    } else if (this.selectedColumnEnrolled == "Stage") {
      this.codeCommonEnrolled.stageList = this.filterListEnrolled;
    } else if (this.selectedColumnEnrolled == "Alarm_LV") {
      this.codeCommonEnrolled.alarmLevelList = this.filterListEnrolled;
    } else if (this.selectedColumnEnrolled == "Enrolled School") {
      this.codeCommonEnrolled.schoolNameList = this.filterListEnrolled;
    }

    this.filterModalEnrolled.hide();
    this.searchFollowupEnrolled();
  }

  onPageNew(event) {}

  onPageEnrolled(event) {}

  setDefaultParamsNew() {
    this.searchParamsNew = new FollowupSearchParams();
    this.codeCommonNew = this.codeService.getOrg();
    this.setDefaultFilterNew();
    this.searchFollowupNew();
  }

  setDefaultParamsEnrolled() {
    this.searchParamsEnrolled = new FollowupSearchParams();
    this.codeCommonEnrolled = this.codeService.getOrg();
    this.setDefaultFilterEnrolled();
    this.searchFollowupEnrolled();
  }

  exportAsCSVNew() {
    const columns: TableColumn[] = this.dataTableNew.columns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTableNew.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "followup1",
      useBom: true,
    };
    return new Angular2Csv(rows, "followup1", options);
  }

  exportAsCSVEnrolled() {
    const columns: TableColumn[] = this.dataTableEnrolled.columns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTableEnrolled.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "followup2",
      useBom: true,
    };
    return new Angular2Csv(rows, "followup2", options);
  }
}
