import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
//import { RepositionScrollStrategyConfig } from '@angular/material/typings/core/overlay/scroll/reposition-scroll-strategy';
//import { start } from 'repl';

export class BasicSearchParams {
  public searchKey?: string = "Name";
  public searchValue?: string = "";
  public fromRegDate?: string = "";
  public toRegDate?: string = "";
  public fromNextCrm?: string = "";
  public toNextCrm?: string = "";
  public fromLastCrm?: string = "";
  public toLastCrm?: string = "";
  public fromArrivedDate?: string = "";
  public toArrivedDate?: string = "";
  public fromDob?: string = "";
  public toDob?: string = "";
  public evEvent?: string = "";
  public pickupStatus?: string = "";
  public pageNumber?: number = 0;

  public ciSubAgent: any[];
  public ciMemberField: any[];
  public ciMemberCategory: any[];
  public ciEp: any[];
  public ciStage: any[];
  public mmAlarmLevel: any[];
  public school: any[];
  public csGrade: any[];
  public vsVisaType: any[];

  public fromRegDate1?: NgbDateStruct;
  public toRegDate1?: NgbDateStruct;
  public fromNextCrm1?: NgbDateStruct;
  public toNextCrm1?: NgbDateStruct;
  public fromLastCrm1?: NgbDateStruct;
  public toLastCrm1?: NgbDateStruct;
  public fromArrivedDate1?: NgbDateStruct;
  public toArrivedDate1?: NgbDateStruct;

}

export class BasicMappingSearchParams {
  public mdNameOrg?: string = "";
  public mdEMail?: string = "";
  public mdKorMobile: string = "";
  public camKakaoId: string = "";
  public mdDob: string = "";
  public basicId: string | number = "";
}

export class Basic {
  id: string | number;
  name?: string;
  mdNameOrg?: string;
  mdNameEng?: string;
  mdNameEngFamily?: string;
  mdGender?: string;
  mdEMail?: string;
  mdKorMobile?: string;
  mdNzMobile?: string;
  mdNzPhoneHome?: string;
  mdKrPhoneHome?: string;
  mdOverseasAddress?: string;
  mdNzAddress?: string;
  mdDob?: string;
  mdKorSms?: string;
  camCafeNickName?: string;
  camCafeId?: string;
  camNateId?: string;
  camKakaoId?: string;
  camQqId?: string;
  hiName?: string;
  hiAddress?: string;
  hiMemo?: string;
  ciEp?: string;
  ciSubAgent?: string;
  ciSubAgentName?: string;
  ciMemberCategory?: string;
  ciMemberCategoryName?: string;
  ciMemberField?: string;
  ciMemberFieldName?: string;
  ciStage?: string;
  ciStageName?: string;
  ciPriority?: string;
  ciEstDate?: string;
  ciEstDateDisplay?: string;
  ciEstCheckNz?: string;
  vsPassportNo?: string;
  vsCurrentStatus?: string;
  vsIssueDate?: string;
  vsExpiryDate?: string;
  vsVisaType?: string;
  vsVisaTypeName?: string;
  vsVisaExpiry?: string;
  evEvent?: string;
  evRoute?: string;
  csCurrentSchool?: string;
  csSchoolType?: string;
  csExamType?: string;
  csGrade?: string;
  csGradeName?; string;
  csNsnNo?: string;
  csNote?: string;
  csStudentno?: string;
  csNsnPw?: string;
  candidateNo?: string;
  examType?: string;
  wishCountries?: string
  mmAlarmDate?: string;
  mmLastcrmDate?: string;
  mmAlarmLevel?: string;
  mmAlarmLevelName?: string;
  regDate?: string;
  courseExpiry?: string;
  asStartAirline?: string;
  asMidAirline?: string;
  asEndAirline?: string;
  asStartTime?: string;
  asMidTime?: string;
  asEndTime?: string;
  asStartDate?: string;
  asMidDate?: string;
  asEndDate?: string;
  asStartZone?: string;
  asMidZone?: string;
  asEndZone?: string;
  asPuEp?: string;
  createDate?: string;
  saveDate?: string;
  createUser?: string;
  saveUser?: string;
  visaExpiryisok?: string;
  oldStage?: string;
  mdEMail2?: string;
  memoSaveDate?: string;
  importantDetailMemo?: string;
  whyMember?: string;
  fileCount?: string | number;
  nationality?: string;
  asFlighthour?: string;
  asFlightmin?: string;
  controlStatic?: string;
  ncDate?: string;
  globalNumber?: string;
  globalNumberKor?: string;
  scheduleAf?: string;
  smsEnrollment?: string;
  smsVisa?: string;
  onlyVisibleForOnline?: string;
  scheduleQuickAf?: string;
  initialMemberCategory?: string;
  initialMemberCategoryName?: string;
  emailservicesent?: string;
  isActive?: boolean;
  endDate?: string;
  startingDate?: string;
  school?: string;
  schoolName?: string;
  lapsed?: number;
  adminCnt?: number;

  vsVisaType1?: string;
  vsVisaExpiry1?: string;
  vsVisaType2?: string;
  vsVisaExpiry2?: string;
  vsVisaType3?: string;
  vsVisaExpiry3?: string;
  vsVisaType4?: string;
  vsVisaExpiry4?: string;
  vsVisaType5?: string;
  vsVisaExpiry5?: string;

  extContact?: string;

  csNsnId?: string;
  mdEmergencyAddress?: string;

  TQH0schoolName?: string;
  TQH0major?: string;
  TQH0startDate?: string;
  TQH0endDate?: string;

  TQH1schoolName?: string;
  TQH1major?: string;
  TQH1startDate?: string;
  TQH1endDate?: string;

  TQH2schoolName?: string;
  TQH2major?: string;
  TQH2startDate?: string;
  TQH2endDate?: string;

  TQH3schoolName?: string;
  TQH3major?: string;
  TQH3startDate?: string;
  TQH3endDate?: string;

  TQH4schoolName?: string;
  TQH4major?: string;
  TQH4startDate?: string;
  TQH4endDate?: string;

  DSC0schoolName?: string;
  DSC0major?: string;
  DSC0startDate?: string;
  DSC0qualification: string;

  DSC1schoolName?: string;
  DSC1major?: string;
  DSC1startDate?: string;
  DSC1qualification: string;

  DSC2schoolName?: string;
  DSC2major?: string;
  DSC2startDate?: string;
  DSC2qualification: string;

  DSC3schoolName?: string;
  DSC3major?: string;
  DSC3startDate?: string;
  DSC3qualification: string;

  DSC4schoolName?: string;
  DSC4major?: string;
  DSC4startDate?: string;
  DSC4qualification?: string;

  currentSchoolStart?: string;
  currentSchoolEnd?: string;

  familyIds?: string;
  newToCounsellingDate?: string;
  familyPosition?: string;

  mInterest?: string;
  customerId?: string | number;
}

export class BasicParams extends Basic {
}
