import { NgModule               } from '@angular/core';
import { BrowserModule          } from '@angular/platform-browser';
import { DecimalMask            } from '../../shared/directive/decimal-mask.directive'; 

@NgModule({
  imports: [
    BrowserModule            
  ],
  declarations: [
    DecimalMask
  ],
  exports: [
    DecimalMask            
  ]
})
export class DirectiveCommonModule {
}
