import { Injectable } from "@angular/core";
import { Thread } from "app/model/thread";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { JsonHttp } from "./json-http";

export interface SearchResult<T> {
  content: T[];
  totalElements: number;
}

interface State {
  page: number;
  size: number;
  type: string;
  searchKey: string;
  searchValue: string;
}

@Injectable({ providedIn: "root" })
export class ThreadService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  public _search$ = new Subject<void>();
  private _content$ = new BehaviorSubject<Thread[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 0,
    size: 10,
    type: "",
    searchKey: "",
    searchValue: "",
  };

  constructor(private http: JsonHttp) {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result) => {
        this._content$.next(result.content);
        this._total$.next(result.totalElements);
      });

    this._search$.next();
  }


  public getData(){

  }

  get content$() {
    return this._content$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get size() {
    return this._state.size;
  }
  get type() {
    return this._state.type;
  }
  get searchKey() {
    return this._state.searchKey;
  }
  get searchValue() {
    return this._state.searchValue;
  }

  set page(page: number) {
    this._set({ page });
  }
  set size(size: number) {
    this._set({ size });
  }
  set type(type: string) {
    this._set({ type });
  }
  set searchKey(searchKey: string) {
    this._set({ searchKey });
  }
  set searchValue(searchValue: string) {
    this._set({ searchValue });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  public _search(): Observable<any> {
    let queryString = Object.keys(this._state)
      .map((key) => {
        if (this._state[key]) {
          return `${key}=${this._state[key]}`;
        }
      })
      .filter(Boolean)
      .join("&");
      //console.log("$$$$ querystring check :", queryString);
    return this.http.get(`${environment.api_url}/api/threads?${queryString}`);
  }
}
