import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { InformationBoardParams } from "../model/information-board";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/informationBoard`;

@Injectable()
export class InformationBoardService {
  constructor(private http: JsonHttp) {}

  searchInformationBoard(searchParams, page) {
    let params: string = `${url}?boardCategory=${searchParams.boardCategory}&boardCategoryMiddle=${searchParams.boardCategoryMiddle}&boardCategoryThird=${searchParams.boardCategoryThird}&boardCategoryFourth=${searchParams.boardCategoryFourth}&boardHead=${searchParams.boardHead}&page=${page.pageNumber}&size=${page.size}`;

    //console.log(params);
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: InformationBoardParams) {
    return this.http.post(url, params);
  }

  update(params: InformationBoardParams) {
    return this.http.put(url, params);
  }

  delete(params: InformationBoardParams) {
    return this.http.delete(url, params);
  }

  deleteByList(params) {
    return this.http.delete(`${url}/deletebylist`, params);
  }

  getHomeList1() {
    return this.http.get(`${url}/home1`);
  }

  getHomeList2(name) {
    return this.http.get(`${url}/home2?name=${name}`);
  }

  searchDailyReport(informationBoardParams: InformationBoardParams) {
    let params: string = `${url}/dailyreport?boardHead=${informationBoardParams.boardHead}`;

    //console.log(params);

    return this.http.get(params);
  }
}
