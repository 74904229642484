import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/dashboard`;

@Injectable()
export class DashboardService {
  constructor(private http: JsonHttp) {}

  getAllSales() {
    return this.http.get(`${url}/get_all_sales`);
  }

  getAccounting(searchParams) {
    let params: string = `${url}/view?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  getEPs(searchParams) {
    let params: string = `${url}/vieweps?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  //Admin -> Admin Lapsed and others
  getAdms(searchParams) {
    let params: string = `${url}/viewadm?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  getTarget(searchParams) {
    let params: string = `${url}/viewtarget?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  //Admin -> Today Done -> Done
  getTodayDone(searchParams) {
    let params: string = `${url}/viewtodaydone?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  //Admin -> EP -> Liz ... EP -> Kristy
  getAdmsByStudents(searchParams) {
    let params: string = `${url}/viewadmsbystudents?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  //Admin -> Your Admin Student, Your Admin No
  getAdmsByStudentsGroupByAdminEp(searchParams) {
    let params: string = `${url}/viewadmsbystudentsgroupbyadminep?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    //return this.http.get(params).pipe(map(response => response));
    return this.http.get(params);
  }

  //Admin -> Total Admin Stud, Total Admin No
  getAdmsByStudentsNoGroup(searchParams) {
    let params: string = `${url}/viewadmsbystudentsnogroup?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  getAdmsByStudentsGroupByCiEP(searchParams) {
    let params: string = `${url}/viewadmsbystudentsgroupbyciep?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }

  getFeematchingListByAccEP(searchParams) {
    let params: string = `${url}/viewfeematchinglistbyaccep?searchEP=${searchParams.searchEP}`;

    //console.log(params);

    return this.http.get(params);
  }
}
