import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts,
} from "angular-2-dropdown-multiselect";
import { Angular2Csv } from "angular2-csv";
import { Profile } from "app/model/profile";
// import * as Collections from 'typescript-collections';
// import * as moment from 'moment';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
//import { Code } from '../../model/dto';
import { AccountingSearchParams } from "../../model/accounting";
import { CodeCommon } from "../../model/code-common";
import { Statistics, StatisticsSearchParams } from "../../model/statistics";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
// import { DatePipe } from '@angular/common';
// import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StatisticsService } from "../../services/statistics.service";
import Utils from "../../shared/utils";

@Component({
  selector: "app-statistics",
  providers: [StatisticsService],
  templateUrl: "./statistics.component.html",
})
export class StatisticsComponent implements OnInit {
  filterList: any[] = [];
  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  @ViewChild("dataTable1") public dataTable1: DatatableComponent;
  @ViewChild("dataTable2") public dataTable2: DatatableComponent;
  @ViewChild("dataTable21") public dataTable21: DatatableComponent;
  @ViewChild("dataTable3") public dataTable3: DatatableComponent;
  @ViewChild("dataTable4") public dataTable4: DatatableComponent;

  public rows1 = new Array<Statistics>();
  public rows2 = new Array<Statistics>();
  public rows21 = new Array<Statistics>();
  public rows3 = new Array<Statistics>();
  public rows4 = new Array<Statistics>();
  public userId: number = 0;

  public totalRows1 = new Statistics();
  public totalRows2 = new Statistics();
  public totalRows21 = new Statistics();
  public totalRows3 = new Statistics();
  public totalRows4 = new Statistics();

  public searchParams1 = new StatisticsSearchParams();
  public searchParams2 = new StatisticsSearchParams();
  public searchParams21 = new StatisticsSearchParams();
  public searchParams3 = new StatisticsSearchParams();
  public searchParams4 = new StatisticsSearchParams();

  public accountingSearchParams = new AccountingSearchParams();

  codeCommon: CodeCommon;
  codeCommon1: CodeCommon;
  codeCommon2: CodeCommon;
  codeCommon21: CodeCommon;
  codeCommon3: CodeCommon;
  codeCommon4: CodeCommon;

  action: string;
  idx: string;
  tab: string;

  tab1: boolean = true;
  tab2: boolean = false;
  tab3: boolean = false;
  tab4: boolean = false;
  currentTab: string = "tab1";

  loadingIndicator1: boolean = false;
  loadingIndicator2: boolean = false;
  loadingIndicator21: boolean = false;
  loadingIndicator3: boolean = false;
  loadingIndicator4: boolean = false;

  optionsModel1: any[];
  optionsModel2: any[];
  optionsModel3: any[];
  optionsModel4: any[];

  myOptions1: IMultiSelectOption[] = [];
  myOptions2: IMultiSelectOption[] = [];
  myOptions3: IMultiSelectOption[] = [];
  myOptions4: IMultiSelectOption[] = [];

  epList2: any[] = [];

  //Settings configuration
  mySettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 4,
    displayAllSelectedText: false,
    buttonClasses: "btn btn-secondary",
  };

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  //Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: "Select all",
    uncheckAll: "Unselect all",
    checked: "EP selected",
    checkedPlural: "EPs selected",
    searchPlaceholder: "Find",
    searchEmptyResult: "Nothing found...",
    searchNoRenderText: "Type in search box to see results...",
    defaultTitle: "Selected EP",
    allSelected: "All selected",
  };

  yearList: any[] = [];

  authority: string;
  name: string;
  company: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private statisticsService: StatisticsService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    let thisYear = new Date().getFullYear();
    for (var idx = 0; idx < 7; idx++) {
      this.yearList.push({ code: thisYear - idx, value: thisYear - idx });
    }

    this.route.queryParams.subscribe((params) => {
      this.action = params["action"];
      this.idx = params["idx"];
      this.tab = params["tab"];

      //        if(this.tab == "ep") {
      //          this.tab1  = false;
      //          this.tab2  = true;
      //          this.tab3  = false;
      //          this.tab4  = false;
      //        } else {
      //          this.tab1  = false;
      //          this.tab2  = true;
      //          this.tab3  = false;
      //          this.tab4  = false;
      //        }
      codeService.getCommon(this);
    });
  }

  setCodeList(codeCommon: CodeCommon) {
    console.log("setCodeList.codeCommon", codeCommon);
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.codeCommon = codeCommon;
    this.codeCommon1 = JSON.parse(JSON.stringify(codeCommon));
    this.codeCommon2 = JSON.parse(JSON.stringify(codeCommon));
    this.codeCommon3 = JSON.parse(JSON.stringify(codeCommon));
    this.codeCommon4 = JSON.parse(JSON.stringify(codeCommon));

    let options = [];
    this.codeCommon1.epAllList.forEach((x) => {
      options.push(this.generateItem(x.code, x.value));
    });

    this.myOptions1 = options;
    this.myOptions2 = options;
    this.myOptions3 = options;
    this.myOptions4 = options;

    //this.search();
  }

  generateItem(id, value): IMultiSelectOption {
    return { id, name: value };
  }

  ngOnInit() {
    this.setSearch();
  }

  search() {
    if (this.currentTab == "tab1") {
      this.searchStatistics1();
    } else if (this.currentTab == "tab2") {
      this.searchStatistics2();
    } else if (this.currentTab == "tab3") {
      this.searchStatistics3();
    } else if (this.currentTab == "tab4") {
      this.searchStatistics4();
    }
  }

  searchStatistics1() {
    this.rows1 = new Array<Statistics>();
    this.search1();
  }

  searchStatistics2() {
    this.rows2 = new Array<Statistics>();
    this.rows21 = new Array<Statistics>();
    this.search2();
  }

  searchStatistics3() {
    this.rows3 = new Array<Statistics>();
    this.search3();
  }

  searchStatistics4() {
    this.rows4 = new Array<Statistics>();
    this.search4();
  }

  search1() {
    //    if(this.myOptions1.length > 0) {
    //      this.searchParams.ciEp = [];
    //      this.myOptions1.forEach(x => {
    //        this.searchParams.ciEp.push({code:x.id, value:x.name});
    //      });
    //    }
    //console.log('search1.searchParams1', this.searchParams1);
    if (this.searchParams1.fromAppliedDate1 != undefined)
      this.searchParams1.fromAppliedDate = Utils.formatDateStr(
        this.searchParams1.fromAppliedDate1
      );
    else this.searchParams1.fromAppliedDate = "";
    if (this.searchParams1.toAppliedDate1 != undefined)
      this.searchParams1.toAppliedDate = Utils.formatDateStr(
        this.searchParams1.toAppliedDate1
      );
    else this.searchParams1.toAppliedDate = "";
    if (this.searchParams1.fromIntakeDate1 != undefined)
      this.searchParams1.fromIntakeDate = Utils.formatDateStr(
        this.searchParams1.fromIntakeDate1
      );
    else this.searchParams1.fromIntakeDate = "";
    if (this.searchParams1.toIntakeDate1 != undefined)
      this.searchParams1.toIntakeDate = Utils.formatDateStr(
        this.searchParams1.toIntakeDate1
      );
    else this.searchParams1.toIntakeDate = "";
    if (this.searchParams1.fromCompletedDate1 != undefined)
      this.searchParams1.fromCompletedDate = Utils.formatDateStr(
        this.searchParams1.fromCompletedDate1
      );
    else this.searchParams1.fromCompletedDate = "";
    if (this.searchParams1.toCompletedDate1 != undefined)
      this.searchParams1.toCompletedDate = Utils.formatDateStr(
        this.searchParams1.toCompletedDate1
      );
    else this.searchParams1.toCompletedDate = "";

    //console.log("searchParams1 : " + JSON.stringify(this.searchParams1));

    this.loadingIndicator1 = true;
    this.statisticsService.getView1(this.searchParams1).subscribe(
      (data) => {
        console.log("data : ", data);
        //console.log("data.content : " + data.content.length);

        this.totalRows1.schoolTypeName = "Total";
        this.totalRows1.profit1 = 0;
        this.totalRows1.profit2 = 0;
        this.totalRows1.profit3 = 0;
        this.totalRows1.profit4 = 0;
        this.totalRows1.profit5 = 0;
        this.totalRows1.profit6 = 0;
        this.totalRows1.profit7 = 0;
        this.totalRows1.profit8 = 0;
        this.totalRows1.profit9 = 0;
        this.totalRows1.profit10 = 0;
        this.totalRows1.profit11 = 0;
        this.totalRows1.profit12 = 0;
        this.totalRows1.total = 0;

        data.forEach((statistics) => {
          console.log("statistics : ", statistics);

          if (
            !this.codeCommon.schoolTypeList.some((x) => x.checked) ||
            this.codeCommon.schoolTypeList.some(
              (x) => x.code == statistics.schoolType && x.checked
            )
          ) {
            console.log("rows1.push", statistics);
            this.rows1.push(statistics);

            this.totalRows1.profit1 =
              this.totalRows1.profit1 + statistics.profit1;
            this.totalRows1.profit2 =
              this.totalRows1.profit2 + statistics.profit2;
            this.totalRows1.profit3 =
              this.totalRows1.profit3 + statistics.profit3;
            this.totalRows1.profit4 =
              this.totalRows1.profit4 + statistics.profit4;
            this.totalRows1.profit5 =
              this.totalRows1.profit5 + statistics.profit5;
            this.totalRows1.profit6 =
              this.totalRows1.profit6 + statistics.profit6;
            this.totalRows1.profit7 =
              this.totalRows1.profit7 + statistics.profit7;
            this.totalRows1.profit8 =
              this.totalRows1.profit8 + statistics.profit8;
            this.totalRows1.profit9 =
              this.totalRows1.profit9 + statistics.profit9;
            this.totalRows1.profit10 =
              this.totalRows1.profit10 + statistics.profit10;
            this.totalRows1.profit11 =
              this.totalRows1.profit11 + statistics.profit11;
            this.totalRows1.profit12 =
              this.totalRows1.profit12 + statistics.profit12;
            this.totalRows1.total = this.totalRows1.total + statistics.total;
          }
        });

        if (this.rows1.length > 0) {
          this.rows1.push(this.totalRows1);
        }

        this.rows1 = [...this.rows1];

        setTimeout(() => {
          this.loadingIndicator1 = false;
        }, 1500);
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        } else {
          setTimeout(() => {
            this.loadingIndicator1 = false;
          }, 1500);
        }
      }
    );
  }

  search2() {
    if (this.searchParams2.fromAppliedDate1 != undefined)
      this.searchParams2.fromAppliedDate = Utils.formatDateStr(
        this.searchParams2.fromAppliedDate1
      );
    else this.searchParams2.fromAppliedDate = "";
    if (this.searchParams2.toAppliedDate1 != undefined)
      this.searchParams2.toAppliedDate = Utils.formatDateStr(
        this.searchParams2.toAppliedDate1
      );
    else this.searchParams2.toAppliedDate = "";
    if (this.searchParams2.fromIntakeDate1 != undefined)
      this.searchParams2.fromIntakeDate = Utils.formatDateStr(
        this.searchParams2.fromIntakeDate1
      );
    else this.searchParams2.fromIntakeDate = "";
    if (this.searchParams2.toIntakeDate1 != undefined)
      this.searchParams2.toIntakeDate = Utils.formatDateStr(
        this.searchParams2.toIntakeDate1
      );
    else this.searchParams2.toIntakeDate = "";
    if (this.searchParams2.fromCompletedDate1 != undefined)
      this.searchParams2.fromCompletedDate = Utils.formatDateStr(
        this.searchParams2.fromCompletedDate1
      );
    else this.searchParams2.fromCompletedDate = "";
    if (this.searchParams2.toCompletedDate1 != undefined)
      this.searchParams2.toCompletedDate = Utils.formatDateStr(
        this.searchParams2.toCompletedDate1
      );
    else this.searchParams2.toCompletedDate = "";

    //console.log("searchParams2 : " + JSON.stringify(this.searchParams2));

    this.epList2 = [];

    if (
      this.searchParams2.ciEp != undefined &&
      this.searchParams2.ciEp.length > 0
    ) {
      let idx = 1;
      this.searchParams2.ciEp.forEach((code) =>
        this.epList2.push({
          name: code.id,
          prop: "profit" + idx,
          cnt: "count" + idx++,
        })
      );
    } else {
      let idx = 1;
      this.codeCommon1.epAllList.forEach((code) =>
        this.epList2.push({
          name: code.code,
          prop: "profit" + idx,
          cnt: "count" + idx++,
        })
      );
    }

    //console.log("this.epList2 : " + JSON.stringify(this.epList2));

    this.loadingIndicator2 = true;
    this.statisticsService.getView2(this.searchParams2).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.content : " + data.content.length);

        this.totalRows2.schoolTypeName = "Total";
        this.totalRows2.profit1 = 0;
        this.totalRows2.profit2 = 0;
        this.totalRows2.profit3 = 0;
        this.totalRows2.profit4 = 0;
        this.totalRows2.profit5 = 0;
        this.totalRows2.profit6 = 0;
        this.totalRows2.profit7 = 0;
        this.totalRows2.profit8 = 0;
        this.totalRows2.profit9 = 0;
        this.totalRows2.profit10 = 0;
        this.totalRows2.profit11 = 0;
        this.totalRows2.profit12 = 0;
        this.totalRows2.profit13 = 0;
        this.totalRows2.profit14 = 0;
        this.totalRows2.profit15 = 0;
        this.totalRows2.profit16 = 0;
        this.totalRows2.profit17 = 0;
        this.totalRows2.profit18 = 0;
        this.totalRows2.profit19 = 0;
        this.totalRows2.profit20 = 0;
        this.totalRows2.profit21 = 0;
        this.totalRows2.profit22 = 0;
        this.totalRows2.profit23 = 0;
        this.totalRows2.profit24 = 0;
        this.totalRows2.profit25 = 0;
        this.totalRows2.profit26 = 0;
        this.totalRows2.profit27 = 0;
        this.totalRows2.profit28 = 0;
        this.totalRows2.profit29 = 0;
        this.totalRows2.profit30 = 0;
        this.totalRows2.profit31 = 0;
        this.totalRows2.profit32 = 0;
        this.totalRows2.profit33 = 0;
        this.totalRows2.profit34 = 0;
        this.totalRows2.profit35 = 0;
        this.totalRows2.profit36 = 0;
        this.totalRows2.profit37 = 0;
        this.totalRows2.profit38 = 0;
        this.totalRows2.profit39 = 0;
        this.totalRows2.profit40 = 0;
        this.totalRows2.profit41 = 0;
        this.totalRows2.profit42 = 0;
        this.totalRows2.profit43 = 0;
        this.totalRows2.profit44 = 0;
        this.totalRows2.profit45 = 0;
        this.totalRows2.profit46 = 0;
        this.totalRows2.profit47 = 0;
        this.totalRows2.profit48 = 0;
        this.totalRows2.profit49 = 0;
        this.totalRows2.profit50 = 0;
        this.totalRows2.profit61 = 0;
        this.totalRows2.profit62 = 0;
        this.totalRows2.profit63 = 0;
        this.totalRows2.profit64 = 0;
        this.totalRows2.profit65 = 0;
        this.totalRows2.profit66 = 0;
        this.totalRows2.profit67 = 0;
        this.totalRows2.profit68 = 0;
        this.totalRows2.profit69 = 0;
        this.totalRows2.profit70 = 0;
        this.totalRows2.total = 0;

        this.totalRows2.count1 = 0;
        this.totalRows2.count2 = 0;
        this.totalRows2.count3 = 0;
        this.totalRows2.count4 = 0;
        this.totalRows2.count5 = 0;
        this.totalRows2.count6 = 0;
        this.totalRows2.count7 = 0;
        this.totalRows2.count8 = 0;
        this.totalRows2.count9 = 0;
        this.totalRows2.count10 = 0;
        this.totalRows2.count11 = 0;
        this.totalRows2.count12 = 0;
        this.totalRows2.count13 = 0;
        this.totalRows2.count14 = 0;
        this.totalRows2.count15 = 0;
        this.totalRows2.count16 = 0;
        this.totalRows2.count17 = 0;
        this.totalRows2.count18 = 0;
        this.totalRows2.count19 = 0;
        this.totalRows2.count20 = 0;
        this.totalRows2.count21 = 0;
        this.totalRows2.count22 = 0;
        this.totalRows2.count23 = 0;
        this.totalRows2.count24 = 0;
        this.totalRows2.count25 = 0;
        this.totalRows2.count26 = 0;
        this.totalRows2.count27 = 0;
        this.totalRows2.count28 = 0;
        this.totalRows2.count29 = 0;
        this.totalRows2.count30 = 0;
        this.totalRows2.count31 = 0;
        this.totalRows2.count32 = 0;
        this.totalRows2.count33 = 0;
        this.totalRows2.count34 = 0;
        this.totalRows2.count35 = 0;
        this.totalRows2.count36 = 0;
        this.totalRows2.count37 = 0;
        this.totalRows2.count38 = 0;
        this.totalRows2.count39 = 0;
        this.totalRows2.count40 = 0;
        this.totalRows2.count41 = 0;
        this.totalRows2.count42 = 0;
        this.totalRows2.count43 = 0;
        this.totalRows2.count44 = 0;
        this.totalRows2.count45 = 0;
        this.totalRows2.count46 = 0;
        this.totalRows2.count47 = 0;
        this.totalRows2.count48 = 0;
        this.totalRows2.count49 = 0;
        this.totalRows2.count50 = 0;
        this.totalRows2.count51 = 0;
        this.totalRows2.count52 = 0;
        this.totalRows2.count53 = 0;
        this.totalRows2.count54 = 0;
        this.totalRows2.count55 = 0;
        this.totalRows2.count56 = 0;
        this.totalRows2.count57 = 0;
        this.totalRows2.count58 = 0;
        this.totalRows2.count59 = 0;
        this.totalRows2.count60 = 0;
        this.totalRows2.count61 = 0;
        this.totalRows2.count62 = 0;
        this.totalRows2.count63 = 0;
        this.totalRows2.count64 = 0;
        this.totalRows2.count65 = 0;
        this.totalRows2.count66 = 0;
        this.totalRows2.count67 = 0;
        this.totalRows2.count68 = 0;
        this.totalRows2.count69 = 0;
        this.totalRows2.count70 = 0;
        this.totalRows2.countTotal = 0;

        let idx = 0;
        let len = this.epList2.length;
        let stat;
        data.forEach((statistics) => {
          if (
            !this.codeCommon.schoolTypeList.some((x) => x.checked) ||
            this.codeCommon.schoolTypeList.some(
              (x) => x.code == statistics.schoolType && x.checked
            )
          ) {
            console.log("statistics : " + JSON.stringify(statistics));
            //this.rows2.push(statistics);

            //console.log("idx : " + idx + ", school : " + statistics.schoolType + ", name : " + statistics.username);
            //this.totalRows2.total      = this.totalRows2.total   + statistics.total;
            if (idx == 0) {
              stat = JSON.parse(JSON.stringify(statistics));

              stat.profit1 = 0;
              stat.profit2 = 0;
              stat.profit3 = 0;
              stat.profit4 = 0;
              stat.profit5 = 0;
              stat.profit6 = 0;
              stat.profit7 = 0;
              stat.profit8 = 0;
              stat.profit9 = 0;
              stat.profit10 = 0;
              stat.profit11 = 0;
              stat.profit12 = 0;
              stat.profit13 = 0;
              stat.profit14 = 0;
              stat.profit15 = 0;
              stat.profit16 = 0;
              stat.profit17 = 0;
              stat.profit18 = 0;
              stat.profit19 = 0;
              stat.profit20 = 0;
              stat.profit21 = 0;
              stat.profit22 = 0;
              stat.profit23 = 0;
              stat.profit24 = 0;
              stat.profit25 = 0;
              stat.profit26 = 0;
              stat.profit27 = 0;
              stat.profit28 = 0;
              stat.profit29 = 0;
              stat.profit30 = 0;
              stat.profit31 = 0;
              stat.profit32 = 0;
              stat.profit33 = 0;
              stat.profit34 = 0;
              stat.profit35 = 0;
              stat.profit36 = 0;
              stat.profit37 = 0;
              stat.profit38 = 0;
              stat.profit39 = 0;
              stat.profit40 = 0;
              stat.profit41 = 0;
              stat.profit42 = 0;
              stat.profit43 = 0;
              stat.profit44 = 0;
              stat.profit45 = 0;
              stat.profit46 = 0;
              stat.profit47 = 0;
              stat.profit48 = 0;
              stat.profit49 = 0;
              stat.profit50 = 0;
              stat.profit51 = 0;
              stat.profit52 = 0;
              stat.profit53 = 0;
              stat.profit54 = 0;
              stat.profit55 = 0;
              stat.profit56 = 0;
              stat.profit57 = 0;
              stat.profit58 = 0;
              stat.profit59 = 0;
              stat.profit60 = 0;
              stat.profit61 = 0;
              stat.profit62 = 0;
              stat.profit63 = 0;
              stat.profit64 = 0;
              stat.profit65 = 0;
              stat.profit66 = 0;
              stat.profit67 = 0;
              stat.profit68 = 0;
              stat.profit69 = 0;
              stat.profit70 = 0;
              stat.countTotal = 0;

              stat.count1 = 0;
              stat.count2 = 0;
              stat.count3 = 0;
              stat.count4 = 0;
              stat.count5 = 0;
              stat.count6 = 0;
              stat.count7 = 0;
              stat.count8 = 0;
              stat.count9 = 0;
              stat.count10 = 0;
              stat.count11 = 0;
              stat.count12 = 0;
              stat.count13 = 0;
              stat.count14 = 0;
              stat.count15 = 0;
              stat.count16 = 0;
              stat.count17 = 0;
              stat.count18 = 0;
              stat.count19 = 0;
              stat.count20 = 0;
              stat.count21 = 0;
              stat.count22 = 0;
              stat.count23 = 0;
              stat.count24 = 0;
              stat.count25 = 0;
              stat.count26 = 0;
              stat.count27 = 0;
              stat.count28 = 0;
              stat.count29 = 0;
              stat.count30 = 0;
              stat.count31 = 0;
              stat.count32 = 0;
              stat.count33 = 0;
              stat.count34 = 0;
              stat.count35 = 0;
              stat.count36 = 0;
              stat.count37 = 0;
              stat.count38 = 0;
              stat.count39 = 0;
              stat.count40 = 0;
              stat.count41 = 0;
              stat.count42 = 0;
              stat.count43 = 0;
              stat.count44 = 0;
              stat.count45 = 0;
              stat.count46 = 0;
              stat.count47 = 0;
              stat.count48 = 0;
              stat.count49 = 0;
              stat.count50 = 0;
              stat.count51 = 0;
              stat.count52 = 0;
              stat.count53 = 0;
              stat.count54 = 0;
              stat.count55 = 0;
              stat.count56 = 0;
              stat.count57 = 0;
              stat.count58 = 0;
              stat.count59 = 0;
              stat.count60 = 0;
              stat.count61 = 0;
              stat.count62 = 0;
              stat.count63 = 0;
              stat.count64 = 0;
              stat.count65 = 0;
              stat.count66 = 0;
              stat.count67 = 0;
              stat.count68 = 0;
              stat.count69 = 0;
              stat.count70 = 0;
              stat.countTotal = 0;
            }

            if (idx <= len - 1) {
              if (idx == 0) {
                stat.profit1 = statistics.profit;
                stat.count1 = statistics.count;
              } else if (idx == 1) {
                stat.profit2 = statistics.profit;
                stat.count2 = statistics.count;
              } else if (idx == 2) {
                stat.profit3 = statistics.profit;
                stat.count3 = statistics.count;
              } else if (idx == 3) {
                stat.profit4 = statistics.profit;
                stat.count4 = statistics.count;
              } else if (idx == 4) {
                stat.profit5 = statistics.profit;
                stat.count5 = statistics.count;
              } else if (idx == 5) {
                stat.profit6 = statistics.profit;
                stat.count6 = statistics.count;
              } else if (idx == 6) {
                stat.profit7 = statistics.profit;
                stat.count7 = statistics.count;
              } else if (idx == 7) {
                stat.profit8 = statistics.profit;
                stat.count8 = statistics.count;
              } else if (idx == 8) {
                stat.profit9 = statistics.profit;
                stat.count9 = statistics.count;
              } else if (idx == 9) {
                stat.profit10 = statistics.profit;
                stat.count10 = statistics.count;
              } else if (idx == 10) {
                stat.profit11 = statistics.profit;
                stat.count11 = statistics.count;
              } else if (idx == 11) {
                stat.profit12 = statistics.profit;
                stat.count12 = statistics.count;
              } else if (idx == 12) {
                stat.profit13 = statistics.profit;
                stat.count13 = statistics.count;
              } else if (idx == 13) {
                stat.profit14 = statistics.profit;
                stat.count14 = statistics.count;
              } else if (idx == 14) {
                stat.profit15 = statistics.profit;
                stat.count15 = statistics.count;
              } else if (idx == 15) {
                stat.profit16 = statistics.profit;
                stat.count16 = statistics.count;
              } else if (idx == 16) {
                stat.profit17 = statistics.profit;
                stat.count17 = statistics.count;
              } else if (idx == 17) {
                stat.profit18 = statistics.profit;
                stat.count18 = statistics.count;
              } else if (idx == 18) {
                stat.profit19 = statistics.profit;
                stat.count19 = statistics.count;
              } else if (idx == 19) {
                stat.profit20 = statistics.profit;
                stat.count20 = statistics.count;
              } else if (idx == 20) {
                stat.profit21 = statistics.profit;
                stat.count21 = statistics.count;
              } else if (idx == 21) {
                stat.profit22 = statistics.profit;
                stat.count22 = statistics.count;
              } else if (idx == 22) {
                stat.profit23 = statistics.profit;
                stat.count23 = statistics.count;
              } else if (idx == 23) {
                stat.profit24 = statistics.profit;
                stat.count24 = statistics.count;
              } else if (idx == 24) {
                stat.profit25 = statistics.profit;
                stat.count25 = statistics.count;
              } else if (idx == 25) {
                stat.profit26 = statistics.profit;
                stat.count26 = statistics.count;
              } else if (idx == 26) {
                stat.profit27 = statistics.profit;
                stat.count27 = statistics.count;
              } else if (idx == 27) {
                stat.profit28 = statistics.profit;
                stat.count28 = statistics.count;
              } else if (idx == 28) {
                stat.profit29 = statistics.profit;
                stat.count29 = statistics.count;
              } else if (idx == 29) {
                stat.profit30 = statistics.profit;
                stat.count30 = statistics.count;
              } else if (idx == 30) {
                stat.profit31 = statistics.profit;
                stat.count31 = statistics.count;
              } else if (idx == 31) {
                stat.profit32 = statistics.profit;
                stat.count32 = statistics.count;
              } else if (idx == 32) {
                stat.profit33 = statistics.profit;
                stat.count33 = statistics.count;
              } else if (idx == 33) {
                stat.profit34 = statistics.profit;
                stat.count34 = statistics.count;
              } else if (idx == 34) {
                stat.profit35 = statistics.profit;
                stat.count35 = statistics.count;
              } else if (idx == 35) {
                stat.profit36 = statistics.profit;
                stat.count36 = statistics.count;
              } else if (idx == 36) {
                stat.profit37 = statistics.profit;
                stat.count37 = statistics.count;
              } else if (idx == 37) {
                stat.profit38 = statistics.profit;
                stat.count38 = statistics.count;
              } else if (idx == 38) {
                stat.profit39 = statistics.profit;
                stat.count39 = statistics.count;
              } else if (idx == 39) {
                stat.profit40 = statistics.profit;
                stat.count40 = statistics.count;
              } else if (idx == 40) {
                stat.profit41 = statistics.profit;
                stat.count41 = statistics.count;
              } else if (idx == 41) {
                stat.profit42 = statistics.profit;
                stat.count42 = statistics.count;
              } else if (idx == 42) {
                stat.profit43 = statistics.profit;
                stat.count43 = statistics.count;
              } else if (idx == 43) {
                stat.profit44 = statistics.profit;
                stat.count44 = statistics.count;
              } else if (idx == 44) {
                stat.profit45 = statistics.profit;
                stat.count45 = statistics.count;
              } else if (idx == 45) {
                stat.profit46 = statistics.profit;
                stat.count46 = statistics.count;
              } else if (idx == 46) {
                stat.profit47 = statistics.profit;
                stat.count47 = statistics.count;
              } else if (idx == 47) {
                stat.profit48 = statistics.profit;
                stat.count48 = statistics.count;
              } else if (idx == 48) {
                stat.profit49 = statistics.profit;
                stat.count49 = statistics.count;
              } else if (idx == 49) {
                stat.profit50 = statistics.profit;
                stat.count50 = statistics.count;
              } else if (idx == 50) {
                stat.profit51 = statistics.profit;
                stat.count51 = statistics.count;
              } else if (idx == 51) {
                stat.profit52 = statistics.profit;
                stat.count52 = statistics.count;
              } else if (idx == 52) {
                stat.profit53 = statistics.profit;
                stat.count53 = statistics.count;
              } else if (idx == 53) {
                stat.profit54 = statistics.profit;
                stat.count54 = statistics.count;
              } else if (idx == 54) {
                stat.profit55 = statistics.profit;
                stat.count55 = statistics.count;
              } else if (idx == 55) {
                stat.profit56 = statistics.profit;
                stat.count56 = statistics.count;
              } else if (idx == 56) {
                stat.profit57 = statistics.profit;
                stat.count57 = statistics.count;
              } else if (idx == 57) {
                stat.profit58 = statistics.profit;
                stat.count58 = statistics.count;
              } else if (idx == 58) {
                stat.profit59 = statistics.profit;
                stat.count59 = statistics.count;
              } else if (idx == 59) {
                stat.profit60 = statistics.profit;
                stat.count60 = statistics.count;
              }

              idx++;
            }

            if (idx >= len) {
              stat.total =
                stat.profit1 +
                stat.profit2 +
                stat.profit3 +
                stat.profit4 +
                stat.profit5 +
                stat.profit6 +
                stat.profit7 +
                stat.profit8 +
                stat.profit9 +
                stat.profit10 +
                stat.profit11 +
                stat.profit12 +
                stat.profit13 +
                stat.profit14 +
                stat.profit15 +
                stat.profit16 +
                stat.profit17 +
                stat.profit18 +
                stat.profit19 +
                stat.profit20 +
                stat.profit21 +
                stat.profit22 +
                stat.profit23 +
                stat.profit24 +
                stat.profit25 +
                stat.profit26 +
                stat.profit27 +
                stat.profit28 +
                stat.profit29 +
                stat.profit30 +
                stat.profit31 +
                stat.profit32 +
                stat.profit33 +
                stat.profit34 +
                stat.profit35 +
                stat.profit36 +
                stat.profit37 +
                stat.profit38 +
                stat.profit39 +
                stat.profit40 +
                stat.profit41 +
                stat.profit42 +
                stat.profit43 +
                stat.profit44 +
                stat.profit45 +
                stat.profit46 +
                stat.profit47 +
                stat.profit48 +
                stat.profit49 +
                stat.profit50 +
                stat.profit51 +
                stat.profit52 +
                stat.profit53 +
                stat.profit54 +
                stat.profit55 +
                stat.profit56 +
                stat.profit57 +
                stat.profit58 +
                stat.profit59 +
                stat.profit60;

              stat.countTotal =
                stat.count1 +
                stat.count2 +
                stat.count3 +
                stat.count4 +
                stat.count5 +
                stat.count6 +
                stat.count7 +
                stat.count8 +
                stat.count9 +
                stat.count10 +
                stat.count11 +
                stat.count12 +
                stat.count13 +
                stat.count14 +
                stat.count15 +
                stat.count16 +
                stat.count17 +
                stat.count18 +
                stat.count19 +
                stat.count20 +
                stat.count21 +
                stat.count22 +
                stat.count23 +
                stat.count24 +
                stat.count25 +
                stat.count26 +
                stat.count27 +
                stat.count28 +
                stat.count29 +
                stat.count30 +
                stat.count31 +
                stat.count32 +
                stat.count33 +
                stat.count34 +
                stat.count35 +
                stat.count36 +
                stat.count37 +
                stat.count38 +
                stat.count39 +
                stat.count40 +
                stat.count41 +
                stat.count42 +
                stat.count43 +
                stat.count44 +
                stat.count45 +
                stat.count46 +
                stat.count47 +
                stat.count48 +
                stat.count49 +
                stat.count50 +
                stat.count51 +
                stat.count52 +
                stat.count53 +
                stat.count54 +
                stat.count55 +
                stat.count56 +
                stat.count57 +
                stat.count58 +
                stat.count59 +
                stat.count60;

              this.totalRows2.profit1 = this.totalRows2.profit1 + stat.profit1;
              this.totalRows2.profit2 = this.totalRows2.profit2 + stat.profit2;
              this.totalRows2.profit3 = this.totalRows2.profit3 + stat.profit3;
              this.totalRows2.profit4 = this.totalRows2.profit4 + stat.profit4;
              this.totalRows2.profit5 = this.totalRows2.profit5 + stat.profit5;
              this.totalRows2.profit6 = this.totalRows2.profit6 + stat.profit6;
              this.totalRows2.profit7 = this.totalRows2.profit7 + stat.profit7;
              this.totalRows2.profit8 = this.totalRows2.profit8 + stat.profit8;
              this.totalRows2.profit9 = this.totalRows2.profit9 + stat.profit9;
              this.totalRows2.profit10 =
                this.totalRows2.profit10 + stat.profit10;
              this.totalRows2.profit11 =
                this.totalRows2.profit11 + stat.profit11;
              this.totalRows2.profit12 =
                this.totalRows2.profit12 + stat.profit12;
              this.totalRows2.profit13 =
                this.totalRows2.profit13 + stat.profit13;
              this.totalRows2.profit14 =
                this.totalRows2.profit14 + stat.profit14;
              this.totalRows2.profit15 =
                this.totalRows2.profit15 + stat.profit15;
              this.totalRows2.profit16 =
                this.totalRows2.profit16 + stat.profit16;
              this.totalRows2.profit17 =
                this.totalRows2.profit17 + stat.profit17;
              this.totalRows2.profit18 =
                this.totalRows2.profit18 + stat.profit18;
              this.totalRows2.profit19 =
                this.totalRows2.profit19 + stat.profit19;
              this.totalRows2.profit20 =
                this.totalRows2.profit20 + stat.profit20;

              this.totalRows2.profit21 =
                this.totalRows2.profit21 + stat.profit21;
              this.totalRows2.profit22 =
                this.totalRows2.profit22 + stat.profit22;
              this.totalRows2.profit23 =
                this.totalRows2.profit23 + stat.profit23;
              this.totalRows2.profit24 =
                this.totalRows2.profit24 + stat.profit24;
              this.totalRows2.profit25 =
                this.totalRows2.profit25 + stat.profit25;
              this.totalRows2.profit26 =
                this.totalRows2.profit26 + stat.profit26;
              this.totalRows2.profit27 =
                this.totalRows2.profit27 + stat.profit27;
              this.totalRows2.profit28 =
                this.totalRows2.profit28 + stat.profit28;
              this.totalRows2.profit29 =
                this.totalRows2.profit29 + stat.profit29;
              this.totalRows2.profit30 =
                this.totalRows2.profit30 + stat.profit30;

              this.totalRows2.profit31 =
                this.totalRows2.profit31 + stat.profit31;
              this.totalRows2.profit32 =
                this.totalRows2.profit32 + stat.profit32;
              this.totalRows2.profit33 =
                this.totalRows2.profit33 + stat.profit33;
              this.totalRows2.profit34 =
                this.totalRows2.profit34 + stat.profit34;
              this.totalRows2.profit35 =
                this.totalRows2.profit35 + stat.profit35;
              this.totalRows2.profit36 =
                this.totalRows2.profit36 + stat.profit36;
              this.totalRows2.profit37 =
                this.totalRows2.profit37 + stat.profit37;
              this.totalRows2.profit38 =
                this.totalRows2.profit38 + stat.profit38;
              this.totalRows2.profit39 =
                this.totalRows2.profit39 + stat.profit39;
              this.totalRows2.profit40 =
                this.totalRows2.profit40 + stat.profit40;

              this.totalRows2.profit41 =
                this.totalRows2.profit41 + stat.profit41;
              this.totalRows2.profit42 =
                this.totalRows2.profit42 + stat.profit42;
              this.totalRows2.profit43 =
                this.totalRows2.profit43 + stat.profit43;
              this.totalRows2.profit44 =
                this.totalRows2.profit44 + stat.profit44;
              this.totalRows2.profit45 =
                this.totalRows2.profit45 + stat.profit45;
              this.totalRows2.profit46 =
                this.totalRows2.profit46 + stat.profit46;
              this.totalRows2.profit47 =
                this.totalRows2.profit47 + stat.profit47;
              this.totalRows2.profit48 =
                this.totalRows2.profit48 + stat.profit48;
              this.totalRows2.profit49 =
                this.totalRows2.profit49 + stat.profit49;
              this.totalRows2.profit50 =
                this.totalRows2.profit50 + stat.profit50;

              this.totalRows2.profit51 =
                this.totalRows2.profit51 + stat.profit51;
              this.totalRows2.profit52 =
                this.totalRows2.profit52 + stat.profit52;
              this.totalRows2.profit53 =
                this.totalRows2.profit53 + stat.profit53;
              this.totalRows2.profit54 =
                this.totalRows2.profit54 + stat.profit54;
              this.totalRows2.profit55 =
                this.totalRows2.profit55 + stat.profit55;
              this.totalRows2.profit56 =
                this.totalRows2.profit56 + stat.profit56;
              this.totalRows2.profit57 =
                this.totalRows2.profit57 + stat.profit57;
              this.totalRows2.profit58 =
                this.totalRows2.profit58 + stat.profit58;
              this.totalRows2.profit59 =
                this.totalRows2.profit59 + stat.profit59;
              this.totalRows2.profit60 =
                this.totalRows2.profit60 + stat.profit60;
              this.totalRows2.total = this.totalRows2.total + stat.total;

              this.totalRows2.count1 = this.totalRows2.count1 + stat.count1;
              this.totalRows2.count2 = this.totalRows2.count2 + stat.count2;
              this.totalRows2.count3 = this.totalRows2.count3 + stat.count3;
              this.totalRows2.count4 = this.totalRows2.count4 + stat.count4;
              this.totalRows2.count5 = this.totalRows2.count5 + stat.count5;
              this.totalRows2.count6 = this.totalRows2.count6 + stat.count6;
              this.totalRows2.count7 = this.totalRows2.count7 + stat.count7;
              this.totalRows2.count8 = this.totalRows2.count8 + stat.count8;
              this.totalRows2.count9 = this.totalRows2.count9 + stat.count9;
              this.totalRows2.count10 = this.totalRows2.count10 + stat.count10;
              this.totalRows2.count11 = this.totalRows2.count11 + stat.count11;
              this.totalRows2.count12 = this.totalRows2.count12 + stat.count12;
              this.totalRows2.count13 = this.totalRows2.count13 + stat.count13;
              this.totalRows2.count14 = this.totalRows2.count14 + stat.count14;
              this.totalRows2.count15 = this.totalRows2.count15 + stat.count15;
              this.totalRows2.count16 = this.totalRows2.count16 + stat.count16;
              this.totalRows2.count17 = this.totalRows2.count17 + stat.count17;
              this.totalRows2.count18 = this.totalRows2.count18 + stat.count18;
              this.totalRows2.count19 = this.totalRows2.count19 + stat.count19;
              this.totalRows2.count20 = this.totalRows2.count20 + stat.count20;

              this.totalRows2.count21 = this.totalRows2.count21 + stat.count21;
              this.totalRows2.count22 = this.totalRows2.count22 + stat.count22;
              this.totalRows2.count23 = this.totalRows2.count23 + stat.count23;
              this.totalRows2.count24 = this.totalRows2.count24 + stat.count24;
              this.totalRows2.count25 = this.totalRows2.count25 + stat.count25;
              this.totalRows2.count26 = this.totalRows2.count26 + stat.count26;
              this.totalRows2.count27 = this.totalRows2.count27 + stat.count27;
              this.totalRows2.count28 = this.totalRows2.count28 + stat.count28;
              this.totalRows2.count29 = this.totalRows2.count29 + stat.count29;
              this.totalRows2.count30 = this.totalRows2.count30 + stat.count30;

              this.totalRows2.count31 = this.totalRows2.count31 + stat.count31;
              this.totalRows2.count32 = this.totalRows2.count32 + stat.count32;
              this.totalRows2.count33 = this.totalRows2.count33 + stat.count33;
              this.totalRows2.count34 = this.totalRows2.count34 + stat.count34;
              this.totalRows2.count35 = this.totalRows2.count35 + stat.count35;
              this.totalRows2.count36 = this.totalRows2.count36 + stat.count36;
              this.totalRows2.count37 = this.totalRows2.count37 + stat.count37;
              this.totalRows2.count38 = this.totalRows2.count38 + stat.count38;
              this.totalRows2.count39 = this.totalRows2.count39 + stat.count39;
              this.totalRows2.count40 = this.totalRows2.count40 + stat.count40;

              this.totalRows2.count41 = this.totalRows2.count41 + stat.count41;
              this.totalRows2.count42 = this.totalRows2.count42 + stat.count42;
              this.totalRows2.count43 = this.totalRows2.count43 + stat.count43;
              this.totalRows2.count44 = this.totalRows2.count44 + stat.count44;
              this.totalRows2.count45 = this.totalRows2.count45 + stat.count45;
              this.totalRows2.count46 = this.totalRows2.count46 + stat.count46;
              this.totalRows2.count47 = this.totalRows2.count47 + stat.count47;
              this.totalRows2.count48 = this.totalRows2.count48 + stat.count48;
              this.totalRows2.count49 = this.totalRows2.count49 + stat.count49;
              this.totalRows2.count50 = this.totalRows2.count50 + stat.count50;

              this.totalRows2.count51 = this.totalRows2.count51 + stat.count51;
              this.totalRows2.count52 = this.totalRows2.count52 + stat.count52;
              this.totalRows2.count53 = this.totalRows2.count53 + stat.count53;
              this.totalRows2.count54 = this.totalRows2.count54 + stat.count54;
              this.totalRows2.count55 = this.totalRows2.count55 + stat.count55;
              this.totalRows2.count56 = this.totalRows2.count56 + stat.count56;
              this.totalRows2.count57 = this.totalRows2.count57 + stat.count57;
              this.totalRows2.count58 = this.totalRows2.count58 + stat.count58;
              this.totalRows2.count59 = this.totalRows2.count59 + stat.count59;
              this.totalRows2.count60 = this.totalRows2.count60 + stat.count60;

              this.totalRows2.countTotal =
                this.totalRows2.countTotal + stat.countTotal;

              this.rows2.push(stat);

              idx = 0;
            }
          }
        });

        if (this.rows2.length > 0) {
          this.rows2.push(this.totalRows2);
        }

        this.rows2 = [...this.rows2];

        setTimeout(() => {
          this.loadingIndicator2 = false;
        }, 1500);
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        } else {
          setTimeout(() => {
            this.loadingIndicator2 = false;
          }, 1500);
        }
      }
    );

    this.loadingIndicator21 = true;
    this.statisticsService.getView21(this.searchParams2).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.content : " + data.content.length);

        this.totalRows21.ciMemberCategoryName = "Total";
        this.totalRows21.profit1 = 0;
        this.totalRows21.profit2 = 0;
        this.totalRows21.profit3 = 0;
        this.totalRows21.profit4 = 0;
        this.totalRows21.profit5 = 0;
        this.totalRows21.profit6 = 0;
        this.totalRows21.profit7 = 0;
        this.totalRows21.profit8 = 0;
        this.totalRows21.profit9 = 0;
        this.totalRows21.profit10 = 0;
        this.totalRows21.profit11 = 0;
        this.totalRows21.profit12 = 0;
        this.totalRows21.profit13 = 0;
        this.totalRows21.profit14 = 0;
        this.totalRows21.profit15 = 0;
        this.totalRows21.profit16 = 0;
        this.totalRows21.profit17 = 0;
        this.totalRows21.profit18 = 0;
        this.totalRows21.profit19 = 0;
        this.totalRows21.profit20 = 0;
        this.totalRows21.profit21 = 0;
        this.totalRows21.profit22 = 0;
        this.totalRows21.profit23 = 0;
        this.totalRows21.profit24 = 0;
        this.totalRows21.profit25 = 0;
        this.totalRows21.profit26 = 0;
        this.totalRows21.profit27 = 0;
        this.totalRows21.profit28 = 0;
        this.totalRows21.profit29 = 0;
        this.totalRows21.profit30 = 0;
        this.totalRows21.profit31 = 0;
        this.totalRows21.profit32 = 0;
        this.totalRows21.profit33 = 0;
        this.totalRows21.profit34 = 0;
        this.totalRows21.profit35 = 0;
        this.totalRows21.profit36 = 0;
        this.totalRows21.profit37 = 0;
        this.totalRows21.profit38 = 0;
        this.totalRows21.profit39 = 0;
        this.totalRows21.profit40 = 0;
        this.totalRows21.profit41 = 0;
        this.totalRows21.profit42 = 0;
        this.totalRows21.profit43 = 0;
        this.totalRows21.profit44 = 0;
        this.totalRows21.profit45 = 0;
        this.totalRows21.profit46 = 0;
        this.totalRows21.profit47 = 0;
        this.totalRows21.profit48 = 0;
        this.totalRows21.profit49 = 0;
        this.totalRows21.profit50 = 0;
        this.totalRows21.profit61 = 0;
        this.totalRows21.profit62 = 0;
        this.totalRows21.profit63 = 0;
        this.totalRows21.profit64 = 0;
        this.totalRows21.profit65 = 0;
        this.totalRows21.profit66 = 0;
        this.totalRows21.profit67 = 0;
        this.totalRows21.profit68 = 0;
        this.totalRows21.profit69 = 0;
        this.totalRows21.profit70 = 0;
        this.totalRows21.total = 0;

        this.totalRows21.count1 = 0;
        this.totalRows21.count2 = 0;
        this.totalRows21.count3 = 0;
        this.totalRows21.count4 = 0;
        this.totalRows21.count5 = 0;
        this.totalRows21.count6 = 0;
        this.totalRows21.count7 = 0;
        this.totalRows21.count8 = 0;
        this.totalRows21.count9 = 0;
        this.totalRows21.count10 = 0;
        this.totalRows21.count11 = 0;
        this.totalRows21.count12 = 0;
        this.totalRows21.count13 = 0;
        this.totalRows21.count14 = 0;
        this.totalRows21.count15 = 0;
        this.totalRows21.count16 = 0;
        this.totalRows21.count17 = 0;
        this.totalRows21.count18 = 0;
        this.totalRows21.count19 = 0;
        this.totalRows21.count20 = 0;
        this.totalRows21.count21 = 0;
        this.totalRows21.count22 = 0;
        this.totalRows21.count23 = 0;
        this.totalRows21.count24 = 0;
        this.totalRows21.count25 = 0;
        this.totalRows21.count26 = 0;
        this.totalRows21.count27 = 0;
        this.totalRows21.count28 = 0;
        this.totalRows21.count29 = 0;
        this.totalRows21.count30 = 0;
        this.totalRows21.count31 = 0;
        this.totalRows21.count32 = 0;
        this.totalRows21.count33 = 0;
        this.totalRows21.count34 = 0;
        this.totalRows21.count35 = 0;
        this.totalRows21.count36 = 0;
        this.totalRows21.count37 = 0;
        this.totalRows21.count38 = 0;
        this.totalRows21.count39 = 0;
        this.totalRows21.count40 = 0;
        this.totalRows21.count41 = 0;
        this.totalRows21.count42 = 0;
        this.totalRows21.count43 = 0;
        this.totalRows21.count44 = 0;
        this.totalRows21.count45 = 0;
        this.totalRows21.count46 = 0;
        this.totalRows21.count47 = 0;
        this.totalRows21.count48 = 0;
        this.totalRows21.count49 = 0;
        this.totalRows21.count50 = 0;
        this.totalRows21.count51 = 0;
        this.totalRows21.count52 = 0;
        this.totalRows21.count53 = 0;
        this.totalRows21.count54 = 0;
        this.totalRows21.count55 = 0;
        this.totalRows21.count56 = 0;
        this.totalRows21.count57 = 0;
        this.totalRows21.count58 = 0;
        this.totalRows21.count59 = 0;
        this.totalRows21.count60 = 0;
        this.totalRows21.count61 = 0;
        this.totalRows21.count62 = 0;
        this.totalRows21.count63 = 0;
        this.totalRows21.count64 = 0;
        this.totalRows21.count65 = 0;
        this.totalRows21.count66 = 0;
        this.totalRows21.count67 = 0;
        this.totalRows21.count68 = 0;
        this.totalRows21.count69 = 0;
        this.totalRows21.count70 = 0;
        this.totalRows21.countTotal = 0;

        let idx = 0;
        let len = this.epList2.length;
        let stat;
        data.forEach((statistics) => {
          if (
            !this.codeCommon.memberCategoryList.some((x) => x.checked) ||
            this.codeCommon.memberCategoryList.some(
              (x) => x.code == statistics.ciMemberCategory && x.checked
            )
          ) {
            //console.log("statistics : " + JSON.stringify(statistics));
            //this.rows2.push(statistics);

            //console.log("idx : " + idx + ", school : " + statistics.memberCategory + ", name : " + statistics.username);
            //this.totalRows21.total      = this.totalRows21.total   + statistics.total;
            if (idx == 0) {
              stat = JSON.parse(JSON.stringify(statistics));
              stat.profit1 = 0;
              stat.profit2 = 0;
              stat.profit3 = 0;
              stat.profit4 = 0;
              stat.profit5 = 0;
              stat.profit6 = 0;
              stat.profit7 = 0;
              stat.profit8 = 0;
              stat.profit9 = 0;
              stat.profit10 = 0;
              stat.profit11 = 0;
              stat.profit12 = 0;
              stat.profit13 = 0;
              stat.profit14 = 0;
              stat.profit15 = 0;
              stat.profit16 = 0;
              stat.profit17 = 0;
              stat.profit18 = 0;
              stat.profit19 = 0;
              stat.profit20 = 0;
              stat.profit21 = 0;
              stat.profit22 = 0;
              stat.profit23 = 0;
              stat.profit24 = 0;
              stat.profit25 = 0;
              stat.profit26 = 0;
              stat.profit27 = 0;
              stat.profit28 = 0;
              stat.profit29 = 0;
              stat.profit30 = 0;
              stat.profit31 = 0;
              stat.profit32 = 0;
              stat.profit33 = 0;
              stat.profit34 = 0;
              stat.profit35 = 0;
              stat.profit36 = 0;
              stat.profit37 = 0;
              stat.profit38 = 0;
              stat.profit39 = 0;
              stat.profit40 = 0;
              stat.profit41 = 0;
              stat.profit42 = 0;
              stat.profit43 = 0;
              stat.profit44 = 0;
              stat.profit45 = 0;
              stat.profit46 = 0;
              stat.profit47 = 0;
              stat.profit48 = 0;
              stat.profit49 = 0;
              stat.profit50 = 0;
              stat.profit51 = 0;
              stat.profit52 = 0;
              stat.profit53 = 0;
              stat.profit54 = 0;
              stat.profit55 = 0;
              stat.profit56 = 0;
              stat.profit57 = 0;
              stat.profit58 = 0;
              stat.profit59 = 0;
              stat.profit60 = 0;
              stat.profit61 = 0;
              stat.profit62 = 0;
              stat.profit63 = 0;
              stat.profit64 = 0;
              stat.profit65 = 0;
              stat.profit66 = 0;
              stat.profit67 = 0;
              stat.profit68 = 0;
              stat.profit69 = 0;
              stat.profit70 = 0;
              stat.countTotal = 0;

              stat.count1 = 0;
              stat.count2 = 0;
              stat.count3 = 0;
              stat.count4 = 0;
              stat.count5 = 0;
              stat.count6 = 0;
              stat.count7 = 0;
              stat.count8 = 0;
              stat.count9 = 0;
              stat.count10 = 0;
              stat.count11 = 0;
              stat.count12 = 0;
              stat.count13 = 0;
              stat.count14 = 0;
              stat.count15 = 0;
              stat.count16 = 0;
              stat.count17 = 0;
              stat.count18 = 0;
              stat.count19 = 0;
              stat.count20 = 0;
              stat.count21 = 0;
              stat.count22 = 0;
              stat.count23 = 0;
              stat.count24 = 0;
              stat.count25 = 0;
              stat.count26 = 0;
              stat.count27 = 0;
              stat.count28 = 0;
              stat.count29 = 0;
              stat.count30 = 0;
              stat.count31 = 0;
              stat.count32 = 0;
              stat.count33 = 0;
              stat.count34 = 0;
              stat.count35 = 0;
              stat.count36 = 0;
              stat.count37 = 0;
              stat.count38 = 0;
              stat.count39 = 0;
              stat.count40 = 0;
              stat.count41 = 0;
              stat.count42 = 0;
              stat.count43 = 0;
              stat.count44 = 0;
              stat.count45 = 0;
              stat.count46 = 0;
              stat.count47 = 0;
              stat.count48 = 0;
              stat.count49 = 0;
              stat.count50 = 0;
              stat.count51 = 0;
              stat.count52 = 0;
              stat.count53 = 0;
              stat.count54 = 0;
              stat.count55 = 0;
              stat.count56 = 0;
              stat.count57 = 0;
              stat.count58 = 0;
              stat.count59 = 0;
              stat.count60 = 0;
              stat.count61 = 0;
              stat.count62 = 0;
              stat.count63 = 0;
              stat.count64 = 0;
              stat.count65 = 0;
              stat.count66 = 0;
              stat.count67 = 0;
              stat.count68 = 0;
              stat.count69 = 0;
              stat.count70 = 0;
              stat.countTotal = 0;
            }

            if (idx <= len - 1) {
              if (idx == 0) {
                stat.profit1 = statistics.profit;
                stat.count1 = statistics.count;
              } else if (idx == 1) {
                stat.profit2 = statistics.profit;
                stat.count2 = statistics.count;
              } else if (idx == 2) {
                stat.profit3 = statistics.profit;
                stat.count3 = statistics.count;
              } else if (idx == 3) {
                stat.profit4 = statistics.profit;
                stat.count4 = statistics.count;
              } else if (idx == 4) {
                stat.profit5 = statistics.profit;
                stat.count5 = statistics.count;
              } else if (idx == 5) {
                stat.profit6 = statistics.profit;
                stat.count6 = statistics.count;
              } else if (idx == 6) {
                stat.profit7 = statistics.profit;
                stat.count7 = statistics.count;
              } else if (idx == 7) {
                stat.profit8 = statistics.profit;
                stat.count8 = statistics.count;
              } else if (idx == 8) {
                stat.profit9 = statistics.profit;
                stat.count9 = statistics.count;
              } else if (idx == 9) {
                stat.profit10 = statistics.profit;
                stat.count10 = statistics.count;
              } else if (idx == 10) {
                stat.profit11 = statistics.profit;
                stat.count11 = statistics.count;
              } else if (idx == 11) {
                stat.profit12 = statistics.profit;
                stat.count12 = statistics.count;
              } else if (idx == 12) {
                stat.profit13 = statistics.profit;
                stat.count13 = statistics.count;
              } else if (idx == 13) {
                stat.profit14 = statistics.profit;
                stat.count14 = statistics.count;
              } else if (idx == 14) {
                stat.profit15 = statistics.profit;
                stat.count15 = statistics.count;
              } else if (idx == 15) {
                stat.profit16 = statistics.profit;
                stat.count16 = statistics.count;
              } else if (idx == 16) {
                stat.profit17 = statistics.profit;
                stat.count17 = statistics.count;
              } else if (idx == 17) {
                stat.profit18 = statistics.profit;
                stat.count18 = statistics.count;
              } else if (idx == 18) {
                stat.profit19 = statistics.profit;
                stat.count19 = statistics.count;
              } else if (idx == 19) {
                stat.profit20 = statistics.profit;
                stat.count20 = statistics.count;
              } else if (idx == 20) {
                stat.profit21 = statistics.profit;
                stat.count21 = statistics.count;
              } else if (idx == 21) {
                stat.profit22 = statistics.profit;
                stat.count22 = statistics.count;
              } else if (idx == 22) {
                stat.profit23 = statistics.profit;
                stat.count23 = statistics.count;
              } else if (idx == 23) {
                stat.profit24 = statistics.profit;
                stat.count24 = statistics.count;
              } else if (idx == 24) {
                stat.profit25 = statistics.profit;
                stat.count25 = statistics.count;
              } else if (idx == 25) {
                stat.profit26 = statistics.profit;
                stat.count26 = statistics.count;
              } else if (idx == 26) {
                stat.profit27 = statistics.profit;
                stat.count27 = statistics.count;
              } else if (idx == 27) {
                stat.profit28 = statistics.profit;
                stat.count28 = statistics.count;
              } else if (idx == 28) {
                stat.profit29 = statistics.profit;
                stat.count29 = statistics.count;
              } else if (idx == 29) {
                stat.profit30 = statistics.profit;
                stat.count30 = statistics.count;
              } else if (idx == 30) {
                stat.profit31 = statistics.profit;
                stat.count31 = statistics.count;
              } else if (idx == 31) {
                stat.profit32 = statistics.profit;
                stat.count32 = statistics.count;
              } else if (idx == 32) {
                stat.profit33 = statistics.profit;
                stat.count33 = statistics.count;
              } else if (idx == 33) {
                stat.profit34 = statistics.profit;
                stat.count34 = statistics.count;
              } else if (idx == 34) {
                stat.profit35 = statistics.profit;
                stat.count35 = statistics.count;
              } else if (idx == 35) {
                stat.profit36 = statistics.profit;
                stat.count36 = statistics.count;
              } else if (idx == 36) {
                stat.profit37 = statistics.profit;
                stat.count37 = statistics.count;
              } else if (idx == 37) {
                stat.profit38 = statistics.profit;
                stat.count38 = statistics.count;
              } else if (idx == 38) {
                stat.profit39 = statistics.profit;
                stat.count39 = statistics.count;
              } else if (idx == 39) {
                stat.profit40 = statistics.profit;
                stat.count40 = statistics.count;
              } else if (idx == 40) {
                stat.profit41 = statistics.profit;
                stat.count41 = statistics.count;
              } else if (idx == 41) {
                stat.profit42 = statistics.profit;
                stat.count42 = statistics.count;
              } else if (idx == 42) {
                stat.profit43 = statistics.profit;
                stat.count43 = statistics.count;
              } else if (idx == 43) {
                stat.profit44 = statistics.profit;
                stat.count44 = statistics.count;
              } else if (idx == 44) {
                stat.profit45 = statistics.profit;
                stat.count45 = statistics.count;
              } else if (idx == 45) {
                stat.profit46 = statistics.profit;
                stat.count46 = statistics.count;
              } else if (idx == 46) {
                stat.profit47 = statistics.profit;
                stat.count47 = statistics.count;
              } else if (idx == 47) {
                stat.profit48 = statistics.profit;
                stat.count48 = statistics.count;
              } else if (idx == 48) {
                stat.profit49 = statistics.profit;
                stat.count49 = statistics.count;
              } else if (idx == 49) {
                stat.profit50 = statistics.profit;
                stat.count50 = statistics.count;
              } else if (idx == 50) {
                stat.profit51 = statistics.profit;
                stat.count51 = statistics.count;
              } else if (idx == 51) {
                stat.profit52 = statistics.profit;
                stat.count52 = statistics.count;
              } else if (idx == 52) {
                stat.profit53 = statistics.profit;
                stat.count53 = statistics.count;
              } else if (idx == 53) {
                stat.profit54 = statistics.profit;
                stat.count54 = statistics.count;
              } else if (idx == 54) {
                stat.profit55 = statistics.profit;
                stat.count55 = statistics.count;
              } else if (idx == 55) {
                stat.profit56 = statistics.profit;
                stat.count56 = statistics.count;
              } else if (idx == 56) {
                stat.profit57 = statistics.profit;
                stat.count57 = statistics.count;
              } else if (idx == 57) {
                stat.profit58 = statistics.profit;
                stat.count58 = statistics.count;
              } else if (idx == 58) {
                stat.profit59 = statistics.profit;
                stat.count59 = statistics.count;
              } else if (idx == 59) {
                stat.profit60 = statistics.profit;
                stat.count60 = statistics.count;
              }

              idx++;
            }

            if (idx >= len) {
              stat.total =
                stat.profit1 +
                stat.profit2 +
                stat.profit3 +
                stat.profit4 +
                stat.profit5 +
                stat.profit6 +
                stat.profit7 +
                stat.profit8 +
                stat.profit9 +
                stat.profit10 +
                stat.profit11 +
                stat.profit12 +
                stat.profit13 +
                stat.profit14 +
                stat.profit15 +
                stat.profit16 +
                stat.profit17 +
                stat.profit18 +
                stat.profit19 +
                stat.profit20 +
                stat.profit21 +
                stat.profit22 +
                stat.profit23 +
                stat.profit24 +
                stat.profit25 +
                stat.profit26 +
                stat.profit27 +
                stat.profit28 +
                stat.profit29 +
                stat.profit30 +
                stat.profit31 +
                stat.profit32 +
                stat.profit33 +
                stat.profit34 +
                stat.profit35 +
                stat.profit36 +
                stat.profit37 +
                stat.profit38 +
                stat.profit39 +
                stat.profit40 +
                stat.profit41 +
                stat.profit42 +
                stat.profit43 +
                stat.profit44 +
                stat.profit45 +
                stat.profit46 +
                stat.profit47 +
                stat.profit48 +
                stat.profit49 +
                stat.profit50 +
                stat.profit51 +
                stat.profit52 +
                stat.profit53 +
                stat.profit54 +
                stat.profit55 +
                stat.profit56 +
                stat.profit57 +
                stat.profit58 +
                stat.profit59 +
                stat.profit60;

              stat.countTotal =
                stat.count1 +
                stat.count2 +
                stat.count3 +
                stat.count4 +
                stat.count5 +
                stat.count6 +
                stat.count7 +
                stat.count8 +
                stat.count9 +
                stat.count10 +
                stat.count11 +
                stat.count12 +
                stat.count13 +
                stat.count14 +
                stat.count15 +
                stat.count16 +
                stat.count17 +
                stat.count18 +
                stat.count19 +
                stat.count20 +
                stat.count21 +
                stat.count22 +
                stat.count23 +
                stat.count24 +
                stat.count25 +
                stat.count26 +
                stat.count27 +
                stat.count28 +
                stat.count29 +
                stat.count30 +
                stat.count31 +
                stat.count32 +
                stat.count33 +
                stat.count34 +
                stat.count35 +
                stat.count36 +
                stat.count37 +
                stat.count38 +
                stat.count39 +
                stat.count40 +
                stat.count41 +
                stat.count42 +
                stat.count43 +
                stat.count44 +
                stat.count45 +
                stat.count46 +
                stat.count47 +
                stat.count48 +
                stat.count49 +
                stat.count50 +
                stat.count51 +
                stat.count52 +
                stat.count53 +
                stat.count54 +
                stat.count55 +
                stat.count56 +
                stat.count57 +
                stat.count58 +
                stat.count59 +
                stat.count60;

              this.totalRows21.profit1 =
                this.totalRows21.profit1 + stat.profit1;
              this.totalRows21.profit2 =
                this.totalRows21.profit2 + stat.profit2;
              this.totalRows21.profit3 =
                this.totalRows21.profit3 + stat.profit3;
              this.totalRows21.profit4 =
                this.totalRows21.profit4 + stat.profit4;
              this.totalRows21.profit5 =
                this.totalRows21.profit5 + stat.profit5;
              this.totalRows21.profit6 =
                this.totalRows21.profit6 + stat.profit6;
              this.totalRows21.profit7 =
                this.totalRows21.profit7 + stat.profit7;
              this.totalRows21.profit8 =
                this.totalRows21.profit8 + stat.profit8;
              this.totalRows21.profit9 =
                this.totalRows21.profit9 + stat.profit9;
              this.totalRows21.profit10 =
                this.totalRows21.profit10 + stat.profit10;
              this.totalRows21.profit11 =
                this.totalRows21.profit11 + stat.profit11;
              this.totalRows21.profit12 =
                this.totalRows21.profit12 + stat.profit12;
              this.totalRows21.profit13 =
                this.totalRows21.profit13 + stat.profit13;
              this.totalRows21.profit14 =
                this.totalRows21.profit14 + stat.profit14;
              this.totalRows21.profit15 =
                this.totalRows21.profit15 + stat.profit15;
              this.totalRows21.profit16 =
                this.totalRows21.profit16 + stat.profit16;
              this.totalRows21.profit17 =
                this.totalRows21.profit17 + stat.profit17;
              this.totalRows21.profit18 =
                this.totalRows21.profit18 + stat.profit18;
              this.totalRows21.profit19 =
                this.totalRows21.profit19 + stat.profit19;
              this.totalRows21.profit20 =
                this.totalRows21.profit20 + stat.profit20;

              this.totalRows21.profit21 =
                this.totalRows21.profit21 + stat.profit21;
              this.totalRows21.profit22 =
                this.totalRows21.profit22 + stat.profit22;
              this.totalRows21.profit23 =
                this.totalRows21.profit23 + stat.profit23;
              this.totalRows21.profit24 =
                this.totalRows21.profit24 + stat.profit24;
              this.totalRows21.profit25 =
                this.totalRows21.profit25 + stat.profit25;
              this.totalRows21.profit26 =
                this.totalRows21.profit26 + stat.profit26;
              this.totalRows21.profit27 =
                this.totalRows21.profit27 + stat.profit27;
              this.totalRows21.profit28 =
                this.totalRows21.profit28 + stat.profit28;
              this.totalRows21.profit29 =
                this.totalRows21.profit29 + stat.profit29;
              this.totalRows21.profit30 =
                this.totalRows21.profit30 + stat.profit30;

              this.totalRows21.profit31 =
                this.totalRows21.profit31 + stat.profit31;
              this.totalRows21.profit32 =
                this.totalRows21.profit32 + stat.profit32;
              this.totalRows21.profit33 =
                this.totalRows21.profit33 + stat.profit33;
              this.totalRows21.profit34 =
                this.totalRows21.profit34 + stat.profit34;
              this.totalRows21.profit35 =
                this.totalRows21.profit35 + stat.profit35;
              this.totalRows21.profit36 =
                this.totalRows21.profit36 + stat.profit36;
              this.totalRows21.profit37 =
                this.totalRows21.profit37 + stat.profit37;
              this.totalRows21.profit38 =
                this.totalRows21.profit38 + stat.profit38;
              this.totalRows21.profit39 =
                this.totalRows21.profit39 + stat.profit39;
              this.totalRows21.profit40 =
                this.totalRows21.profit40 + stat.profit40;

              this.totalRows21.profit41 =
                this.totalRows21.profit41 + stat.profit41;
              this.totalRows21.profit42 =
                this.totalRows21.profit42 + stat.profit42;
              this.totalRows21.profit43 =
                this.totalRows21.profit43 + stat.profit43;
              this.totalRows21.profit44 =
                this.totalRows21.profit44 + stat.profit44;
              this.totalRows21.profit45 =
                this.totalRows21.profit45 + stat.profit45;
              this.totalRows21.profit46 =
                this.totalRows21.profit46 + stat.profit46;
              this.totalRows21.profit47 =
                this.totalRows21.profit47 + stat.profit47;
              this.totalRows21.profit48 =
                this.totalRows21.profit48 + stat.profit48;
              this.totalRows21.profit49 =
                this.totalRows21.profit49 + stat.profit49;
              this.totalRows21.profit50 =
                this.totalRows21.profit50 + stat.profit50;

              this.totalRows21.profit51 =
                this.totalRows21.profit51 + stat.profit51;
              this.totalRows21.profit52 =
                this.totalRows21.profit52 + stat.profit52;
              this.totalRows21.profit53 =
                this.totalRows21.profit53 + stat.profit53;
              this.totalRows21.profit54 =
                this.totalRows21.profit54 + stat.profit54;
              this.totalRows21.profit55 =
                this.totalRows21.profit55 + stat.profit55;
              this.totalRows21.profit56 =
                this.totalRows21.profit56 + stat.profit56;
              this.totalRows21.profit57 =
                this.totalRows21.profit57 + stat.profit57;
              this.totalRows21.profit58 =
                this.totalRows21.profit58 + stat.profit58;
              this.totalRows21.profit59 =
                this.totalRows21.profit59 + stat.profit59;
              this.totalRows21.profit60 =
                this.totalRows21.profit60 + stat.profit60;
              this.totalRows21.total = this.totalRows21.total + stat.total;

              this.totalRows21.count1 = this.totalRows21.count1 + stat.count1;
              this.totalRows21.count2 = this.totalRows21.count2 + stat.count2;
              this.totalRows21.count3 = this.totalRows21.count3 + stat.count3;
              this.totalRows21.count4 = this.totalRows21.count4 + stat.count4;
              this.totalRows21.count5 = this.totalRows21.count5 + stat.count5;
              this.totalRows21.count6 = this.totalRows21.count6 + stat.count6;
              this.totalRows21.count7 = this.totalRows21.count7 + stat.count7;
              this.totalRows21.count8 = this.totalRows21.count8 + stat.count8;
              this.totalRows21.count9 = this.totalRows21.count9 + stat.count9;
              this.totalRows21.count10 =
                this.totalRows21.count10 + stat.count10;
              this.totalRows21.count11 =
                this.totalRows21.count11 + stat.count11;
              this.totalRows21.count12 =
                this.totalRows21.count12 + stat.count12;
              this.totalRows21.count13 =
                this.totalRows21.count13 + stat.count13;
              this.totalRows21.count14 =
                this.totalRows21.count14 + stat.count14;
              this.totalRows21.count15 =
                this.totalRows21.count15 + stat.count15;
              this.totalRows21.count16 =
                this.totalRows21.count16 + stat.count16;
              this.totalRows21.count17 =
                this.totalRows21.count17 + stat.count17;
              this.totalRows21.count18 =
                this.totalRows21.count18 + stat.count18;
              this.totalRows21.count19 =
                this.totalRows21.count19 + stat.count19;
              this.totalRows21.count20 =
                this.totalRows21.count20 + stat.count20;

              this.totalRows21.count21 =
                this.totalRows21.count21 + stat.count21;
              this.totalRows21.count22 =
                this.totalRows21.count22 + stat.count22;
              this.totalRows21.count23 =
                this.totalRows21.count23 + stat.count23;
              this.totalRows21.count24 =
                this.totalRows21.count24 + stat.count24;
              this.totalRows21.count25 =
                this.totalRows21.count25 + stat.count25;
              this.totalRows21.count26 =
                this.totalRows21.count26 + stat.count26;
              this.totalRows21.count27 =
                this.totalRows21.count27 + stat.count27;
              this.totalRows21.count28 =
                this.totalRows21.count28 + stat.count28;
              this.totalRows21.count29 =
                this.totalRows21.count29 + stat.count29;
              this.totalRows21.count30 =
                this.totalRows21.count30 + stat.count30;

              this.totalRows21.count31 =
                this.totalRows21.count31 + stat.count31;
              this.totalRows21.count32 =
                this.totalRows21.count32 + stat.count32;
              this.totalRows21.count33 =
                this.totalRows21.count33 + stat.count33;
              this.totalRows21.count34 =
                this.totalRows21.count34 + stat.count34;
              this.totalRows21.count35 =
                this.totalRows21.count35 + stat.count35;
              this.totalRows21.count36 =
                this.totalRows21.count36 + stat.count36;
              this.totalRows21.count37 =
                this.totalRows21.count37 + stat.count37;
              this.totalRows21.count38 =
                this.totalRows21.count38 + stat.count38;
              this.totalRows21.count39 =
                this.totalRows21.count39 + stat.count39;
              this.totalRows21.count40 =
                this.totalRows21.count40 + stat.count40;

              this.totalRows21.count41 =
                this.totalRows21.count41 + stat.count41;
              this.totalRows21.count42 =
                this.totalRows21.count42 + stat.count42;
              this.totalRows21.count43 =
                this.totalRows21.count43 + stat.count43;
              this.totalRows21.count44 =
                this.totalRows21.count44 + stat.count44;
              this.totalRows21.count45 =
                this.totalRows21.count45 + stat.count45;
              this.totalRows21.count46 =
                this.totalRows21.count46 + stat.count46;
              this.totalRows21.count47 =
                this.totalRows21.count47 + stat.count47;
              this.totalRows21.count48 =
                this.totalRows21.count48 + stat.count48;
              this.totalRows21.count49 =
                this.totalRows21.count49 + stat.count49;
              this.totalRows21.count50 =
                this.totalRows21.count50 + stat.count50;

              this.totalRows21.count51 =
                this.totalRows21.count51 + stat.count51;
              this.totalRows21.count52 =
                this.totalRows21.count52 + stat.count52;
              this.totalRows21.count53 =
                this.totalRows21.count53 + stat.count53;
              this.totalRows21.count54 =
                this.totalRows21.count54 + stat.count54;
              this.totalRows21.count55 =
                this.totalRows21.count55 + stat.count55;
              this.totalRows21.count56 =
                this.totalRows21.count56 + stat.count56;
              this.totalRows21.count57 =
                this.totalRows21.count57 + stat.count57;
              this.totalRows21.count58 =
                this.totalRows21.count58 + stat.count58;
              this.totalRows21.count59 =
                this.totalRows21.count59 + stat.count59;
              this.totalRows21.count60 =
                this.totalRows21.count60 + stat.count60;

              this.totalRows21.countTotal =
                this.totalRows21.countTotal + stat.countTotal;

              this.rows21.push(stat);

              idx = 0;
            }
          }
        });

        if (this.rows21.length > 0) {
          this.rows21.push(this.totalRows21);
        }

        this.rows21 = [...this.rows21];

        setTimeout(() => {
          this.loadingIndicator21 = false;
        }, 1500);
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        } else {
          setTimeout(() => {
            this.loadingIndicator21 = false;
          }, 1500);
        }
      }
    );
  }

  search3() {
    if (this.searchParams3.fromAppliedDate1 != undefined)
      this.searchParams3.fromAppliedDate = Utils.formatDateStr(
        this.searchParams3.fromAppliedDate1
      );
    else this.searchParams3.fromAppliedDate = "";
    if (this.searchParams3.toAppliedDate1 != undefined)
      this.searchParams3.toAppliedDate = Utils.formatDateStr(
        this.searchParams3.toAppliedDate1
      );
    else this.searchParams3.toAppliedDate = "";
    if (this.searchParams3.fromIntakeDate1 != undefined)
      this.searchParams3.fromIntakeDate = Utils.formatDateStr(
        this.searchParams3.fromIntakeDate1
      );
    else this.searchParams3.fromIntakeDate = "";
    if (this.searchParams3.toIntakeDate1 != undefined)
      this.searchParams3.toIntakeDate = Utils.formatDateStr(
        this.searchParams3.toIntakeDate1
      );
    else this.searchParams3.toIntakeDate = "";
    if (this.searchParams3.fromCompletedDate1 != undefined)
      this.searchParams3.fromCompletedDate = Utils.formatDateStr(
        this.searchParams3.fromCompletedDate1
      );
    else this.searchParams3.fromCompletedDate = "";
    if (this.searchParams3.toCompletedDate1 != undefined)
      this.searchParams3.toCompletedDate = Utils.formatDateStr(
        this.searchParams3.toCompletedDate1
      );
    else this.searchParams3.toCompletedDate = "";

    //console.log("searchParams3 : " + JSON.stringify(this.searchParams3));

    this.loadingIndicator3 = true;
    this.statisticsService.getView3(this.searchParams3).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.content : " + data.content.length);

        this.totalRows3.schoolName = "Total";
        this.totalRows3.count = 0;
        this.totalRows3.schoolFee = 0;
        this.totalRows3.commission = 0;
        this.totalRows3.subCommission = 0;
        this.totalRows3.dc = 0;
        this.totalRows3.profit = 0;
        this.totalRows3.average = 0;

        data.forEach((statistics) => {
          //console.log("statistics : " + JSON.stringify(statistics));

          if (
            (!this.codeCommon.schoolNameListIncFalse.some((x) => x.checked) ||
              this.codeCommon.schoolNameListIncFalse.some(
                (x) => x.code == statistics.school && x.checked
              )) &&
            (!this.codeCommon.schoolTypeList.some((x) => x.checked) ||
              this.codeCommon.schoolTypeList.some(
                (x) => x.code == statistics.schoolType && x.checked
              ))
          ) {
            this.rows3.push(statistics);

            this.totalRows3.count = this.totalRows3.count + statistics.count;
            this.totalRows3.schoolFee =
              this.totalRows3.schoolFee + statistics.schoolFee;
            this.totalRows3.commission =
              this.totalRows3.commission + statistics.commission;
            this.totalRows3.subCommission =
              this.totalRows3.subCommission + statistics.subCommission;
            this.totalRows3.dc = this.totalRows3.dc + statistics.dc;
            this.totalRows3.profit = this.totalRows3.profit + statistics.profit;
            this.totalRows3.average =
              this.totalRows3.average + statistics.average;
          }
        });

        if (this.rows3.length > 0) {
          this.rows3.push(this.totalRows3);
        }

        this.rows3 = [...this.rows3];

        setTimeout(() => {
          this.loadingIndicator3 = false;
        }, 1500);
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        } else {
          setTimeout(() => {
            this.loadingIndicator3 = false;
          }, 1500);
        }
      }
    );
  }

  search4() {
    if (this.searchParams4.fromAppliedDate1 != undefined)
      this.searchParams4.fromAppliedDate = Utils.formatDateStr(
        this.searchParams4.fromAppliedDate1
      );
    else this.searchParams4.fromAppliedDate = "";
    if (this.searchParams4.toAppliedDate1 != undefined)
      this.searchParams4.toAppliedDate = Utils.formatDateStr(
        this.searchParams4.toAppliedDate1
      );
    else this.searchParams4.toAppliedDate = "";
    if (this.searchParams4.fromIntakeDate1 != undefined)
      this.searchParams4.fromIntakeDate = Utils.formatDateStr(
        this.searchParams4.fromIntakeDate1
      );
    else this.searchParams4.fromIntakeDate = "";
    if (this.searchParams4.toIntakeDate1 != undefined)
      this.searchParams4.toIntakeDate = Utils.formatDateStr(
        this.searchParams4.toIntakeDate1
      );
    else this.searchParams4.toIntakeDate = "";
    if (this.searchParams4.fromCompletedDate1 != undefined)
      this.searchParams4.fromCompletedDate = Utils.formatDateStr(
        this.searchParams4.fromCompletedDate1
      );
    else this.searchParams4.fromCompletedDate = "";
    if (this.searchParams4.toCompletedDate1 != undefined)
      this.searchParams4.toCompletedDate = Utils.formatDateStr(
        this.searchParams4.toCompletedDate1
      );
    else this.searchParams4.toCompletedDate = "";

    //console.log("searchParams4 : " + JSON.stringify(this.searchParams4));

    this.loadingIndicator4 = true;
    this.statisticsService.getView4(this.searchParams4).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.content : " + data.content.length);

        this.totalRows4.cpSubAgentName = "Total";
        this.totalRows4.profit1 = 0;
        this.totalRows4.profit2 = 0;
        this.totalRows4.profit3 = 0;
        this.totalRows4.profit4 = 0;
        this.totalRows4.profit5 = 0;
        this.totalRows4.profit6 = 0;
        this.totalRows4.profit7 = 0;
        this.totalRows4.profit8 = 0;
        this.totalRows4.profit9 = 0;
        this.totalRows4.profit10 = 0;
        this.totalRows4.profit11 = 0;
        this.totalRows4.profit12 = 0;
        this.totalRows4.total = 0;

        data.forEach((statistics) => {
          if (
            !this.codeCommon.subAgentList.some((x) => x.checked) ||
            this.codeCommon.subAgentList.some(
              (x) => x.code == statistics.cpSubAgent && x.checked
            )
          ) {
            //console.log("statistics : " + JSON.stringify(statistics));
            if (
              statistics.cpSubAgent == undefined ||
              statistics.cpSubAgent == ""
            ) {
              statistics.cpSubAgent = "null";
            }
            this.rows4.push(statistics);

            this.totalRows4.profit1 =
              this.totalRows4.profit1 + statistics.profit1;
            this.totalRows4.profit2 =
              this.totalRows4.profit2 + statistics.profit2;
            this.totalRows4.profit3 =
              this.totalRows4.profit3 + statistics.profit3;
            this.totalRows4.profit4 =
              this.totalRows4.profit4 + statistics.profit4;
            this.totalRows4.profit5 =
              this.totalRows4.profit5 + statistics.profit5;
            this.totalRows4.profit6 =
              this.totalRows4.profit6 + statistics.profit6;
            this.totalRows4.profit7 =
              this.totalRows4.profit7 + statistics.profit7;
            this.totalRows4.profit8 =
              this.totalRows4.profit8 + statistics.profit8;
            this.totalRows4.profit9 =
              this.totalRows4.profit9 + statistics.profit9;
            this.totalRows4.profit10 =
              this.totalRows4.profit10 + statistics.profit10;
            this.totalRows4.profit11 =
              this.totalRows4.profit11 + statistics.profit11;
            this.totalRows4.profit12 =
              this.totalRows4.profit12 + statistics.profit12;
            this.totalRows4.total = this.totalRows4.total + statistics.total;
          }
        });

        if (this.rows4.length > 0) {
          this.rows4.push(this.totalRows4);
        }

        this.rows4 = [...this.rows4];

        setTimeout(() => {
          this.loadingIndicator4 = false;
        }, 1500);
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        } else {
          setTimeout(() => {
            this.loadingIndicator4 = false;
          }, 1500);
        }
      }
    );
  }

  onSelect1({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onSelect2({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onSelect21({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onSelect3({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onSelect4({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onActivate1(event) {
    if (event.type === "dblclick") {
      // console.log('Event: activate', event);
      // console.log("School Type : " + event.row.schoolType + ", Month : " + event.column.name);//Month

      this.searchAccounting(event.row, event.column.name, this.searchParams1);
    }
  }

  onActivate2(event) {
    if (event.type === "dblclick") {
      // console.log('Event: activate', event);
      // console.log("School Type : " + event.row.schoolType + ", EP : " + event.column.name);
      this.searchAccounting(event.row, event.column.name, this.searchParams2);
    }
  }

  onActivate21(event) {
    if (event.type === "dblclick") {
      // console.log('Event: activate', event);
      // console.log("Member Category : " + event.row.ciMemberCategory + ", EP : " + event.column.name);
      this.searchAccounting(event.row, event.column.name, this.searchParams21);
    }
  }

  onActivate3(event) {
    if (event.type === "dblclick") {
      // console.log('Event: activate', event);
      // console.log("School : " + event.row.school + ", Column : " + event.column.name);
      this.searchAccounting(event.row, event.column.name, this.searchParams3);
    }
  }

  onActivate4(event) {
    if (event.type === "dblclick") {
      // console.log('Event: activate', event);
      // console.log("Subagent : " + event.row.cpSubAgent + ", Month : " + event.column.name);
      this.searchAccounting(event.row, event.column.name, this.searchParams4);
    }
  }

  searchAccounting(row, column, searchParam) {
    if (this.codeCommon != undefined) {
      this.codeService.setOrg();
      this.codeCommon = this.codeService.getCodeCommon();

      this.codeCommon.epList.forEach((x) => {
        if (
          searchParam.ciEp != undefined &&
          searchParam.ciEp.some((y) => y == x.code)
        ) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });

      if (row.ciMemberCategory != undefined) {
        this.codeCommon.memberCategoryList.forEach((x) => {
          if (x.code == row.ciMemberCategory) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      if (row.school != undefined) {
        this.codeCommon.schoolNameListIncFalse.forEach((x) => {
          if (x.code == row.school) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      if (row.schoolType != undefined) {
        this.codeCommon.schoolTypeList.forEach((x) => {
          if (x.code == row.schoolType) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      if (row.cpSubAgent != undefined) {
        this.codeCommon.subAgentList.forEach((x) => {
          if (x.code == row.cpSubAgent) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      this.codeCommon.accountStatusList.forEach((x) => {
        if (x.code == "Estimated" || x.code == "Pending") {
          if (
            searchParam.searchKey == "EA" ||
            searchParam.searchKey == "EP" ||
            searchParam.searchKey == "DEA" ||
            searchParam.searchKey == "DEP"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        } else if (x.code == "OnGoing" || x.code == "Received") {
          if (
            searchParam.searchKey == "EA" ||
            searchParam.searchKey == "AP" ||
            searchParam.searchKey == "DEA" ||
            searchParam.searchKey == "DAP"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        } else if (x.code == "Holding") {
          if (searchParam.holding) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        } else {
          x.checked = false;
        }
      });

      if (
        searchParam.searchKey == "DEA" ||
        searchParam.searchKey == "DEP" ||
        searchParam.searchKey == "DAP"
      ) {
        this.codeCommon.subAgentList.forEach((x) => {
          if (x.code == "null") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }
    }

    this.accountingSearchParams = new AccountingSearchParams();

    this.accountingSearchParams.fromAppliedDate1 = searchParam.fromAppliedDate1;
    this.accountingSearchParams.toAppliedDate1 = searchParam.toAppliedDate1;
    this.accountingSearchParams.fromCompletedDate1 =
      searchParam.fromCompletedDate1;
    this.accountingSearchParams.toCompletedDate1 = searchParam.toCompletedDate1;
    this.accountingSearchParams.fromIntakeDate1 = searchParam.fromIntakeDate1;
    this.accountingSearchParams.toIntakeDate1 = searchParam.toIntakeDate1;

    if (searchParam.searchDate != undefined) {
      if (searchParam.searchDate == "AD") {
        if (column == "JAN") this.accountingSearchParams.appliedMonth = "01";
        else if (column == "FEB")
          this.accountingSearchParams.appliedMonth = "02";
        else if (column == "MAR")
          this.accountingSearchParams.appliedMonth = "03";
        else if (column == "APR")
          this.accountingSearchParams.appliedMonth = "04";
        else if (column == "MAY")
          this.accountingSearchParams.appliedMonth = "05";
        else if (column == "JUN")
          this.accountingSearchParams.appliedMonth = "06";
        else if (column == "JUL")
          this.accountingSearchParams.appliedMonth = "07";
        else if (column == "AUG")
          this.accountingSearchParams.appliedMonth = "08";
        else if (column == "SEP")
          this.accountingSearchParams.appliedMonth = "09";
        else if (column == "OCT")
          this.accountingSearchParams.appliedMonth = "10";
        else if (column == "NOV")
          this.accountingSearchParams.appliedMonth = "11";
        else if (column == "DEC")
          this.accountingSearchParams.appliedMonth = "12";
      } else {
        if (column == "JAN") this.accountingSearchParams.intakeMonth = "01";
        else if (column == "FEB")
          this.accountingSearchParams.intakeMonth = "02";
        else if (column == "MAR")
          this.accountingSearchParams.intakeMonth = "03";
        else if (column == "APR")
          this.accountingSearchParams.intakeMonth = "04";
        else if (column == "MAY")
          this.accountingSearchParams.intakeMonth = "05";
        else if (column == "JUN")
          this.accountingSearchParams.intakeMonth = "06";
        else if (column == "JUL")
          this.accountingSearchParams.intakeMonth = "07";
        else if (column == "AUG")
          this.accountingSearchParams.intakeMonth = "08";
        else if (column == "SEP")
          this.accountingSearchParams.intakeMonth = "09";
        else if (column == "OCT")
          this.accountingSearchParams.intakeMonth = "10";
        else if (column == "NOV")
          this.accountingSearchParams.intakeMonth = "11";
        else if (column == "DEC")
          this.accountingSearchParams.intakeMonth = "12";
      }
    }

    this.codeService.keepSearchParams("4", this.accountingSearchParams);
    //EP, School Type, Applied Date, Intake Date, Complete Date, Account Status(Holding), sub Agent(All or Nothing)
    this.router.navigate(["/accounting"], {
      queryParams: { action: "statistics" },
    });
  }

  getRowHeight1(row) {
    //console.log("row : " + JSON.stringify(row));

    if (!row) return 50;
    if (row.height === undefined) return 50;

    return row.height;
  }

  selectPeriod1(period) {
    let dateTo = new Date().getFullYear();
    let dateFrom = dateTo - period;
    //      this.searchParams1.fromAppliedDate1 = {year:new Date().getFullYear(), month:1, day:1 };

    if (this.searchParams1.searchDate == "AD") {
      this.searchParams1.fromAppliedDate1 = {
        year: dateFrom,
        month: 1,
        day: 1,
      };
      this.searchParams1.toAppliedDate1 = { year: dateTo, month: 12, day: 31 };
      this.searchParams1.fromIntakeDate1 = undefined;
      this.searchParams1.toIntakeDate1 = undefined;
    } else {
      this.searchParams1.fromAppliedDate1 = undefined;
      this.searchParams1.toAppliedDate1 = undefined;
      this.searchParams1.fromIntakeDate1 = { year: dateFrom, month: 1, day: 1 };
      this.searchParams1.toIntakeDate1 = { year: dateTo, month: 12, day: 31 };
    }
    this.searchStatistics1();
  }

  selectYear1(year) {
    if (this.searchParams1.searchDate == "AD") {
      this.searchParams1.fromAppliedDate1 = { year: year, month: 1, day: 1 };
      this.searchParams1.toAppliedDate1 = { year: year, month: 12, day: 31 };
      this.searchParams1.fromIntakeDate1 = undefined;
      this.searchParams1.toIntakeDate1 = undefined;
    } else {
      this.searchParams1.fromAppliedDate1 = undefined;
      this.searchParams1.toAppliedDate1 = undefined;
      this.searchParams1.fromIntakeDate1 = { year: year, month: 1, day: 1 };
      this.searchParams1.toIntakeDate1 = { year: year, month: 12, day: 31 };
    }
    this.searchStatistics1();
  }

  selectYear3(year) {
    this.searchParams3.fromAppliedDate1 = { year: year, month: 1, day: 1 };
    this.searchParams3.toAppliedDate1 = { year: year, month: 12, day: 31 };
    this.searchParams3.fromIntakeDate1 = undefined;
    this.searchParams3.toIntakeDate1 = undefined;
    this.searchStatistics3();
  }

  selectYear4(year) {
    this.searchParams4.fromAppliedDate1 = { year: year, month: 1, day: 1 };
    this.searchParams4.toAppliedDate1 = { year: year, month: 12, day: 31 };
    this.searchParams4.fromIntakeDate1 = undefined;
    this.searchParams4.toIntakeDate1 = undefined;
    this.searchStatistics4();
  }

  setDefaultDate() {
    let thisYear = new Date().getFullYear();

    if (this.currentTab == "tab1") {
      if (
        this.searchParams1.fromAppliedDate1 == undefined &&
        this.searchParams1.toAppliedDate1 == undefined &&
        this.searchParams1.fromIntakeDate1 == undefined &&
        this.searchParams1.toIntakeDate1 == undefined
      ) {
        this.searchParams1.fromIntakeDate1 = {
          year: thisYear,
          month: 1,
          day: 1,
        };
        this.searchParams1.toIntakeDate1 = {
          year: thisYear,
          month: 12,
          day: 31,
        };
      }
      this.rows1.length = 0;
    } else if (this.currentTab == "tab2") {
      if (
        this.searchParams2.fromAppliedDate1 == undefined &&
        this.searchParams2.toAppliedDate1 == undefined &&
        this.searchParams2.fromIntakeDate1 == undefined &&
        this.searchParams2.toIntakeDate1 == undefined
      ) {
        this.searchParams2.fromIntakeDate1 = {
          year: thisYear,
          month: 1,
          day: 1,
        };
        this.searchParams2.toIntakeDate1 = {
          year: thisYear,
          month: 12,
          day: 31,
        };
      }
      this.rows2.length = 0;
      this.rows21.length = 0;
    } else if (this.currentTab == "tab3") {
      if (
        this.searchParams3.fromAppliedDate1 == undefined &&
        this.searchParams3.toAppliedDate1 == undefined &&
        this.searchParams3.fromIntakeDate1 == undefined &&
        this.searchParams3.toIntakeDate1 == undefined
      ) {
        this.searchParams3.fromIntakeDate1 = {
          year: thisYear,
          month: 1,
          day: 1,
        };
        this.searchParams3.toIntakeDate1 = {
          year: thisYear,
          month: 12,
          day: 31,
        };
      }
      this.rows3.length = 0;
    } else if (this.currentTab == "tab4") {
      if (
        this.searchParams4.fromAppliedDate1 == undefined &&
        this.searchParams4.toAppliedDate1 == undefined &&
        this.searchParams4.fromIntakeDate1 == undefined &&
        this.searchParams4.toIntakeDate1 == undefined
      ) {
        this.searchParams4.fromIntakeDate1 = {
          year: thisYear,
          month: 1,
          day: 1,
        };
        this.searchParams4.toIntakeDate1 = {
          year: thisYear,
          month: 12,
          day: 31,
        };
      }
      this.rows4.length = 0;
    }
  }

  setSearch() {
    this.setDefaultDate();

    if (this.currentTab == "tab1") {
      //this.rows1.length = 0;
      this.search1();
    } else if (this.currentTab == "tab2") {
      //this.rows2.length  = 0;
      //this.rows21.length = 0;
      this.search2();
    } else if (this.currentTab == "tab3") {
      //this.rows3.length = 0;
      this.search3();
    } else if (this.currentTab == "tab4") {
      //this.rows4.length = 0;
      this.search4();
    }
  }

  onClickTab(event) {
    this.currentTab = "tab" + event.nextId;

    this.setSearch();
  }

  onFilter1(column) {
    this.selectedColumn = column;
    if (column == "School Type") {
      this.filterList = this.codeService.codeCommon.schoolTypeList;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  onFilter2(column) {
    this.selectedColumn = column;
    if (column == "School Type") {
      this.filterList = this.codeService.codeCommon.schoolTypeList.filter(
        (code) => this.rows2.some((x) => x.schoolType == code.code)
      );
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  onFilter21(column) {
    if (column == "Member Category") {
      this.filterList = this.codeService.codeCommon.memberCategoryList.filter(
        (code) => this.rows21.some((x) => x.ciMemberCategory == code.code)
      );
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  onFilter3(column) {
    this.selectedColumn = column;
    if (column == "School Name") {
      this.filterList =
        this.codeService.codeCommon.schoolNameListIncFalse.filter((code) =>
          this.rows3.some((x) => x.school == code.code)
        );
    } else if (column == "School Type") {
      this.filterList = this.codeService.codeCommon.schoolTypeList.filter(
        (code) => this.rows3.some((x) => x.schoolType == code.code)
      );
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  onFilter4(column) {
    if (column == "Sub Agent") {
      this.filterList = this.codeService.codeCommon.subAgentList.filter(
        (code) => this.rows4.some((x) => x.cpSubAgent == code.code)
      );
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "School Name") {
      this.codeCommon.schoolNameListIncFalse.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "School Type") {
      this.codeCommon.schoolTypeList.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    }

    this.filterModal.hide();

    if (this.currentTab === "tab1") {
      this.searchStatistics1();
    } else if (this.currentTab === "tab2") {
      this.searchStatistics2();
    } else if (this.currentTab === "tab3") {
      this.searchStatistics3();
    } else if (this.currentTab === "tab4") {
      this.searchStatistics4();
    }
  }

  exportAsCSV1() {
    const columns: TableColumn[] = this.dataTable1.columns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable1.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "annual",
      useBom: true,
    };
    return new Angular2Csv(rows, "annual", options);
  }

  exportAsCSV2() {
    const columns: TableColumn[] = this.dataTable2.columns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable2.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "ep",
      useBom: true,
    };

    new Angular2Csv(rows, "ep", options);

    const columns1: TableColumn[] = this.dataTable21.columns;
    const headers1 = columns1
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows1: any[] = this.dataTable21.rows.map((row) => {
      let r = {};
      columns1.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options1 = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "ep1",
      useBom: true,
    };
    return new Angular2Csv(rows1, "ep1", options1);
  }

  exportAsCSV3() {
    const columns: TableColumn[] = this.dataTable3.columns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable3.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "school name",
      useBom: true,
    };
    return new Angular2Csv(rows, "school name", options);
  }

  exportAsCSV4() {
    const columns: TableColumn[] = this.dataTable4.columns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable4.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "sub agent",
      useBom: true,
    };
    return new Angular2Csv(rows, "sub agent", options);
  }
}
