import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { FormBuilder } from "@angular/forms";

import * as _ from "lodash";
import { Subject } from "rxjs";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { environment } from "../../../environments/environment";
import { Thread } from "../../model/thread";
import { JsonHttp } from "../../services/json-http";
import * as moment from 'moment';
@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.css"],
})
export class ChatComponent implements OnInit, OnDestroy {


  @Input() thread: Subject<Thread> = new Subject<Thread>();
  @ViewChildren("chatbox") chatbox: QueryList<any>;
  @ViewChild("content") content: ElementRef;

  selectedThread: Thread;

  messages: any[] = [];
  disabled = true;
  newmessage: string;
  private stompClient = null;

  isLast: boolean;
  pageNumber: number;
  cnt: number;

  isLoadedMore: boolean;
  public _moment = moment;




  constructor(
    private http: JsonHttp,
    private formBuilder: FormBuilder,
  ) {}


  ngAfterViewInit() {
    this.scrollToBottom();
    this.chatbox.changes.subscribe((data) => {
      if (!this.isLoadedMore) {
        this.scrollToBottom();
      } else {
        this.isLoadedMore = false;
      }
    });
  }
  ngOnInit(): void {
    //console.log("##### chat check point :", JSON.stringify(this.thread));
    this.pageNumber = 0;
    this.cnt = 50;
    this.thread.subscribe((thread) => {
      this.selectedThread = thread;
      console.log("#### selectedThread user check :", JSON.stringify(this.selectedThread.user));
      this.http
        .get(
          `${environment.api_url}/api/messages?page=${this.pageNumber}&size=${this.cnt}&threadId=${this.selectedThread.customer.id}`
        )
        .subscribe((data: any) => {
          //console.log("### thread user data check :", JSON.stringify(data.content[0].user));
          if (data.content.length > 0) {
            this.messages = _.reverse(data.content);
          }
          this.isLast = data.last;
        });
      this.connect();
    });



  }

  ngOnDestroy(): void {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  }

  scrollToBottom = () => {
    try {
      this.content.nativeElement.scrollTop =
        this.content.nativeElement.scrollHeight;
    } catch (err) {}
  };

  setConnected(connected: boolean) {
    this.disabled = !connected;
    if (connected) {
      this.messages = [];
    }
  }

  connect() {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const socket = new SockJS(`${environment.api_url}/endpoint`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      this.stompClient = Stomp.over(socket);
      const _this = this;
      this.stompClient.connect(
        { Authorization: `Bearer ${accessToken}` },
        function (frame) {
        //console.log("Connected: " + frame);
          _this.stompClient.subscribe(
            `/thread/${_this.selectedThread.customer.id}`,
            function (data) {
              _this.showMessage(JSON.parse(data.body));
            }
          );
        }
      );
    }
  }
  sendMessage() {
    let accessToken = localStorage.getItem("accessToken");
    this.stompClient.send(
      `/chat/message/${this.selectedThread.customer.id}`,
      { Authorization: `Bearer ${accessToken}` },
      this.newmessage
    );
    this.newmessage = "";
  }

  showMessage(message) {
    this.messages.push(message);
  }

  loadMore() {
    this.pageNumber++;

    this.http
      .get(
        `${environment.api_url}/api/messages?page=${this.pageNumber}&size=10&threadId=${this.selectedThread.customer.id}`
      )
      .subscribe((data: any) => {
        if (data.content.length > 0) {
          this.isLoadedMore = true;
          this.messages = [..._.reverse(data.content), ...this.messages];

          //console.log(this.messages);
        }
        this.isLast = data.last;
      });
  }

  triggerFunction(event) {
    if (event.ctrlKey && event.key === "Enter") {
      this.newmessage += "\n";
    } else if (event.key === "Enter") {
      if(event.preventDefault){
        event.preventDefault();
     }
      this.sendMessage();
    }
  }

  clickImportant() {
    //console.log(this.selectedThread);
    this.selectedThread.isImportant = !this.selectedThread.isImportant;

    this.http
      .put(`${environment.api_url}/api/threads`, this.selectedThread)
      .subscribe((data) => {
        //console.log(data);
      });
  }
}
