import { Component, OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ViewEncapsulation       } from '@angular/core';

import { Router                  } from '@angular/router';
import { DatePipe                } from '@angular/common';

import { CalendarEvent,
  CalendarDateFormatter   } from 'angular-calendar';
import { setHours, setMinutes,
  getHours, getMinutes,
  format                  } from 'date-fns';
import { colors                  } from './colors';
import { CalendarHeaderComponent } from './calendar-header.component';
import { DateTimePickerComponent } from './date-time-picker.component';

import { ScheduleService         } from '../../services/schedule.service';
import { Schedule                } from '../../model/schedule';

import { Subject                 } from 'rxjs';
import { ModalDirective          } from 'ngx-bootstrap/modal';

import{addMonths            }  from 'date-fns';

import { CustomDateFormatter     } from './custom-date-formatter.provider';


@Component({
selector   : 'app-schedule',
providers: [
ScheduleService,
{
 provide: CalendarDateFormatter,
 useClass: CustomDateFormatter
}      
],  
changeDetection: ChangeDetectionStrategy.OnPush,
encapsulation: ViewEncapsulation.None,  
templateUrl: './schedule.component.html'})


export class ScheduleComponent implements OnInit {

public userId: number = 0;
public events = new Array<CalendarEvent>();

view: string = 'month';
viewDate: Date = new Date();
refresh: Subject<any> = new Subject();

calendarId : string = "";
selectedSchedule : Schedule = new Schedule();
selectedEvent    : CalendarEvent; //for updating and deleting

@ViewChild('eventModal')  public eventModal:ModalDirective;

constructor(private router      : Router,
       private scheduleService: ScheduleService) {
}

ngOnInit() {
this.onGet();
}

onGet() {
this.scheduleService.getList(this.calendarId)
.subscribe(
 data => {
   //console.log("data : " + JSON.stringify(data));
   //console.log("data.content : " + data.content.length);    
   
   this.events = new Array<CalendarEvent>();
   data.forEach(schedule => {
       
     //console.log("schedule : " + JSON.stringify(schedule))
     this.events.push(this.setEvent(schedule));
   
   });
   
   this.refresh.next();          
},
error => {
   console.log("error : " + JSON.stringify(error));
});      
}

setEvent(schedule) : CalendarEvent {

let start = new Date(schedule.startDateTime);
//console.log(schedule.summary + " ==> " + getHours(start) + ":" + (getMinutes(start)==0?'00':getMinutes(start)));
let min   = getMinutes(start) == 0 ? '00' : getMinutes(start);
let time  = getHours(start) + ":" + min;

let title = "";
if(schedule.startDateTime.length == 10) {
title = schedule.summary;
} else {
title = time + ' ' + schedule.summary;
}
let event = { title  : title,
           start  : new Date(schedule.startDateTime),
           end    : new Date(schedule.endDateTime),
           color  : colors.blue,
           meta   : {
             id : schedule.eventId,
             description : schedule.description,
             summary : schedule.summary,
             pickup  : schedule.summary.indexOf('[Pick-Up]') > -1 || schedule.summary.indexOf('[PU') > -1 ? true : false
           }};    

//console.log("title : " + title + ", eventId : " + schedule.eventId);
return event; 
}

onCreate() {
this.selectedSchedule.startDate  = format(this.selectedSchedule.start,'DD-MM-YYYY'); 
this.selectedSchedule.startTime  = format(this.selectedSchedule.start,'HH:mm:ss');     
this.selectedSchedule.endDate    = format(this.selectedSchedule.end,'DD-MM-YYYY'); 
this.selectedSchedule.endTime    = format(this.selectedSchedule.end,'HH:mm:ss');     
this.selectedSchedule.calendarId = this.calendarId;

this.scheduleService.create(this.selectedSchedule)
.subscribe(
 data => {
   console.log('create successful');

   //console.log(data);
   this.onGet();
   
   //this.refresh.next();    
},
error => {
   console.log(error);

});        
}

onUpdate() {
this.selectedSchedule.startDate   = format(this.selectedSchedule.start,'DD-MM-YYYY'); 
this.selectedSchedule.startTime   = format(this.selectedSchedule.start,'HH:mm:ss');     
this.selectedSchedule.endDate     = format(this.selectedSchedule.end,'DD-MM-YYYY'); 
this.selectedSchedule.endTime     = format(this.selectedSchedule.end,'HH:mm:ss');    
this.selectedSchedule.calendarId  = this.calendarId;

console.log(this.selectedSchedule);

this.scheduleService.update(this.selectedSchedule)
.subscribe(
 data => {
   console.log('update successful');
   
   this.onGet();
},
error => {
   console.log(error);

});        
}

onDelete() {

this.selectedSchedule.startDate = format(this.selectedSchedule.start,'DD-MM-YYYY'); 
this.selectedSchedule.startTime = format(this.selectedSchedule.start,'HH:mm:ss');     
this.selectedSchedule.endDate   = format(this.selectedSchedule.end,'DD-MM-YYYY'); 
this.selectedSchedule.endTime   = format(this.selectedSchedule.end,'HH:mm:ss');    
this.selectedSchedule.calendarId = this.calendarId;

console.log(this.selectedSchedule);

this.scheduleService.delete(this.selectedSchedule)
.subscribe(
 data => {
   console.log('delete successful');
   
//          let idx = this.events.indexOf(this.selectedEvent);
//          if(idx>-1){
//            this.events.splice(idx, 1);
//          }
    
},
error => {
   //console.log(error);

});    

this.onGet();
}  

onClickEvent(event){
this.selectedEvent = event; // for updating and deleting
this.selectedSchedule.calendarId     = event.meta.calendarId;      
this.selectedSchedule.eventId        = event.meta.id;    
this.selectedSchedule.summary        = event.meta.summary; 
this.selectedSchedule.description    = event.meta.description;   
this.selectedSchedule.start          = event.start;
this.selectedSchedule.end            = event.end;
this.selectedSchedule.startDateTime  = format(event.start,'DD-MM-YYYY HH:mm');
this.selectedSchedule.endDateTime    = format(event.end,'DD-MM-YYYY HH:mm');    

this.eventModal.show();   
}

addEvent(date:Date) {
console.log('addEvent : ' + date);
this.selectedSchedule = new Schedule();
this.selectedSchedule.calendarId = this.calendarId;
this.selectedSchedule.start      = date;
this.selectedSchedule.end        = date;       
this.eventModal.show();         
}  

onCloseEvent() {
this.eventModal.hide();       
}  

onCreateEvent() {
this.onCreate();
this.eventModal.hide();       
}  

onUpdateEvent() {
this.onUpdate()    
this.eventModal.hide();       
}  

onDeleteEvent() {
this.onDelete();    
this.eventModal.hide();       
}    


get month2() {
return addMonths(this.viewDate, 1);       
}

get month3() {
return addMonths(this.viewDate, 2);        
}  

get month4() {
return addMonths(this.viewDate, 3);       
}

get month5() {
return addMonths(this.viewDate, 4);        
}  

get month6() {
return addMonths(this.viewDate, 5);       
}

handleEvent(event: CalendarEvent): void {
if(event.meta.pickup) {
this.router.navigate(['/basicDetail', "sch"+event.meta.description]);  
}

//alert("event : " + event.title);
}

}
