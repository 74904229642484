import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AgentParams } from "../model/agent";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/agent`;

@Injectable()
export class AgentService {
  constructor(private http: JsonHttp) {}

  getAll() {
    return this.http.get(url);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: AgentParams) {
    return this.http.post(url, params);
  }

  update(params: AgentParams) {
    return this.http.put(url, params);
  }

  delete(params: AgentParams) {
    return this.http.delete(url, params);
  }
}
