import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NativeDateModule } from '@angular/material/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopupModule } from 'ng2-opd-popup';
import { DetailMenuModule } from '../detail/detail-menu.module';
import { EventComponent } from './event.component';
import { EventDetailComponent } from './event-detail.component';
import { ApplicationModule } from '../application/application.module';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    NativeDateModule,
    NgxDatatableModule,
    ModalModule.forRoot(),

    PopupModule.forRoot(),
    DetailMenuModule,
    ApplicationModule
  ],
  declarations: [
    EventComponent,
    EventDetailComponent
  ],
  exports: [
    EventComponent,
    EventDetailComponent
  ]
})
export class EventModule {
}
