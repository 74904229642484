import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Profile } from "../model/profile";
import { JsonHttp } from "./json-http";
@Injectable()
export class AuthenticationService {
  private profileSource = new BehaviorSubject<Profile>(null);
  profile$ = this.profileSource.asObservable();

  constructor(private http: JsonHttp) {
    if (localStorage.getItem("accessToken")) {
      this.getProfile();
    }
  }

  async getProfile(): Promise<void> {
    this.http.get(`${environment.api_url}/api/profile`).subscribe((profile) => {
      if (profile) {
        this.setProfile(profile);
      }
    });
  }

  login(username: string, password: string) {
    return this.http
      .post(
        `${environment.api_url}/api/auth`,
        JSON.stringify({ username: username, password: password })
      )
      .pipe(
        map((response) => {
          if (response && response.token) {
            localStorage.setItem("accessToken", response.token);
            this.getProfile();
          }
        })
      );
  }

  logout(): void {
    localStorage.removeItem("accessToken");
  }

  setProfile(profile: Profile) {
    this.profileSource.next(profile);
  }
}
