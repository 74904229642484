import { NgbDateStruct          } from '@ng-bootstrap/ng-bootstrap';

export class DashboardSearchParams {
    public searchEP?         :string = "";
}

export class Dashboard {
 holdingpending0?: number;
 holdingpending1?: number;
 holdingpending2?: number;
 holdingpending3?: number;
 
 invoiced0?: number;
 invoiced1?: number;
 invoiced2?: number;
 invoiced3?: number;
 
 subUnpaid0?: number;
 subUnpaid1?: number;
 subUnpaid2?: number;
 subUnpaid3?: number;

 case0?: number;
 case1?: number;
 case2?: number;
 case3?: number;
 case4?: number;
 case5?: number;
 case6?: number;

 plannedcrm0?: number;
 plannedcrm1?: number;
 plannedcrm2?: number;

 totalnumber0?: number;
 totalnumber1?: number;
 totalnumber2?: number;
 totalnumber3?: number;
 totalnumber4?: number;
 totalnumber5?: number;
 
 mynumber0?: number;
 mynumber1?: number;
 mynumber2?: number;
 mynumber3?: number;
 mynumber4?: number;
 mynumber5?: number;
 
 lapsednm0?: number;
 lapsednm1?: number;
 lapsednm2?: number;
 lapsednm3?: number;
 
 lapsedcm0?: number;
 lapsedcm1?: number;
 lapsedcm2?: number;
 lapsedcm3?: number;
 
 currentcm0?: number;
 currentcm1?: number;
 currentcm2?: number;
 currentcm3?: number;
 currentcm4?: number;
 
 arrivingm0?: number;
 arrivingm1?: number;

 todaycrm0?: number;
 todaycrm1?: number;
 todaycrm2?: number;
 todaycrm3?: number;
 todaycrm4?: number;
 todaycrm5?: number;
 todaycrm6?: number;
 todaycrm7?: number;

 epcrm0?: number;
 epcrm1?: number;
 epcrm2?: number;
 epcrm3?: number;
 epcrm4?: number;
 epcrm5?: number;
 epcrm6?: number;
 epcrm7?: number;

 admincrm0?: number;
 admincrm1?: number;
 admincrm2?: number;
 admincrm3?: number;
 admincrm4?: number;
 admincrm5?: number;
 admincrm6?: number;
 admincrm7?: number;


 next5crm0?: number;
 next5crm1?: number;
 next5crm2?: number;
 next5crm3?: number;
 next5crm4?: number;
 next5crm5?: number;
 next5crm6?: number;
 next5crm7?: number;

 todaydone0?: number;
 todaydone1?: number;
 todaydone2?: number;
 todaydone3?: number;
 todaydone4?: number;
 todaydone5?: number;
 todaydone5b?: number;
 todaydone6?: number;
 todaydone7?: number;
 todaydone8?: number;

 lapsedappsub0?: number;
 lapsedappsub1?: number;
 lapsedappsub2?: number;
 lapsedappsub3?: number;
 lapsedappsub4?: number;
 lapsedappsub5?: number;
 lapsedappsub6?: number;

 lapsedpendingdoc0?: number;
 lapsedpendingdoc1?: number;
 lapsedpendingdoc2?: number;
 lapsedpendingdoc3?: number;
 lapsedpendingdoc4?: number;
 lapsedpendingdoc5?: number;
 lapsedpendingdoc6?: number;

 lapsedconoffer0?: number;
 lapsedconoffer1?: number;
 lapsedconoffer2?: number;
 lapsedconoffer3?: number;
 lapsedconoffer4?: number;
 lapsedconoffer5?: number;
 lapsedconoffer6?: number;

 lapsedunconoffer0?: number;
 lapsedunconoffer1?: number;
 lapsedunconoffer2?: number;
 lapsedunconoffer3?: number;
 lapsedunconoffer4?: number;
 lapsedunconoffer5?: number;
 lapsedunconoffer6?: number;
}

export class DashboardParams extends Dashboard {
    
}