import { Component, OnInit } from '@angular/core';
//import { CodeService       } from './core/services/code.service';
declare var require: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
//,'../../node_modules/bootstrap/dist/css/bootstrap.css'
export class AppComponent implements OnInit {
  
  version: string = require( '../../package.json').version;

  constructor() { 
  }
  
  ngOnInit() {
  }  
}
