import { Component, OnInit }      from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, 
         FormControl, 
         Validators,
         ValidatorFn  }           from '@angular/forms';
import { UserService  }           from '../../services/user.service';
import { User         }           from '../../model/dto';
  
@Component({
  selector   : 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  loading = false;

  userForm            : FormGroup;
  name                : FormControl;
  email               : FormControl;
  password            : FormControl;
  passwordConfirmation: FormControl;
  phoneNumber         : FormControl;

  user                : User;  
  
  constructor(
              private route : ActivatedRoute,
              private router: Router,
              private userService: UserService) { }
  
  ngOnInit() {
    this.route.data.subscribe(data => {
                                        this.user = data['profile'];
                                        console.log("user : " + JSON.stringify(this.user));
                                        this.initForm();
                              },
                              error => {
                                console.log("error : ", error);
                                if(error.status == '401') {
                                  this.router.navigate(['/logout']);          
                                }              
                              });  
  }
      
  onSubmit(userParams) {
    
    //values(this.myForm.controls).forEach(c => c.markAsTouched());

    if (!this.userForm.valid) return;
    
    userParams.id = this.user.id;
    
    this.loading  = true;
    this.userService.update(userParams)
        .subscribe(
            data => {
                //this.alertService.success('Registration successful', true);
              this.router.navigate(['/home']);
          },
          error => {
              //this.alertService.error(error);
              this.loading = false;
          });
  }

  private initForm() {
    this.name                 = new FormControl(this.user.name    , Validators.compose( [
                                                                          Validators.required,
                                                                          Validators.minLength(3)
                                                                        ]));
    this.email                = new FormControl(this.user.email   , Validators.compose( [
                                                                          Validators.required,
                                                                          Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
                                                                        ]));
    this.password             = new FormControl('', Validators.compose( [
                                                                          Validators.required,
                                                                          Validators.minLength(4)
                                                                        ]));
    this.passwordConfirmation = new FormControl('', Validators.compose( [
                                                                          Validators.required
                                                                        ]));
    
    this.phoneNumber          = new FormControl(this.user.phoneNumber, Validators.compose( [
                                                                         Validators.required
                                                                       ]));
    
    this.userForm = new FormGroup({
      name                  : this.name,
      email                 : this.email,
      password              : this.password,
      passwordConfirmation  : this.passwordConfirmation,
      phoneNumber           : this.phoneNumber,
    }, this.match(this.password, this.passwordConfirmation));
  }  
  
  match(c1: FormControl, c2: FormControl): ValidatorFn {
    return (): {[key: string]: any} =>  {
                                          if (c1.value !== c2.value) return {unmatched: true};
                                          return {};
                                        };
  }  
}
