import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SchoolFilesParams } from "../model/school-files";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/schoolFiles`;

@Injectable()
export class SchoolFilesService {
  constructor(private http: JsonHttp) {}

  searchSchoolFiles(searchParams, page) {
    let params: string = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;

    ////console.log(params);

    return this.http.get(params);
  }

  getList(schoolId: number) {
    return this.http.get(`${url}/school/${schoolId}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: SchoolFilesParams) {
    return this.http.post(url, params);
  }

  update(params: SchoolFilesParams) {
    return this.http.put(url, params);
  }

  delete(params: SchoolFilesParams) {
    return this.http.delete(url, params);
  }
}
