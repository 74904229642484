import { Component, OnInit }      from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, 
         FormControl, 
         Validators,
         ValidatorFn    }         from '@angular/forms';
import { UserService    }         from '../../services/user.service';
import  * as _                    from 'lodash';

@Component({
  selector   : 'app-register',
  templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
  loading = false;

  userForm            : FormGroup;
  name                : FormControl;
  email               : FormControl;
  password            : FormControl;
  passwordConfirmation: FormControl;
  phoneNumber         : FormControl;

  constructor(
              private route : ActivatedRoute,
              private router: Router,
              private userService: UserService) { }
  
  ngOnInit() {
    this.initForm();
  }
      
  onSubmit(userParams) {
    
    _.values(this.userForm.controls).forEach(c => c.markAsTouched());
    
    if (!this.userForm.valid) return;
    
    this.loading = true;
    this.userService.create(userParams)
        .subscribe(
            data => {
              this.router.navigate(['/login']);
          },
          error => {
              this.loading = false;
          });
  }

  private initForm() {
    this.name                 = new FormControl('', Validators.compose( [
                                                                          Validators.required,
                                                                          Validators.minLength(3)
                                                                        ]));
    this.email                = new FormControl('', Validators.compose( [
                                                                          Validators.required,
                                                                          Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
                                                                        ]));
    this.password             = new FormControl('', Validators.compose( [
                                                                          Validators.required,
                                                                          Validators.minLength(4)
                                                                        ]));
    this.passwordConfirmation = new FormControl('', Validators.compose( [
                                                                          Validators.required
                                                                        ]));
    
    this.phoneNumber          = new FormControl('', Validators.compose( [
                                                                         Validators.required
                                                                       ]));
    this.userForm = new FormGroup({
      name                  : this.name,
      email                 : this.email,
      password              : this.password,
      passwordConfirmation  : this.passwordConfirmation,
      phoneNumber           : this.phoneNumber//,
    }, this.match(this.password, this.passwordConfirmation));
  }  
  
  match(c1: FormControl, c2: FormControl): ValidatorFn {
    return (): {[key: string]: any} =>  {
                                          if (c1.value !== c2.value) return {unmatched: true};
                                          return {};
                                        };
  }
}
