import { AgentFiles     } from './agent-files';

export class Agent {
    id: number;
    name?: string;
    phone?: string;
    marketCountry?: string | string[];
    basedCountry?: string;
    basedCity?: string;
    representative?: string;
    email?: string;
    managingEP?: string;
    active?: boolean;
    commissionRateNZ?: number;
    commissionRateAU?: number;
    memo?: string;
    createDate?: string;
    createUser?: string;
    updateDate?: string;
    updateUser?: string;
    agentCode?: string;
    code?: string;
    
    agentFilesParams = new Array<AgentFiles>();
}

export class AgentParams extends Agent {
}


