export class CodeItem {
    code: string;
    value: any;
    id: string | number;
    masterCode?: string;
    value2?: string;
    value3?: string;
    value4?: string;
    value5?: string;
    value6?: string;
    orderIdx?: number;
    parentId?: string | number;
    parentCode?: string;
    isActive?: boolean;
    createdBy?: string;
    createdOnDate?: string;
    createdOnTime?: string;
    updatedBy?: string;
    updatedOnDate?: string;
    updatedOnTime?: string;

    constructor(
        code: string,
        value: any = undefined,
        id?: string | number,
        masterCode?: string,
        value2?: string,
        value3?: string,
        value4?: string,
        value5?: string,
        value6?: string,
        orderIdx?: number,
        parentId?: string | number,
        parentCode?: string,
        isActive?: boolean,
        createdBy?: string,
        createdOnDate?: string,
        createdOnTime?: string,
        updatedBy?: string,
        updatedOnDate?: string,
        updatedOnTime?: string,
    ) {
        this.code = code;
        this.value = value;
        this.id = id;
        this.masterCode = masterCode;
        this.value2 = value2;
        this.value3 = value3;
        this.value4 = value4;
        this.value5 = value5;
        this.value6 = value6;
        this.orderIdx = orderIdx;
        this.parentId = parentId;
        this.parentCode = parentCode;
        this.isActive = isActive;
        this.createdBy = createdBy;
        this.createdOnDate = createdOnDate;
        this.createdOnTime = createdOnTime;
        this.updatedBy = updatedBy;
        this.updatedOnDate = updatedOnDate;
        this.updatedOnTime = updatedOnTime;

    }
}