import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
//import { CloseScrollStrategy } from '@angular/material';
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { FileUploader } from "ng2-file-upload";
import { Popup } from "ng2-opd-popup";
import { filter } from "rxjs/operators";
import { School } from "../../model/school";
import { SchoolFiles } from "../../model/school-files";
import { SchoolGdlinks } from "../../model/school-gdlinks";
import { SchoolUpdate } from "../../model/school-update";
import { AuthenticationService } from "../../services/authentication.service";
import { SchoolFilesService } from "../../services/school-files.service";
import { SchoolGdlinksService } from "../../services/school-gdlinks-service";
import { SchoolUpdateService } from "../../services/school-update.service";
import { SchoolService } from "../../services/school.service";



@Component({
  selector: "app-school",
  providers: [
    SchoolService,
    SchoolUpdateService,
    SchoolGdlinksService,
    SchoolFilesService,
  ],
  templateUrl: "./school.component.html",
  styleUrls: ["./school.component.css"],
})
export class SchoolComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  schoolCodeValueToPass: string;

  public searchTerm: string;

  public country: string;

  public schoolUpdate: string = "";

  public schoolFiles = new Array<SchoolFiles>();

  processedCount = 0;
  totalFiles = 0;

  public uploader: FileUploader = new FileUploader({ queueLimit: 999 });
  public uploader2: FileUploader = new FileUploader({ queueLimit: 999 });
  public uploader3: FileUploader = new FileUploader({ queueLimit: 999 }); //0913

  authority: string;
  name: string;
  company: string;

  createUpdateButtonDisabled: boolean = false;

  isLoading : boolean  = false;  // 로딩 상태 변수

  //myOptions1         : IMultiSelectOption[] = [];
  myOptions2: IMultiSelectOption[] = [];

  option1var: any = [];
  option2var: any = [];

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  public selectedAdminTypes: any[] = [];
  public gdlinks: SchoolGdlinks[] = [];

  // FIXME: 여기 부분 코드 api 사용해서 처리하기. 

  countryOptions = [
    { name: "NZ" },
    { name: "AUS" },
    { name: "UK" },
    { name: "CA" },
    { name: "US" },
    { name: "PHP" },
    { name: "Ireland" },
  ];

  adminTypes = [
    { name: "UAC" },
    { name: "VTAC" },
    { name: "Study link" },
    { name: "QTAC" },
    { name: "Direct School Portal" },
    { name: "Study Group Portal" },
    { name: "Navitas" },
    { name: "Enroller" },
    { name: "Paper" },
    { name: "Others" },
  ];

  courseTypes = [
    { name: "NZ ELICOS", code: "ELICOS" },
    { name: "NZ PTE", code: "NZ PTE" },
    { name: "NZ Uni&ITP", code: "NZ Uni/Poly" },
    { name: "NZ Primary&Secondary", code: "Secondary" },
    { name: "AU Uni&TAFE", code: "AU Uni" },
    { name: "AU PTE", code: "AU PTE" },
    { name: "AU Secondary", code: "AU Secondary" },
    { name: "Canada", code: "Canada" },
    { name: "UK", code: "UK" },
    { name: "Others", code: "Others" },
    { name: "Visa&Insurance", code: "Service" },
    { name: "Immigration", code: "Immigration" },
  ];

  regionOptions: IMultiSelectOption[] = [
    { id: "Ashburton", name: "Ashburton" },
    { id: "Auckland", name: "Auckland" },
    { id: "Blenheim", name: "Blenheim" },
    { id: "Cambridge", name: "Cambridge" },
    { id: "Christchurch", name: "Christchurch" },
    { id: "Dunedin", name: "Dunedin" },
    { id: "Gisborne", name: "Gisborne" },
    { id: "Hamilton", name: "Hamilton" },
    { id: "Invercargill", name: "Invercargill" },
    { id: "Lower Hutt", name: "Lower Hutt" },
    { id: "Napier", name: "Napier" },
    { id: "Nelson", name: "Nelson" },
    { id: "New Plymouth", name: "New Plymouth" },
    { id: "Palmerston North", name: "Palmerston North" },
    { id: "Porirua", name: "Porirua" },
    { id: "Queenstown", name: "Queenstown" },
    { id: "Rangiora", name: "Rangiora" },
    { id: "Rotorua", name: "Rotorua" },
    { id: "Taupo", name: "Taupo" },
    { id: "Tauranga", name: "Tauranga" },
    { id: "Timaru", name: "Timaru" },
    { id: "Upper Hutt", name: "Upper Hutt" },
    { id: "Wanaka", name: "Wanaka" },
    { id: "Wellington", name: "Wellington" },
    { id: "Whanganui", name: "Whanganui" },
    { id: "Whangarei", name: "Whangarei" },
  ];

  fourthCategoryOptions: IMultiSelectOption[] = [
    { id: "NZ PTE Course Summary", name: "Course Summary" },
    { id: "NZ PTE Admin Update", name: "Admin Update" },
    { id: "NZ PTE Meeting/Visit", name: "Meeting/Visit" },
    { id: "NZ PTE Scholarship", name: "Scholarship" },
    { id: "NZ PTE Case Study", name: "Case Study" },
    { id: "NZ PTE Visa", name: "Visa" },
    { id: "NZ PTE Others", name: "Others" },
    { id: "NZ PTE Newsletter", name: "Newsletter" },
    { id: "NZ PTE Tuition Fee", name: "Tuition Fee" },
  ];

  courseTypesForMultiChoiceOptions: IMultiSelectOption[] = [
    { name: "NZ ELICOS", id: "ELICOS" },
    { name: "NZ PTE", id: "NZ PTE" },
    { name: "NZ Uni&ITP", id: "NZ Uni/Poly" },
    { name: "NZ Primary&Secondary", id: "Secondary" },
    { name: "AU ELICOS", id: "AU ELICOS" },
    { name: "AU Uni&TAFE", id: "AU Uni" },
    { name: "AU PTE", id: "AU PTE" },
    { name: "AU Secondary", id: "AU Secondary" },
    { name: "Canada", id: "Canada" },
    { name: "UK", id: "UK" },
    { name: "Others", id: "Others" },
    { name: "Visa&Insurance", id: "Service" },
    { name: "Immigration", id: "Immigration" },
  ];

  public form: FormGroup;

  public maxLength = 2000;  //0917 최대 글자 수 제한
  public currentLength = 0;  //0917 현재 입력된 글자 수

  public schools: School[] = [];
  public selectedSchool: School;

  constructor(
    formBuilder: FormBuilder,
    private schoolFilesService: SchoolFilesService,
    private schoolService: SchoolService,
    private schoolUpdateService: SchoolUpdateService,
    private schoolgdLinksService: SchoolGdlinksService,
    private authenticationService: AuthenticationService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.form = formBuilder.group({
      id: formBuilder.control(null, []),
      name: formBuilder.control(null, [Validators.required]),
      address: formBuilder.control(null, []),
      location: formBuilder.control(null, []),
      courseType: formBuilder.control(null, []),
      region: formBuilder.control(null, []),
      courseTypes: formBuilder.control(null, []),
      phone: formBuilder.control(null, []),
      fax: formBuilder.control(null, []),
      email: formBuilder.control(null, []),
      website: formBuilder.control(null, []),
      display: formBuilder.control(true, []),
      schoolInfo: formBuilder.control(null, []),
      //schoolContent: formBuilder.control(null, []),
      schoolContent: ['', [ Validators.maxLength(this.maxLength)]], //0917
      generalManagerInfo: formBuilder.control(null, []),
      admissionStaffInfo: formBuilder.control(null, []),
      invoiceStaffInfo: formBuilder.control(null, []),
      keyAdminInfo: formBuilder.control(null, []),
      applicationFee: formBuilder.control(null, []),
      internalPortal: formBuilder.control(null, []),
      adminNote: formBuilder.control(null, []),
      agentChange: formBuilder.control(null, []),
      agentChangeYN: formBuilder.control(null, []),
      territoryRestrict: formBuilder.control(null, []),
      territoryRestrictYN: formBuilder.control(null, []),
      under18: formBuilder.control(null, []),
      under18YN: formBuilder.control(null, []),
      crmRate: formBuilder.control(null, []),
      accountEmail: formBuilder.control(null, []),
      commissionRate: formBuilder.control(null, []),
      commissionClaim: formBuilder.control(null, []),
    });
    // 0917 초기 글자 수 설정 (폼이 변경될 때마다 감지)
    this.form.get('schoolContent')?.valueChanges.subscribe(value => {
      this.currentLength = value ? value.length : 0;
      //console.log("value.length: " + value);
    });
  }

  onFileWithoutDeleting(schoolFiles: SchoolFiles[]): SchoolFiles[] {
    let result: SchoolFiles[] = [];

    for (var i = 0; schoolFiles != undefined && i < schoolFiles.length; i++) {
      //console.log("informationFiles["+i+"].mode : " + informationFiles[i].mode);
      if (schoolFiles[i].mode === undefined || schoolFiles[i].mode == "") {
        result.push(schoolFiles[i]);
      }
    }
    return result;
  }

  readQueue(action) {
    for (var idx = 0; idx < this.uploader.queue.length; idx++) {
      this.readFile(action, this.uploader.queue[idx]._file, 0);
    }
    for (var idx = 0; idx < this.uploader2.queue.length; idx++) {
      this.readFile(action, this.uploader2.queue[idx]._file, 1);
    }
    //0913
    for (var idx = 0; idx < this.uploader3.queue.length; idx++) {
      this.readFile(action, this.uploader3.queue[idx]._file, 2);
    }
  }

  readFile(action, f, numb) {
    var fileReader = new FileReader();

    var that = this;
    fileReader.onload = function () {
      console.log("content   : " + window.btoa(fileReader.result as string));
      that.addFile(
        action,
        window.btoa(fileReader.result as string),
        f.name,
        f.size,
        numb
      );
    };

    fileReader.readAsBinaryString(f);
  }

  addFile(action, result, filename, filesize, numb) {
    let informationFile = new SchoolFiles();

    informationFile.filesize = filesize;
    informationFile.filename = filename;
    informationFile.file = result;

    if (numb == 0) {
      informationFile.mode = "I";
      this.schoolFiles.push(informationFile);
    } else if (numb == 1) {
      informationFile.mode = "1";
      this.schoolFiles.push(informationFile);
    } else if (numb == 2) {
      informationFile.mode = "2";
      this.schoolFiles.push(informationFile);
    }
    console.log("informationFile.mode : " + informationFile.mode); 

    this.processedCount++;

    console.log('this.processedCount : ' + this.processedCount + ', this.totalFiles : ' + this.totalFiles);

    this.schoolFiles.forEach(function (elem) {
      console.log("File Name : " + elem.filename);
    });

    if (this.processedCount == this.totalFiles) {
      this.onSubmitWroker(action);
    }
  }

  clickOKButton() {
    this.popup1.hide();
  }

  refreshData(form: any) {
    this.selectedAdminTypes["UAC"] = false;
    this.selectedAdminTypes["VTAC"] = false;
    this.selectedAdminTypes["Study link"] = false;
    this.selectedAdminTypes["QTAC"] = false;
    this.selectedAdminTypes["Direct School Portal"] = false;
    this.selectedAdminTypes["Study Group Portal"] = false;
    this.selectedAdminTypes["Navitas"] = false;
    this.selectedAdminTypes["Enroller"] = false;
    this.selectedAdminTypes["Paper"] = false;
    this.selectedAdminTypes["Others"] = false;

    form.patchValue({
      id: null,
      name: null,
      address: null,
      location: "NZ",
      courseType: null,
      region: null,
      courseTypes: null,
      phone: null,
      fax: null,
      email: null,
      website: null,
      display: true,
      schoolInfo: null,
      schoolContent: null,
      generalManagerInfo: null,
      admissionStaffInfo: null,
      invoiceStaffInfo: null,
      keyAdminInfo: null,
      applicationFee: null,
      internalPortal: null,
      adminNote: null,
      agentChange: null,
      agentChangeYN: null,
      territoryRestrict: null,
      territoryRestrictYN: null,
      under18: null,
      under18YN: null,
      crmRate: null,
      accountEmail: null,
      commissionRate: null,
      commissionClaim: null,
    });

    this.gdlinks = [];

    return form;
  }

  resetData(school: School) {
    school = new School();
    school.display = true;
    return school;
  }

  ngOnInit() {
    this.schoolService.getAll().subscribe((data) => {
      this.schools = data.content;      
    });

    this.selectSchool(null);

    this.schoolCodeValueToPass = "";
  }

  onSubmit(school: School) {
    this.createUpdateButtonDisabled = true;

    this.totalFiles = this.uploader.queue.length;
    this.totalFiles += this.uploader2.queue.length;
    this.totalFiles += this.uploader3.queue.length; //0913
    console.log("this.uploader3: " + this.uploader3);
    if (this.totalFiles > 0) {
      this.readQueue(school);
    } else {
      this.onSubmitWroker(school);
    }
  }

  onSubmitWroker(school: School) {
    school.schoolFilesParams = new Array<SchoolFiles>();

    for (var i = 0; i < this.schoolFiles.length; i++) {
      school.schoolFilesParams.push(this.schoolFiles[i]);
    }

    school.adminType = Object.keys(this.selectedAdminTypes)
      .filter((k) => this.selectedAdminTypes[k])
      .map(String)
      .join(", ");

    school.region =
      _.size(school.region) > 0
        ? _.map(school.region, (r) => r.id).join(", ")
        : "";

    school.courseTypes =
      _.size(school.courseTypes) > 0
        ? _.map(school.courseTypes, (r) => r.id).join(", ")
        : "";

    school.schoolGdlinksList = this.gdlinks;

    console.log("course Types?: " + school.courseTypes);

    for (var i = 0; i < this.gdlinks.length; i++) {
      // console.log("GD LINK PROVIDED: ");
      // console.log(this.gdlinks[i].bucket);
      // console.log(this.gdlinks[i].gdlink);
    }

    if (school.id) {
      this.schoolService.update(school).subscribe((data) => {
        this.schools = _.map(this.schools, (school) => {
          return school.id === data.id ? data : school;
        });

        this.uploader = new FileUploader({ queueLimit: 999 });
        this.uploader2 = new FileUploader({ queueLimit: 999 });
        this.uploader3 = new FileUploader({ queueLimit: 999 }); //0913

        this.processedCount = 0;
        this.totalFiles = 0;

        console.log("update successful");

        this.schoolService.getAll().subscribe((data) => {
          this.schools = data.content;

          for (var i = 0; i < this.schools.length; i++) {
            if (this.schools[i].id == this.selectedSchool.id) {
              this.selectedSchool = this.schools[i];
              this.selectSchool(this.selectedSchool);
            }
          }

          this.createUpdateButtonDisabled = false;
        });

        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      });
    } else {
      var schoolselector = "";

      this.schoolService.create(school).subscribe((data) => {
        this.schools = [data, ...this.schools];

        this.uploader = new FileUploader({ queueLimit: 999 });
        this.uploader2 = new FileUploader({ queueLimit: 999 });
        this.uploader3 = new FileUploader({ queueLimit: 999 }); //0913

        this.processedCount = 0;
        this.totalFiles = 0;

        schoolselector = school.name;

        this.schoolService.getAll().subscribe((data) => {
          this.schools = data.content;

          for (var i = 0; i < this.schools.length; i++) {
            if (this.schools[i].name == schoolselector) {
              this.selectedSchool = this.schools[i];
              this.selectSchool(this.selectedSchool);
            }
          }
        });

        this.createUpdateButtonDisabled = false;
      });

      console.log("create successful");

      this.popupMessage = "Succeed in creating";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c", // red, blue....
        widthProsentage: 20, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
    }
  }

  onDeleteFile(fileName, numb) {
    this.schoolFiles.find(function (item) {
      return item.filename === fileName;
    }).mode = "D";
  }

  getFiles() {
    if (
      this.selectedSchool.id !== undefined &&
      this.selectedSchool.id != null
    ) {
      this.isLoading = true;
      this.schoolFilesService.getList(this.selectedSchool.id).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.length : " + data.length);

          this.schoolFiles = new Array<SchoolFiles>();
          data.forEach((file) => this.schoolFiles.push(file));
          //console.log(this.schoolFiles.length);
          this.isLoading = false;
        },
        (error) => {
          console.log("error : ", error);
        }
      );
    }
  }

  selectSchool(school: School) {
    this.form = this.refreshData(this.form);

    if (school == null) school = this.resetData(school);

    this.selectedSchool = school;

    if (school) {
      if (school.id != null) {
        this.schoolUpdateService.getList(school.id).subscribe((data) => {
          this.selectedSchool.schoolUpdateList = data;
        });

        this.schoolgdLinksService.getList(school.id).subscribe((data) => {
          this.selectedSchool.schoolGdlinksList = data;
          this.gdlinks = this.selectedSchool.schoolGdlinksList;
        });
      }

      if (school.adminType) {
        _.forEach(
          school.adminType.split(", "),
          (at) => (this.selectedAdminTypes[at] = true)
        );
      }

      this.form.patchValue({
        id: school.id,
        name: school.name,
        address: school.address,
        location: school.location,
        courseType: school.courseType
          ? school.courseType
          : school.courseTypes
          ? (<string>school.courseTypes).split(", ")[0]
          : null,
        region: school.region ? (<string>school.region).split(", ") : [],
        courseTypes: school.courseTypes
          ? (<string>school.courseTypes).split(", ").map((item) => {
              return {
                id: item,
                name: this.courseTypesForMultiChoiceOptions.filter(
                  (i) => i.id === item
                )[0].name,
              };
            })
          : [],
        phone: school.phone,
        fax: school.fax,
        email: school.email,
        website: school.website,
        display: school.display,
        schoolInfo: school.schoolInfo,
        schoolContent: school.schoolContent,
        generalManagerInfo: school.generalManagerInfo,
        admissionStaffInfo: school.admissionStaffInfo,
        invoiceStaffInfo: school.invoiceStaffInfo,
        keyAdminInfo: school.keyAdminInfo,
        applicationFee: school.applicationFee,
        internalPortal: school.internalPortal,
        adminNote: school.adminNote,
        agentChange: school.agentChange,
        agentChangeYN: school.agentChangeYN ? "true" : "false",
        territoryRestrict: school.territoryRestrict,
        territoryRestrictYN: school.territoryRestrictYN ? "true" : "false",
        under18: school.under18,
        under18YN: school.under18YN ? "true" : "false",
        crmRate: school.crmRate,
        accountEmail: school.accountEmail,
        commissionRate: school.commissionRate,
        commissionClaim: school.commissionClaim,
      });

      if (this.selectedSchool.code != null) {
        this.schoolCodeValueToPass = this.selectedSchool.code;
      } else {
        this.schoolCodeValueToPass = "ThisWouldNotBeSelectable";
      }
    }

    this.getFiles();
  }

  openFile(fileContent: string, fileName: string): void {
    console.log('fileContent:', fileContent);
    console.log('fileName:', fileName);
    // 파일 확장자를 기반으로 MIME 타입 결정
    const extension = fileName.split('.').pop().toLowerCase();
    let mimeType = 'application/octet-stream'; // 기본값
    switch (extension) {
        case 'pdf':
            mimeType = 'application/pdf';
            break;
        case 'png':
            mimeType = 'image/png';
            break;
        case 'jpg':
        case 'jpeg':
            mimeType = 'image/jpeg';
            break;
        case 'gif':
            mimeType = 'image/gif';
            break;
        // 여기에 필요한 다른 파일 형식을 추가할 수 있습니다.
    }
    console.log('mimeType:', mimeType);
    // fileContent를 Blob으로 변환
    const byteCharacters = atob(fileContent);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Blob URL 생성
    const blobUrl = URL.createObjectURL(blob);
    console.log('Url:', blobUrl);
    // 파일을 브라우저로 열기 위한 링크 생성
    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank'; // 새 창에서 열도록 설정
    document.body.appendChild(link); // DOM에 링크 요소 추가
    link.click();
    document.body.removeChild(link); // 사용 후 링크 요소 제거
  }
  openPdf(fileContent: string): void {
    // fileContent를 Blob으로 변환
    const byteCharacters = atob(fileContent);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Blob URL 생성
    const blobUrl = URL.createObjectURL(blob);

    // PDF 파일을 브라우저로 열기 위한 링크 생성
    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank'; // 새 창에서 열도록 설정
    link.click();
  }

  onSaveFile(fileContent, fileName) {
    //this.seletedImage = val;
    //console.log("onDisplayImage");
    //this.imageModal.show();

    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }

  addFunction(code: string) {
    /*
    Application form
    Agent form
    Accounting Agent Agreement
    */

    var gdlink = new SchoolGdlinks();

    if (
      code == "Application form" ||
      code == "Agent form" ||
      code == "Accounting Agent Agreement"
    ) {
      gdlink.gdlink = "";
      gdlink.gdname = "";
      gdlink.isDelete = false;
      gdlink.bucket = code;
    }

    if (this.gdlinks == null) {
      this.gdlinks = new Array<SchoolGdlinks>(gdlink);
    } else {
      this.gdlinks.unshift(gdlink);
    }
  }

  deleteFunction(id: number) {
    for (var i = 0; i < this.gdlinks.length; i++) {
      if (this.gdlinks[i].id == id) {
        this.gdlinks[i].isDelete = true;
      }
    }
  }

  createSchoolUpdate() {
    let schoolUpdate = new SchoolUpdate();
    schoolUpdate.update = this.schoolUpdate;
    schoolUpdate.createUser = this.name;
    schoolUpdate.schoolId = this.selectedSchool.id;

    this.schoolUpdateService.create(schoolUpdate).subscribe((data) => {
      this.schoolUpdate = "";
      if (this.selectedSchool.schoolUpdateList) {
        this.selectedSchool.schoolUpdateList = [
          data,
          ...this.selectedSchool.schoolUpdateList,
        ];
      } else {
        this.selectedSchool.schoolUpdateList = [data];
      }
    });
  }

  deleteSchoolUpdate(schoolUpdate: SchoolUpdate) {
    this.schoolUpdateService.delete(schoolUpdate).subscribe((data) => {
      this.selectedSchool.schoolUpdateList = _.filter(
        this.selectedSchool.schoolUpdateList,
        (su) => su.id != data.id
      );
    });
  }
}
