export class ApplicationSearchParams {
    public realType?  :string;
    public kname?     :string;
    public email?     :string;
    public tertiaryId?:number;
    public event?     :string;
    public answered?     :boolean;
}

export class Application {
    id:string|number;
    kname?:string;
    cafe?:string;
    email?:string;
    phone?:string;
    addr?:string;
    ename?:string;
    dob?:string;
    gender?:string;
    passportNo?:string;
    passportExp?:string;
    identifyNo?:string;
    identifyIssue?:string;
    job?:string;
    nzEstDate?:string;
    health1?:string;
    health2?:string;
    health3?:string;
    health4?:string;
    crime1?:string;
    crime2?:string;
    crime3?:string;
    crime4?:string;
    signature?:string;
    processing?:string;
    realType?:string;
    basicId?:string;
    maybeBasicId?:string;
    passportImage?:string;
    passportName?:string;
    passpportType?:string;
    passportImage2?:string;
    passportName2?:string;
    passportType2?:string;
    specialize?:string;
    nzcertiEstDate?:string;
    kakao?:string;
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
    realTypeName?:string;
}

export class ApplicationParams extends Application {
}
