import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import { Popup } from "ng2-opd-popup";
import { filter, switchMap } from "rxjs/operators";
import { Basic } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { Event } from "../../model/event";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import { EventService } from "../../services/event.service";

@Component({
  selector: "app-event-detail",
  providers: [EventService, BasicService, DatePipe],
  templateUrl: "./event-detail.component.html",
})
export class EventDetailComponent implements OnInit {
  public eventRows = new Array<Event>();

  public selectedEventRow = new Event();

  //@ViewChild('eventModal')  public eventModal:ModalDirective;
  //@ViewChild('childModal')  public childModal:ModalDirective;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  selected = [];

  selectedBasicId: number;
  basic = new Basic();

  schoolTypeList: any[] = [];
  schoolNameList: any[] = [];

  authority: string;
  name: string;
  company: string;

  fromEventDetail: string;
  fromEventDetailData: string;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private authenticationService: AuthenticationService,
    private eventService: EventService,
    private basicService: BasicService,
    private codeService: CodeService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.schoolTypeList = codeCommon.schoolTypeList;
    //this.schoolNameList = codeCommon.schoolNameList;
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = +params["id"];
          return this.eventService.getList(+params["id"]);
        })
      )
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));

          this.eventRows = new Array<Event>();
          data.forEach((event) => this.eventRows.push(event));
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );

    this.basicService.get(this.selectedBasicId).subscribe((basic) => {
      this.basic = basic;
    });

    this.fromEventDetail = "aaa";
    this.fromEventDetailData = "-1";
  }

  find() {
    this.eventService.getList(this.selectedBasicId).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.length : " + data.length);

        this.eventRows = new Array<Event>();
        data.forEach((event) => this.eventRows.push(event));
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  onSelectEvent({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateEvent(obj) {
    if (obj.type === "click") {
      this.fromEventDetailData = -1 + "";
      this.selectedEventRow = obj.row;
    } else if (obj.type == "dblclick") {
      if (this.selectedEventRow.tertiaryAppId == null) {
        this.fromEventDetailData = -1 + "";
      } else {
        this.fromEventDetailData = this.selectedEventRow.tertiaryAppId;
      }
    }
  }

  onCreateEvent() {
    this.selectedEventRow.basicId = this.selectedBasicId;

    this.eventService.create(this.selectedEventRow).subscribe(
      (data) => {
        //this.eventModal.hide();
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onUpdateEvent(attendance) {
    //this.selectedEventRow.basicId = this.selectedBasicId;

    this.selectedEventRow.attendance = attendance;

    this.eventService.update(this.selectedEventRow).subscribe(
      (data) => {
        this.selected = [];
        console.log("update successful");

        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        //this.eventModal.hide();
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteEvent() {
    this.eventService.delete(this.selectedEventRow).subscribe(
      (data) => {
        this.selected = [];
        console.log("delete successful");

        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        //this.eventModal.hide();
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in updating" ||
      this.popupMessage === "Succeed in deleting"
    ) {
      //this.onClear();
      this.find();

      this.basicService.get(this.selectedBasicId).subscribe((basic) => {
        this.basic = basic;
      });
    } else if (this.popupMessage === "Succeed in creating a memo") {
    }
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  goBack(): void {
    this.location.back();
  }
}
