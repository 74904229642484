import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import { Profile } from "app/model/profile";
import { cloneDeep } from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { DashboardSearchParams } from "../../model/dashboard";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-admin",
  providers: [DashboardService],
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.css"],
})
export class AdminComponent implements OnInit {
  @Input("ep") selectedEpFromParent;
  @Input("from") fromWhere;
  @Input("table1") rows2;
  @Input("table2") rows3;

  epList: any[] = [];
  authority: string;
  name: string;
  company: string;

  option1var: any = [];

  serachNotAllowed1: boolean = false;
  serachNotAllowed2: boolean = false;
  serachNotAllowed3: boolean = false;
  serachNotAllowed4: boolean = false;
  serachNotAllowed5: boolean = false;
  serachNotAllowed6: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  epOptions: IMultiSelectOption[] = [];

  public searchParams = new DashboardSearchParams();

  isReady: boolean = false;

  getDate(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10);
    } else {
      return "";
    }
  }

  getDateTime(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10) + " " + datetime.substring(11, 16);
    } else {
      return "";
    }
  }

  navigateReportView(id) {
    this.router.navigate(["/informationBoardDetail", id]);
  }

  //This is not arriving members but Today CRM and Next 5 days CRM.
  arrivingm: number[] = new Array(2).fill(0);
  //This is about OnGoing, Defer - Intake passed counts.
  cases: number[] = new Array(2).fill(0);
  todaycrm: number[] = new Array(8).fill(0);
  next5crm: number[] = new Array(8).fill(0);
  todaydone: number[] = new Array(10).fill(0);
  lapsedappsub: number[] = new Array(7).fill(0);
  lapsedpendingdoc: number[] = new Array(7).fill(0);
  lapsedconoffer: number[] = new Array(7).fill(0);
  lapsedunconoffer: number[] = new Array(7).fill(0);
  epcrm: number[] = new Array(8).fill(0);
  admincrm: number[] = new Array(8).fill(0);

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardService: DashboardService,
    private codeService: CodeService,
    private router: Router
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.epList = cloneDeep(codeCommon.epList);

    for (var i = 0; i < this.epList.length; i++) {
      this.epOptions.push({
        name: this.epList[i].value,
        id: this.epList[i].code,
      });

      if (this.name == this.epList[i].code) {
        this.option1var.push(this.epList[i].code);
      } else if (this.authority == "manager") {
        this.option1var.push(this.epList[i].code);
      }
    }

    this.isReady = true;
    /*
    this.epList =  cloneDeep(codeCommon.epList);
    
    var temp = {code: "All", value: "All"};
    this.epList.unshift(temp);
    */
  }

  ngOnInit() {}
  /*
  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedEpFromParent"]) {
      const currentValue = changes["selectedEpFromParent"].currentValue;
      const previousValue = changes["selectedEpFromParent"].previousValue;
  
      // 값이 변경되었을 때만 search() 호출
      if (currentValue !== previousValue) {
        console.log("ngOnChanges idx - 변경됨:", currentValue);
        this.search(currentValue);
      }
    }
  }*/
  
  ngOnChanges(changes: SimpleChanges) {
    var change = changes["selectedEpFromParent"];
    if (change?.currentValue.length > 0) {
      this.search(change.currentValue);
    }
  }

  navigateActionWithOnlyIdx(idx) {
    this.codeService.setSelectedEp(this.searchParams.searchEP);

    this.router.navigate(["/admission"], {
      queryParams: { action: "home", idx: idx, ep: this.searchParams.searchEP },
    });
  }

  reset() {
    this.serachNotAllowed1 = true;
    this.serachNotAllowed2 = true;
    this.serachNotAllowed3 = true;
    this.serachNotAllowed4 = true;
    this.serachNotAllowed5 = true;
    this.serachNotAllowed6 = true;

    this.arrivingm.forEach((n) => (n = 0));
    this.cases.forEach((n) => (n = 0));
    this.todaycrm.forEach((n) => (n = 0));
    this.next5crm.forEach((n) => (n = 0));
    this.todaydone.forEach((n) => (n = 0));
    this.lapsedappsub.forEach((n) => (n = 0));
    this.lapsedpendingdoc.forEach((n) => (n = 0));
    this.lapsedconoffer.forEach((n) => (n = 0));
    this.lapsedunconoffer.forEach((n) => (n = 0));
  }

  search(change?) {
    //console.log("0123!! change : "+change);

    //0114 noga 데이터 초기화 루틴 추가
    //console.log("admin before init : " + this.lapsedappsub[0]);
    
    this.arrivingm.fill(0); // arrivingm 초기화
    this.cases.fill(0); // cases 초기화
    this.todaycrm.fill(0); // todaycrm 초기화
    this.next5crm.fill(0); // next5crm 초기화
    this.todaydone.fill(0); // todaydone 초기화
    this.epcrm.fill(0); // epcrm 초기화
    this.admincrm.fill(0); // admincrm 초기화
    this.lapsedappsub.fill(0); // lapsedappsub 초기화
    this.lapsedpendingdoc.fill(0); // lapsedpendingdoc 초기화
    this.lapsedconoffer.fill(0); // lapsedconoffer 초기화
    this.lapsedunconoffer.fill(0); // lapsedunconoffer 초기화

    if (change != null) {
      this.searchParams.searchEP = change;
    } else {
      this.searchParams.searchEP = "";
      for (var i = 0; i < this.option1var.length; i++) {
        if (i == this.option1var.length - 1) {
          if (this.option1var[i].id == undefined) {
            this.searchParams.searchEP += "" + this.option1var[i] + "";
          } else {
            this.searchParams.searchEP += "" + this.option1var[i].id + "";
          }
        } else if (this.option1var[i].id == undefined) {
          this.searchParams.searchEP += "" + this.option1var[i] + "" + ",";
        } else {
          this.searchParams.searchEP += "" + this.option1var[i].id + "" + ",";
        }
      }
    }
    console.log("0123! this.searchParams.searchEP : " + this.searchParams.searchEP);
    //console.log("this.fromWhere : " + this.fromWhere);
    if (
      (this.fromWhere == null && change == null) ||
      (this.fromWhere != null && change != null)
    ) {
      this.reset();

      this.dashboardService.getAdms(this.searchParams).subscribe(
        (data) => {
          data.forEach((admin) => {
            //console.log("statistics : " + JSON.stringify(statistics));
            this.arrivingm[0] += +admin.arrivingm0;
            this.arrivingm[1] += +admin.arrivingm1;

            this.cases[0] += +admin.case0;
            this.cases[1] += +admin.case1;

            this.todaycrm[0] += +admin.todaycrm0;
            this.todaycrm[1] += +admin.todaycrm1;
            this.todaycrm[2] += +admin.todaycrm2;
            this.todaycrm[3] += +admin.todaycrm3;
            this.todaycrm[4] += +admin.todaycrm4;
            this.todaycrm[5] += +admin.todaycrm5;
            this.todaycrm[6] += +admin.todaycrm6;
            this.todaycrm[7] += +admin.todaycrm7;
            //console.log("this.todaycrm[7] : " + this.todaycrm[7]);
            //console.log("this.todaycrm[2] : " + this.todaycrm[2]);
            //console.log("this.todaycrm[3] : " + this.todaycrm[3]);
            this.next5crm[0] += +admin.next5crm0;
            this.next5crm[1] += +admin.next5crm1;
            this.next5crm[2] += +admin.next5crm2;
            this.next5crm[3] += +admin.next5crm3;
            this.next5crm[4] += +admin.next5crm4;
            this.next5crm[5] += +admin.next5crm5;
            this.next5crm[6] += +admin.next5crm6;
            this.next5crm[7] += +admin.next5crm7;

            //0226
            this.epcrm[0] += +admin.epcrm0;
            this.epcrm[1] += +admin.epcrm1;
            this.epcrm[2] += +admin.epcrm2;
            this.epcrm[3] += +admin.epcrm3;
            this.epcrm[4] += +admin.epcrm4;
            this.epcrm[5] += +admin.epcrm5;
            this.epcrm[6] += +admin.epcrm6;
            this.epcrm[7] += +admin.epcrm7;

            this.admincrm[0] += +admin.admincrm0;
            this.admincrm[1] += +admin.admincrm1;
            this.admincrm[2] += +admin.admincrm2;
            this.admincrm[3] += +admin.admincrm3;
            this.admincrm[4] += +admin.admincrm4;
            this.admincrm[5] += +admin.admincrm5;
            this.admincrm[6] += +admin.admincrm6;
            this.admincrm[7] += +admin.admincrm7;
            //0226
            
            this.todaydone[0] += +admin.todaydone0;
            this.todaydone[1] += +admin.todaydone1;
            this.todaydone[2] += +admin.todaydone2;
            this.todaydone[3] += +admin.todaydone3;
            this.todaydone[4] += +admin.todaydone4;
            this.todaydone[5] += +admin.todaydone5;
            this.todaydone[6] += +admin.todaydone6;
            this.todaydone[7] += +admin.todaydone7;
            this.todaydone[8] += +admin.todaydone8;
            this.todaydone[9] += +admin.todaydone5b;

            this.lapsedappsub[0] += +admin.lapsedappsub0;            
            this.lapsedappsub[1] += +admin.lapsedappsub1;
            this.lapsedappsub[2] += +admin.lapsedappsub2;
            this.lapsedappsub[3] += +admin.lapsedappsub3;
            this.lapsedappsub[4] += +admin.lapsedappsub4;            
            this.lapsedappsub[5] += +admin.lapsedappsub5;
            this.lapsedappsub[6] += +admin.lapsedappsub6;

            this.lapsedpendingdoc[0] += +admin.lapsedpendingdoc0;
            this.lapsedpendingdoc[1] += +admin.lapsedpendingdoc1;
            this.lapsedpendingdoc[2] += +admin.lapsedpendingdoc2;
            this.lapsedpendingdoc[3] += +admin.lapsedpendingdoc3;
            this.lapsedpendingdoc[4] += +admin.lapsedpendingdoc4;
            this.lapsedpendingdoc[5] += +admin.lapsedpendingdoc5;
            this.lapsedpendingdoc[6] += +admin.lapsedpendingdoc6;

            this.lapsedconoffer[0] += +admin.lapsedconoffer0;
            this.lapsedconoffer[1] += +admin.lapsedconoffer1;
            this.lapsedconoffer[2] += +admin.lapsedconoffer2;
            this.lapsedconoffer[3] += +admin.lapsedconoffer3;
            this.lapsedconoffer[4] += +admin.lapsedconoffer4;
            this.lapsedconoffer[5] += +admin.lapsedconoffer5;
            this.lapsedconoffer[6] += +admin.lapsedconoffer6;

            this.lapsedunconoffer[0] += +admin.lapsedunconoffer0;
            this.lapsedunconoffer[1] += +admin.lapsedunconoffer1;
            this.lapsedunconoffer[2] += +admin.lapsedunconoffer2;
            this.lapsedunconoffer[3] += +admin.lapsedunconoffer3;
            this.lapsedunconoffer[4] += +admin.lapsedunconoffer4;
            this.lapsedunconoffer[5] += +admin.lapsedunconoffer5;
            this.lapsedunconoffer[6] += +admin.lapsedunconoffer6;
          });
          this.serachNotAllowed1 = false;
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );

      this.dashboardService.getTodayDone(this.searchParams).subscribe(
        (data) => {
          data.forEach((admin) => {
            //console.log("statistics : " + JSON.stringify(statistics));
            this.arrivingm[0] += +admin.arrivingm0;
            this.arrivingm[1] += +admin.arrivingm1;

            this.cases[0] += +admin.case0;
            this.cases[1] += +admin.case1;

            this.todaycrm[0] += +admin.todaycrm0;
            this.todaycrm[1] += +admin.todaycrm1;
            //0311 0117 data fixed
	    this.todaycrm[2] += +admin.todaycrm2;
            //03110117 data fixed
	    this.todaycrm[3] += +admin.todaycrm3;
            this.todaycrm[4] += +admin.todaycrm4;
            this.todaycrm[5] += +admin.todaycrm5;
            this.todaycrm[6] += +admin.todaycrm6;
            this.todaycrm[7] += +admin.todaycrm7;

            this.next5crm[0] += +admin.next5crm0;
            this.next5crm[1] += +admin.next5crm1;
            this.next5crm[2] += +admin.next5crm2;
            this.next5crm[3] += +admin.next5crm3;
            this.next5crm[4] += +admin.next5crm4;
            this.next5crm[5] += +admin.next5crm5;
            this.next5crm[6] += +admin.next5crm6;
            this.next5crm[7] += +admin.next5crm7;

            this.todaydone[0] += +admin.todaydone0;
            this.todaydone[1] += +admin.todaydone1;
            this.todaydone[2] += +admin.todaydone2;
            this.todaydone[3] += +admin.todaydone3;
            this.todaydone[4] += +admin.todaydone4;
            this.todaydone[5] += +admin.todaydone5;
            this.todaydone[6] += +admin.todaydone6;
            this.todaydone[7] += +admin.todaydone7;
            this.todaydone[8] += +admin.todaydone8;
            this.todaydone[9] += +admin.todaydone5b;

            
	          this.lapsedappsub[0] += +admin.lapsedappsub0;
            this.lapsedappsub[1] += +admin.lapsedappsub1;
            this.lapsedappsub[2] += +admin.lapsedappsub2;
            this.lapsedappsub[3] += +admin.lapsedappsub3;
            this.lapsedappsub[4] += +admin.lapsedappsub4;
            this.lapsedappsub[5] += +admin.lapsedappsub5;
            this.lapsedappsub[6] += +admin.lapsedappsub6;

            this.lapsedpendingdoc[0] += +admin.lapsedpendingdoc0;
            this.lapsedpendingdoc[1] += +admin.lapsedpendingdoc1;
            this.lapsedpendingdoc[2] += +admin.lapsedpendingdoc2;
            this.lapsedpendingdoc[3] += +admin.lapsedpendingdoc3;
            this.lapsedpendingdoc[4] += +admin.lapsedpendingdoc4;
            this.lapsedpendingdoc[5] += +admin.lapsedpendingdoc5;
            this.lapsedpendingdoc[6] += +admin.lapsedpendingdoc6;

            this.lapsedconoffer[0] += +admin.lapsedconoffer0;
            this.lapsedconoffer[1] += +admin.lapsedconoffer1;
            this.lapsedconoffer[2] += +admin.lapsedconoffer2;
            this.lapsedconoffer[3] += +admin.lapsedconoffer3;
            this.lapsedconoffer[4] += +admin.lapsedconoffer4;
            this.lapsedconoffer[5] += +admin.lapsedconoffer5;
            this.lapsedconoffer[6] += +admin.lapsedconoffer6;

            this.lapsedunconoffer[0] += +admin.lapsedunconoffer0;
            this.lapsedunconoffer[1] += +admin.lapsedunconoffer1;
            this.lapsedunconoffer[2] += +admin.lapsedunconoffer2;
            this.lapsedunconoffer[3] += +admin.lapsedunconoffer3;
            this.lapsedunconoffer[4] += +admin.lapsedunconoffer4;
            this.lapsedunconoffer[5] += +admin.lapsedunconoffer5;
            this.lapsedunconoffer[6] += +admin.lapsedunconoffer6;

              //0311 0226 

              this.epcrm[0] += +admin.epcrm0;
              this.epcrm[1] += +admin.epcrm1;
              this.epcrm[2] += +admin.epcrm2;
              this.epcrm[3] += +admin.epcrm3;
              this.epcrm[4] += +admin.epcrm4;
              this.epcrm[5] += +admin.epcrm5;
              this.epcrm[6] += +admin.epcrm6;
              this.epcrm[7] += +admin.epcrm7;
  
              this.admincrm[0] += +admin.admincrm0;
              this.admincrm[1] += +admin.admincrm1;
              this.admincrm[2] += +admin.admincrm2;
              this.admincrm[3] += +admin.admincrm3;
              this.admincrm[4] += +admin.admincrm4;
              this.admincrm[5] += +admin.admincrm5;
              this.admincrm[6] += +admin.admincrm6;
              this.admincrm[7] += +admin.admincrm7;
            
              //0226
          });
          this.serachNotAllowed2 = false;
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
      
      this.dashboardService.getAdmsByStudents(this.searchParams).subscribe(
        (data) => {
          data.forEach((admin) => {
            //console.log("statistics : " + JSON.stringify(statistics));
            this.arrivingm[0] += +admin.arrivingm0;
            this.arrivingm[1] += +admin.arrivingm1;

            this.cases[0] += +admin.case0;
            this.cases[1] += +admin.case1;

            this.todaycrm[0] += +admin.todaycrm0;
            this.todaycrm[1] += +admin.todaycrm1;
            //0117 data fixed this.todaycrm[2] += +admin.todaycrm2;
            //0117 data fixed this.todaycrm[3] += +admin.todaycrm3;
            this.todaycrm[4] += +admin.todaycrm4;
            this.todaycrm[5] += +admin.todaycrm5;
            this.todaycrm[6] += +admin.todaycrm6;
            //this.todaycrm[7] += +admin.todaycrm7;

            this.next5crm[0] += +admin.next5crm0;
            this.next5crm[1] += +admin.next5crm1;
            this.next5crm[2] += +admin.next5crm2;
            this.next5crm[3] += +admin.next5crm3;
            this.next5crm[4] += +admin.next5crm4;
            this.next5crm[5] += +admin.next5crm5;
            this.next5crm[6] += +admin.next5crm6;
            this.next5crm[7] += +admin.next5crm7;

            this.todaydone[0] += +admin.todaydone0;
            this.todaydone[1] += +admin.todaydone1;
            this.todaydone[2] += +admin.todaydone2;
            this.todaydone[3] += +admin.todaydone3;
            this.todaydone[4] += +admin.todaydone4;
            this.todaydone[5] += +admin.todaydone5;
            this.todaydone[6] += +admin.todaydone6;
            this.todaydone[7] += +admin.todaydone7;
            this.todaydone[8] += +admin.todaydone8;
            this.todaydone[9] += +admin.todaydone5b;
            
            this.lapsedappsub[0] += +admin.lapsedappsub0;
            this.lapsedappsub[1] += +admin.lapsedappsub1;
            this.lapsedappsub[2] += +admin.lapsedappsub2;
            this.lapsedappsub[3] += +admin.lapsedappsub3;
            this.lapsedappsub[4] += +admin.lapsedappsub4;
            this.lapsedappsub[5] += +admin.lapsedappsub5;
            this.lapsedappsub[6] += +admin.lapsedappsub6;

            this.lapsedpendingdoc[0] += +admin.lapsedpendingdoc0;
            this.lapsedpendingdoc[1] += +admin.lapsedpendingdoc1;
            this.lapsedpendingdoc[2] += +admin.lapsedpendingdoc2;
            this.lapsedpendingdoc[3] += +admin.lapsedpendingdoc3;
            this.lapsedpendingdoc[4] += +admin.lapsedpendingdoc4;
            this.lapsedpendingdoc[5] += +admin.lapsedpendingdoc5;
            this.lapsedpendingdoc[6] += +admin.lapsedpendingdoc6;

            this.lapsedconoffer[0] += +admin.lapsedconoffer0;
            this.lapsedconoffer[1] += +admin.lapsedconoffer1;
            this.lapsedconoffer[2] += +admin.lapsedconoffer2;
            this.lapsedconoffer[3] += +admin.lapsedconoffer3;
            this.lapsedconoffer[4] += +admin.lapsedconoffer4;
            this.lapsedconoffer[5] += +admin.lapsedconoffer5;
            this.lapsedconoffer[6] += +admin.lapsedconoffer6;

            this.lapsedunconoffer[0] += +admin.lapsedunconoffer0;
            this.lapsedunconoffer[1] += +admin.lapsedunconoffer1;
            this.lapsedunconoffer[2] += +admin.lapsedunconoffer2;
            this.lapsedunconoffer[3] += +admin.lapsedunconoffer3;
            this.lapsedunconoffer[4] += +admin.lapsedunconoffer4;
            this.lapsedunconoffer[5] += +admin.lapsedunconoffer5;
            this.lapsedunconoffer[6] += +admin.lapsedunconoffer6;

            //0226 
            
            this.epcrm[0] += +admin.epcrm0;
            this.epcrm[1] += +admin.epcrm1;
            this.epcrm[2] += +admin.epcrm2;
            this.epcrm[3] += +admin.epcrm3;
            this.epcrm[4] += +admin.epcrm4;
            this.epcrm[5] += +admin.epcrm5;
            this.epcrm[6] += +admin.epcrm6;
            this.epcrm[7] += +admin.epcrm7;

            this.admincrm[0] += +admin.admincrm0;
            this.admincrm[1] += +admin.admincrm1;
            this.admincrm[2] += +admin.admincrm2;
            this.admincrm[3] += +admin.admincrm3;
            this.admincrm[4] += +admin.admincrm4;
            this.admincrm[5] += +admin.admincrm5;
            this.admincrm[6] += +admin.admincrm6;
            this.admincrm[7] += +admin.admincrm7;
          
            //0226
          });
          this.serachNotAllowed3 = false;
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );

      this.dashboardService
        .getAdmsByStudentsGroupByAdminEp(this.searchParams)
        .subscribe(
          (data) => {
            // 0521 itmanager ; 
            //this.arrivingm.fill(0);
            //this.cases.fill(0);
            //this.todaycrm.fill(0);  
            //this.todaydone.fill(0);

            data.forEach((admin) => {
              console.log("admin getAdmsByStudentsGroupByAdminEp success: " );
              //console.log("statistics : " + JSON.stringify(statistics));
              this.arrivingm[0] += +admin.arrivingm0;
              this.arrivingm[1] += +admin.arrivingm1;

              this.cases[0] += +admin.case0;
              this.cases[1] += +admin.case1;

              this.todaycrm[0] += +admin.todaycrm0;
              this.todaycrm[1] += +admin.todaycrm1;
              this.todaycrm[2] += +admin.todaycrm2;
              this.todaycrm[3] += +admin.todaycrm3;
              this.todaycrm[4] += +admin.todaycrm4;
              this.todaycrm[5] += +admin.todaycrm5;
              this.todaycrm[6] += +admin.todaycrm6;
              this.todaycrm[7] += +admin.todaycrm7;

              this.next5crm[0] += +admin.next5crm0;
              this.next5crm[1] += +admin.next5crm1;
              this.next5crm[2] += +admin.next5crm2;
              this.next5crm[3] += +admin.next5crm3;
              this.next5crm[4] += +admin.next5crm4;
              this.next5crm[5] += +admin.next5crm5;
              this.next5crm[6] += +admin.next5crm6;
              this.next5crm[7] += +admin.next5crm7;

              this.todaydone[0] += +admin.todaydone0;
              this.todaydone[1] += +admin.todaydone1;
              this.todaydone[2] += +admin.todaydone2;
              this.todaydone[3] += +admin.todaydone3;
              this.todaydone[4] += +admin.todaydone4;
              this.todaydone[5] += +admin.todaydone5;
              this.todaydone[6] += +admin.todaydone6;
              this.todaydone[7] += +admin.todaydone7;
              this.todaydone[8] += +admin.todaydone8;
              this.todaydone[9] += +admin.todaydone5b;
              
              this.lapsedappsub[0] += +admin.lapsedappsub0;
              this.lapsedappsub[1] += +admin.lapsedappsub1;
              this.lapsedappsub[2] += +admin.lapsedappsub2;
              this.lapsedappsub[3] += +admin.lapsedappsub3;
              this.lapsedappsub[4] += +admin.lapsedappsub4;
              this.lapsedappsub[5] += +admin.lapsedappsub5;
              this.lapsedappsub[6] += +admin.lapsedappsub6;
              
              this.lapsedpendingdoc[0] += +admin.lapsedpendingdoc0;
              this.lapsedpendingdoc[1] += +admin.lapsedpendingdoc1;
              this.lapsedpendingdoc[2] += +admin.lapsedpendingdoc2;
              this.lapsedpendingdoc[3] += +admin.lapsedpendingdoc3;
              this.lapsedpendingdoc[4] += +admin.lapsedpendingdoc4;
              this.lapsedpendingdoc[5] += +admin.lapsedpendingdoc5;
              this.lapsedpendingdoc[6] += +admin.lapsedpendingdoc6;

              this.lapsedconoffer[0] += +admin.lapsedconoffer0;
              this.lapsedconoffer[1] += +admin.lapsedconoffer1;
              this.lapsedconoffer[2] += +admin.lapsedconoffer2;
              this.lapsedconoffer[3] += +admin.lapsedconoffer3;
              this.lapsedconoffer[4] += +admin.lapsedconoffer4;
              this.lapsedconoffer[5] += +admin.lapsedconoffer5;
              this.lapsedconoffer[6] += +admin.lapsedconoffer6;

              this.lapsedunconoffer[0] += +admin.lapsedunconoffer0;
              this.lapsedunconoffer[1] += +admin.lapsedunconoffer1;
              this.lapsedunconoffer[2] += +admin.lapsedunconoffer2;
              this.lapsedunconoffer[3] += +admin.lapsedunconoffer3;
              this.lapsedunconoffer[4] += +admin.lapsedunconoffer4;
              this.lapsedunconoffer[5] += +admin.lapsedunconoffer5;
              this.lapsedunconoffer[6] += +admin.lapsedunconoffer6;

            //0226 
            
            this.epcrm[0] += +admin.epcrm0;
            this.epcrm[1] += +admin.epcrm1;
            this.epcrm[2] += +admin.epcrm2;
            this.epcrm[3] += +admin.epcrm3;
            this.epcrm[4] += +admin.epcrm4;
            this.epcrm[5] += +admin.epcrm5;
            this.epcrm[6] += +admin.epcrm6;
            this.epcrm[7] += +admin.epcrm7;

            this.admincrm[0] += +admin.admincrm0;
            this.admincrm[1] += +admin.admincrm1;
            this.admincrm[2] += +admin.admincrm2;
            this.admincrm[3] += +admin.admincrm3;
            this.admincrm[4] += +admin.admincrm4;
            this.admincrm[5] += +admin.admincrm5;
            this.admincrm[6] += +admin.admincrm6;
            this.admincrm[7] += +admin.admincrm7;
          
           //0226
            });
            this.serachNotAllowed4 = false;
          },
          (error) => {
            //console.log("error : ", error);
            console.log("error : ", error);

            if (error.status == "401") {
              this.router.navigate(["/logout"]);
            }
          }
        );

      this.dashboardService
        .getAdmsByStudentsNoGroup(this.searchParams)
        .subscribe(
          (data) => {
            data.forEach((admin) => {
              console.log("admin getAdmsByStudentsNoGroup success: " );
              //console.log("statistics : " + JSON.stringify(statistics));
              this.arrivingm[0] += +admin.arrivingm0;
              this.arrivingm[1] += +admin.arrivingm1;

              this.cases[0] += +admin.case0;
              this.cases[1] += +admin.case1;

              this.todaycrm[0] += +admin.todaycrm0;
              this.todaycrm[1] += +admin.todaycrm1;
              //0117 data fixed this.todaycrm[2] += +admin.todaycrm2;
              //0117 data fixed this.todaycrm[3] += +admin.todaycrm3;
              this.todaycrm[4] += +admin.todaycrm4;
              this.todaycrm[5] += +admin.todaycrm5;
              this.todaycrm[6] += +admin.todaycrm6;
              //this.todaycrm[7] += +admin.todaycrm7;

              this.next5crm[0] += +admin.next5crm0;
              this.next5crm[1] += +admin.next5crm1;
              this.next5crm[2] += +admin.next5crm2;
              this.next5crm[3] += +admin.next5crm3;
              this.next5crm[4] += +admin.next5crm4;
              this.next5crm[5] += +admin.next5crm5;
              this.next5crm[6] += +admin.next5crm6;
              this.next5crm[7] += +admin.next5crm7;

              this.todaydone[0] += +admin.todaydone0;
              this.todaydone[1] += +admin.todaydone1;
              this.todaydone[2] += +admin.todaydone2;
              this.todaydone[3] += +admin.todaydone3;
              this.todaydone[4] += +admin.todaydone4;
              this.todaydone[5] += +admin.todaydone5;
              this.todaydone[6] += +admin.todaydone6;
              this.todaydone[7] += +admin.todaydone7;
              this.todaydone[8] += +admin.todaydone8;
              this.todaydone[9] += +admin.todaydone5b;
              
              this.lapsedappsub[0] += +admin.lapsedappsub0;
              this.lapsedappsub[1] += +admin.lapsedappsub1;
              this.lapsedappsub[2] += +admin.lapsedappsub2;
              this.lapsedappsub[3] += +admin.lapsedappsub3;
              this.lapsedappsub[4] += +admin.lapsedappsub4;
              this.lapsedappsub[5] += +admin.lapsedappsub5;
              this.lapsedappsub[6] += +admin.lapsedappsub6;

              this.lapsedpendingdoc[0] += +admin.lapsedpendingdoc0;
              this.lapsedpendingdoc[1] += +admin.lapsedpendingdoc1;
              this.lapsedpendingdoc[2] += +admin.lapsedpendingdoc2;
              this.lapsedpendingdoc[3] += +admin.lapsedpendingdoc3;
              this.lapsedpendingdoc[4] += +admin.lapsedpendingdoc4;
              this.lapsedpendingdoc[5] += +admin.lapsedpendingdoc5;
              this.lapsedpendingdoc[6] += +admin.lapsedpendingdoc6;

              this.lapsedconoffer[0] += +admin.lapsedconoffer0;
              this.lapsedconoffer[1] += +admin.lapsedconoffer1;
              this.lapsedconoffer[2] += +admin.lapsedconoffer2;
              this.lapsedconoffer[3] += +admin.lapsedconoffer3;
              this.lapsedconoffer[4] += +admin.lapsedconoffer4;
              this.lapsedconoffer[5] += +admin.lapsedconoffer5;
              this.lapsedconoffer[6] += +admin.lapsedconoffer6;

              this.lapsedunconoffer[0] += +admin.lapsedunconoffer0;
              this.lapsedunconoffer[1] += +admin.lapsedunconoffer1;
              this.lapsedunconoffer[2] += +admin.lapsedunconoffer2;
              this.lapsedunconoffer[3] += +admin.lapsedunconoffer3;
              this.lapsedunconoffer[4] += +admin.lapsedunconoffer4;
              this.lapsedunconoffer[5] += +admin.lapsedunconoffer5;
              this.lapsedunconoffer[6] += +admin.lapsedunconoffer6;

              //0226 
              
              this.epcrm[0] += +admin.epcrm0;
              this.epcrm[1] += +admin.epcrm1;
              this.epcrm[2] += +admin.epcrm2;
              this.epcrm[3] += +admin.epcrm3;
              this.epcrm[4] += +admin.epcrm4;
              this.epcrm[5] += +admin.epcrm5;
              this.epcrm[6] += +admin.epcrm6;
              this.epcrm[7] += +admin.epcrm7;

              this.admincrm[0] += +admin.admincrm0;
              this.admincrm[1] += +admin.admincrm1;
              this.admincrm[2] += +admin.admincrm2;
              this.admincrm[3] += +admin.admincrm3;
              this.admincrm[4] += +admin.admincrm4;
              this.admincrm[5] += +admin.admincrm5;
              this.admincrm[6] += +admin.admincrm6;
              this.admincrm[7] += +admin.admincrm7;
            
              //0226
            });
            this.serachNotAllowed5 = false;
          },
          (error) => {
            console.log("error : ", error);

            if (error.status == "401") {
              this.router.navigate(["/logout"]);
            }
          }
        );

      this.dashboardService
        .getAdmsByStudentsGroupByCiEP(this.searchParams)
        .subscribe(
          (data) => {
            // 0521 itmager ; 
            console.log("admin getAdmsByStudentsGroupByCiEP success : " );
            this.next5crm.fill(0);

            data.forEach((admin) => {
              //console.log("statistics : " + JSON.stringify(statistics));
              this.arrivingm[0] += +admin.arrivingm0;
              this.arrivingm[1] += +admin.arrivingm1;

              this.cases[0] += +admin.case0;
              this.cases[1] += +admin.case1;

              this.todaycrm[0] += +admin.todaycrm0;
              this.todaycrm[1] += +admin.todaycrm1;
              //0117 data fixed this.todaycrm[2] += +admin.todaycrm2;
              //0117 data fixed this.todaycrm[3] += +admin.todaycrm3;
              this.todaycrm[4] += +admin.todaycrm4;
              this.todaycrm[5] += +admin.todaycrm5;
              this.todaycrm[6] += +admin.todaycrm6;
              //this.todaycrm[7] += +admin.todaycrm7;

              this.next5crm[0] += +admin.next5crm0;
              this.next5crm[1] += +admin.next5crm1;
              this.next5crm[2] += +admin.next5crm2;
              this.next5crm[3] += +admin.next5crm3;
              this.next5crm[4] += +admin.next5crm4;
              this.next5crm[5] += +admin.next5crm5;
              this.next5crm[6] += +admin.next5crm6;
              this.next5crm[7] += +admin.next5crm7;

              this.todaydone[0] += +admin.todaydone0;
              this.todaydone[1] += +admin.todaydone1;
              this.todaydone[2] += +admin.todaydone2;
              this.todaydone[3] += +admin.todaydone3;
              this.todaydone[4] += +admin.todaydone4;
              this.todaydone[5] += +admin.todaydone5;
              this.todaydone[6] += +admin.todaydone6;
              this.todaydone[7] += +admin.todaydone7;
              this.todaydone[8] += +admin.todaydone8;
              this.todaydone[9] += +admin.todaydone5b;
              
              this.lapsedappsub[0] += +admin.lapsedappsub0;
              this.lapsedappsub[1] += +admin.lapsedappsub1;
              this.lapsedappsub[2] += +admin.lapsedappsub2;
              this.lapsedappsub[3] += +admin.lapsedappsub3;
              this.lapsedappsub[4] += +admin.lapsedappsub4;
              this.lapsedappsub[5] += +admin.lapsedappsub5;
              this.lapsedappsub[6] += +admin.lapsedappsub6;

              this.lapsedpendingdoc[0] += +admin.lapsedpendingdoc0;
              this.lapsedpendingdoc[1] += +admin.lapsedpendingdoc1;
              this.lapsedpendingdoc[2] += +admin.lapsedpendingdoc2;
              this.lapsedpendingdoc[3] += +admin.lapsedpendingdoc3;
              this.lapsedpendingdoc[4] += +admin.lapsedpendingdoc4;
              this.lapsedpendingdoc[5] += +admin.lapsedpendingdoc5;
              this.lapsedpendingdoc[6] += +admin.lapsedpendingdoc6;

              this.lapsedconoffer[0] += +admin.lapsedconoffer0;
              this.lapsedconoffer[1] += +admin.lapsedconoffer1;
              this.lapsedconoffer[2] += +admin.lapsedconoffer2;
              this.lapsedconoffer[3] += +admin.lapsedconoffer3;
              this.lapsedconoffer[4] += +admin.lapsedconoffer4;
              this.lapsedconoffer[5] += +admin.lapsedconoffer5;
              this.lapsedconoffer[6] += +admin.lapsedconoffer6;

              this.lapsedunconoffer[0] += +admin.lapsedunconoffer0;
              this.lapsedunconoffer[1] += +admin.lapsedunconoffer1;
              this.lapsedunconoffer[2] += +admin.lapsedunconoffer2;
              this.lapsedunconoffer[3] += +admin.lapsedunconoffer3;
              this.lapsedunconoffer[4] += +admin.lapsedunconoffer4;
              this.lapsedunconoffer[5] += +admin.lapsedunconoffer5;
              this.lapsedunconoffer[6] += +admin.lapsedunconoffer6;
              //0226 
              
              this.epcrm[0] += +admin.epcrm0;
              this.epcrm[1] += +admin.epcrm1;
              this.epcrm[2] += +admin.epcrm2;
              this.epcrm[3] += +admin.epcrm3;
              this.epcrm[4] += +admin.epcrm4;
              this.epcrm[5] += +admin.epcrm5;
              this.epcrm[6] += +admin.epcrm6;
              this.epcrm[7] += +admin.epcrm7;

              this.admincrm[0] += +admin.admincrm0;
              this.admincrm[1] += +admin.admincrm1;
              this.admincrm[2] += +admin.admincrm2;
              this.admincrm[3] += +admin.admincrm3;
              this.admincrm[4] += +admin.admincrm4;
              this.admincrm[5] += +admin.admincrm5;
              this.admincrm[6] += +admin.admincrm6;
              this.admincrm[7] += +admin.admincrm7;
            
              //0226

            });
            this.serachNotAllowed6 = false;
          },
          (error) => {
            console.log("error : ", error);

            if (error.status == "401") {
              this.router.navigate(["/logout"]);
            }
          }
        );
    }
  }
}
