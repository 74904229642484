import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { PersonalFiles, PersonalFilesParams } from "../model/personal-files";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/personalFiles`;

@Injectable()
export class PersonalFilesService {
  constructor(private http: JsonHttp) {}

  searchPersonalFiles(searchParams, page) {
    let params: string = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;
    //console.log(params);
    return this.http.get(params);
  }

  getList(basicId: number) {
    return this.http.get(`${url}/basic/${basicId}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: PersonalFilesParams) {
    //console.log("Personal basicId  : " + params.basicId);
    //console.log("params.personalFilesList.length : " + params.personalFilesList.length);
    return this.http.post(url, params);
  }

  update(params: PersonalFilesParams) {
    return this.http.put(url, params);
  }

  updateFile(params: PersonalFiles) {
    return this.http.put(`${url}/${params.id}`, params);
  }

  delete(params: PersonalFilesParams) {
    return this.http.delete(url, params);
  }
}
