import { Component, OnInit      } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
       
import { AuthenticationService  } from '../../services/authentication.service';
import   * as _                   from 'lodash';
import { FlashMessagesService   } from 'angular2-flash-messages';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
    returnUrl: string = '/';
    loading = false;

    constructor(
                private route : ActivatedRoute,
                private router: Router,
                private authenticationService: AuthenticationService,
                private _flashMessagesService: FlashMessagesService) { }
    
    ngOnInit() {
      console.log('LogoutComponent');
       this._flashMessagesService.show('Please login again', { cssClass: 'alert-danger', timeout: 5000 });        
       this.authenticationService.logout();
       this.router.navigate(['/login']);        
       //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
        
}
