import { Location } from "@angular/common";
import { Component, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import { FileUploader } from "ng2-file-upload";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter, switchMap } from "rxjs/operators";
import { Basic } from "../../model/basic";
import { PersonalFiles, PersonalFilesParams } from "../../model/personal-files";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { PersonalFilesService } from "../../services/personal-files.service";

@Component({
  selector: "app-personal-files",
  providers: [BasicService, PersonalFilesService],
  templateUrl: "./personal-files.component.html",
  styles: [
    `
      .my-drop-zone {
        border: dotted 3px lightgray;
      }
      .file-over-class {
        border: dotted 3px red;
      }
      .point {
        cursor: pointer;
      }
      .my-drop-zone:hover {
        border: dashed 2px green;
      }
    `,
  ],
})
export class PersonalFilesComponent {
  @ViewChild("imageModal") public imageModal: ModalDirective;
  public seletedImage;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  public uploader: FileUploader = new FileUploader({ queueLimit: 999 });
  public hasDropZoneOver: boolean = false;

  // Add new request
  public uploaderA: FileUploader = new FileUploader({ queueLimit: 20 });
  public uploaderB: FileUploader = new FileUploader({ queueLimit: 20 });
  public uploaderC: FileUploader = new FileUploader({ queueLimit: 20 });
  public uploaderD: FileUploader = new FileUploader({ queueLimit: 20 });
  public uploaderE: FileUploader = new FileUploader({ queueLimit: 20 });
  public uploaderF: FileUploader = new FileUploader({ queueLimit: 20 });
  public uploaderZ: FileUploader = new FileUploader({ queueLimit: 20 });

  selectedBasicId: number;
  basic = new Basic();

  personalFilesParams = new PersonalFilesParams();
  processedCount = 0;
  totalQueueFiles = 0;
  deletedFiles = 0;

  isCreate: boolean = false;
  isUpdate: boolean = false;

  disabledCreate: boolean = false;
  disabledUpdate: boolean = false;

  authority: string;
  name: string;
  company: string;

  constructor(
    private personalFilesService: PersonalFilesService,
    private authenticationService: AuthenticationService,
    private basicService: BasicService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = params["id"];
          return this.basicService.get(+params["id"]);
        })
      )
      .subscribe(
        (basic) => {
          this.basic = basic;

          this.find();
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  getBasic() {
    this.basicService.get(this.selectedBasicId).subscribe(
      (basic) => {
        this.basic = basic;
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/"]);
        }
      }
    );
  }

  find() {
    //console.log("this.selectedBasicId : " + this.selectedBasicId);

    if (this.selectedBasicId !== undefined) {
      this.personalFilesService.getList(this.selectedBasicId).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          this.personalFilesParams = new PersonalFilesParams();
          this.personalFilesParams.personalFilesList =
            new Array<PersonalFiles>();

          this.personalFilesParams.basicId = data.basicId;
          data.filesList.forEach((file) =>
            this.personalFilesParams.personalFilesList.push(file)
          );

          if (this.personalFilesParams.personalFilesList.length > 0) {
            this.isCreate = false;
            this.isUpdate = true;
          } else {
            this.isCreate = true;
            this.isUpdate = false;
          }
        },
        (error) => {
          console.log("error : ", error);
        }
      );
    }
  }

  readQueue(action) {
    for (let idx = 0; idx < this.uploaderA.queue.length; idx++) {
      this.readFile(action, this.uploaderA.queue[idx]._file, "A");
    }
    for (let idx = 0; idx < this.uploaderB.queue.length; idx++) {
      this.readFile(action, this.uploaderB.queue[idx]._file, "B");
    }
    for (let idx = 0; idx < this.uploaderC.queue.length; idx++) {
      this.readFile(action, this.uploaderC.queue[idx]._file, "C");
    }
    for (let idx = 0; idx < this.uploaderD.queue.length; idx++) {
      this.readFile(action, this.uploaderD.queue[idx]._file, "D");
    }
    for (let idx = 0; idx < this.uploaderE.queue.length; idx++) {
      this.readFile(action, this.uploaderE.queue[idx]._file, "E");
    }
    for (let idx = 0; idx < this.uploaderF.queue.length; idx++) {
      this.readFile(action, this.uploaderF.queue[idx]._file, "F");
    }
    for (let idx = 0; idx < this.uploaderZ.queue.length; idx++) {
      this.readFile(action, this.uploaderZ.queue[idx]._file, "Z");
    }
  }

  readFile(action, f, kind) {
    var fileReader = new FileReader();

    var that = this;
    fileReader.onload = function () {
      //console.log("content   : " + window.btoa(fileReader.result));
      that.addFile(
        action,
        window.btoa(fileReader.result as string),
        f.name,
        f.size,
        kind
      );
    };

    fileReader.readAsBinaryString(f);
  }

  addFile(action, result, filename, filesize, kind) {
    let personalFile = new PersonalFiles();

    personalFile.filename = filename;
    personalFile.attachFile = result;
    personalFile.mode = "I";
    personalFile.kind = kind;

    console.log("filename : " + filename);
    //console.log('result   : ' + result);

    this.personalFilesParams.basicId = this.selectedBasicId;
    this.personalFilesParams.personalFilesList.push(personalFile);
    this.processedCount++;

    if (this.processedCount == this.totalQueueFiles) {
      if (action == "create") this.onCreate();
      else if (action == "update") this.onUpdate();
    }
    //this.personalFilesService.forEach(function(elem) {
    //   console.log("File Name : " + elem.filename);
    //});
  }

  onCreateBoard() {
    this.processedCount = 0;
    this.totalQueueFiles =
      this.uploaderA.queue.length +
      this.uploaderB.queue.length +
      this.uploaderC.queue.length +
      this.uploaderD.queue.length +
      this.uploaderE.queue.length +
      this.uploaderF.queue.length +
      this.uploaderZ.queue.length;

    this.personalFilesParams.personalFilesList =
      this.personalFilesParams.personalFilesList.filter((x) => x.mode != "I");

    if (this.totalQueueFiles > 0) {
      this.readQueue("create");
    }
  }

  onCreate() {
    this.disabledCreate = true;
    this.personalFilesService.create(this.personalFilesParams).subscribe(
      (data) => {
        console.log("create successful");

        this.uploaderA.clearQueue();
        this.uploaderB.clearQueue();
        this.uploaderC.clearQueue();
        this.uploaderD.clearQueue();
        this.uploaderE.clearQueue();
        this.uploaderF.clearQueue();
        this.uploaderZ.clearQueue();

        this.getBasic();
        this.find();

        this.disabledCreate = false;

        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.disabledCreate = false;
      }
    );
    //console.log('informationFiles : ' + this.informationBoard.informationFilesParams.length);
  }

  onUpdateBoard() {
    this.processedCount = 0;
    this.totalQueueFiles =
      this.uploaderA.queue.length +
      this.uploaderB.queue.length +
      this.uploaderC.queue.length +
      this.uploaderD.queue.length +
      this.uploaderE.queue.length +
      this.uploaderF.queue.length +
      this.uploaderZ.queue.length;

    this.personalFilesParams.personalFilesList =
      this.personalFilesParams.personalFilesList.filter((x) => x.mode != "I");

    let that = this;
    this.personalFilesParams.personalFilesList.forEach(function (item) {
      if (item.mode == "D") {
        that.deletedFiles++;
      }
    });

    if (this.totalQueueFiles == 0 && this.deletedFiles > 0) {
      this.onUpdate();
    } else if (this.totalQueueFiles > 0) {
      this.readQueue("update");
    }
  }

  onUpdate() {
    this.disabledUpdate = true;
    this.personalFilesService.update(this.personalFilesParams).subscribe(
      (data) => {
        console.log("update successful");

        this.uploaderA.clearQueue();
        this.uploaderB.clearQueue();
        this.uploaderC.clearQueue();
        this.uploaderD.clearQueue();
        this.uploaderE.clearQueue();
        this.uploaderF.clearQueue();
        this.uploaderZ.clearQueue();

        this.getBasic();
        this.find();

        this.disabledUpdate = false;

        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.disabledUpdate = false;
      }
    );
  }

  onDeleteBoard() {
    this.personalFilesService.delete(this.personalFilesParams).subscribe(
      (data) => {
        console.log("delete successful");

        this.getBasic();
        this.find();

        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  get previewImage() {
    return "";
    //return this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64,'+ this.seletedImage);
  }

  onFileWithoutDeleting(personalFiles: PersonalFiles[], kind): PersonalFiles[] {
    let result: PersonalFiles[] = [];

    for (
      var i = 0;
      personalFiles != undefined && i < personalFiles.length;
      i++
    ) {
      //console.log("personalFiles["+i+"].mode : " + personalFiles[i].mode);
      if (
        personalFiles[i].mode === null ||
        personalFiles[i].mode === undefined ||
        personalFiles[i].mode == ""
      ) {
        if (personalFiles[i].kind == kind) {
          result.push(personalFiles[i]);
        }
      }
    }
    return result;
  }

  onDeleteFile(id) {
    this.personalFilesParams.personalFilesList.find(function (item) {
      return item.id === id;
    }).mode = "D";
  }

  onSaveFile(fileContent, fileName) {
    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }

  onSaveFileAfterGetting(id) {
    this.personalFilesService.get(id).subscribe(
      (data) => {
        var byteContents = window.atob(data.attachFile);
        var byteNumbers = new Array(byteContents.length);

        for (var i = 0; i < byteContents.length; i++) {
          byteNumbers[i] = byteContents.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);

        let blob = new Blob([byteArray]);
        FileSaver.saveAs(blob, data.filename);
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }

  onCloseImage() {
    this.imageModal.hide();
  }

  fileOverBase(e: any) {
    this.hasDropZoneOver = e;
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  clickOKButton() {
    this.popup1.hide();
  }
}
