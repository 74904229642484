export class CounsellingCard {
    id:string|number;   
    firstName?:string;
    lastName?:string;
    dob?:string;
    gender?:string;
    countryCode?:string;    
    mobile?:string;
    email?:string;
    address?:string;
    howHear?:string;
    nationality?:string;
    visaType?:string;
    visaExpiryDate?:string;
    note?:string;
    realType?:string;
    realTypeName?:string;    
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
    processing?:string;
    basicId?:number;
}

export class CounsellingCardParams extends CounsellingCard {
}


