export class EventSearchParams {
    public searchKey?       :string = "Name";
    public searchValue?     :string = "";
    
    public evEvent?         :any[];
    public evRoute?         :any[];    
    public ciSubAgent       :any[];
    public ciMemberField    :any[];
    public ciMemberCategory :any[];
    public ciEp             :any[];
    public ciStage          :any[];    
    public vsCurrentStatus  :any[];  
    public csGrade          :any[];      
}

export class Event {
    id:string|number;   
    name?:string;
    mdNameOrg?:string;
    mdNameEng?:string;
    mdNameEngFamily?:string;
    mdGender?:string;
    mdEMail?:string;
    mdKorMobile?:string;
    mdNzMobile?:string;
    mdNzPhoneHome?:string;
    mdKrPhoneHome?:string;
    mdOverseasAddress?:string;
    mdNzAddress?:string;
    mdDob?:string;
    mdKorSms?:string;
    camCafeNickName?:string;
    camCafeId?:string;
    camNateId?:string;
    camKakaoId?:string;
    camQqId?:string;
    hiName?:string;
    hiAddress?:string;
    hiMemo?:string;
    ciEp?:string;
    ciSubAgent?:string;
    ciSubAgentName?:string;    
    ciMemberCategory?:string;
    ciMemberCategoryName?:string;    
    ciMemberField?:string;
    ciMemberFieldName?:string;    
    ciStage?:string;
    ciStageName?:string;    
    ciPriority?:string;
    ciEstDate?:string;
    ciEstDateDisplay?:string;
    ciEstCheckNz?:string;
    vsPassportNo?:string;
    vsCurrentStatus?:string;
    vsIssueDate?:string;
    vsExpiryDate?:string;
    vsVisaType?:string;
    vsVisaExpiry?:string;
    evEvent?:string;
    evRoute?:string;
    csCurrentSchool?:string;
    csSchoolType?:string;
    csExamType?:string;
    csGrade?:string;
    csGradeName?;string;
    csNsnNo?:string;
    csNote?:string;
    csStudentno?:string;
    csNsnPw?:string;
    mmAlarmDate?:string;
    mmLastcrmDate?:string;
    mmAlarmLevel?:string;
    mmAlarmLevelName?:string;    
    regDate?:string;
    courseExpiry?:string;
    asStartAirline?:string;
    asMidAirline?:string;
    asEndAirline?:string;
    asStartTime?:string;
    asMidTime?:string;
    asEndTime?:string;
    asStartDate?:string;
    asMidDate?:string;
    asEndDate?:string;
    asStartZone?:string;
    asMidZone?:string;
    asEndZone?:string;
    asPuEp?:string;
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
    visaExpiryisok?:string;
    oldStage?:string;
    mdEMail2?:string;
    memoSaveDate?:string;
    importantDetailMemo?:string;
    whyMember?:string;
    fileCount?:string|number;
    nationality?:string;
    asFlighthour?:string;
    asFlightmin?:string;
    controlStatic?:string;
    ncDate?:string;
    globalNumber?:string;
    globalNumberKor?:string;
    scheduleAf?:string;
    smsEnrollment?:string;
    smsVisa?:string;
    onlyVisibleForOnline?:string;
    scheduleQuickAf?:string;
    initialMemberCategory?:string;
    initialMemberCategoryName?:string;
    emailservicesent?:string;
    isActive?:boolean;
    endDate?:string;
    startingDate?:string;
    school?:string;
    schoolName?:string;    
    lapsed?:number;
    adminCnt?:number;
    
    basicId?:number;
    evEventName?:string;
    evRouteName?:string;
    attendance?:string;
    eventDay?:string;
    whId?:string;
    profit?:number;

    tertiaryAppId?:string;

}

export class EventParams extends Event {
}


