import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { UsersComponent } from './users.component';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';

import { PopupModule } from 'ng2-opd-popup';
import { DirectiveCommonModule } from '../../shared/directive/directive-common.module';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    FormsModule,
    NgxDatatableModule,
    ModalModule.forRoot(),

    TabsModule.forRoot(),
    PopupModule.forRoot(),
    DirectiveCommonModule
  ],
  declarations: [
    UsersComponent,
  ],
  exports: [
    UsersComponent
  ]
})
export class UsersModule {
}
