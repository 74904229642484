import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PopupModule } from "ng2-opd-popup";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { RestrictInputDirectveModule } from "../../shared/directive/restrict-input-directive.module";
import { CounsellingComponent } from "./counselling.component";

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    NgxDatatableModule,
    NgbModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PopupModule.forRoot(),
    RestrictInputDirectveModule,
  ],
  declarations: [CounsellingComponent],
  exports: [CounsellingComponent],
})
export class CounsellingModule {}
