import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Profile } from "app/model/profile";
import { Popup } from "ng2-opd-popup";
//import 'rxjs/add/operator/map';
import { filter, switchMap } from "rxjs/operators";
import { Basic } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";

@Component({
  selector: "app-basic-detail",
  providers: [BasicService, DatePipe],
  templateUrl: "./basic-detail.component.html",
})
export class BasicDetailComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  basic = new Basic();
  selectedBasicId: number = null;

  disabled = false;

  nationalityList: any[] = [];
  visaTypeList: any[] = [];
  arrivedCityList: any[] = [];
  epList: any[] = [];
  studyFieldList: any[] = [];
  subAgentList: any[] = [];
  stageList: any[] = [];
  priorityList: any[] = [];
  memberCategoryList: any[] = [];
  eventList: any[] = [];
  routeList: any[] = [];
  gradeList: any[] = [];
  examTypeList: any[] = [];
  globalNumberList: any[] = [];
  genderList: any[] = [
    { code: "Female", value: "Female" },
    { code: "Male", value: "Male" },
    { code: "Other", value: "Other" },
  ];

  visaList: any[] = [];

  hourList: any[] = [];
  minuteList: any[] = [];

  flightHour: string;
  filghtMinute: string;
  eventRows: string;
  mdDob: NgbDateStruct;
  vsIssueDate: NgbDateStruct;
  selected: string;
  earrival: NgbDateStruct;
  mInterest: String;
  vsExpiryDate: NgbDateStruct;
  vsVisaExpiry: NgbDateStruct;
  ciEstDate: NgbDateStruct;
  asEndDate: NgbDateStruct;
  minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };

  disabledCreate: boolean = false;
  disabledUpdate: boolean = false;

  disabledCiSubAgent: boolean = false;
  disabledCiStage: boolean = false;
  disabledCiEp: boolean = false;
  disabledArriving: boolean = false; //true;

  mdEMail1Name: string;
  mdEMail2Name: string;

  mdKorMobile2: string;
  mdNzMobile2: string;

  authority: string;
  name: string;
  company: string;
  action: string;
  otherTab: boolean = false;

  codeCommonForChild: CodeCommon;

  selectedCountryCode = "";
  countryCodes = [];
  customLabels = {};
  nationalityCodes = {};

  constructor(
    private basicService: BasicService,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.nationalityList = codeCommon.nationalityList;
    this.visaTypeList = codeCommon.visaTypeList;
    this.arrivedCityList = codeCommon.arrivedCityList;
    // 0131 this.epList = codeCommon.epList;
    const excludedCodes = ['Baden','Mijin','Jane']; // 제외할 사람 추가
    this.epList = codeCommon.epList.filter(val => !excludedCodes.includes(val.code)).sort((a, b) => a.code.localeCompare(b.code)); ;
    
    this.studyFieldList = codeCommon.studyFieldList;
    this.subAgentList = codeCommon.subAgentList.filter((x) => x.code != "self");
    this.stageList = codeCommon.stageList;
    this.priorityList = codeCommon.priorityList;
    this.memberCategoryList = codeCommon.memberCategoryList;
    this.eventList = codeCommon.eventList;
    this.routeList = codeCommon.routeList;
    this.gradeList = codeCommon.gradeList;
    this.examTypeList = codeCommon.examTypeList;
    this.globalNumberList = codeCommon.globalNumberList;
    this.genderList = codeCommon.genderList;
    this.hourList = codeCommon.hourList;
    this.minuteList = codeCommon.minuteList;

    this.codeCommonForChild = codeCommon;

    let exNum = 0;
    this.nationalityList.forEach((currentValue, index) => {
      if (currentValue.value2 != null && currentValue.value2 != "") {
        this.countryCodes.push(currentValue.value2.toLowerCase());
        this.customLabels[currentValue.value2.toLowerCase()] =
          currentValue.value;
        this.nationalityCodes[currentValue.value2.toLowerCase()] =
          currentValue.code;
      } else {
        this.countryCodes.push(exNum);
        this.customLabels[exNum] = currentValue.value;
        this.nationalityCodes[exNum] = currentValue.code;
        exNum++;
      }
    });
  }

  changeFlagBoxStyle() {
    const flagBox = document.getElementsByClassName("list-country-flags");
    const flagBoxElement = flagBox.item(0) as HTMLElement;
    if (flagBoxElement) flagBoxElement.style.marginLeft = "-180px";
  }

  changeFlagBoxClickArea() {
    const clickArea = document.getElementsByClassName("select-flags");
    const clickAreaElement = clickArea.item(0) as HTMLElement;
    clickAreaElement.style.width = "150px";
  }

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
    this.basic.nationality = this.nationalityCodes[value];
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          if (params["id"] === undefined) {
            return null;
          } else {
            if (params["id"].indexOf("sch") > -1) {
              this.selectedBasicId = params["id"].substring(
                params["id"].indexOf("sch") + 3
              );
              this.otherTab = true;
              return this.basicService.get(+this.selectedBasicId);
            } else {
              this.selectedBasicId = params["id"];
              this.otherTab = false;
              return this.basicService.get(+this.selectedBasicId);
            }
          }
        })
      )
      .subscribe(
        (basic) => {
          this.basic = basic;
          this.convStrToDate();

          this.onSetDefault();
          this.onSetVisa();

          //console.log("basic.ciSubAgent : " + basic.ciSubAgent);
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }

          this.onSetDefault();
        }
      );

    this.changeFlagBoxClickArea();
  }

  find() {
    if (this.selectedBasicId !== undefined) {
      this.basicService.get(this.selectedBasicId).subscribe(
        (basic) => {
          //console.log(basic.mdDob + " : " + basic.vsIssueDate + " : " + basic.vsExpiryDate + " : " + basic.vsVisaExpiry + " : " + basic.ciEstDate + " : " + basic.asEndDate);
          this.basic = basic;
          this.convStrToDate();
          this.onSetDefault();
          this.onSetVisa();
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/"]);
          }
          this.onSetDefault();
        }
      );
    }
  }

  mdNameEngChange(value: string): void {
    this.basic.mdNameEng = value;
  }

  mdNameEngFamilyChange(value: string): void {
    this.basic.mdNameEngFamily = value;
  }

  onValidate() {
    this.popupMessage = "";

    if (this.basic.mdNameOrg == undefined || this.basic.mdNameOrg == "") {
      this.popupMessage = "Name(Origin) is required";
    } else if (
      this.basic.mdNameEng == undefined ||
      this.basic.mdNameEng == "" ||
      this.basic.mdNameEngFamily == undefined ||
      this.basic.mdNameEngFamily == ""
    ) {
      this.popupMessage = "Name(English) is required";
    } else if (
      (this.basic.mdEMail == undefined || this.basic.mdEMail == "") &&
      (this.basic.mdKorMobile == undefined || this.basic.mdKorMobile == "") &&
      (this.basic.mdNzMobile == undefined || this.basic.mdNzMobile == "")
    ) {
      this.popupMessage = "Email or Mobile is required";
    } else if (this.basic.ciEp == undefined || this.basic.ciEp == "") {
      this.popupMessage = "EP is required";
    } else if (this.basic.mdGender == undefined || this.basic.mdGender == "") {
      this.popupMessage = "Gender is required";
    } else if (
      this.basic.nationality == undefined ||
      this.basic.nationality == ""
    ) {
      this.popupMessage = "Nationality is required";
    } else if (this.basic.ciStage == undefined || this.basic.ciStage == "") {
      this.popupMessage = "Stage is required";
    } else if (
      this.basic.ciMemberField === undefined ||
      this.basic.ciMemberField == ""
    ) {
      this.popupMessage = "Study Field is required";
    } else if (
      this.basic.ciMemberCategory == undefined ||
      this.basic.ciMemberCategory == ""
    ) {
      this.popupMessage = "Member Category is required";
    } else if (this.asEndDate != undefined) {
      if (this.basic.asEndZone == undefined || this.basic.asEndZone == "") {
        this.popupMessage = "Arrived City is required";
      } else if (
        this.basic.asFlighthour == undefined ||
        this.basic.asFlighthour == ""
      ) {
        this.popupMessage = "Arrival Time is required";
      } else if (
        this.basic.asFlightmin == undefined ||
        this.basic.asFlightmin == ""
      ) {
        this.popupMessage = "Arrival Time is required";
      }
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red", // red, blue....
        widthProsentage: 40, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onDeleteMsg() {
    this.popupMessage = "Would you like to delete this basic information?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateMsg() {
    this.popupMessage = "Would you like to create this basic information?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateMsg() {
    this.popupMessage = "Would you like to update this basic information?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreate() {
    this.convDateToStr();
    this.convData();
    this.onGetVisa();

    if (!this.onValidate()) return;

    this.disabledCreate = true;
    this.basicService.create(this.basic).subscribe(
      (data) => {
        this.selectedBasicId = data.id;

        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);

        this.disabledCreate = false;

        this.router.navigate(["/followupDetail", this.selectedBasicId]);
      },
      (error) => {
        if (error.status == "500") {
          this.popupMessage =
            "Fail to create because " + error.json()["message"];
        } else {
          this.popupMessage = "Fail to create";
        }

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 50, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);

        this.disabledCreate = false;
      }
    );
  }

  onUpdate() {
    this.disabledUpdate = true;

    this.convDateToStr();
    this.convData();
    this.onGetVisa();

    if (!this.onValidate()) {
      this.disabledUpdate = false;
      return;
    }

    this.basicService.update(this.basic).subscribe(
      (data) => {
        console.log("update successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
        this.disabledUpdate = false;
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        this.disabledUpdate = false;
      }
    );
  }

  onDelete() {
    this.basicService.delete(this.basic).subscribe(
      (data) => {
        console.log("delete successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  clickOKButton() {
    this.popup1.hide();
    if (this.popupMessage === "Succeed in creating") {
      this.find();
    } else if (this.popupMessage === "Succeed in updating") {
      this.find();
    } else if (this.popupMessage === "Succeed in deleting") {
      this.router.navigate(["/basic"]);
    } else if (
      this.popupMessage === "Would you like to create this basic information?"
    ) {
      this.onCreate();
    } else if (
      this.popupMessage === "Would you like to update this basic information?"
    ) {
      this.onUpdate();
    } else if (
      this.popupMessage === "Would you like to delete this basic information?"
    ) {
      this.onDelete();
    }
  }

  convStrToDate() {
    if (this.basic.mdDob != undefined && this.basic.mdDob.length == 10)
      this.mdDob = Utils.formatStrDate(this.basic.mdDob);
    else this.mdDob = null;
    if (
      this.basic.vsIssueDate != undefined &&
      this.basic.vsIssueDate.length == 10
    )
      this.vsIssueDate = Utils.formatStrDate(this.basic.vsIssueDate);
    else this.vsIssueDate = null;
    if (
      this.basic.vsExpiryDate != undefined &&
      this.basic.vsExpiryDate.length == 10
    )
      this.vsExpiryDate = Utils.formatStrDate(this.basic.vsExpiryDate);
    else this.vsExpiryDate = null;
    if (
      this.basic.vsVisaExpiry != undefined &&
      this.basic.vsVisaExpiry.length == 10
    )
      this.vsVisaExpiry = Utils.formatStrDate(this.basic.vsVisaExpiry);
    else this.vsVisaExpiry = null;
    if (this.basic.ciEstDate != undefined && this.basic.ciEstDate.length == 10)
      this.ciEstDate = Utils.formatStrDate(this.basic.ciEstDate);
    else this.ciEstDate = null;
    if (this.basic.asEndDate != undefined && this.basic.asEndDate.length == 10)
      this.asEndDate = Utils.formatStrDate(this.basic.asEndDate);
    else this.asEndDate = null;
  }

  convDateToStr() {
    if (this.mdDob != undefined)
      this.basic.mdDob = Utils.formatDateStr(this.mdDob);
    else this.basic.mdDob = null;
    if (this.vsIssueDate != undefined)
      this.basic.vsIssueDate = Utils.formatDateStr(this.vsIssueDate);
    else this.basic.vsIssueDate = null;
    if (this.vsExpiryDate != undefined)
      this.basic.vsExpiryDate = Utils.formatDateStr(this.vsExpiryDate);
    else this.basic.vsExpiryDate = null;
    if (this.vsVisaExpiry != undefined)
      this.basic.vsVisaExpiry = Utils.formatDateStr(this.vsVisaExpiry);
    else this.basic.vsVisaExpiry = null;
    if (this.ciEstDate != undefined)
      this.basic.ciEstDate = Utils.formatDateStr(this.ciEstDate);
    else this.basic.ciEstDate = null;
    if (this.asEndDate != undefined)
      this.basic.asEndDate = Utils.formatDateStr(this.asEndDate);
    else this.basic.asEndDate = "";
  }

  convData() {
    if (this.mdEMail1Name == null) {
      this.basic.mdEMail = "";
    } else {
      this.basic.mdEMail = this.mdEMail1Name;
    }

    if (this.mdEMail2Name == null) {
      this.basic.mdEMail2 = "";
    } else {
      this.basic.mdEMail2 = this.mdEMail2Name;
    }

    if (this.mdKorMobile2 != null || this.mdKorMobile2 != "") {
      this.basic.mdKorMobile = this.mdKorMobile2;
    } else {
      this.basic.mdKorMobile = "";
    }

    if (this.mdNzMobile2 != null || this.mdNzMobile2 != "") {
      this.basic.mdNzMobile = this.mdNzMobile2;
    } else {
      this.basic.mdNzMobile = "";
    }
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  onSetDefault() {
    if (this.basic == undefined) return;

    if (this.basic.mdEMail != undefined) {
      if (this.basic.mdEMail.length > 0) {
        this.mdEMail1Name = this.basic.mdEMail;
      }
    }

    if (this.basic.mdEMail2 != undefined) {
      if (this.basic.mdEMail2.length > 0) {
        this.mdEMail2Name = this.basic.mdEMail2;
      }
    }

    if (this.basic.mdKorMobile != undefined) {
      this.mdKorMobile2 = this.basic.mdKorMobile;
    }

    if (this.basic.mdNzMobile != undefined) {
      this.mdNzMobile2 = this.basic.mdNzMobile;
    }

    if (this.basic.id != undefined && this.basic.ciStage != undefined) {
      this.disabledCiStage = true;
    } else {
      this.disabledCiStage = false;
    }

    if (this.basic.ciStage == undefined) {
      this.basic.ciStage = "신규 멤버";
    }

    if (this.basic.ciEp == undefined) {
      this.basic.ciEp = this.name;
    }

    if (this.basic.id != undefined && this.basic.ciSubAgent != undefined) {
      this.disabledCiSubAgent = true;
    } else if (this.basic.id == undefined && this.company == "h") {
      if (this.name == "Sunny.jung") {
        this.basic.ciSubAgent = "Sunny";
      } else {
        this.basic.ciSubAgent = "ACCESS NZ";
      }
    }

    if (
      this.basic.globalNumber === undefined ||
      this.basic.globalNumber == null
    ) {
      this.basic.globalNumber = "64";
    }

    if (this.basic.ciStage == "출국준비멤버") {
      //this.disabledArriving   = false;
    } else {
      //this.disabledArriving   = true;
    }

    this.disabledCiEp = true;

    if (this.authority == "manager") {
      this.disabledCiSubAgent = false;
      this.disabledCiStage = false;
      this.disabledCiEp = false;
      //this.disabledArriving   = false;
    } else if (this.authority == "admin" || this.authority == "accountant") {
      this.disabledCiEp = false;
    }
  }

  onSetVisa() {
    this.visaList = [];

    if (this.basic.vsVisaType1 != undefined && this.basic.vsVisaType1 != "") {
      this.visaList.push({
        mode: "I",
        vsVisaType: this.basic.vsVisaType1,
        vsVisaExpiry: Utils.formatStrDate(this.basic.vsVisaExpiry1),
      });
    }
    if (this.basic.vsVisaType2 != undefined && this.basic.vsVisaType2 != "") {
      this.visaList.push({
        mode: "I",
        vsVisaType: this.basic.vsVisaType2,
        vsVisaExpiry: Utils.formatStrDate(this.basic.vsVisaExpiry2),
      });
    }
    if (this.basic.vsVisaType3 != undefined && this.basic.vsVisaType3 != "") {
      this.visaList.push({
        mode: "I",
        vsVisaType: this.basic.vsVisaType3,
        vsVisaExpiry: Utils.formatStrDate(this.basic.vsVisaExpiry3),
      });
    }
    if (this.basic.vsVisaType4 != undefined && this.basic.vsVisaType4 != "") {
      this.visaList.push({
        mode: "I",
        vsVisaType: this.basic.vsVisaType4,
        vsVisaExpiry: Utils.formatStrDate(this.basic.vsVisaExpiry4),
      });
    }
    if (this.basic.vsVisaType5 != undefined && this.basic.vsVisaType5 != "") {
      this.visaList.push({
        mode: "I",
        vsVisaType: this.basic.vsVisaType5,
        vsVisaExpiry: Utils.formatStrDate(this.basic.vsVisaExpiry5),
      });
    }
  }

  onGetVisa() {
    let len = this.visaList.filter((x) => x.mode != "D").length;
    let visa = this.visaList.filter((x) => x.mode != "D");

    this.basic.vsVisaType1 = "";
    this.basic.vsVisaExpiry1 = "";
    this.basic.vsVisaType2 = "";
    this.basic.vsVisaExpiry2 = "";
    this.basic.vsVisaType3 = "";
    this.basic.vsVisaExpiry3 = "";
    this.basic.vsVisaType4 = "";
    this.basic.vsVisaExpiry4 = "";
    this.basic.vsVisaType5 = "";
    this.basic.vsVisaExpiry5 = "";

    if (len > 0) {
      this.basic.vsVisaType1 = visa[0].vsVisaType;
      this.basic.vsVisaExpiry1 = Utils.formatDateStr(visa[0].vsVisaExpiry);
    }
    if (len > 1) {
      this.basic.vsVisaType2 = visa[1].vsVisaType;
      this.basic.vsVisaExpiry2 = Utils.formatDateStr(visa[1].vsVisaExpiry);
    }
    if (len > 2) {
      this.basic.vsVisaType3 = visa[2].vsVisaType;
      this.basic.vsVisaExpiry3 = Utils.formatDateStr(visa[2].vsVisaExpiry);
    }
    if (len > 3) {
      this.basic.vsVisaType4 = visa[3].vsVisaType;
      this.basic.vsVisaExpiry4 = Utils.formatDateStr(visa[3].vsVisaExpiry);
    }
    if (len > 4) {
      this.basic.vsVisaType5 = visa[4].vsVisaType;
      this.basic.vsVisaExpiry5 = Utils.formatDateStr(visa[4].vsVisaExpiry);
    }
  }

  onAddVisa() {
    if (this.visaList.length <= 4) {
      let visa = { mode: "I", vsVisaType: "", vsVisaExpiry: null };
      this.visaList.push(visa);
    }
  }

  onDeleteVisa(idx) {
    this.visaList.filter((x) => x.mode != "D")[idx].mode = "D";
  }

  onVisaList(): any[] {
    let result: any[] = [];

    for (
      var i = 0;
      this.visaList != undefined && i < this.visaList.length;
      i++
    ) {
      if (this.visaList[i].mode != "D") {
        result.push(this.visaList[i]);
      }
    }
    return result;
  }

  onChangeGLobalNumber() {}

  goBack(): void {
    this.location.back();
  }

  onRemoveFamily() {}

  addFamilyIds(newItem: string) {
    if (this.basic.familyIds == null)
      this.basic.familyIds = this.basic.id + "," + newItem;
    else {
      this.basic.familyIds += "," + newItem;
    }
  }
}
