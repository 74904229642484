import { Component, OnInit,   ElementRef,  ViewChild, ViewChildren ,QueryList} from "@angular/core";
import { ThreadService } from "app/services/thread.service";
import { Observable, Subject } from "rxjs";
import { debounceTime, delay, switchMap, tap } from "rxjs/operators";
import { Thread } from "../../model/thread";
import { MatDialog,MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';
import {ChatModalComponent} from './chatmodal.component';
import { JsonHttp } from "../../services/json-http";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-thread",
  templateUrl: "./thread.component.html",
  styleUrls: ["./thread.component.css"],
})
export class ThreadComponent implements OnInit {


  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

  @ViewChildren("stars") stars: QueryList<ElementRef>;



  public selectedThread: Subject<Thread> = new Subject<Thread>();

  public type: "my" | "all"| "admin"| "deleted"| "important" = "my";

  threads$: Observable<Thread[]>;
  total$: Observable<number>;
  dialogRef: MatDialogRef<ChatModalComponent>;

  selectFlag: boolean = false;

  constructor(
    public threadService: ThreadService,
    public dialog: MatDialog,
    private http: JsonHttp


  ) {
    threadService.type = "my";
    threadService.searchKey = "name";
    threadService.searchValue = "";
    this.threads$ = this.threadService.content$;
    this.total$ = this.threadService.total$;

  }

  async ngOnInit(): Promise<void> {
    //this.dialogRef.close();
    //console.log("nginit call..");

  }

  selectThread(thread: Thread): void {



    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '900px';


    dialogConfig.position  = {
      'top':'80px',
    };

    //dialogConfig.data = this.selectedThread.next(thread);
    //dialogConfig.data = this.selectedThread;
    //dialogConfig.data = this.selectedThread.asObservable();
    dialogConfig.data = thread;


    this.dialogRef = this.dialog.open(ChatModalComponent,dialogConfig);
    thread.unreadCnt = 0;
    //this.selectedThread.next(thread);
  }


  importantThread(){
    this.checkboxes.forEach((element) => {
     if(element.nativeElement.checked){
       let t = {};
       t["id"] = element.nativeElement.value;
       t["isImportant"] = true;

       this.http
         .put(`${environment.api_url}/api/threads`, t)
         .subscribe((data) => {
           this.threadService._search$.next();

          })
        }
      });
  }

  delectThread(){

    this.checkboxes.forEach((element) => {
     if(element.nativeElement.checked){
       let t = {};
       t["id"] = element.nativeElement.value;
       t["isDeleted"] = true;

       this.http
         .put(`${environment.api_url}/api/threads`, t)
         .subscribe((data) => {
           this.threadService._search$.next();

          })
        }


      });

  }





  selectAll(){
    this.selectFlag = !this.selectFlag;
    this.checkboxes.forEach((element) => {
     element.nativeElement.checked = this.selectFlag;
     //element.nativeElement.checked = true;
   });

  }

  checkboxEvent(){
    //console.log("hello world");
  }


  async onTypeChanged() {
    this.threadService.type = this.type;
  }

  onSearchKeyChanged() {
    this.threadService.searchValue = "";
  }
}
