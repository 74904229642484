import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { BasicComponent } from './basic.component';
import { BasicDetailComponent } from './basic-detail.component';
import { PersonalFilesComponent } from './personal-files.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopupModule } from 'ng2-opd-popup';
import { FileUploadModule } from 'ng2-file-upload/file-upload/file-upload.module';
import { DetailMenuModule } from '../detail/detail-menu.module';
import { RestrictInputDirectveModule } from '../../shared/directive/restrict-input-directive.module';
import { PersonalFoldersComponent } from './personal-folders.component';
import { DndListModule } from 'ngx-drag-and-drop-lists';
import { CountryFlagDirective } from '../country-dropdown-flags/country-flag.directive';
import { NgxFlagPickerModule } from 'ngx-flag-picker';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgbModule,

    PopupModule.forRoot(),
    FileUploadModule,
    DetailMenuModule,
    DndListModule,
    RestrictInputDirectveModule,
    NgxFlagPickerModule,
  ],
  declarations: [
    BasicComponent,
    BasicDetailComponent,
    PersonalFilesComponent,
    PersonalFoldersComponent,
    CountryFlagDirective
  ],
  exports: [
    BasicComponent,
    BasicDetailComponent,
    PersonalFilesComponent,
    PersonalFoldersComponent
  ]
})
export class BasicModule {
}
