import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { CounsellingParams } from "../model/counselling";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/counselling`;

@Injectable()
export class CounsellingService {
  constructor(private http: JsonHttp) {}

  search(searchParams, page) {
    let params: string = `${url}?answered=${searchParams.answered}&realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: CounsellingParams) {
    return this.http.post(url, params);
  }

  update(params: CounsellingParams) {
    return this.http.put(url, params);
  }

  delete(params: CounsellingParams) {

    console.log("delete param check :", params);

    return this.http.delete(`${url}/${params.id}`);
    //return this.http.delete(url, params);
  }
}
