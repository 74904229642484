export class CodeCommon {
  accountingNoteList?: any[] = [];
  accountStatusList?: any[] = [];
  adminAgentList?: any[] = [];
  adminEpList?: any[] = [];
  adminStatusList?: any[] = [];
  alarmLevelList?: any[] = [];
  arrivedCityList?: any[] = [];
  branchList?: any[] = [];
  courseList?: any[] = [];
  currentStatusList?: any[] = [];
  epAllList?: any[] = [];
  epList?: any[] = [];
  eventList?: any[] = [];
  examTypeList?: any[] = [];
  frequencyList?: any[] = [];
  genderList?: any[] = [];
  globalNumberList?: any[] = [];
  gradeList?: any[] = [];
  hourList?: any[] = [];
  informationCategoryList?: any[] = [];
  memberCategoryList?: any[] = [];
  memberTypeList?: any[] = [];
  memoTypeList?: any[] = [];
  minuteList?: any[] = [];
  nationalityCode?: any[] = [];
  nationalityList?: any[] = [];
  priorityList?: any[] = [];
  realTypeList?: any[] = [];
  routeList?: any[] = [];
  schoolNameList?: any[] = [];
  schoolNameListIncFalse?: any[] = [];
  schoolNewList?: any[] = [];
  schoolTypeList?: any[] = [];
  stageList?: any[] = [];
  studyFieldList?: any[] = [];
  subAgentList?: any[] = [];
  visaTypeList?: any[] = [];
}
