import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule} from '@angular/material/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopupModule } from 'ng2-opd-popup';
import { DetailMenuModule } from '../detail/detail-menu.module';
import { PipesModule } from '../../shared/pipe/pipe.module';

import { AdmissionComponent } from './admission.component';
import { AdmissionDetailComponent } from './admission-detail.component'
import { RestrictInputDirectveModule } from '../../shared/directive/restrict-input-directive.module';


@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    NgxDatatableModule,
    ModalModule.forRoot(),

    NgbModule,
    PopupModule.forRoot(),
    DetailMenuModule,
    PipesModule,
    RestrictInputDirectveModule
  ],
  declarations: [
    AdmissionComponent,
    AdmissionDetailComponent
  ],
  exports: [
    AdmissionComponent,
    AdmissionDetailComponent
  ]
})
export class AdmissionModule {
}
