import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import { Profile } from "app/model/profile";
import { cloneDeep } from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { DashboardSearchParams } from "../../model/dashboard";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-ep",
  providers: [DashboardService],
  templateUrl: "./ep.component.html",
  styleUrls: ["./ep.component.css"],
})
export class EpComponent implements OnInit {
  @Input("ep") selectedEpFromParent;
  @Input("from") fromWhere;
  @Input("table") rows1;
  @Input("yourStudent") yourStudent;
  @Input("yourAdminStudent") yourAdminStudent;
  @Input("yourAdminNo") yourAdminNo;

  epList: any[] = [];
  authority: string;
  name: string;
  company: string;
  option1var: any = [];

  serachNotAllowed: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  epOptions: IMultiSelectOption[] = [];

  isReady: boolean = false;

  getDate(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10);
    } else {
      return "";
    }
  }

  getDateTime(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10) + " " + datetime.substring(11, 16);
    } else {
      return "";
    }
  }

  navigateReportView(id) {
    this.router.navigate(["/informationBoardDetail", id]);
  }


  totalNumber: number[] = new Array(6).fill(0);
  myNumber: number[] = new Array(6).fill(0);

  lapsedNM: number[] = new Array(4).fill(0);
  lapsedCM: number[] = new Array(4).fill(0);
  currentCM: number[] = new Array(5).fill(0);
  arrivingM: number[] = new Array(2).fill(0);
  //0123 noga
  lapsedappsub: number[] = new Array(7).fill(0);
  lapsedpendingdoc: number[] = new Array(7).fill(0);

  enrolled: number[] = new Array(1).fill(0);

  public searchParams = new DashboardSearchParams();

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardService: DashboardService,
    private codeService: CodeService,
    private router: Router
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.epList = cloneDeep(codeCommon.epList);

    for (var i = 0; i < this.epList.length; i++) {
      this.epOptions.push({
        name: this.epList[i].value,
        id: this.epList[i].code,
      });

      if (this.name == this.epList[i].code) {
        this.option1var.push(this.epList[i].code);
      } else if (this.authority == "manager") {
        this.option1var.push(this.epList[i].code);
      }
    }

    this.isReady = true;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    var change = changes["selectedEpFromParent"];

    if (change?.currentValue.length > 0) {
      this.search(change.currentValue);
    }
  }

  navigateActionWithOnlyIdx(idx) {
    this.codeService.setSelectedEp(this.searchParams.searchEP);

    if (idx == "feematchedmissingonly" || idx == "feematchedonly" || idx == "enrolled0") {
      this.router.navigate(["/accounting"], {
        queryParams: {
          action: "home",
          idx: idx,
          ep: this.searchParams.searchEP,
        },
      });
      //0121 noga 
    } else if (idx == "myNumber4" || idx == "myNumber5") {
      this.router.navigate(["/basic"], {
        queryParams: {
          action: "home",
          idx: idx,
          ep: this.searchParams.searchEP,
        },
      });
    /*0124 } else if (idx == "lapsedappsub0" || idx == "lapsedappsub1" || idx == "lapsedpendingdoc0" || idx == "lapsedpendingdoc1") {
      this.router.navigate(["/admission"], {
        queryParams: {
          action: "home",
          idx: idx,
          ep: this.searchParams.searchEP,
        },
      });*/
    } else {
      this.router.navigate(["/basic"], {
        queryParams: {
          action: "home",
          idx: idx,
          ep: this.searchParams.searchEP,
        },
      });
    }
  }

  reset() {
    this.serachNotAllowed = true;

    this.totalNumber.forEach((n) => (n = 0));
    this.myNumber.forEach((n) => (n = 0));
    this.lapsedNM.forEach((n) => (n = 0));
    this.lapsedCM.forEach((n) => (n = 0));
    this.currentCM.forEach((n) => (n = 0));
    this.arrivingM.forEach((n) => (n = 0));

    this.enrolled.forEach((n) => (n = 0));
    //0123 noga
    this.lapsedappsub.forEach((n) => (n = 0)); 
    this.lapsedpendingdoc.forEach((n) => (n = 0)); 
  }

  search(change?) {
 
    this.totalNumber.fill(0);
    this.myNumber.fill(0);
    this.lapsedNM.fill(0);
    this.currentCM.fill(0);
    this.arrivingM.fill(0);
    this.lapsedappsub.fill(0);
    this.lapsedpendingdoc.fill(0);
    this.enrolled.fill(0);


    if (change != null) {
      this.searchParams.searchEP = change;
    } else {
      this.searchParams.searchEP = "";
      for (var i = 0; i < this.option1var.length; i++) {
        if (i == this.option1var.length - 1) {
          if (this.option1var[i].id == undefined) {
            this.searchParams.searchEP += "" + this.option1var[i] + "";
          } else {
            this.searchParams.searchEP += "" + this.option1var[i].id + "";
          }
        } else {
          if (this.option1var[i].id == undefined) {
            this.searchParams.searchEP += "" + this.option1var[i] + "" + ",";
          } else {
            this.searchParams.searchEP += "" + this.option1var[i].id + "" + ",";
          }
        }
      }
    }

    if (
      (this.fromWhere == null && change == null) ||
      (this.fromWhere != null && change != null)
    ) {
      this.reset();
      
      this.dashboardService.getAccounting(this.searchParams).subscribe(
        (data) => {

          this.enrolled.fill(0);
          this.myNumber.fill(0);

          data.forEach((accountings) => {
            //console.log("statistics : " + JSON.stringify(statistics));
            console.log("ep getAccounting success: " );

            this.enrolled[0] += +accountings.holdingpending0;
            this.enrolled[0] += +accountings.holdingpending1;
            this.enrolled[0] += +accountings.holdingpending2;
            this.enrolled[0] += +accountings.holdingpending3;
  
            this.enrolled[0] += +accountings.invoiced0;
            this.enrolled[0] += +accountings.invoiced1;
            this.enrolled[0] += +accountings.invoiced2;
            this.enrolled[0] += +accountings.invoiced3;
  
            this.enrolled[0] += +accountings.subUnpaid0;
            this.enrolled[0] += +accountings.subUnpaid1;
            this.enrolled[0] += +accountings.subUnpaid2;
            this.enrolled[0] += +accountings.subUnpaid3;

            this.myNumber[0] += +accountings.mynumber0;
            this.myNumber[1] += +accountings.mynumber1;
            this.myNumber[2] += +accountings.mynumber2;
            this.myNumber[3] += +accountings.mynumber3;         
            this.myNumber[4] += +accountings.mynumber4;            
            this.myNumber[5] += +accountings.mynumber5;
          });
          this.serachNotAllowed = false;
        },
        (error) => {
          console.log("error : ", error);
  
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
      
      this.dashboardService.getEPs(this.searchParams).subscribe(
        (data) => {

          //this.totalNumber.fill(0);
          //this.myNumber.fill(0);
          //this.lapsedNM.fill(0);
          //this.lapsedCM.fill(0);
          //this.currentCM.fill(0);
          //this.arrivingM.fill(0);
          //0123
          //0311 
          //this.lapsedappsub.fill(0);
          //this.lapsedpendingdoc.fill(0); 

          console.log("ep getEPs success: " );
             // 모든 컬럼을 비교하여 중복 제거
          const uniqueData = data.filter((item, index, self) =>
            index === self.findIndex(t => JSON.stringify(t) === JSON.stringify(item))
          );

          console.log("Deduplicated data: ", uniqueData);
          uniqueData.forEach((eps) => {

            
            /*
              this.plannedCRM[0] += +eps.plannedcrm0;
              this.plannedCRM[1] += +eps.plannedcrm1;
              this.plannedCRM[2] += +eps.plannedcrm2;
              */
            //0311 
            console.log("ep getEPs success2: " );
            this.lapsedappsub[0] += +eps.lapsedappsub0;
            this.lapsedappsub[1] += +eps.lapsedappsub1;
            this.lapsedpendingdoc[0] += +eps.lapsedpendingdoc0;
            if(eps.lapsedpendingdoc0>0) {
              console.log("eps success: " + eps);
              console.log("ep lapsedpendingdoc0 success: ",eps.lapsedpendingdoc0 );
              console.log("ep lapsedpendingdoc[0] success: ",this.lapsedpendingdoc[0] );
            }
            this.lapsedpendingdoc[1] += +eps.lapsedpendingdoc1;                      
            //0311

            this.totalNumber[0] += +eps.totalnumber0;
            this.totalNumber[1] += +eps.totalnumber1;
            this.totalNumber[2] += +eps.totalnumber2;
            this.totalNumber[3] += +eps.totalnumber3;
            this.totalNumber[4] += +eps.totalnumber4;
            this.totalNumber[5] += +eps.totalnumber5;

            this.myNumber[0] += +eps.mynumber0;
            this.myNumber[1] += +eps.mynumber1;
            this.myNumber[2] += +eps.mynumber2;
            
            this.myNumber[3] += +eps.mynumber3; 
            if (eps.mynumber2>0)
              console.log("ep getEPs mynumber2: ",eps.mynumber2 );
            if (eps.mynumber3>0)
              console.log("ep getEPs mynumber3: ",eps.mynumber3 );
            this.myNumber[4] += +eps.mynumber4;            
            this.myNumber[5] += +eps.mynumber5;

            this.lapsedNM[0] += +eps.lapsednm0;
            this.lapsedNM[1] += +eps.lapsednm1;
            this.lapsedNM[2] += +eps.lapsednm2;
            this.lapsedNM[3] += +eps.lapsednm3;

            this.lapsedCM[0] += +eps.lapsedcm0;
            this.lapsedCM[1] += +eps.lapsedcm1;
            this.lapsedCM[2] += +eps.lapsedcm2;
            this.lapsedCM[3] += +eps.lapsedcm3;

            this.currentCM[0] += +eps.currentcm0;
            this.currentCM[1] += +eps.currentcm1;
            this.currentCM[2] += +eps.currentcm2;
            this.currentCM[3] += +eps.currentcm3;
            this.currentCM[4] += +eps.currentcm4;

            this.arrivingM[0] += +eps.arrivingm0;
            this.arrivingM[1] += +eps.arrivingm1;
          });

          //Need to find it out that why are they getting double counted.
          //e.g. if it is 4, than 2.
          this.totalNumber[4] = Math.round(this.totalNumber[4] / 2);
          //this.myNumber[4] = Math.round(this.myNumber[4] / 2);

          this.serachNotAllowed = false;
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
    }
  }
}
