import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopupModule } from 'ng2-opd-popup';
import { CKEditorModule } from 'ng2-ckeditor';
import { FileUploadModule } from 'ng2-file-upload/file-upload/file-upload.module';
import { DetailMenuModule } from '../detail/detail-menu.module';


import { InformationBoardComponent } from './information-board.component';
import { InformationBoardDetailComponent } from './information-board-detail.component'
import { RestrictInputDirectveModule } from '../../shared/directive/restrict-input-directive.module';


@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    NgbModule,
    PopupModule.forRoot(),
    CKEditorModule,
    FileUploadModule,
    DetailMenuModule,
    RestrictInputDirectveModule
  ],
  declarations: [
    InformationBoardComponent,
    InformationBoardDetailComponent
  ],
  exports: [
    InformationBoardComponent,
    InformationBoardDetailComponent
  ]
})
export class InformationBoardModule {
}
