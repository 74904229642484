import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import { Angular2Csv } from "angular2-csv";
import { Profile } from "app/model/profile";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { Event, EventSearchParams } from "../../model/event";
import { Page } from "../../model/page";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { EventService } from "../../services/event.service";
import { BasicService } from "../../services/basic.service"; //noga 1127 memoList preview

const fixedSize = 10000;

@Component({
  selector: "app-event",
  providers: [EventService, BasicService], //noga 1127 memoList preview
  templateUrl: "./event.component.html",
})
export class EventComponent implements OnInit {
  public page = new Page();
  public rows = new Array<Event>();
  public userId: number = 0;

  public searchParams = new EventSearchParams();

  basicrow: any[] = []; //noga 1127 memoList preview
  public _basicId: number ;

  selected: any[] = [];
  codeCommon: CodeCommon;
  filterList: any[] = [];

  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  @ViewChild("dataTable") public dataTable: DatatableComponent;

  authority: string;
  name: string;
  company: string;

  loadingIndicator: boolean = false;
  timeout: any;

  totalProfit: number = 0;
  selectedProfit: number = 0;

  displaySearchValue: string;

  constructor(
    private router: Router,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private basicService: BasicService, //noga 1127 memoList preview
    private eventService: EventService    
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    this.searchParams.searchKey = "Name";
    this.searchParams.searchValue = "";

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.codeCommon = codeCommon;

    this.searchEvent();
  }

  setDefaultFilter() {}

  ngOnInit() {
    //this.setPage({ offset: 0 });
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.rows = new Array<Event>();
    this.page = new Page();
    this.page.pageNumber = pageInfo.offset;
    this.page.size = fixedSize;

    this.search();
  }

  searchEvent() {
    this.rows = new Array<Event>();
    this.page = new Page();

    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    this.search();
  }

  search() {
    this.searchParams.evEvent = this.codeCommon.eventList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.evRoute = this.codeCommon.routeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciSubAgent = this.codeCommon.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciMemberField = this.codeCommon.studyFieldList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciMemberCategory = this.codeCommon.memberCategoryList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciEp = this.codeCommon.epList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciStage = this.codeCommon.stageList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.vsCurrentStatus = this.codeCommon.currentStatusList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.csGrade = this.codeCommon.gradeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

    this.displaySearchValue = "";
    if (this.searchParams.evEvent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Event in " +
        JSON.stringify(
          this.codeCommon.eventList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.evRoute.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Route in " +
        JSON.stringify(
          this.codeCommon.routeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciSubAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " SubName in " +
        JSON.stringify(
          this.codeCommon.subAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciMemberField.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Field in " +
        JSON.stringify(
          this.codeCommon.studyFieldList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciMemberCategory.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " M Category in " +
        JSON.stringify(
          this.codeCommon.memberCategoryList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " EP in " +
        JSON.stringify(
          this.codeCommon.epList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciStage.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Stage in " +
        JSON.stringify(
          this.codeCommon.stageList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.vsCurrentStatus.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Current Visa in " +
        JSON.stringify(
          this.codeCommon.currentStatusList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.csGrade.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Grade in " +
        JSON.stringify(
          this.codeCommon.gradeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    this.loadingIndicator = true;
    this.eventService.searchEvent(this.searchParams, this.page).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.content : " + data.content.length);

        this.totalProfit = 0;
        this.rows = new Array<Event>();
        data.content.forEach((event) => {
          this.rows.push(event);
          this.totalProfit = this.totalProfit + event.profit;
        });

        let addLine = 0;
        if (this.rows.length > 0) {
          let acc = new Event();

          acc.profit = this.totalProfit;
          this.rows.push(acc);
          addLine = 1;
        }

        this.page.totalElements = data.totalElements + addLine;
        this.page.totalPages = data.totalPages;
        this.page.pageNumber = data.number;
        /* noga 1127 memoList preview
        this.rows = this.rows.map(row => ({
          ...row,          // 기존 row의 모든 속성을 유지
          expanded: false, // expanded 속성을 추가하고 초기값을 false로 설정
        })); //1127 
        */    
        this.rows = [...this.rows];
        //this.rows = this.rows.concat(records);

        setTimeout(() => {
          this.loadingIndicator = false;
        }, 1500);
      },
      (error) => {
        console.log("error : ", error);
        this.loadingIndicator = false;
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

    this.selectedProfit = 0;
    this.selected.forEach((x) => {
      this.selectedProfit = this.selectedProfit + x.profit;
    });
  }

  onActivate(obj) {
    if (obj.type === "click" && obj.event.altKey) {
      window.open(
        `${window.location.origin}/eventDetail/${obj.row.basicId}`,
        "_blank"
      );
    } else if (obj.type === "dblclick") {
      // console.log('Activate Event', obj);
      // console.log('Type', obj.type);
      // console.log('basicId', obj.row.basicId);
      this.router.navigate(["/eventDetail", obj.row.basicId]);
    }
  }

  onFilter(column) {
    //console.log("column : " + column);
    this.selectedColumn = column;
    if (column == "Event") {
      this.filterList = this.codeCommon.eventList;
    } else if (column == "Route") {
      this.filterList = this.codeCommon.routeList;
    } else if (column == "SubName") {
      //this.filterList = this.codeCommon.subAgentList;

      this.filterList = this.codeService.codeCommon.subAgentList.filter(
        (code) => this.rows.some((x) => x.ciSubAgent == code.code)
      );
    } else if (column == "Field") {
      this.filterList = this.codeCommon.studyFieldList;
    } else if (column == "M Category") {
      this.filterList = this.codeCommon.memberCategoryList;
    } else if (column == "EP") {
      this.filterList = this.codeCommon.epList;
    } else if (column == "Stage") {
      this.filterList = this.codeCommon.stageList;
    } else if (column == "Current Visa") {
      this.filterList = this.codeCommon.currentStatusList;
    } else if (column == "Grade") {
      this.filterList = this.codeCommon.gradeList;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "Event") {
      this.codeCommon.eventList = this.filterList;
    } else if (this.selectedColumn == "Route") {
      this.codeCommon.routeList = this.filterList;
    } else if (this.selectedColumn == "SubName") {
      //this.codeCommon.subAgentList = this.filterList;

      this.codeCommon.subAgentList.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "Field") {
      this.codeCommon.studyFieldList = this.filterList;
    } else if (this.selectedColumn == "M Category") {
      this.codeCommon.memberCategoryList = this.filterList;
    } else if (this.selectedColumn == "EP") {
      this.codeCommon.epList = this.filterList;
    } else if (this.selectedColumn == "Stage") {
      this.codeCommon.stageList = this.filterList;
    } else if (this.selectedColumn == "Current Visa") {
      this.codeCommon.currentStatusList = this.filterList;
    } else if (this.selectedColumn == "Grade") {
      this.codeCommon.gradeList = this.filterList;
    }

    this.filterModal.hide();
    this.search();
  }

  onPage(event) {}

  setDefaultParams() {
    this.searchParams = new EventSearchParams();
    this.codeCommon = this.codeService.getOrg();
    this.setDefaultFilter();
    this.searchEvent();
  }

  exportAsCSV() {
    //console.log('this.dataTable', this.dataTable);
    const columns: TableColumn[] = this.dataTable._internalColumns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "event",
      useBom: true,
    };
    return new Angular2Csv(rows, "event", options);
  }

  //noga 1127 memoList preview
  toggleExpandRow(row) {

    //console.log("noga _basicId: " + row.basicId);
    //console.log('Current expanded state:', row.expanded);
    //console.log('selected.length:', this.selected);
    //console.log('selected.length:', this.selected.length);
    //console.log('selectedProfit:', this.selectedProfit);

    if(!row.expanded){
      console.log('collapseAllRows expanded:', row.expanded); //expand rows flush
      this.dataTable.rowDetail.collapseAllRows();
    }    

    this.basicService.get(row.basicId).subscribe({
      next: (basic) => {
        this.basicrow = basic;
        //console.log('this.basicrow:', JSON.stringify(this.basicrow));
      },
      error: (err) => {
        console.error("Error fetching basic data:", err);
      },
    });
    if(this.selected.length>0){
      console.log('selectedRows flush this.selected.length:', this.selected.length);
      this.selected = []; // selected rows flush
      this.selectedProfit = 0;
    }
    
    row.expanded = !row.expanded;
    console.log('after expanded state:', row.expanded);
    this.dataTable.rowDetail.toggleExpandRow(row);
  }

}
