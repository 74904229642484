export class Memo {
    id?:string|number;   
    basicId?:string|number;
    crmMethod?:string;
    crmMethodName?:string;    
    crmType?:string;    
    memo?:string;
    ep?:string;
    epName?:string;    
    emailAlarm?:string;    
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
    crmTypeStage?:string;
    crmTypeType?:string;
    senderHp?:string;
    receiverHp?:string;
    sendDate?:string;          
    
    mmAlarmDate?:string;  
    ciStage?:string;    
    request?:string;
    memberType?:string;
    changedEp?:string;
    
    keyMemo?:boolean;    
    
    lapsed?:number;   
    subject?:string;
}

export class MemoParams extends Memo {
}


