export class Comment {
    id?: string | number;
    informationBoardId?: string | number;
    comment?: string;
    createDate?: string;
    createUser?: string;
    likes?: string;
}

export class CommentParams extends Comment {
}