import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { TertiaryApplicantParams } from "../model/tertiary-applicant";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/tertiaryapplicant`;

@Injectable()
export class TertiaryApplicantService {
  constructor(private http: JsonHttp) {}

  search(searchParams, page) {
    let params: string;

    if (searchParams.tertiaryId == null)  {
      //console.log("searchParams.realType",searchParams);
      // 0822 all option add params = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}&event=${searchParams.event}`;
      if (searchParams.event === 'ALL' || searchParams.kname != "") {
        params = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;
      } 
      else {
        params = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}&event=${searchParams.event}`;
      }

    } else {
      params = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}&tertiaryId=${searchParams.tertiaryId}`;
    }

    //let params : string = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;

    ////console.log(params);

    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: TertiaryApplicantParams) {
    return this.http.post(url, params);
  }

  createBasic(params: TertiaryApplicantParams) {
    return this.http.post(`${url}/createbasic`, params);
  }

  update(params: TertiaryApplicantParams) {
    return this.http.put(url, params);
  }

  delete(params: TertiaryApplicantParams) {
    return this.http.delete(url, params);
  }

  getFile(id: number) {
    return this.http.get(`${url}/file/${id}`);
  }
}
