import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import { FileUploader } from "ng2-file-upload";
import { Popup } from "ng2-opd-popup";
import { filter, switchMap } from "rxjs/operators";
import { Basic } from "../../model/basic";
import { PersonalFiles, PersonalFilesParams } from "../../model/personal-files";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { PersonalFilesService } from "../../services/personal-files.service";

@Component({
  selector: "app-personal-folders",
  providers: [BasicService, PersonalFilesService],
  templateUrl: "./personal-folders.component.html",
  styles: [
    `
      input[type="file"] {
        color: transparent;
      }
      .my-drop-zone {
        border: dotted 3px lightgray;
      }
      .file-over-class {
        border: dotted 3px red;
      }
      .point {
        cursor: pointer;
      }
      .my-drop-zone:hover {
        border: dashed 2px green;
      }
      .dndPlaceholder {
        background-color: #ddd;
        display: block;
        min-height: 42px;
        padding-top: 15px;
        padding-bottom: 15px;
        border: 1px solid rgba(86, 61, 124, 0.2);
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
      }
      .dropZone {
        margin-left: 20px;
      }

      .nv-file-over {
        border: dotted 3px red;
      }
    `,
  ],
})
export class PersonalFoldersComponent {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  selectedBasicId: number;
  basic = new Basic();

  personalFilesParams = new PersonalFilesParams();
  processedCount = 0;
  totalQueueFiles = 0;
  updatedFiles = 0;

  isCreate: boolean = false;

  authority: string;
  name: string;
  company: string;

  public fileList: { [key: string]: PersonalFiles[] } = {};
  public uploaderList: { [key: string]: FileUploader } = {};

  public folderKeys = [
    "Personal Documents/ID (Passport etc)",
    "Personal Documents/Qualification (School Report)",
    "Personal Documents/Visa and Insurance",
    "Personal Documents/English Test (IELTS, PTE etc)",
    "Personal Documents/Signed Doc From Clients",
    "Personal Documents/Others",
    "Pending Documents/Received Files",
  ];

  public enrolledSchoolKeys: string[] = [];
  private enrolledSchool: string = "Enrolled School";
  public appliedSchoolKeys: string[] = [];
  private appliedSchool: string = "Applied School";

  public removeItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }

  public insertItem(event: any, bucket: string) {
    setTimeout(() => {
      console.log("event.item.bucket : " + event.item.bucket);
      console.log("bucket : " + bucket);
      if (event.item.bucket != bucket) {
        event.item.mode = "U";
      } else {
        event.item.mode = undefined;
      }
    });
  }

  public hasDropZoneOver: { [key: string]: boolean } = {};

  public fileOver(key, isFileOver: boolean): void {
    this.hasDropZoneOver[key] = isFileOver;
  }

  constructor(
    private personalFilesService: PersonalFilesService,
    private authenticationService: AuthenticationService,
    private basicService: BasicService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = params["id"];
          return this.basicService.get(+params["id"]);
        })
      )
      .subscribe(
        (basic) => {
          this.basic = basic;
          this.find();
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  isDisable() {
    let isDisable: boolean = true;
    _.each(this.folderKeys, (key) => {
      if (isDisable) {
        if (
          _.filter(this.fileList[key], (f) => f.mode && f.mode != "I").length >
            0 ||
          (this.uploaderList[key] && this.uploaderList[key].queue.length > 0)
        ) {
          isDisable = false;
        }
      }
    });
    return isDisable;
  }

  getBasic() {
    this.basicService.get(this.selectedBasicId).subscribe(
      (basic) => {
        this.basic = basic;
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/"]);
        }
      }
    );
  }

  find() {
    //console.log("this.selectedBasicId : " + this.selectedBasicId);
    if (this.selectedBasicId !== undefined) {
      this.personalFilesService.getList(this.selectedBasicId).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          this.personalFilesParams = new PersonalFilesParams();
          this.personalFilesParams.basicId = data.basicId;
          this.personalFilesParams.basicFolderName = data.basicFolderName;
          this.personalFilesParams.adminList = _.map(data.adminList, _.trim);
          this.personalFilesParams.personalFilesList =
            new Array<PersonalFiles>();
          data.filesList.forEach((file) =>
            this.personalFilesParams.personalFilesList.push(file)
          );
          
          
          _.each(this.personalFilesParams.adminList, (admin) => {
            if (admin.startsWith("Enrolled:")) {
              if (
                !_.includes(
                  this.folderKeys,
                  `${this.enrolledSchool}/${admin.split(":")[1]}`
                )
              ) {
                let schoolName = `${this.enrolledSchool}/${
                  admin.split(":")[1]
                }`;
                this.folderKeys.push(schoolName);
                this.enrolledSchoolKeys.push(schoolName);

                this.folderKeys.push(schoolName + "/payments");
                this.folderKeys.push(schoolName + "/visa"); //0916
                
              }
            } else {
              if (
                !_.includes(
                  this.folderKeys,
                  `${this.appliedSchool}/${admin.split(":")[1]}`
                )
              ) {
                let schoolName = `${this.appliedSchool}/${admin.split(":")[1]}`;
                this.folderKeys.push(schoolName);
                this.appliedSchoolKeys.push(schoolName);                
              }
            }
          });
          //this.folderKeys.push('Enrolled School/Visa Record');
          //console.log("this.folderKeys: " + JSON.stringify(this.folderKeys, null, 2));
          //console.log("this.appliedSchoolKeys: " + JSON.stringify(this.appliedSchoolKeys, null, 2));


          _.each(this.folderKeys, (key) => {
            this.fileList[key] = _.map(
              this.personalFilesParams.personalFilesList,
              (file) => {
                if (file.bucket == key) {
                  file.filtered = true;
                  return file;
                }
              }
            ).filter(Boolean);
            this.uploaderList[key] = new FileUploader({ queueLimit: 10 });
          });
          //console.log("this.uploaderList: " + JSON.stringify(this.uploaderList, null, 2));

          this.fileList["Pending Documents/Received Files"] = [
            ...this.fileList["Pending Documents/Received Files"],
            ..._.filter(
              this.personalFilesParams.personalFilesList,
              (file) => !file.filtered
            ),
          ];

          if (this.personalFilesParams.personalFilesList.length > 0) {
            this.isCreate = false;
          } else {
            this.isCreate = true;
          }
        },
        (error) => {
          console.log("error : ", error);
        }
      );
    }
  }

  readQueue(action) {
    _.each(this.folderKeys, (key) => {
      let uploader = this.uploaderList[key];

      for (let idx = 0; idx < uploader.queue.length; idx++) {

        // this is error..only support Z....
      //  console.log("##### check key :", key);


        this.readFile(action, uploader.queue[idx]._file, "Z", key);



      }
    });
  }

  readFile(action: string, f, kind: string, bucket: string) {
    var fileReader = new FileReader();

    var that = this;
    fileReader.onload = function () {
      //console.log("content   : " + window.btoa(fileReader.result));
      that.addFile(
        action,
        window.btoa(fileReader.result as string),
        f.name,
        f.size,
        kind,
        bucket
      );
    };

    fileReader.readAsBinaryString(f);
  }

  addFile(action, result, filename, filesize, kind, bucket) {
    let personalFile = new PersonalFiles();

    personalFile.filename = filename;
    personalFile.attachFile = result;
    personalFile.mode = "I";
    personalFile.kind = kind;
    personalFile.bucket = bucket;

    //console.log('result   : ' + result);

    this.personalFilesParams.basicId = this.selectedBasicId;
    this.personalFilesParams.personalFilesList.push(personalFile);
    this.processedCount++;


/*
    console.log(
      "this.processedCount : " +
        this.processedCount +
        ", this.totalQueueFiles : " +
        this.totalQueueFiles
    );
*/

    if (this.processedCount == this.totalQueueFiles) {
      if (action == "create") this.onCreate();
      else if (action == "update") this.onUpdate();
    }
    //this.personalFilesService.forEach(function(elem) {
    //   console.log("File Name : " + elem.filename);
    //});
  }

  onCreateBoard() {
    this.processedCount = 0;
    this.totalQueueFiles = 0;

    _.each(this.folderKeys, (key) => {
      this.totalQueueFiles += this.uploaderList[key].queue.length;
    });

    this.personalFilesParams.personalFilesList =
      this.personalFilesParams.personalFilesList.filter((x) => x.mode != "I");

    if (this.totalQueueFiles > 0) {
      this.readQueue("create");
    }
  }

  onCreate() {
    this.personalFilesService.create(this.personalFilesParams).subscribe(
      (data) => {
        //console.log("create successful");

        _.each(this.folderKeys, (key) => {
          this.uploaderList[key].clearQueue();
        });

        this.getBasic();
        this.find();

        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
      }
    );
    //console.log('informationFiles : ' + this.informationBoard.informationFilesParams.length);
  }

  onUpdateBoard() {
    this.processedCount = 0;
    this.totalQueueFiles = 0;
    this.personalFilesParams.personalFilesList = [];

    _.each(this.folderKeys, (key) => {
      this.totalQueueFiles += this.uploaderList[key].queue.length;

      let fileList = _.filter(
        this.fileList[key],
        (f) => f.mode && f.mode != "I"
      );
      _.each(fileList, (f) => (f.bucket = key));
      this.personalFilesParams.personalFilesList = [
        ...this.personalFilesParams.personalFilesList,
        ...fileList,
      ];
    });

    let that = this;
    this.personalFilesParams.personalFilesList.forEach(function (item) {
      if (item.mode == "U" || item.mode == "D") {
        //console.log("##### DELETE check point :", item.mode);
        //console.log("##### item isShare check :", item.isShare);

        that.updatedFiles++;
      }
    });

    if (this.totalQueueFiles > 0) {
      this.readQueue("update");
    } else {
      this.onUpdate();
    }
  }

  onUpdate() {

    //console.log("### onUpate call...", JSON.stringify(this.personalFilesParams));
    this.personalFilesService.update(this.personalFilesParams).subscribe(
      (data) => {
        //console.log("update successful");
        _.each(this.folderKeys, (key) => {
          this.uploaderList[key].clearQueue();
        });
        this.getBasic();
        this.find();
        this.popupMessage = "Succeed in updating";
        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onDeleteBoard() {
    this.personalFilesService.delete(this.personalFilesParams).subscribe(
      (data) => {
        //console.log("delete successful");

        this.getBasic();
        this.find();

        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onFileWithoutDeleting(
    personalFiles: PersonalFiles[],
    bucket: string
  ): PersonalFiles[] {
    let result: PersonalFiles[] = [];

    for (
      var i = 0;
      personalFiles != undefined && i < personalFiles.length;
      i++
    ) {
      //console.log("personalFiles["+i+"].mode : " + personalFiles[i].mode);
      if (
        personalFiles[i].mode === null ||
        personalFiles[i].mode === undefined ||
        personalFiles[i].mode == ""
      ) {
        if (personalFiles[i].bucket == bucket) {
          result.push(personalFiles[i]);
        }
      }
    }
    return result;
  }

  onDeleteFile(item) {
    if (item.mode == "D") {
      item.mode = "";
    } else {
      item.mode = "D";
    }
  }


  onShareFile(item) {
    item.isShare = !item.isShare
    item.mode = "U";
  }



  onSaveFile(fileContent, fileName) {
    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }


  openFile(id: any, fileName: string): void {
    // 파일 확장자를 기반으로 MIME 타입 결정
    //console.log('fileContent:', fileContent);
    console.log('id:', id);
    const extension = fileName.split('.').pop().toLowerCase();
    let mimeType = 'application/octet-stream'; // 기본값
    switch (extension) {
        case 'pdf':
            mimeType = 'application/pdf';
            break;
        case 'png':
            mimeType = 'image/png';
            break;
        case 'jpg':
        case 'jpeg':
            mimeType = 'image/jpeg';
            break;
        case 'gif':
            mimeType = 'image/gif';
            break;
        // 여기에 필요한 다른 파일 형식을 추가할 수 있습니다.
    }

    // fileContent를 Blob으로 변환
    //const byteCharacters = atob(fileContent);
    //const byteNumbers = new Array(byteCharacters.length);
    //for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //}
    //const byteArray = new Uint8Array(byteNumbers);
    //const blob = new Blob([byteArray], { type: mimeType });
    console.log('mimeType:', mimeType);

    this.personalFilesService.get(id).subscribe(
      (data) => {
        var byteContents = window.atob(data.attachFile);
        var byteNumbers = new Array(byteContents.length);

        for (var i = 0; i < byteContents.length; i++) {
          byteNumbers[i] = byteContents.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);

        //let blob = new Blob([byteArray]);
        let blob = new Blob([byteArray], { type: mimeType });

        // Blob URL 생성
        const blobUrl = URL.createObjectURL(blob);
        console.log('Url:', blobUrl);
        // 파일을 브라우저로 열기 위한 링크 생성
        const link = document.createElement('a');
        link.href = blobUrl;
        link.target = '_blank'; // 새 창에서 열도록 설정
        document.body.appendChild(link); // DOM에 링크 요소 추가
        link.click();
        document.body.removeChild(link); // 사용 후 링크 요소 제거
      },
      (error) => {
        console.log("error : ", error);
      }
    );

    // Blob URL 생성
    //const blobUrl = URL.createObjectURL(blob);

    // 파일을 브라우저로 열기 위한 링크 생성
    //const link = document.createElement('a');
    //link.href = blobUrl;
    //link.target = '_blank'; // 새 창에서 열도록 설정
    //document.body.appendChild(link); // DOM에 링크 요소 추가
    //link.click();
    //document.body.removeChild(link); // 사용 후 링크 요소 제거
  }



  onSaveFileAfterGetting(id) {
    this.personalFilesService.get(id).subscribe(
      (data) => {
        var byteContents = window.atob(data.attachFile);
        var byteNumbers = new Array(byteContents.length);

        for (var i = 0; i < byteContents.length; i++) {
          byteNumbers[i] = byteContents.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);

        let blob = new Blob([byteArray]);
        FileSaver.saveAs(blob, data.filename);
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  clickOKButton() {
    this.popup1.hide();
  }
}
