import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule } from '@angular/material/core';
import { StatisticsComponent } from './statistics.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PopupModule } from 'ng2-opd-popup';
import { DetailMenuModule } from '../detail/detail-menu.module';
import { RestrictInputDirectveModule } from '../../shared/directive/restrict-input-directive.module';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    FormsModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),

    NgbModule,
    PopupModule.forRoot(),
    DetailMenuModule,
    RestrictInputDirectveModule,
    MultiselectDropdownModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  declarations: [
    StatisticsComponent
  ],
  exports: [
    StatisticsComponent
  ]
})
export class StatisticsModule {
}
