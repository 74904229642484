import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { PopupModule } from "ng2-opd-popup";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { DirectiveCommonModule } from "../../shared/directive/directive-common.module";
import { CustomersComponent } from "./customers.component";

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatNativeDateModule,
    FormsModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PopupModule.forRoot(),
    DirectiveCommonModule,
  ],
  declarations: [CustomersComponent],
  exports: [CustomersComponent],
})
export class CustomersModule {}
