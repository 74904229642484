import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { environment } from "../../environments/environment";
import { AccountingSearchParams } from "../model/accounting";
import { AdmissionSearchParams } from "../model/admission";
import { BasicSearchParams } from "../model/basic";
import { CodeCommon } from "../model/code-common";
import { CodeParams } from "../model/dto";
import { FollowupSearchParams } from "../model/followup";
import { InformationBoardSearchParams } from "../model/information-board";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/code`;

@Injectable()
export class CodeService {
  orgCodeCommon: CodeCommon;
  codeCommon: CodeCommon;
  returnBack: boolean = false;

  basicSearchParams: BasicSearchParams;
  followupSearchParams: FollowupSearchParams;
  admissionSearchParams: AdmissionSearchParams;
  accountingSearchParams: AccountingSearchParams;
  informationBoardSearchParams: InformationBoardSearchParams;

  selectedEp: string;

  private isLoading: boolean = false;

  private callbacks: any[] = [];

  constructor(private http: JsonHttp, private router: Router) {}

  getRoot() {
    return this.http.get(`${url}/Root`);
  }

  get(masterCode: string) {
    return this.http.get(`${url}/${masterCode}`);
  }

  create(params: CodeParams) {
    return this.http.post(url, params);
  }

  update(params: CodeParams) {
    return this.http.put(url, params);
  }

  delete(params: CodeParams) {
    return this.http.delete(url, params);
  }

  resetCommon() {
    this.orgCodeCommon = undefined;
    this.codeCommon = undefined;
  }

  getCommon(that) {
    if (this.isLoading) {
      this.callbacks.push(that);
    } else {
      if (this.codeCommon == undefined) {
        this.callbacks.push(that);
        this.isLoading = true;
        this.http.get(`${url}/Common`).subscribe(
          (code) => {
            this.orgCodeCommon = JSON.parse(JSON.stringify(code));
            this.codeCommon = JSON.parse(JSON.stringify(code));
            this.codeCommon.adminEpList = _.cloneDeep(this.codeCommon.epList);
            this.isLoading = false;

            while (this.callbacks.length > 0) {
              this.callbacks.pop().setCodeList(this.codeCommon);
            }
          },
          (error) => {
            console.log("error : ", error);
            this.isLoading = false;
            this.router.navigate(["/logout"]);
          }
        );
      } else {
        that.setCodeList(this.codeCommon);
      }
    }
  }

  getOrg() {
    return JSON.parse(JSON.stringify(this.orgCodeCommon));
  }

  setOrg() {
    this.codeCommon = JSON.parse(JSON.stringify(this.orgCodeCommon));
  }

  getCodeCommon() {
    return this.codeCommon;
  }

  getEachCommon(that, target) {
    this.getCommon(that);

    if (target == "0") {
      that.setSearchParams(undefined);
      this.returnBack = false;
    } else if (target == "1") {
      that.setSearchParams(this.basicSearchParams);
    } else if (target == "2") {
      that.setSearchParams(this.followupSearchParams);
    } else if (target == "3") {
      that.setSearchParams(this.admissionSearchParams);
    } else if (target == "4") {
      that.setSearchParams(this.accountingSearchParams);
    } else if (target == "5") {
      that.setSearchParams(this.informationBoardSearchParams);
    }
  }

  keepSearchParams(target, searchParams) {
    if (target == "1") {
      this.basicSearchParams = searchParams;
    } else if (target == "2") {
      this.followupSearchParams = searchParams;
    } else if (target == "3") {
      this.admissionSearchParams = searchParams;
    } else if (target == "4") {
      this.accountingSearchParams = searchParams;
    } else if (target == "5") {
      this.informationBoardSearchParams = searchParams;
    }
  }

  getReturnBack() {
    return this.returnBack;
  }

  setSelectedEp(selectedEp) {
    this.selectedEp = selectedEp;
  }

  getSelectedEp() {
    return this.selectedEp;
  }
}
