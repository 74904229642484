import { NgbDateStruct          } from '@ng-bootstrap/ng-bootstrap';

export default class Utils {
    
    static toInteger(value) {
        return parseInt("" + value, 10);
    }
    
    static toString(value) {
        return (value !== undefined && value !== null) ? "" + value : '';
    }
    
    static getValueInRange(value, max, min) {
        if (min === void 0) { min = 0; }
        return Math.max(Math.min(value, max), min);
    }
    
    static isString(value) {
        return typeof value === 'string';
    }
    
    static isNumber(value) {
        return !isNaN(this.toInteger(value));
    }
    
    static isInteger(value) {
        return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    }
    
    static isDefined(value) {
        return value !== undefined && value !== null;
    }
    
    static padNumber(value) {
        if (this.isNumber(value)) {
            return ("0" + value).slice(-2);
        }
        else {
            return '';
        }
    }
    
    static regExpEscape(text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }   
    
    //this function is not completed.
    static compareStrDate(val : string, val2: string) : number {
      var arr = val.split("-");
      var arr2 = val2.split("-");

      var number = +arr2[0] - +arr[0];
      var number2 = +arr2[1] - +arr[1];
      var number3 = +arr2[2] - +arr[2];

      number = number * 366;
      number2 = number2 * 31;

      return (number + number2 + number3);
    }

    static formatDateStr(date : NgbDateStruct) : string {
        return date ? date.year + "-" + (this.isNumber(date.month) ? this.padNumber(date.month) : '') + "-" + (this.isNumber(date.day) ? this.padNumber(date.day) : '') : '';
    }
    
    static formatStrDate(val : string) : NgbDateStruct {
        if(val == 'today') {
            let now = new Date();
            return { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        } else {
            return { year: +val.substring(0,4), month: +val.substring(5,7), day: +val.substring(8,10)};
        }
    }
    
    static getNgbDate(val : number) : NgbDateStruct {
      let now = new Date();
      now.setDate(now.getDate() + val);
      return { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    }    
    
    static addComma(inputValue : any) {

      if(inputValue == null) return "";
      
      var negSymbol = "";

      if (+inputValue < 0) {
        negSymbol = "-";
      }
    
      inputValue = "" + inputValue;
      
      var valArray = inputValue.split('.');
      
      for(var i = 0; i < valArray.length; ++i) {
        valArray[i] = valArray[i].replace(/\D/g, '');
      }

      var newVal: string;

      if(valArray.length === 0) {
        newVal = '';
      }
      else {
        let matches = valArray[0].match(/[0-9]{3}/mig);

        if(matches !== null && valArray[0].length > 3) {
          let commaGroups = Array.from(Array.from(valArray[0]).reverse().join('').match(/[0-9]{3}/mig).join()).reverse().join('');

          let replacement = valArray[0].split('').reverse().join('').replace(commaGroups.replace(/\D/g, '').split('').reverse().join(''), '').split('').reverse().join('');

          newVal = (replacement.length > 0 ? replacement + "," : "") + commaGroups;
          
        } else {
          newVal = valArray[0];
        }
      
        if(valArray.length > 1) {
          newVal += "." + valArray[1].substring(0,2);
        }
      }

      if(negSymbol == "-") {
        newVal = negSymbol + newVal;
      }
      
      return newVal;
    }
    
}