export class Schedule {
  summary?:string;
  startDate?:string;
  startTime?:string;
  startDateTime?:string;
  start?:Date;
  endDate?:string;
  endTime?:string;
  endDateTime?:string;
  end?;Date;
  description?:string;
  eventId?:string;
  calendarId?:string;
}

export class ScheduleParams extends Schedule {
}


