import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { User, UserSearchParams } from "../../model/dto";
import { Page } from "../../model/page";
import { UserTarget, UserTargetSearchParams } from "../../model/user-target";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { UserTargetService } from "../../services/user-target.service";
import { UserService } from "../../services/user.service";
import Utils from "../../shared/utils";

@Component({
  selector: "app-users",
  providers: [UserService, UserTargetService, DatePipe],
  templateUrl: "./users.component.html",
  styles: [
    `
      .well .list-group {
        margin-bottom: 0px;
      }
      .ngx-datatable {
        height: 400px;
      }
    `,
  ],
})
export class UsersComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  public rows = new Array<User>();
  public selectedRow = new User();

  public searchParams = new UserSearchParams();

  public page = new Page();
  public rowsTarget = new Array<UserTarget>();
  public selectedRowTarget = new UserTarget();

  public searchParamsTarget = new UserTargetSearchParams();

  codeCommon: CodeCommon;

  filterList: any[] = [];
  activeList: any[] = [
    { code: true, value: "True", checked: true },
    { code: false, value: "False", checked: false },
  ];

  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  selected: any[] = [];
  selectedTarget: any[] = [];

  authorityList: any[] = [
    { code: "normal", value: "normal" },
    { code: "admin", value: "admin" },
    { code: "accountant", value: "accountant" },
    { code: "manager", value: "manager" },
    { code: "agent", value: "agent" },
  ];
  tfList: any[] = [
    { code: "true", value: "true" },
    { code: "false", value: "false" },
  ];
  cmpyList: any[] = [
    { code: "k", value: "Kokos" },
    { code: "h", value: "Hanuhak" },
  ];

  epList: any[] = [];
  yearList: any[] = [];
  targetEpList: any[] = [];
  targetYearList: any[] = [];

  disabledCreateBtn: boolean = true;
  disabledUpdateBtn: boolean = true;
  disabledUser: boolean = true;
  disabledCreateTargetBtn: boolean = true;
  disabledUpdateTargetBtn: boolean = true;
  disabledTarget: boolean = true;

  userTab: boolean = true;
  targetTab: boolean = false;

  authority: string;
  name: string;
  company: string;

  cmpy: string;

  target1: string;
  target2: string;
  target3: string;
  target4: string;

  constructor(
    private userService: UserService,
    private userTargetService: UserTargetService,
    private authenticationService: AuthenticationService,
    private codeService: CodeService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.page.pageNumber = 0;
    this.page.size = 10000;

    let thisYear = new Date().getFullYear();
    for (var idx = -1; idx < 3; idx++) {
      this.yearList.push({ code: thisYear - idx, value: thisYear - idx });
    }

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.epList = codeCommon.epList;

    this.getAll();
    this.searchTarget();
  }

  ngOnInit() {}

  onSelect({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivate(obj) {
    if (obj.type === "click") {
      if (obj.row.id == undefined) {
        this.disabledCreateBtn = false;
        this.disabledUpdateBtn = true;
        this.disabledUser = false;
        this.selectedRow = new User();
      } else {
        this.disabledCreateBtn = true;
        this.disabledUpdateBtn = false;
        this.disabledUser = false;
        this.selectedRow = obj.row;
      }
    } else if (obj.type === "dblclick") {
    }
  }

  onSelectTarget({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateTarget(obj) {
    if (obj.type === "click") {
      if (obj.row.id == undefined) {
        this.disabledCreateTargetBtn = false;
        this.disabledUpdateTargetBtn = true;
        this.disabledTarget = false;
        this.selectedRowTarget = new UserTarget();

        if (this.searchParamsTarget.name != "") {
          this.selectedRowTarget.name = this.searchParamsTarget.name;
        }

        if (this.searchParamsTarget.year != "") {
          this.selectedRowTarget.year = this.searchParamsTarget.year;
        }
      } else {
        this.disabledUpdateTargetBtn = true;
        this.disabledUpdateTargetBtn = false;
        this.disabledTarget = false;
        this.selectedRowTarget = obj.row;

        if (this.selectedRowTarget.target1 == 0) this.target1 = null;
        else this.target1 = Utils.addComma(this.selectedRowTarget.target1);

        if (this.selectedRowTarget.target2 == 0) this.target2 = null;
        else this.target2 = Utils.addComma(this.selectedRowTarget.target2);

        if (this.selectedRowTarget.target3 == 0) this.target3 = null;
        else this.target3 = Utils.addComma(this.selectedRowTarget.target3);

        if (this.selectedRowTarget.target4 == 0) this.target4 = null;
        else this.target4 = Utils.addComma(this.selectedRowTarget.target4);
      }
    } else if (obj.type === "dblclick") {
    }
  }

  onClear() {
    this.disabledCreateBtn = true;
    this.disabledUpdateBtn = true;
    this.disabledUser = true;
    this.selected.splice(0, this.selected.length);
    this.selectedRow = new User();
  }

  onClearTarget() {
    this.disabledCreateTargetBtn = true;
    this.disabledUpdateTargetBtn = true;
    this.disabledTarget = true;
    this.selectedTarget.splice(0, this.selectedTarget.length);

    this.selectedRowTarget = new UserTarget();
    this.target1 = undefined;
    this.target2 = undefined;
    this.target3 = undefined;
    this.target4 = undefined;
  }

  onUpdate() {
    if (this.cmpy != undefined && this.cmpy != "") {
      this.selectedRow.company = this.cmpy;
    }

    this.userService.update(this.selectedRow).subscribe(
      (data) => {
        console.log("update successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onValidate() {
    this.popupMessage = "";

    if (this.selectedRow.name == undefined || this.selectedRow.name == "") {
      this.popupMessage = "Name is required";
    } else if (
      this.selectedRow.email == undefined ||
      this.selectedRow.email == ""
    ) {
      this.popupMessage = "Email is required";
    } else if (
      this.selectedRow.password == undefined ||
      this.selectedRow.password == ""
    ) {
      this.popupMessage = "Password is required";
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red", // red, blue....
        widthProsentage: 40, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreate() {
    if (this.cmpy != undefined && this.cmpy != "") {
      this.selectedRow.company = this.cmpy;
    }

    if (!this.onValidate()) return;

    this.userService.create(this.selectedRow).subscribe(
      (data) => {
        console.log("create successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onUpdateTarget() {
    this.convertTarget();

    this.userTargetService.update(this.selectedRowTarget).subscribe(
      (data) => {
        console.log("update successful");
        this.popupMessage = "Succeed in updating target";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update target";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onValidateTarget() {
    this.popupMessage = "";

    if (
      this.selectedRowTarget.name == undefined ||
      this.selectedRowTarget.name == ""
    ) {
      this.popupMessage = "Name is required";
    } else if (
      this.selectedRowTarget.year == undefined ||
      this.selectedRowTarget.year == ""
    ) {
      this.popupMessage = "Target Year is required";
    } else {
      if (
        this.rowsTarget.some(
          (x) =>
            x.name == this.selectedRowTarget.name &&
            x.year == this.selectedRowTarget.year
        )
      ) {
        this.popupMessage = "The same name and year have already existed";
      }
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red", // red, blue....
        widthProsentage: 40, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  convertTarget() {
    if (this.target1 != undefined)
      this.selectedRowTarget.target1 = this.removeComma(this.target1);
    if (this.target2 != undefined)
      this.selectedRowTarget.target2 = this.removeComma(this.target2);
    if (this.target3 != undefined)
      this.selectedRowTarget.target3 = this.removeComma(this.target3);
    if (this.target4 != undefined)
      this.selectedRowTarget.target4 = this.removeComma(this.target4);
  }

  onCreateTarget() {
    if (!this.onValidateTarget()) return;

    this.convertTarget();

    this.userTargetService.create(this.selectedRowTarget).subscribe(
      (data) => {
        console.log("create successful");
        this.popupMessage = "Succeed in creating target";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create target";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in updating"
    ) {
      this.onClear();
      this.getAll();
    } else if (
      this.popupMessage === "Succeed in updating target" ||
      this.popupMessage === "Succeed in creating target"
    ) {
      this.onClearTarget();
      this.searchTarget();
    }
  }

  getAll() {
    this.searchParams.isActive = this.activeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

    this.userService.getAll(this.searchParams).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));

        this.rows = new Array<User>();

        let user = new User();
        user.email = "Click here to create";
        this.rows.push(user);

        data.content.forEach((item) => this.rows.push(item));
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  searchTarget() {
    if (this.searchParamsTarget.name == "") {
      this.targetEpList = this.epList;
    } else {
      this.targetEpList = this.epList.filter(
        (x) => x.value == this.searchParamsTarget.name
      );
    }

    if (this.searchParamsTarget.year == "") {
      this.targetYearList = this.yearList;
    } else {
      this.targetYearList = this.yearList.filter(
        (x) => x.value == this.searchParamsTarget.year
      );
    }

    this.rowsTarget = new Array<UserTarget>();
    this.page = new Page();

    this.page.pageNumber = 0;
    this.page.size = 10000;

    this.userTargetService.search(this.searchParamsTarget, this.page).subscribe(
      (data) => {
        console.log("data : " + JSON.stringify(data));
        //console.log("data.content : " + data.content.length);

        let target = new UserTarget();
        target.year = "Click here to create";
        this.rowsTarget.push(target);

        data.content.forEach((followup) => {
          this.rowsTarget.push(followup);
        });

        this.rowsTarget = [...this.rowsTarget];

        //this.page.size = data.size;
        this.page.totalElements = data.totalElements;
        this.page.totalPages = data.totalPages;
        this.page.pageNumber = data.number;

        this.onClearTarget();
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        } else {
          //setTimeout(() => { this.loadingIndicator1 = false; }, 1500);
        }
      }
    );
  }

  removeComma(value) {
    if (value == undefined) return value;
    return +value.replace(",", "");
  }

  goBack(): void {
    this.location.back();
  }

  onFilter(column) {
    this.selectedColumn = column;
    if (column == "Active") {
      this.filterList = this.activeList;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "Active") {
      this.activeList = this.filterList;
    }

    this.filterModal.hide();
    this.getAll();
  }
}
