export class AgentMemo {
    id?:number;
    agentId?:number;
    crmMethod?:string;
    crmType?:string;
    memo?:string;
    ep?:string;
    emailAlarm?:string;
    createDate?:string;
    saveDate?:string;  
    createUser?:string;
    saveUser?:string;
    agentNote?:string;
    crmMethodName?:string;
    epName?:string;
  }
  
  export class AgentMemoParams extends AgentMemo {
    
  }
  