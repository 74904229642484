import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import { Angular2Csv } from "angular2-csv";
import { Profile } from "app/model/profile";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { Basic, BasicSearchParams } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { Page } from "../../model/page";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";

@Component({
  selector: "app-baisc",
  providers: [BasicService],
  templateUrl: "./basic.component.html",
})
export class BasicComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  @Input("parentSearchData") parentSearchData;
  @Input("parentIdData") parentIdData;
  @Input("cc") codeCommonFromParent;

  @Output() newItemEvent = new EventEmitter<string>();

  public page = new Page();
  public rows = new Array<Basic>();
  public userId: number = 0;

  public showDatepicker: boolean = false;
  public showFromRegDatePicker: boolean = false;

  public searchParams = new BasicSearchParams();

  public pageSize = 20;

  //selected : any[];
  datePipe = new DatePipe("en-US");

  codeCommon: CodeCommon;
  filterList: any[] = [];

  selectedRow: Basic = new Basic();
  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  @ViewChild("dataTable") public dataTable: DatatableComponent;

  @ViewChild("changeModal") public changeModal: ModalDirective;

  authority: string;
  name: string;
  company: string;

  action: string;
  stage: string;
  dob: string;
  idx: string;
  selectedEp: string;

  changeValueList: any[] = [];

  changeTarget: string;
  changeValue: string;
  basicIdList: any[] = [];

  selected: any[] = [];

  displaySearchValue: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private codeService: CodeService,
    private basicService: BasicService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.page.pageNumber = 0;
    this.page.size = this.pageSize;

    this.route.queryParams.subscribe((params) => {
      this.action = params["action"];
      this.dob = params["dob"];
      this.stage = params["stage"];
      this.idx = params["idx"];
      this.selectedEp = params["ep"];

      //codeService.getCommon(this);
      codeService.getEachCommon(this, "1");
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.parentSearchData != null) {
        if (this.parentSearchData != -1) {
          this.searchBasic();
        } else {
          this.searchBasic();
        }
      }
    }, 500);
  }

  setSearchParams(searchParams) {
    if (searchParams != undefined) {
      this.searchParams = searchParams;
    } else {
      this.searchParams.searchKey = "Name";
      this.searchParams.searchValue = "";
      this.searchParams.fromRegDate = "";
      this.searchParams.toRegDate = "";
      this.searchParams.fromNextCrm = "";
      this.searchParams.toNextCrm = "";
      this.searchParams.fromLastCrm = "";
      this.searchParams.toLastCrm = "";
      this.searchParams.fromArrivedDate = "";
      this.searchParams.toArrivedDate = "";
      this.searchParams.fromDob = "";
      this.searchParams.toDob = "";
      this.searchParams.pickupStatus = "";
      this.searchParams.pageNumber = 0;
    }
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.codeCommon = codeCommon;

    if (this.action == "home") {
      if (this.dob != undefined && this.dob != "") {
        this.searchParams.fromDob = this.dob;
        this.searchParams.toDob = this.dob;
      }

      if (this.stage == "4all") {
        this.codeCommon.stageList.forEach((x) => {
          if (
            x.code == "출국준비멤버" ||
            x.code == "신규 멤버" ||
            x.code == "상담 멤버" ||
            x.code == "수속멤버"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      } else if (
        this.stage == "NEW MEMBER" ||
        this.stage == "COUNSELLING MEMBER" ||
        this.stage == "ENROLLED MEMBER"
      ) {
        this.codeCommon.stageList.forEach((x) => {
          if (
            (this.stage == "NEW MEMBER" && x.code == "신규 멤버") ||
            (this.stage == "COUNSELLING MEMBER" && x.code == "상담 멤버") ||
            (this.stage == "ENROLLED MEMBER" && x.code == "수속멤버")
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "2") {
          this.searchParams.fromNextCrm1 = Utils.formatStrDate("today");
          this.searchParams.toNextCrm1 = Utils.formatStrDate("today");
        } else if (this.idx == "3") {
          this.searchParams.fromNextCrm1 = Utils.getNgbDate(1);
          this.searchParams.toNextCrm1 = Utils.getNgbDate(5);
        } else if (this.idx == "4") {
          this.searchParams.fromNextCrm1 = undefined;
          this.searchParams.toNextCrm1 = Utils.getNgbDate(-6);
        }
      } else if (this.stage == "ARRIVING MEMBER") {
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "출국준비멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx != "1") {
          this.searchParams.pickupStatus = this.idx;
        }
      }

      if (this.selectedEp != undefined) {
        this.codeCommon.epList.forEach((x) => {
          if (x.code == this.selectedEp) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }
    } else {
      if (!this.codeCommon.stageList.some((x) => x.checked == true)) {
        this.setDefaultFilter();
      }
    }

    if (this.authority == "normal") {
      this.codeCommon.epList.forEach((x) => {
        if (x.code == this.name) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    }

    //-- From Dashboard Starts --

    if (this.action == "home") {
      var selectedEps = this.selectedEp.split(",");

      this.searchParams.fromLastCrm1 = undefined;
      this.searchParams.toLastCrm1 = undefined;

      this.searchParams.fromNextCrm1 = undefined;
      this.searchParams.toNextCrm1 = undefined;

      //We hope reset everything before goes in.
      this.codeCommon.memberCategoryList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.subAgentList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.studyFieldList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.gradeList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.epList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.visaTypeList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.stageList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.alarmLevelList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.schoolNameList.forEach((x) => {
        x.checked = false;
      });

      //My Today CRM
      if (this.idx == "totalNumber0") {
        this.searchParams.fromNextCrm1 = Utils.formatStrDate("today");
        this.searchParams.toNextCrm1 = Utils.formatStrDate("today");

        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        this.codeCommon.stageList.forEach((x) => {
          if (
            x.code == "신규 멤버" ||
            x.code == "상담 멤버" ||
            x.code == "수속멤버" ||
            x.code == "출국준비멤버"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      //Total numbers
      else if (
        this.idx == "totalNumber1" ||
        this.idx == "totalNumber2" ||
        this.idx == "totalNumber3" ||
        this.idx == "totalNumber4" ||
        this.idx == "totalNumber5"
      ) {
        if (this.idx == "totalNumber1") {
          this.codeCommon.stageList.forEach((x) => {
            if (
              x.code == "신규 멤버" ||
              x.code == "상담 멤버" ||
              x.code == "수속멤버" ||
              x.code == "출국준비멤버"
            ) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "totalNumber2") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "신규 멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "totalNumber3") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "상담 멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "totalNumber4") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "수속멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "totalNumber5") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "출국준비멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }
      }

      //My Next 5 days CRM
      else if (this.idx == "myNumber0") {
        this.searchParams.fromNextCrm1 = Utils.getNgbDate(1);
        this.searchParams.toNextCrm1 = Utils.getNgbDate(5);

        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        this.codeCommon.stageList.forEach((x) => {
          if (
            x.code == "신규 멤버" ||
            x.code == "상담 멤버" ||
            x.code == "수속멤버" ||
            x.code == "출국준비멤버"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      //My Overdue
      else if (this.idx == "over5days") {
        this.searchParams.fromNextCrm1 = undefined;
        this.searchParams.toNextCrm1 = Utils.getNgbDate(-1);

        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        this.codeCommon.stageList.forEach((x) => {
          if (
            x.code == "신규 멤버" ||
            x.code == "상담 멤버" ||
            x.code == "수속멤버" ||
            x.code == "출국준비멤버"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      //My Total Numbers
      else if (
        this.idx == "myNumber1" ||
        this.idx == "myNumber2" ||
        this.idx == "myNumber3" ||
        this.idx == "myNumber4" ||
        this.idx == "myNumber5"
      ) {
        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        if (this.idx == "myNumber1") {
          this.codeCommon.stageList.forEach((x) => {
            if (
              x.code == "신규 멤버" ||
              x.code == "상담 멤버" ||
              x.code == "수속멤버" ||
              x.code == "출국준비멤버"
            ) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "myNumber2") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "신규 멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "myNumber3") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "상담 멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "myNumber4") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "수속멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "myNumber5") {
          this.codeCommon.stageList.forEach((x) => {
            if (x.code == "출국준비멤버") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }
      } else if (
        this.idx == "lapsedNM0" ||
        this.idx == "lapsedNM1" ||
        this.idx == "lapsedNM2" ||
        this.idx == "lapsedNM3"
      ) {
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "신규 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        if (this.idx == "lapsedNM0") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-10);
          this.searchParams.toLastCrm1 = Utils.formatStrDate("today");
        } else if (this.idx == "lapsedNM1") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-20);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-11);
        } else if (this.idx == "lapsedNM2") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-21);
        } else if (this.idx == "lapsedNM3") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
        }
      } else if (
        this.idx == "lapsedCM0" ||
        this.idx == "lapsedCM1" ||
        this.idx == "lapsedCM2" ||
        this.idx == "lapsedCM3"
      ) {
        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "상담 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        if (this.idx == "lapsedCM0") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-10);
          this.searchParams.toLastCrm1 = Utils.formatStrDate("today");
        } else if (this.idx == "lapsedCM1") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-20);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-11);
        } else if (this.idx == "lapsedCM2") {
          this.searchParams.fromLastCrm1 = Utils.getNgbDate(-30);
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-21);
        } else if (this.idx == "lapsedCM3") {
          this.searchParams.fromLastCrm1 = undefined;
          this.searchParams.toLastCrm1 = Utils.getNgbDate(-31);
        }
      } else if (
        this.idx == "plannedCRM0" ||
        this.idx == "plannedCRM1" ||
        this.idx == "plannedCRM2"
      ) {
        /*
        this.codeCommon.stageList.forEach(x => {
          if(x.code == "출국준비멤버" || x.code == "신규 멤버" || x.code == "상담 멤버" || x.code == "수속멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
  
        if(this.idx == "plannedCRM0") {
          this.searchParams.fromNextCrm1 = Utils.formatStrDate("today");
          this.searchParams.toNextCrm1   = Utils.formatStrDate("today");
        } else if(this.idx == "plannedCRM1") {
          this.searchParams.fromNextCrm1 = Utils.getNgbDate(1);             
          this.searchParams.toNextCrm1   = Utils.getNgbDate(5);              
        } else if(this.idx == "plannedCRM2") {
          this.searchParams.fromNextCrm1 = undefined;              
          this.searchParams.toNextCrm1   = Utils.getNgbDate(-1);
        }
        */
      } else if (
        this.idx == "currentCM0" ||
        this.idx == "currentCM1" ||
        this.idx == "currentCM2" ||
        this.idx == "currentCM3" ||
        this.idx == "currentCM4"
      ) {
        this.codeCommon.epList.forEach((x) => {
          x.checked = false;
        });

        this.codeCommon.epList.forEach((x) => {
          selectedEps.forEach(function (value) {
            if (x.code == value) {
              x.checked = true;
            }
          });
        });

        this.codeCommon.stageList.forEach((x) => {
          if (x.code == "상담 멤버") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "currentCM1") {
          //Referal
          this.codeCommon.memberCategoryList.forEach((x) => {
            if (x.code == "18" || x.code == "20" || x.code == "6") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "currentCM2") {
          //Sub-Agent
          this.codeCommon.memberCategoryList.forEach((x) => {
            if (x.code == "8") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "currentCM3") {
          //Online
          this.codeCommon.memberCategoryList.forEach((x) => {
            if (x.code == "Online") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        } else if (this.idx == "currentCM4") {
          //Others
          this.codeCommon.memberCategoryList.forEach((x) => {
            if (
              x.code != "18" &&
              x.code != "20" &&
              x.code != "6" &&
              x.code != "8" &&
              x.code != "Online"
            ) {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
        }
      }
    }

    //-- From Dashboard Ends --

    if (this.parentSearchData != null) {
      //do nothing
    } else {
      this.searchBasic();
    }
  }

  setDefaultFilter() {
    this.codeCommon.stageList.forEach((x) => {
      if (
        x.code == "신규 멤버" ||
        x.code == "상담 멤버" ||
        x.code == "출국준비멤버"
      ) {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
    if (this.codeCommonFromParent != null) {
      this.codeCommon = this.codeCommonFromParent;
    }
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.rows = new Array<Basic>();
    this.page = new Page();
    this.page.pageNumber = pageInfo.offset;
    this.page.size = this.pageSize;

    this.searchParams.pageNumber = this.page.pageNumber;
    this.search();
  }

  searchBasic() {
    /*
    if(this.parentSearchData != null)
    {
      if(this.parentSearchData != -1) {
        this.searchFromParent();
      }
      else {
        this.searchFromParent();
      }
    }
    else {
      */

    this.rows = new Array<Basic>();
    this.page = new Page();

    this.page.pageNumber = this.searchParams.pageNumber;
    this.page.size = this.pageSize;

    if (this.searchParams.fromRegDate1 != undefined)
      this.searchParams.fromRegDate = Utils.formatDateStr(
        this.searchParams.fromRegDate1
      );
    else this.searchParams.fromRegDate = "";
    if (this.searchParams.toRegDate1 != undefined)
      this.searchParams.toRegDate = Utils.formatDateStr(
        this.searchParams.toRegDate1
      );
    else this.searchParams.toRegDate = "";
    if (this.searchParams.fromNextCrm1 != undefined)
      this.searchParams.fromNextCrm = Utils.formatDateStr(
        this.searchParams.fromNextCrm1
      );
    else this.searchParams.fromNextCrm = "";
    if (this.searchParams.toNextCrm1 != undefined)
      this.searchParams.toNextCrm = Utils.formatDateStr(
        this.searchParams.toNextCrm1
      );
    else this.searchParams.toNextCrm = "";
    if (this.searchParams.fromLastCrm1 != undefined)
      this.searchParams.fromLastCrm = Utils.formatDateStr(
        this.searchParams.fromLastCrm1
      );
    else this.searchParams.fromLastCrm = "";
    if (this.searchParams.toLastCrm1 != undefined)
      this.searchParams.toLastCrm = Utils.formatDateStr(
        this.searchParams.toLastCrm1
      );
    else this.searchParams.toLastCrm = "";
    if (this.searchParams.fromArrivedDate1 != undefined)
      this.searchParams.fromArrivedDate = Utils.formatDateStr(
        this.searchParams.fromArrivedDate1
      );
    else this.searchParams.fromArrivedDate = "";
    if (this.searchParams.toArrivedDate1 != undefined)
      this.searchParams.toArrivedDate = Utils.formatDateStr(
        this.searchParams.toArrivedDate1
      );
    else this.searchParams.toArrivedDate = "";

    this.search();

    //}
  }

  /*
  searchFromParent() {
    var searchKey = this.searchParams.searchKey;
    var searchValue = this.searchParams.searchValue;

    if(this.parentSearchData != -1) {
      this.searchParams.searchKey = "BasicStuId";
      this.searchParams.searchValue = this.parentSearchData;
      console.log("Parent search -> " + this.parentSearchData);
    }
    else {
      //
    }
      
    this.basicService.searchBasic(this.searchParams, this.page)
    .subscribe(
        data => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.content : " + data.content.length);    

          data.content.forEach(basic => {

            if(basic.mdNzMobile != null && basic.mdNzMobile.length > 1 && basic.mdNzMobile.substr(0,1) != "0")
            {
              basic.mdNzMobile = "0" + basic.mdNzMobile;
            }

            this.rows.push(basic);
          });
          
          //this.page.size = data.size;
          this.page.totalElements = data.totalElements;
          this.page.totalPages    = data.totalPages;
          this.page.pageNumber    = data.number;
       
      },
      error => {
          console.log("error : ", error);
          if(error.status == '401') {
            this.router.navigate(['/logout']);          
          }            
      });

    this.searchParams.searchKey = searchKey;
    this.searchParams.searchValue = searchValue;
  }
  */

  search() {
    this.searchParams.ciSubAgent = this.codeCommon.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciMemberField = this.codeCommon.studyFieldList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciMemberCategory = this.codeCommon.memberCategoryList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciEp = this.codeCommon.epList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciStage = this.codeCommon.stageList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.mmAlarmLevel = this.codeCommon.alarmLevelList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.school = this.codeCommon.schoolNameListIncFalse
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.csGrade = this.codeCommon.gradeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.vsVisaType = this.codeCommon.visaTypeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

    this.displaySearchValue = "";
    if (this.searchParams.ciSubAgent.length > 0) {
      this.displaySearchValue =
        this.displaySearchValue +
        " SubName in " +
        JSON.stringify(
          this.codeCommon.subAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciMemberField.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Study Field in " +
        JSON.stringify(
          this.codeCommon.studyFieldList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciMemberCategory.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " M Category in " +
        JSON.stringify(
          this.codeCommon.memberCategoryList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " EP in " +
        JSON.stringify(
          this.codeCommon.epList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciStage.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Stage in " +
        JSON.stringify(
          this.codeCommon.stageList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.mmAlarmLevel.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Alarm_LV in " +
        JSON.stringify(
          this.codeCommon.alarmLevelList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.school.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Enrolled School in " +
        JSON.stringify(
          this.codeCommon.schoolNameListIncFalse
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.csGrade.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Grade in " +
        JSON.stringify(
          this.codeCommon.gradeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.vsVisaType.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Visa in " +
        JSON.stringify(
          this.codeCommon.visaTypeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    var originalsearchKey = this.searchParams.searchKey;
    var originalsearchValue = this.searchParams.searchValue;

    if (this.parentSearchData != null && this.parentSearchData != -1) {
      this.searchParams.searchKey = "BasicStuId";
      this.searchParams.searchValue = this.parentSearchData;
    }

    this.basicService.searchBasic(this.searchParams, this.page).subscribe(
      (data) => {
        data.content.forEach((basic) => {
          if (
            basic.mdNzMobile != null &&
            basic.mdNzMobile.length > 1 &&
            basic.mdNzMobile.substr(0, 1) != "0"
          ) {
            basic.mdNzMobile = "0" + basic.mdNzMobile;
          }

          this.rows.push(basic);
        });

        this.rows = [...this.rows];

        //this.page.size = data.size;
        this.page.totalElements = data.totalElements;
        this.page.totalPages = data.totalPages;
        this.page.pageNumber = data.number;

        this.searchParams.searchKey = originalsearchKey;
        this.searchParams.searchValue = originalsearchValue;
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
    //this.selected.splice(0, this.selected.length);
    //this.selected.push(...selected);
  }

  onActivate(obj) {
    if (obj.type === "click") {
      if (obj.event.altKey) {
        window.open(
          `${window.location.origin}/#/basicDetail/${obj.row.id}`,
          "_blank"
        );
      } else {
        this.selectedRow = obj.row;
      }
    } else if (obj.type === "dblclick") {
      this.codeService.keepSearchParams("1", this.searchParams);
      this.router.navigate(["/basicDetail", obj.row.id]);
    }
  }

  private toggleFromRegDatePicker(): void {
    this.showFromRegDatePicker = !this.showFromRegDatePicker;
    //this.showEndDatePicker = false;
  }

  goBasic() {
    this.router.navigate(["/basicDetail", this.selectedRow.id]);
  }

  onFilter(column) {
    this.selectedColumn = column;
    if (column == "SubName") {
      this.filterList = this.codeCommon.subAgentList;
    } else if (column == "Study Field") {
      this.filterList = this.codeCommon.studyFieldList;
    } else if (column == "M Category") {
      this.filterList = this.codeCommon.memberCategoryList;
    } else if (column == "EP") {
      this.filterList = this.codeCommon.epList;
    } else if (column == "Stage") {
      this.filterList = this.codeCommon.stageList;
    } else if (column == "Alarm_LV") {
      this.filterList = this.codeCommon.alarmLevelList;
    } else if (column == "Enrolled School") {
      this.filterList = this.codeCommon.schoolNameListIncFalse;
    } else if (column == "Grade") {
      this.filterList = this.codeCommon.gradeList;
    } else if (column == "Visa") {
      this.filterList = this.codeCommon.visaTypeList;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "SubName") {
      this.codeCommon.subAgentList = this.filterList;
    } else if (this.selectedColumn == "Field") {
      this.codeCommon.studyFieldList = this.filterList;
    } else if (this.selectedColumn == "M Category") {
      this.codeCommon.memberCategoryList = this.filterList;
    } else if (this.selectedColumn == "EP") {
      this.codeCommon.epList = this.filterList;
    } else if (this.selectedColumn == "Stage") {
      this.codeCommon.stageList = this.filterList;
    } else if (this.selectedColumn == "Alarm_LV") {
      this.codeCommon.alarmLevelList = this.filterList;
    } else if (this.selectedColumn == "Enrolled School") {
      this.codeCommon.schoolNameListIncFalse = this.filterList;
    } else if (this.selectedColumn == "Grade") {
      this.codeCommon.gradeList = this.filterList;
    } else if (this.selectedColumn == "Visa") {
      this.codeCommon.visaTypeList = this.filterList;
    }

    this.filterModal.hide();
    this.searchBasic();
  }

  openChangePopup() {
    if (this.selected.length == 0) return;

    this.changeTarget = "EP";
    this.onChangeTarget();
    this.changeModal.show();
  }

  onCloseChange() {
    this.changeModal.hide();
  }

  onChangeTarget() {
    if (this.changeTarget == "EP") {
      this.changeValueList = JSON.parse(JSON.stringify(this.codeCommon.epList));
      this.changeValue = this.name;
    } else if (this.changeTarget == "Stage") {
      this.changeValueList = JSON.parse(
        JSON.stringify(this.codeCommon.stageList)
      );
      this.changeValue = "상담 멤버";
    } else if (this.changeTarget == "Delete") {
      this.changeValueList = [];
      this.changeValue = "";
    }
  }

  onChange() {
    if (this.selected.length == 0) return;

    this.basicIdList = [];

    this.selected.forEach((x) => {
      this.basicIdList.push(x.id);
    });

    this.selected = [];

    this.basicService
      .updateStatus({
        changeTarget: this.changeTarget,
        changeValue: this.changeValue,
        basicIdList: this.basicIdList,
      })
      .subscribe(
        (data) => {
          //console.log("data" + JSON.stringify(data))

          this.onCloseChange();

          if (this.changeTarget == "Delete") {
            this.searchBasic();
          } else {
            this.rows = new Array<Basic>();
            this.page = new Page();

            data.forEach((basic) => this.rows.push(basic));

            this.page.pageNumber = 0;
            this.page.size = 100;
            this.page.totalElements = this.rows.length;
            this.page.totalPages = 1;
          }
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  onDelete() {
    this.changeTarget = "Delete";
    this.changeValueList = [];
    this.changeValue = "";
    this.onChange();
  }

  setDefaultParams() {
    this.searchParams = new BasicSearchParams();
    this.codeCommon = this.codeService.getOrg();
    this.setDefaultFilter();
    this.searchBasic();
  }

  exportAsCSV() {
    const columns: TableColumn[] = this.dataTable._internalColumns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const printRows: any[] = [];

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "basic",
      useBom: true,
    };

    this.basicService
      .searchBasic(this.searchParams, { pageNumber: 0, size: 10000 })
      .subscribe(
        (data) => {
          //data.content.forEach(basic => this.rows.push(basic));
          //console.log("data.content : "+ data.content.length);
          data.content.forEach((row) => {
            let r = {};
            columns.forEach((column) => {
              if (!column.name) {
                return;
              } // ignore column without name

              if (column.prop) {
                let prop = column.prop;

                r[prop] =
                  typeof row[prop] === "boolean"
                    ? row[prop]
                      ? "Yes"
                      : "No"
                    : row[prop] == null
                    ? ""
                    : row[prop];
              } else {
                // special cases handled here
              }
            });

            printRows.push(r);
          });

          return new Angular2Csv(printRows, "basic", options);
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }

          return null;
        }
      );
  }

  ngOnChanges(changes: any) {
    if (this.parentSearchData != null) {
      if (this.parentSearchData != -1) {
        this.searchBasic();
      } else {
        this.searchBasic();
      }
    }
  }

  onAddFamily() {
    this.basicService
      .addFamily({
        changeTarget: this.selectedRow.id,
        changeValue: this.parentIdData,
        basicIdList: null,
      })
      .subscribe(
        (data) => {
          this.popupMessage = "Updated";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c", // red, blue....
            widthProsentage: 20, // The with of the popou measured by browser width
            animationDuration: 0, // in seconds, 0 = no animation
            showButtons: true, // You can hide this in case you want to use custom buttons
            confirmBtnContent: "OK", // The text on your confirm button
            cancleBtnContent: "Cancel", // the text on your cancel button
            confirmBtnClass: "btn btn-default", // your class for styling the confirm button
            cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
            //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
          };

          this.popup1.show(this.popup1.options);

          this.addNewItemToParent(this.selectedRow.id + "");
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  addNewItemToParent(value: string) {
    this.newItemEvent.emit(value);
  }

  clickOKButton() {
    this.popup1.hide();
  }

  toggleExpandRow(row) {
    row.expanded = !row.expanded;
    this.dataTable.rowDetail.toggleExpandRow(row);
  }
}
