import { NgbDateStruct          } from '@ng-bootstrap/ng-bootstrap';

export class AccountingSearchParams {
    public searchKey?         :string = "Name";
    public searchValue?       :string = "";
    public fromAppliedDate?   :string = "";  
    public toAppliedDate?     :string = "";
    public fromIntakeDate?    :string = "";    
    public toIntakeDate?      :string = "";  
    public fromCompletedDate? :string = "";  
    public toCompletedDate?   :string = "";     
    public fromLapsedDate?   :string = "";
    public toLapsedDate?     :string = "";
    
    public appliedMonth?      :string = "";  
    public intakeMonth?       :string = "";    
    
    public ciMemberCategory   :any[];
    public schoolType         :any[];
    public cpEp               :any[];
    public school             :any[];
    public baseCourse1        :any[];
    public cpSubAgent         :any[];
    public adminAgent         :any[];
    public accountStatus      :any[];
    
    public fromAppliedDate1?   :NgbDateStruct;  
    public toAppliedDate1?     :NgbDateStruct;
    public fromIntakeDate1?    :NgbDateStruct;
    public toIntakeDate1?      :NgbDateStruct;  
    public fromCompletedDate1? :NgbDateStruct;  
    public toCompletedDate1?   :NgbDateStruct;    
    public fromLapsedDate1?    :NgbDateStruct; 
    public toLapsedDate1?      :NgbDateStruct; 
    
    public specialMode?      :string = "";
}

export class Accounting {
  id?:number;
  basicId?:number;
  name?:string;
  school?:string;
  schoolName?:string;  
  schoolType?:string;
  schoolTypeName?:string;  
  admissionSchoolType?:string;
  course?:string;
  period?:string;
  startingDate?:string;
  endDate?:string;
  paidDate?:string;
  appliedDate?:string;
  tuitionFee?:string;
  weeks?:string;
  freeWeeks?:string;
  dc?:number;
  comB4Dc?:string;
  profit?:number;
  kubun?:string;
  category?:string;
  comments?:string;
  intake?:string;
  major?:string;
  accountStatus?:string;
  accountStatusName?:string;  
  baseCourse1?:string;
  baseCourse1Name?:string;  
  baseCourse2?:string;
  gstNo?:string;
  offerOfPlace?:string;
  receipt?:string;
  attendanceReport?:string;
  schoolTuitionCash?:number;
  schoolTuitionBank?:number;
  schoolTuitionCheque?:number;
  schoolRegistrationCash?:number;
  schoolRegistrationBank?:number;
  schoolRegistrationCheque?:number;
  schoolMaterialCash?:number;
  schoolMaterialBank?:number;
  schoolMaterialCheque?:number;
  schoolExamCash?:number;
  schoolExamBank?:number;
  schoolExamCheque?:number;
  etcInsuranceCash?:number;
  etcInsuranceBank?:number;
  etcInsuranceCheque?:number;
  etcVisaCash?:number;
  etcVisaBank?:number;
  etcVisaCheque?:number;
  etcPickupBank?:number;
  etcServiceCash?:number;
  etcServiceBank?:number;  
  etcServiceCheque?:number;
  homestayWeeksBank?:number;
  homestayWeeksCheque?:number;
  homestayFindingfeeCash?:number;
  homestayFindingfeeBank?:number;
  homestayFindingfeeCheque?:number;
  subCom?:number;
  totalFee?:number;
  bankTransferToSchool?:number;
  cashDepositToSchool?:number;
  cashDepositToIae?:number;
  tuitionGross?:number;
  tuitionNet?:number;
  commission?:number;  
  cpSubAgent?:string;
  cpSubAgentName?:string;
  cpSubCodeName?:string;  
  cpEp?:string;
  cpEpName?:string;  
  createDate?:string;
  createUser?:string;
  saveDate?:string;
  saveUser?:string;
  auNzCheck?:string;
  commissionCash?:number;
  commissionBank?:number;
  commissionCheque?:number;
  iaeTg?:number;
  tTn?:number;
  sInsuranceCash?:number;
  sInsuranceBank?:number;
  sInsuranceCheque?:number;
  sOthersCash?:number;
  sOthersBank?:number;
  sOthersCheque?:number;
  sNote?:string;
  iaeNote?:string;
  receivedDate?:string;
  visaNo?:string;
  realCom?:number;
  schoolInvoiceNo?:string;
  invoicedDate?:string;
  subPaidDate?:string;
  amended?:string;
  divide?:string;
  parentId?:number;
  subReal?:number;
  otherExCash?:number;
  otherExBank?:number;
  otherExCheque?:number;
  superNumber?:number;
  adminStatus?:string;
  adminNextCrm?:string;
  adminLastCrm?:string;
  adminEp?:string;
  adminEpName?:string;  
  adminBackground?:string;
  urgent?:string;
  receivedCom?:number;
  superId?:number;
  numb1Tt?:number;
  numb2Tt?:number;
  numb3Tt?:number;
  numb4Tt?:number;
  numb1SubTt?:number;
  numb2SubTt?:number;
  numb3SubTt?:number;
  numb4SubTt?:number;
  enrolledDate?:string;
  packedId?:number;
  insuranceId?:number;
  noGst?:number;
  noGst2?:number;
  chargeEp?:string;
  stuId?:string;
  appId?:string;
  stuIdPwd?:string;
  adminAgent?:string;
  adminAgentName?:string;  
  fromAdmin?:string;
  sunny?:number;
  sunnyPaid?:string;
  
  mdDob?:string;
  mdNameOrg?:string;
  mdNameEngFamily?:string;
  mdNameEng?:string;
  ciMemberCategory?:string;
  ciMemberCategoryName?:string;  
  ciStage?:string;
  ciStageName?:string;  
  ciSubAgent?:string;  
  ciSubAgentName?:string;  
  gst?:number;  
  
  displaySchoolName?:string;     
  displaySchoolFee?:number;    
  displayDc?:number;     
  displayCommission?:number;  
  displaySubCommission?:number;
  displayProfit?:number;
  
  sVisaBank?:number;  
  eNote?:string;    
  esNote?:string;  
  
  commissionRate?:string;
  subCommissionRate?:string;  
  accountingMemo?:string;

  lapsedNew?:number;

  insuranceNote?:string;
  visaApplicationFeeNote?:string;
  otherDiscountNote?:string;
  otherServiceFeeNote?:string;

  otherServiceFeeMemo?:string;
  othersMemo?:string;

  tuitionGrossNote?:string;
  registrationNote?:string;
  materialNote?:string;
  examFeeNote?:string;
  othersNote?:string;
  insuranceMemo?:string;
  visaApplicationFeeMemo?:string;

  visaCreditPurchaseDate?:string;
  visaAppliedDate?:string;

  visaStartDate?:string;
  visaEndDate?:string;
  visaApplicantName1?:string;
  visaApplicantName2?:string;
  visaApplicantName3?:string;
  visaApplicantName4?:string;
  visaPaidCreditCard?:string;
  visaAccountMemo?:string;
  visaPaidToKokosDepositType?:string;
  visaPaidDate?:string;

  visaPaidToKokosPaidAmount?:string;

  insPaidToKokosDepositType?:string;
  osPaidToKokosDepositType?:string;
  insPurchaseDate?:string;
  insStartDate?:string;
  insEndDate?:string;
  insName1?:string;
  insName2?:string;
  insName3?:string;
  insName4?:string;
  insPaidToKokosPaidAmount?:number;
  insPaidDate?:string;
  insAccountMemo?:string;
  osStartDate?:string;
  osEndDate?:string;
  osAppliedDate?:string;
  osAccountMemo?:string;
  osPaidToKokosPaidAmount?:number;
  osPaidDate?:string;
  insPolicyNumber?:string;
}

export class AccountingParams extends Accounting {
}