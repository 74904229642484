import { NgModule               } from '@angular/core';
import { BrowserModule          } from '@angular/platform-browser';
import { RestrictInputDirective } from './restrict-input-directive';


@NgModule({
  imports: [
    BrowserModule
  ],
  declarations: [
    RestrictInputDirective
  ],
  exports: [
    RestrictInputDirective
  ]
})
export class RestrictInputDirectveModule {
}