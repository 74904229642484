import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  IMultiSelectOption,
  IMultiSelectSettings,
  IMultiSelectTexts,
} from "angular-2-dropdown-multiselect";
import { cloneDeep } from "lodash";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { DashboardSearchParams } from "../../model/dashboard";
import { UserView } from "../../model/dto";
import { InformationBoard } from "../../model/information-board";
import { Profile } from "../../model/profile";
import { Schedule } from "../../model/schedule";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { DashboardService } from "../../services/dashboard.service";
import { InformationBoardService } from "../../services/information-board.service";
import { ScheduleService } from "../../services/schedule.service";
import { UserService } from "../../services/user.service";
import * as moment from 'moment';

@Component({
  selector: "app-dashboardmain",
  providers: [DashboardService, InformationBoardService, ScheduleService],
  templateUrl: "./dashboardmain.component.html",
  styleUrls: ["./dashboardmain.component.css"],
})
export class DashboardmainComponent implements OnInit {
  lodash = _;

  selectedEp: string = "";
  authority = "manager";
  name: string;
  company: string;
  thisYear: number = Number.parseInt(moment().format("YYYY"));
  startOfYear: string = moment().startOf('year').format('YYYY-MM-DD');
  endOfYear   = moment().endOf('year').format('YYYY-MM-DD');
  startOfNextYear: string = moment().startOf('year').add(1, 'year').format('YYYY-MM-DD');
  endOfNextYear   = moment().endOf('year').add(1, 'year').format('YYYY-MM-DD');
  startOfMonth: string = moment().startOf('month').format('YYYY-MM-DD');
  endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');

  epList: any[] = [];
  isReady: boolean = false;
  option1var: any = [];

  companyTarget: number = 0;
  companyAchievedTarget: number = 0;
  companyDirectProfit: number = 0;
  companyIndrectProfit: number = 0;
  companyMonthlyProfit: number = 0;
  companyWeeklyProfit: number = 0;
  myTarget: number = 0;
  myAchievedTarget: number = 0;
  myDirectProfit: number = 0;
  myIndrectProfit: number = 0;
  myMonthlyProfit: number = 0;
  myWeeklyProfit: number = 0;
  studentsCount: number = 0;
  studentsCountByUser: number = 0;
  studentsCountInd: number = 0;
  studentsCountDir: number = 0;
  studentsCountIndByUser: number = 0;
  studentsCountDirByUser: number = 0;
  profitPerStudent: number = 0;
  profitPerStudentByUser: number = 0;
  todayCRM: number = 0;
  next5daysCRM: number = 0;
  over5daysCRM: number = 0;
  adminTodayCRM: number = 0;
  adminNext5daysCRM: number = 0;
  adminOver5daysCRM: number = 0;
  yourStudent: number = 0;
  yourAdminStudent: number = 0;
  yourAdminNo: number = 0;
  totalConversionRate: number = 0;
  myConversionRate: number = 0;

  public allSales: { name: string; month: number; year: number }[] = [];

  public rows1 = new Array<InformationBoard>();
  public rows2 = new Array<InformationBoard>();
  public rows3 = new Array<Schedule>();
  public userView = new UserView();

  public searchParams = new DashboardSearchParams();

  //Settings configuration
  mySettings: IMultiSelectSettings = {
    showCheckAll: true,
    showUncheckAll: true,
    dynamicTitleMaxItems: 4,
    displayAllSelectedText: false,
    buttonClasses: "btn btn-secondary",
    checkedStyle: "checkboxes",
  };

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  //Text configuration
  myTexts: IMultiSelectTexts = {
    checkAll: "Select all",
    uncheckAll: "Unselect all",
    checked: "option selected",
    checkedPlural: "options selected",
    searchPlaceholder: "Find",
    searchEmptyResult: "Nothing found...",
    searchNoRenderText: "Type in search box to see results...",
    defaultTitle: "Selected option",
    allSelected: "All selected",
  };

  epOptions: IMultiSelectOption[] = [];

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.epList = cloneDeep(codeCommon.epList);

    for (var i = 0; i < this.epList.length; i++) {
      if (this.epList[i].value != "Baden" && this.epList[i].value != "Mijin" && this.epList[i].value != "Jane"){
        this.epOptions.push({
          name: this.epList[i].value,
          id: this.epList[i].code,
        });
      }
      if (this.epList[i].value != "Baden" && this.epList[i].value != "Mijin" && this.epList[i].value != "Jane"){
        if (this.name == this.epList[i].code) {
          this.option1var.push(this.epList[i].code);
        } else if (this.authority == "manager") {
          this.option1var.push(this.epList[i].code);
        }
      }
    }
    this.epOptions.sort((a, b) => a.name.localeCompare(b.name));
    console.log("0128 this.epOptions : " + JSON.stringify(this.epOptions));
    this.isReady = true;
    this.search();
  }

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardService: DashboardService,
    private codeService: CodeService,
    private informationBoardService: InformationBoardService,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private router: Router
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);
  }

  getDate(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10);
    } else {
      return "";
    }
  }

  getDateTime(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10) + " " + datetime.substring(11, 16);
    } else {
      return "";
    }
  }

  getHomeList1() {
    this.informationBoardService.getHomeList1().subscribe(
      (data) => {
        //console.log("getHomeList1 : " + JSON.stringify(data));

        this.rows1 = new Array<InformationBoard>();
        data.forEach((board) => {
          if (board.boardHead != undefined) {
            board.boardHead1 =
              board.boardHead.length > 30
                ? board.boardHead.substring(0, 30) + "..."
                : board.boardHead;
          }

          this.rows1.push(board);
        });

        for (let idx = this.rows1.length; idx < 14; idx++) {
          this.rows1.push(new InformationBoard());
        }
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  getHomeList2() {
    this.informationBoardService
      .getHomeList2(this.selectedEp.includes(",") ? this.name : this.selectedEp)
      .subscribe(
        (data) => {
          //console.log("getHomeList2 : " + JSON.stringify(data));

          this.rows2 = new Array<InformationBoard>();
          data.forEach((board) => {
            if (board.frequency == "Daily") board.dueDate = this.userView.today;
            else if (board.frequency == "Weekly")
              board.dueDate = this.userView.dayOfWeek;
            else if (board.frequency == "Fortnightly")
              board.dueDate = this.userView.dayOfFortnight;
            else if (board.frequency == "Monthly")
              board.dueDate = this.userView.dayOfMonth;

            if (board.boardHead != undefined) {
              board.boardHead1 =
                board.boardHead.length > 30
                  ? board.boardHead.substring(0, 30) + "..."
                  : board.boardHead;
            }

            this.rows2.push(board);
          });

          for (let idx = this.rows2.length; idx < 14; idx++) {
            this.rows2.push(new InformationBoard());
          }
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  getHomeList3() {
    this.scheduleService.getHomeList("").subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        data.forEach((schedule) => {
          if (this.rows3.length < 20) {
            if (schedule.summary != undefined) {
              schedule.summary1 =
                schedule.summary.length > 40
                  ? schedule.summary.substring(0, 40) + "..."
                  : schedule.summary;
            }
            this.rows3.push(schedule);
          }
        });

        for (let idx = this.rows3.length; idx < 20; idx++) {
          this.rows3.push(new Schedule());
        }
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }

  navigateReport() {
    this.router.navigate(["/informationBoardDetail"], {
      queryParams: { action: "home" },
    });
  }

  getUserView() {
    if (this.selectedEp == undefined) {
      this.selectedEp = this.name;
    }

    //console.log("this.selectedEp : " + this.selectedEp);

    this.userService
      .getView(
        this.selectedEp.includes(",") == true ? this.name : this.selectedEp
      )
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          this.userView = data;

          this.getHomeList2();
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/"]);
          }
        }
      );
  }

  ngOnInit() {
    //
  }

  reset() {
    this.companyTarget = 0;
    this.companyAchievedTarget = 0;
    this.companyDirectProfit = 0;
    this.companyIndrectProfit = 0;
    this.companyMonthlyProfit = 0;
    this.companyWeeklyProfit = 0;
    this.myTarget = 0;
    this.myAchievedTarget = 0;
    this.myDirectProfit = 0;
    this.myIndrectProfit = 0;
    this.myMonthlyProfit = 0;
    this.myWeeklyProfit = 0;
    this.studentsCount = 0;
    this.studentsCountByUser = 0;
    this.studentsCountInd = 0;
    this.studentsCountDir = 0;
    this.studentsCountIndByUser = 0;
    this.studentsCountDirByUser = 0;
    this.profitPerStudent = 0;
    this.profitPerStudentByUser = 0;
    this.todayCRM = 0;
    this.next5daysCRM = 0;
    this.over5daysCRM = 0;
    this.adminTodayCRM = 0;
    this.adminNext5daysCRM = 0;
    this.adminOver5daysCRM = 0;
    this.yourStudent = 0;
    this.yourAdminStudent = 0;
    this.yourAdminNo = 0;
    this.totalConversionRate = 0;
    this.myConversionRate = 0;
  }

  navigateReportView(id) {
    this.router.navigate(["/informationBoardDetail", id]);
  }

  navigateActionAccounting(idx) {
    //atarget
    //atargetDirect
    //atargetIndirect
    //myAtarget
    //myAtargetDirect
    //myAtargetIndirect
    //top1year
    //top2year
    //top3year
    //top1month
    //top2month
    //top3month
    //atargetLastyear
    //myAtargetLastyear
    //atarget2yearsAgo
    //myAtarget2yearAgo

    this.codeService.setSelectedEp(this.searchParams.searchEP);

    if (idx == "atarget") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: "" },
      });
    } else if (idx == "atargetDirect") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: "" },
      });
    } else if (idx == "atargetIndirect") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: "" },
      });
    } else if (idx == "myAtarget") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: this.selectedEp },
      });
    } else if (idx == "myAtargetDirect") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: this.selectedEp },
      });
    } else if (idx == "myAtargetIndirect") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: this.selectedEp },
      });
    } else if (idx == "atargetLastyear") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: "" },
      });
    } else if (idx == "myAtargetLastyear") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: this.selectedEp },
      });
    } else if (idx == "atarget2yearsAgo") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: "" },
      });
    } else if (idx == "myAtarget2yearAgo") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "year", idx: idx, ep: this.selectedEp },
      });
    }

    /*
    else if(idx == 'companyMonthlyProfit') {
      this.router.navigate(['/accounting'], { queryParams: { action: "", idx: idx, ep: "" } });
    }
    else if(idx == 'companyWeeklyProfit') {
      this.router.navigate(['/accounting'], { queryParams: { action: "", idx: idx, ep: "" } });
    }
    else if(idx == 'myMonthlyProfit') {
      this.router.navigate(['/accounting'], { queryParams: { action: "", idx: idx, ep: this.selectedEp } });
    }
    else if(idx == 'myWeeklyProfit') {
      this.router.navigate(['/accounting'], { queryParams: { action: "", idx: idx, ep: this.selectedEp } });
    }
    */
  }

  navigateActionWithOnlyIdxEPs(idx) {
    this.codeService.setSelectedEp(this.searchParams.searchEP);

    if (idx == "myNumber4" || idx == "totalNumber4") {
      this.router.navigate(["/admission"], {
        queryParams: {
          action: "home",
          idx: idx,
          ep: this.searchParams.searchEP,
        },
      });
    } else {
      this.router.navigate(["/basic"], {
        queryParams: {
          action: "home",
          idx: idx,
          ep: this.searchParams.searchEP,
        },
      });
    }
  }

  navigateActionWithOnlyIdxAdmin(idx) {
    this.codeService.setSelectedEp(this.searchParams.searchEP);

    this.router.navigate(["/admission"], {
      queryParams: { action: "home", idx: idx, ep: this.searchParams.searchEP },
    });
  }

  search() {
    this.selectedEp = "";
    this.searchParams.searchEP = "";

    let optionValue = "";

    for (var i = 0; i < this.option1var.length; i++) {
      optionValue = this.option1var[i]?.id ?? this.option1var[i];
      if (i == this.option1var.length - 1) {
        this.selectedEp += "" + optionValue + "";
        this.searchParams.searchEP += "" + optionValue + "";
      } else {
        this.selectedEp += "" + optionValue + "" + ",";
        this.searchParams.searchEP += "" + optionValue + "" + ",";
      }
    }

    this.getUserView();
    this.getHomeList1();
    //this.getHomeList2();
    this.getHomeList3();

    this.reset();

    this.dashboardService.getAllSales().subscribe((data) => {
      this.allSales = data ? data : [];
    });

    this.dashboardService.getTarget(this.searchParams).subscribe(
      (data) => {
        data.forEach((newdash) => {

          console.log("dashboardmain getTarget success: " );

          this.companyTarget += +newdash.holdingpending0;
          this.companyAchievedTarget += +newdash.holdingpending1;
          this.companyDirectProfit += +newdash.holdingpending2;
          this.companyIndrectProfit = +newdash.holdingpending3;
          this.companyMonthlyProfit += +newdash.invoiced2;
          this.companyWeeklyProfit += +newdash.invoiced3;
          this.myTarget += +newdash.subUnpaid0;
          this.myAchievedTarget += +newdash.subUnpaid1;
          this.myDirectProfit += +newdash.subUnpaid2;
          this.myIndrectProfit += +newdash.subUnpaid3;
          this.myMonthlyProfit += +newdash.case2;
          this.myWeeklyProfit += +newdash.case3;

          this.studentsCount += +newdash.mynumber0;
          this.studentsCountDir += +newdash.mynumber1;
          this.studentsCountInd += +newdash.mynumber2;
          this.studentsCountByUser += +newdash.mynumber3;
          this.studentsCountDirByUser += +newdash.mynumber4;
          this.studentsCountIndByUser += +newdash.mynumber5;

          this.profitPerStudent += +newdash.invoiced0;
          this.profitPerStudentByUser += +newdash.case0;

          this.todayCRM += +newdash.lapsednm0;
          this.next5daysCRM += +newdash.lapsednm1;
          this.over5daysCRM += +newdash.lapsednm2;
          this.adminTodayCRM += +newdash.lapsednm3;
          this.adminNext5daysCRM += +newdash.lapsedcm0;
          this.adminOver5daysCRM += +newdash.lapsedcm1;

          //make conversion rate from 0.xx to percentage(%).
          this.totalConversionRate = Math.round(
            newdash.invoiced1 * 100 + Number.EPSILON
          );
          this.myConversionRate = Math.round(
            newdash.case1 * 100 + Number.EPSILON
          );

          /*
            this.plannedCRM[0] += +eps.plannedcrm0;
            this.plannedCRM[1] += +eps.plannedcrm1;
            this.plannedCRM[2] += +eps.plannedcrm2;
        

            this.myNumber[0] += +eps.mynumber0;
            this.myNumber[1] += +eps.mynumber1;
            this.myNumber[2] += +eps.mynumber2;
            this.myNumber[3] += +eps.mynumber3;
            this.myNumber[4] += +eps.mynumber4;
            this.myNumber[5] += +eps.mynumber5;

            this.lapsedNM[0] += +eps.lapsednm0;
            this.lapsedNM[1] += +eps.lapsednm1;
            this.lapsedNM[2] += +eps.lapsednm2;
            this.lapsedNM[3] += +eps.lapsednm3;
            
            this.lapsedCM[0] += +eps.lapsedcm0;
            this.lapsedCM[1] += +eps.lapsedcm1;
            this.lapsedCM[2] += +eps.lapsedcm2;
            this.lapsedCM[3] += +eps.lapsedcm3;
            
            this.currentCM[0] += +eps.currentcm0;
            this.currentCM[1] += +eps.currentcm1;
            this.currentCM[2] += +eps.currentcm2;
            this.currentCM[3] += +eps.currentcm3;
            this.currentCM[4] += +eps.currentcm4;
            
            this.arrivingM[0] += +eps.arrivingm0;
            this.arrivingM[1] += +eps.arrivingm1;
            */
        });

        //Need to find it out that why are they getting double counted.
        //e.g. if it is 4, than 2.
        //this.totalNumber[4] = this.totalNumber[4]/2;
        //this.myNumber[4] = this.myNumber[4]/2;

        //this.serachNotAllowed = false;
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );

    this.dashboardService
      .getFeematchingListByAccEP(this.searchParams)
      .subscribe(
        (data) => {
          data.forEach((admin) => {
            this.yourStudent += +admin.next5crm0;
            this.yourAdminStudent += +admin.todaycrm2;
            this.yourAdminNo += +admin.todaycrm3;
          });
        },
        (error) => {
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }
}
