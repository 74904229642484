import { NgModule                 } from '@angular/core';
import { RouterModule             } from '@angular/router';
import { FormsModule              } from '@angular/forms';
import { BrowserModule            } from '@angular/platform-browser';
import { BrowserAnimationsModule  } from '@angular/platform-browser/animations';
import { ModalModule              } from 'ngx-bootstrap/modal';
import { TabsModule               } from 'ngx-bootstrap/tabs';
import { ContextMenuModule        } from 'ngx-contextmenu';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule,
         NgbDatepickerModule,
         NgbTimepickerModule      } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleComponent        } from './schedule.component';
import { CalendarHeaderComponent  } from './calendar-header.component';
import { DateTimePickerComponent  } from './date-time-picker.component';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
    TabsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgbModalModule,
    NgbDatepickerModule,
    NgbTimepickerModule,    
    ContextMenuModule.forRoot({
      useBootstrap4: true
    }),    
  ],
  declarations: [
    ScheduleComponent,
    CalendarHeaderComponent,
    DateTimePickerComponent
  ],
  exports: [
    ScheduleComponent,
    CalendarHeaderComponent,
    DateTimePickerComponent
  ]
})
export class ScheduleModule {
  

}
