import { Routes } from "@angular/router";
import { AccountingDetailComponent } from "./components/accounting/accounting-detail.component";
import { AccountingComponent } from "./components/accounting/accounting.component";
import { AdminComponent } from "./components/admin/admin.component";
import { AdmissionDetailComponent } from "./components/admission/admission-detail.component";
import { AdmissionComponent } from "./components/admission/admission.component";
import { AgentComponent } from "./components/agent/agent.component";
import { ApplicationComponent } from "./components/application/application.component";
import { BasicDetailComponent } from "./components/basic/basic-detail.component";
import { BasicComponent } from "./components/basic/basic.component";
import { PersonalFilesComponent } from "./components/basic/personal-files.component";
import { PersonalFoldersComponent } from "./components/basic/personal-folders.component";
import { CodeComponent } from "./components/code/code.component";
import { CounsellingComponent } from "./components/counselling/counselling.component";
import { CustomersComponent } from "./components/customers/customers.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardmainComponent } from "./components/dashboardmain/dashboardmain.component";
import { EpComponent } from "./components/ep/ep.component";
import { EventDetailComponent } from "./components/event/event-detail.component";
import { EventComponent } from "./components/event/event.component";
import { FollowupDetailComponent } from "./components/followup/followup-detail.component";
import { FollowupComponent } from "./components/followup/followup.component";
import { InformationBoardDetailComponent } from "./components/information/information-board-detail.component";
import { InformationBoardComponent } from "./components/information/information-board.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { RegisterComponent } from "./components/register/register.component";
import { ScheduleComponent } from "./components/schedule/schedule.component";
import { SchoolComponent } from "./components/school/school.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { ThreadComponent } from "./components/thread/thread.component";
import { UsersComponent } from "./components/users/users.component";
import { ProfileDataResolver } from "./services/profile-data.resolver";

export const MainRoutes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "register",
    component: RegisterComponent,
  },
  {
    path: "profile",
    component: ProfileComponent,
    resolve: {
      profile: ProfileDataResolver,
    },
  },
  {
    path: "users",
    component: UsersComponent,
  },
  {
    path: "thread",
    component: ThreadComponent,
  },
  {
    path: "customers",
    component: CustomersComponent,
  },
  {
    path: "basic",
    component: BasicComponent,
  },
  {
    path: "basicDetail/:id",
    component: BasicDetailComponent,
  },
  {
    path: "basicDetail",
    component: BasicDetailComponent,
  },
  {
    path: "dashboardmain",
    component: DashboardmainComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  {
    path: "admin",
    component: AdminComponent,
  },
  {
    path: "ep",
    component: EpComponent,
  },
  {
    path: "newMember",
    component: BasicDetailComponent,
  },
  {
    path: "personalFiles/:id",
    component: PersonalFilesComponent,
  },
  {
    path: "personalFolders/:id",
    component: PersonalFoldersComponent,
  },
  {
    path: "followup",
    component: FollowupComponent,
  },
  {
    path: "followupDetail/:id",
    component: FollowupDetailComponent,
  },
  {
    path: "admission",
    component: AdmissionComponent,
  },
  {
    path: "admissionDetail/:id",
    component: AdmissionDetailComponent,
  },
  {
    path: "admissionDetail/:id/:admid",
    component: AdmissionDetailComponent,
  },
  {
    path: "accounting",
    component: AccountingComponent,
  },
  {
    path: "accountingDetail/:id",
    component: AccountingDetailComponent,
  },
  {
    path: "accountingDetail/:id/:accid",
    component: AccountingDetailComponent,
  },
  {
    path: "schedule",
    component: ScheduleComponent,
  },
  {
    path: "informationBoard",
    component: InformationBoardComponent,
  },
  {
    path: "informationBoardDetail/:id",
    component: InformationBoardDetailComponent,
  },
  {
    path: "informationBoardDetail",
    component: InformationBoardDetailComponent,
  },
  {
    path: "event",
    component: EventComponent,
  },
  {
    path: "eventDetail/:id",
    component: EventDetailComponent,
  },
  {
    path: "application",
    component: ApplicationComponent,
  },
  {
    path: "counselling",
    component: CounsellingComponent,
  },
  {
    path: "statistics",
    component: StatisticsComponent,
  },
  {
    path: "school",
    component: SchoolComponent,
  },
  {
    path: "agent",
    component: AgentComponent,
  },
  {
    path: "code",
    component: CodeComponent,
  },
  {
    path: "",
    component: DashboardmainComponent,
  },

  //otherwise redirect to home
  {
    path: "**",
    redirectTo: "",
  },
];
