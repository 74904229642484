import { NgModule } from "@angular/core";
import { FilterPipe } from "./filter.pipe";
import { MomentAgoPipe } from "./moment-ago.pipe";

const components = [FilterPipe, MomentAgoPipe];

@NgModule({
  imports: [],
  declarations: components,
  exports: components,
})
export class PipesModule {}
