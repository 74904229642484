export class UserTargetSearchParams {
    public name?        :string = "";
    public year?        :string = "";
}

export class UserTarget {
  id:string|number;
  name?:string;
  year?:string;
  target1?:number;
  target2?:number;
  target3?:number;
  target4?:number;
  createDate?:string;
  saveDate?:string;    
  createUser?:string;
  saveUser?:string;
}

export class UserTargetParams extends UserTarget {
}


