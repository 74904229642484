import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MultiselectDropdownModule } from "angular-2-dropdown-multiselect";
import { FlashMessagesModule } from "angular2-flash-messages";
import "hammerjs";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { PopupModule } from "ng2-opd-popup";
import { ModalModule } from "ngx-bootstrap/modal";
import { DndListModule } from "ngx-drag-and-drop-lists";
import { AppComponent } from "./app.component";
import { MainRoutes } from "./app.routes";
import { AccountingModule } from "./components/accounting/accounting.module";
import { AdminComponent } from "./components/admin/admin.component";
import { AdminDoneComponent } from "./components/admindone/admindone.component";
import { AdminlapseComponent } from "./components/adminlapse/adminlapse.component";
import { AdmissionModule } from "./components/admission/admission.module";
import { AgentModule } from "./components/agent/agent.module";
import { ApplicationModule } from "./components/application/application.module";
import { BasicModule } from "./components/basic/basic.module";
import { CodeComponent } from "./components/code/code.component";
import { CounsellingModule } from "./components/counselling/counselling.module";
import { CustomersModule } from "./components/customers/customers.module";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardAccountingComponent } from "./components/dashboardaccounting/dashboardaccounting.component";
import { DashboardmainComponent } from "./components/dashboardmain/dashboardmain.component";
import { EpComponent } from "./components/ep/ep.component";
import { EventModule } from "./components/event/event.module";
import { FollowupModule } from "./components/followup/followup.module";
import { HeaderModule } from "./components/header/header.module";
import { InformationBoardModule } from "./components/information/information-board.module";
import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { RegisterComponent } from "./components/register/register.component";
import { ScheduleModule } from "./components/schedule/schedule.module";
import { SchoolModule } from "./components/school/school.module";
import { StatisticsModule } from "./components/statistics/statistics.module";
import { ChatComponent } from "./components/thread/chat.component";
import { ChatModalComponent } from "./components/thread/chatmodal.component";

import { ThreadComponent } from "./components/thread/thread.component";
import { TopComponent } from "./components/top/top.component";
import { UsersModule } from "./components/users/users.module";
import { AuthenticationService } from "./services/authentication.service";
import { CodeService } from "./services/code.service";
import { JsonHttp } from "./services/json-http";
import { ProfileDataResolver } from "./services/profile-data.resolver";
import { UserService } from "./services/user.service";
import { ComboxComponent } from "./shared/combox/combox.component";
import { DirectiveCommonModule } from "./shared/directive/directive-common.module";
import { RestrictInputDirectveModule } from "./shared/directive/restrict-input-directive.module";
import { NgbDateFRParserFormatter } from "./shared/ngb-date-fr-parser-formatter";
import { PipesModule } from "./shared/pipe/pipe.module";
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(MainRoutes),
    RouterModule.forRoot(MainRoutes, { useHash: true }),
    RestrictInputDirectveModule,
    DirectiveCommonModule,
    HeaderModule,
    UsersModule,
    CustomersModule,
    BasicModule,
    FollowupModule,
    AdmissionModule,
    AccountingModule,
    ScheduleModule,
    SchoolModule,
    AgentModule,
    InformationBoardModule,
    EventModule,
    ApplicationModule,
    CounsellingModule,
    StatisticsModule,
    DndListModule,
    NgbModule,
    PopupModule.forRoot(),
    FlashMessagesModule.forRoot(),
    NgxDatatableModule,
    ModalModule.forRoot(),
    MultiselectDropdownModule,
    NgMultiSelectDropDownModule.forRoot(),
    PipesModule,
    MatDialogModule,
  ],
  declarations: [
    AppComponent,
    TopComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ProfileComponent,
    CodeComponent,
    ComboxComponent,
    DashboardComponent,
    DashboardAccountingComponent,
    AdminComponent,
    EpComponent,
    DashboardmainComponent,
    ThreadComponent,
    ChatComponent,
    ChatModalComponent,
    AdminDoneComponent,
    AdminlapseComponent,
  ],

  providers: [
    AuthenticationService,
    UserService,
    CodeService,
    JsonHttp,
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    ProfileDataResolver,
  ],
  bootstrap: [AppComponent],
  entryComponents: [ChatModalComponent]
})
export class AppModule {}
