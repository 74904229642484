import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { User, UserSearchParams } from "../../model/dto";
import { Page } from "../../model/page";
import { AuthenticationService } from "../../services/authentication.service";
import { CustomerService } from "../../services/customer.service";


@Component({
  selector: "app-customers",
  providers: [CustomerService, DatePipe],
  templateUrl: "./customers.component.html",
  styles: [
    `
      .well .list-group {
        margin-bottom: 0px;
      }
      .ngx-datatable {
        height: 400px;
      }
    `,
  ],
})
export class CustomersComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;
  displaySearchValue: string;

  public rows = new Array<User>();
  public selectedRow = new User();

  public searchParams = new UserSearchParams();

  public page = new Page();
  public pageSize = 20;

  filterList: any[] = [];
  activeList: any[] = [
    { code: true, value: "True", checked: true },
    { code: false, value: "False", checked: false },
  ];

  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  selected: any[] = [];
  selectedTarget: any[] = [];

  authorityList: any[] = [
    { code: "normal", value: "normal" },
    { code: "admin", value: "admin" },
    { code: "accountant", value: "accountant" },
    { code: "manager", value: "manager" },
    { code: "agent", value: "agent" },
  ];

  tfList: any[] = [
    { code: "true", value: "true" },
    { code: "false", value: "false" },
  ];

  authority: string;
  name: string;
  company: string;

  constructor(
    private customerService: CustomerService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    this.getAll();
  }

  ngOnInit() {}

  onSelect({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivate(obj) {
    if (obj.type === "click") {
      if (obj.row.id == undefined) {
        this.selectedRow = new User();
      } else {
        this.selectedRow = obj.row;
      }
    } else if (obj.type === "dblclick") {
      this.onCreate();
    }
  }

  onSelectTarget({ selected }) {
    //console.log('Select Event', selected);
  }

  onClear() {
    this.selected.splice(0, this.selected.length);
    this.selectedRow = new User();
  }

  clickOKButton() {
    this.popup1.hide();
    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in updating"
    ) {
      this.onClear();
      this.getAll();
    }
  }

  searchCustomer(){
    this.page.pageNumber = 0;
    this.getAll();
  }

  getAll() {
    this.searchParams.isActive = this.activeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);


    this.customerService.getAll(this.searchParams, this.page).subscribe(
      (data) => {
        this.rows = new Array<User>();
        data.content.forEach((item) => this.rows.push(item));
        this.page.totalElements = data.totalElements;
        this.page.totalPages = data.totalPages;
        this.page.pageNumber = data.number;
        this.searchParams = new UserSearchParams();
      },

      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  removeComma(value) {
    if (value == undefined) return value;
    return +value.replace(",", "");
  }

  goBack(): void {
    this.location.back();
  }

  onFilter(column) {
    this.selectedColumn = column;
    if (column == "Active") {
      this.filterList = this.activeList;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "Active") {
      this.activeList = this.filterList;
    }

    this.filterModal.hide();
    this.getAll();
  }


  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page-1;
    this.page.size = this.pageSize;
    this.getAll();
  }



  onCreate() {
    this.customerService.getBasicInfo(this.selectedRow.id).subscribe(
      (data) => {
        //console.log("### data check :", JSON.stringify(data));
        //console.log("### data check :", data.content[0].id);
        if(data.content.length >0){
          let basicId = data.content[0].id;
          this.router.navigate(["/basicDetail/"+basicId]);
        }else{
          this.router.navigate(["/newMember"]);
        }
      },
      (error) => {
        //console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }else{
          this.router.navigate(["/newMember"]);
        }
      }
    );



    //console.log("onCreate call check user",user);

    //get basic by customer_id..

    //console.log("onCreate call url",url);

    //this.router.navigate(["/basicDetail/524072"]);

    //this.router.navigate(["/newMember", this.selectedRow]);
  }

}
