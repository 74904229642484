import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/statistics`;

@Injectable()
export class StatisticsService {
  constructor(private http: JsonHttp) {}

  getView1(searchParams) {
    let params: string = `${url}/view1?searchKey=${searchParams.searchKey}&searchDate=${searchParams.searchDate}&holding=${searchParams.holding}&fromAppliedDate=${searchParams.fromAppliedDate}&toAppliedDate=${searchParams.toAppliedDate}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCompletedDate=${searchParams.fromCompletedDate}&toCompletedDate=${searchParams.toCompletedDate}`;

    if (searchParams.ciEp != undefined) {
      searchParams.ciEp.forEach(
        (code) => (params = params + "&ciEp=" + code.id)
      );
    }

    ////console.log(params);

    return this.http.get(params);
  }

  getView2(searchParams) {
    let params: string = `${url}/view2?searchKey=${searchParams.searchKey}&searchDate=${searchParams.searchDate}&holding=${searchParams.holding}&fromAppliedDate=${searchParams.fromAppliedDate}&toAppliedDate=${searchParams.toAppliedDate}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCompletedDate=${searchParams.fromCompletedDate}&toCompletedDate=${searchParams.toCompletedDate}`;

    if (searchParams.ciEp != undefined) {
      searchParams.ciEp.forEach(
        (code) => (params = params + "&ciEp=" + code.id)
      );
    }

    ////console.log(params);

    return this.http.get(params);
  }

  getView21(searchParams) {
    let params: string = `${url}/view21?searchKey=${searchParams.searchKey}&searchDate=${searchParams.searchDate}&holding=${searchParams.holding}&fromAppliedDate=${searchParams.fromAppliedDate}&toAppliedDate=${searchParams.toAppliedDate}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCompletedDate=${searchParams.fromCompletedDate}&toCompletedDate=${searchParams.toCompletedDate}`;

    if (searchParams.ciEp != undefined) {
      searchParams.ciEp.forEach(
        (code) => (params = params + "&ciEp=" + code.id)
      );
    }

    ////console.log(params);

    return this.http.get(params);
  }

  getView3(searchParams) {
    let params: string = `${url}/view3?searchKey=${searchParams.searchKey}&searchDate=${searchParams.searchDate}&holding=${searchParams.holding}&fromAppliedDate=${searchParams.fromAppliedDate}&toAppliedDate=${searchParams.toAppliedDate}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCompletedDate=${searchParams.fromCompletedDate}&toCompletedDate=${searchParams.toCompletedDate}`;

    if (searchParams.ciEp != undefined) {
      searchParams.ciEp.forEach(
        (code) => (params = params + "&ciEp=" + code.id)
      );
    }

    ////console.log(params);

    return this.http.get(params);
  }

  getView4(searchParams) {
    let params: string = `${url}/view4?searchKey=${searchParams.searchKey}&searchDate=${searchParams.searchDate}&holding=${searchParams.holding}&fromAppliedDate=${searchParams.fromAppliedDate}&toAppliedDate=${searchParams.toAppliedDate}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCompletedDate=${searchParams.fromCompletedDate}&toCompletedDate=${searchParams.toCompletedDate}`;

    if (searchParams.ciEp != undefined) {
      searchParams.ciEp.forEach(
        (code) => (params = params + "&ciEp=" + code.id)
      );
    }

    ////console.log(params);

    return this.http.get(params);
  }
}
