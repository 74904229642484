import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Profile } from "app/model/profile";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import {
  InformationBoard,
  InformationBoardSearchParams,
} from "../../model/information-board";
import { Page } from "../../model/page";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { InformationBoardService } from "../../services/information-board.service";

@Component({
  selector: "app-information-board",
  providers: [InformationBoardService],
  templateUrl: "./information-board.component.html",
})
export class InformationBoardComponent implements OnInit {
  @Input() parentData;
  @Input("data") thirdCategoryFixed;
  @Input("second") secondCategoryFixed;
  @Input("fourth") fourthCategoryFixed;

  public page = new Page();
  public rows = new Array<InformationBoard>();
  public userId: number = 0;
  public pageSize = 20;

  selectedColumn: string;

  @ViewChild("filterModal") public filterModal: ModalDirective;

  public searchParams = new InformationBoardSearchParams();

  informationCategoryList: any[] = [];
  informationCategory2List: any[] = [];
  informationCategory3List: any[] = [];
  informationCategory4List: any[] = [];

  informationCategoryListChecked: boolean = false;
  informationCategory2ListChecked: boolean = false;
  informationCategory3ListChecked: boolean = false;
  informationCategory4ListChecked: boolean = false;

  selected: any[] = [];
  idList: any[] = [];

  selectedRow: InformationBoard = new InformationBoard();

  codeCommon: CodeCommon;

  authority: string;
  name: string;
  company: string;

  dailyReportChecker: boolean = false;

  filterList: any[] = [];
  selectedAll: boolean = false;
  isResetSearchboard : boolean = false; //1009 search fixed

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private informationBoardService: InformationBoardService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.page.pageNumber = 0;
    this.page.size = 20;

    //codeService.getCommon(this);
    codeService.getEachCommon(this, "5");
  }

  queryParam: string = "";
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.queryParam = params["val"];
    });
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  setSearchParams(searchParams) {
    if (searchParams != undefined) {
      this.searchParams = searchParams;

      if (this.thirdCategoryFixed != null) {
        this.searchParams.boardCategoryThird = this.thirdCategoryFixed;
      }
    } else {
      this.searchParams.boardCategory = "";
      this.searchParams.boardCategoryMiddle = "";
      this.searchParams.boardCategoryThird = "";
      this.searchParams.boardCategoryFourth = "";
      this.searchParams.boardHead = "";
      this.searchParams.pageNumber = 0;

      if (this.thirdCategoryFixed != null) {
        this.searchParams.boardCategoryThird = this.thirdCategoryFixed;
      }
    }
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.codeCommon = codeCommon;

    this.informationCategoryList = codeCommon.informationCategoryList;

    this.setCombox();

    this.searchInformationBoard(this.searchParams.pageNumber);
  }

  setCombox() {
    if (
      this.searchParams.boardCategory != undefined &&
      this.informationCategoryList.length > 0
    ) {
      if (this.searchParams.boardCategory == "Daily Report") {
        if (this.authority == "manager") {
          this.informationCategory2List = this.codeCommon.epList;
        } else {
          this.informationCategory2List = this.codeCommon.epList.filter(
            (x) => x.code == this.name
          );
        }
      } else {
        let cat2 = this.informationCategoryList.find(
          (x) => x.code == this.searchParams.boardCategory
        );

        if (cat2 != undefined) {
          this.informationCategory2List = cat2.children;
        }
      }

      if (this.informationCategory2List !== undefined) {
        if (this.searchParams.boardCategory == "School") {
          this.informationCategory3List = this.codeCommon.schoolNameList.filter(
            (code) => {
              //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
              if (code.value4) {
                var codes = code.value4.split(", ");
                for (var i = 0; i < codes.length; i++) {
                  if (codes[i] == this.searchParams.boardCategoryMiddle)
                    return true;
                }
              }
            }
          );
        } else {
          let cat3 = this.informationCategory2List.find(
            (x) => x.code == this.searchParams.boardCategoryMiddle
          );

          if (cat3 != undefined) {
            this.informationCategory3List = cat3.children;
          }
        }
      }

      if (this.informationCategory3List !== undefined) {
        if (this.searchParams.boardCategory == "School") {
          let cat3 = this.informationCategory2List.find((code) => {
            //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
            if (code.value4) {
              var codes = code.value4.split(", ");
              for (var i = 0; i < codes.length; i++) {
                if (codes[i] == this.searchParams.boardCategoryMiddle)
                  return true;
              }
            }
          });

          if (cat3 != undefined) {
            this.informationCategory4List = cat3.children;
          }
        } else {
          let cat4 = this.informationCategory3List.find(
            (x) => x.code == this.searchParams.boardCategoryThird
          );

          if (cat4 != undefined) {
            this.informationCategory4List = cat4.children;
          }
        }
      }
    }
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.rows = new Array<InformationBoard>();
    this.page = new Page();
    this.page.pageNumber = pageInfo.offset;
    this.page.size = 15;

    this.searchParams.pageNumber = this.page.pageNumber;
    this.search();
  }

  searchInformationBoard(pageNumber) {
    this.rows = new Array<InformationBoard>();
    this.page = new Page();

    this.page.pageNumber = pageNumber;
    this.page.size = 15;

    //1009 search fixed        
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        val: this.queryParam,
        boardHead: this.searchParams.boardHead,
        pageNumber: this.page.pageNumber,
      },
      queryParamsHandling: 'merge', // 기존 쿼리 파라미터와 병합
    });  


    this.search();
  }

  search() {
    //First board category
    if (1 == 1) {
      //covert filter like (aaa,bbb,ccc)
      this.searchParams.boardCategory = "";

      var array: any[] = this.codeCommon.informationCategoryList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);

      for (var i = 0; i < array.length; i++) {
        array[i] = array[i].replaceAll(",", "и").replaceAll("&", "Ñ");

        if (i < array.length - 1)
          this.searchParams.boardCategory += "" + array[i] + "" + ",";
        else this.searchParams.boardCategory += "" + array[i] + "";
      }
    }

    //Second board category
    if (1 == 1) {
      //covert filter like (aaa,bbb,ccc)
      this.searchParams.boardCategoryMiddle = "";

      var array: any[] = [];

      for (var i = 0; i < this.informationCategory2List.length; i++) {
        array.push(this.informationCategory2List[i]["code"]);
      }

      for (var i = 0; i < array.length; i++) {
        array[i] = array[i].replaceAll(",", "и").replaceAll("&", "Ñ");

        if (i < array.length - 1)
          this.searchParams.boardCategoryMiddle += "" + array[i] + "" + ",";
        else this.searchParams.boardCategoryMiddle += "" + array[i] + "";
      }
    }

    //Third board category
    if (1 == 1) {
      //covert filter like (aaa,bbb,ccc)
      this.searchParams.boardCategoryThird = "";

      var array: any[] = [];

      for (var i = 0; i < this.informationCategory3List.length; i++) {
        array.push(this.informationCategory3List[i]["code"]);
      }

      for (var i = 0; i < array.length; i++) {
        array[i] = array[i].replaceAll(",", "и").replaceAll("&", "Ñ");

        if (i < array.length - 1)
          this.searchParams.boardCategoryThird += "" + array[i] + "" + ",";
        else this.searchParams.boardCategoryThird += "" + array[i] + "";
      }

      if (this.thirdCategoryFixed != null) {
        this.thirdCategoryFixed = this.thirdCategoryFixed
          .replaceAll(",", "и")
          .replaceAll("&", "Ñ");

        this.searchParams.boardCategoryThird = this.thirdCategoryFixed;
      }

      /*
      //some of them has parent code infront of child code so
      //we need to fill that as well.
      var arrayBase: any[] = [];
      
      for(var i = 0; i < this.informationCategory2List.length; i++) {
        arrayBase.push(this.informationCategory2List[i]["code"]);
      }
  
      for(var i = 0; i < arrayBase.length; i++) {
        arrayBase[i] = arrayBase[i].replaceAll(',','и').replaceAll("&", "Ñ");
      }

      var array: any[] = [];
      
      for(var i = 0; i < this.informationCategory3List.length; i++) {
        array.push(this.informationCategory3List[i]["code"]);
      }
  
      for(var i = 0; i < array.length; i++) {
        array[i] = array[i].replaceAll(',','и').replaceAll("&", "Ñ");

        if(i == 0)
          this.searchParams.boardCategoryThird += ",";

        for(var xx = 0; xx < arrayBase.length; xx++) {
          if(i < array.length - 1)
            this.searchParams.boardCategoryThird += arrayBase[xx] + " " + array[i] + "" + ",";
          else
            this.searchParams.boardCategoryThird += arrayBase[xx] + " " + array[i] + "";
        }
      }
      */
    }

    //Fourth board category
    if (1 == 1) {
      //covert filter like (aaa,bbb,ccc)
      this.searchParams.boardCategoryFourth = "";

      var array: any[] = [];

      for (var i = 0; i < this.informationCategory4List.length; i++) {
        array.push(this.informationCategory4List[i]["code"]);
      }

      for (var i = 0; i < array.length; i++) {
        array[i] = array[i].replaceAll(",", "и").replaceAll("&", "Ñ");

        if (i < array.length - 1)
          this.searchParams.boardCategoryFourth += "" + array[i] + "" + ",";
        else this.searchParams.boardCategoryFourth += "" + array[i] + "";
      }
    }

    //when from School search (School -> School Board) works STARTS
    if (
      this.secondCategoryFixed != null &&
      this.secondCategoryFixed.length > 0
    ) {
      var dataDeliverThis = "";

      for (var i = 0; i < this.secondCategoryFixed.length; i++) {
        this.secondCategoryFixed[i].id = this.secondCategoryFixed[i].id
          .replace(",", "и")
          .replace("&", "Ñ");
        if (i == this.secondCategoryFixed.length - 1)
          dataDeliverThis += this.secondCategoryFixed[i].id;
        else dataDeliverThis += this.secondCategoryFixed[i].id + ",";
      }

      //console.log(dataDeliverThis);
      this.searchParams.boardCategoryMiddle = dataDeliverThis;
    }

    if (this.thirdCategoryFixed != null) {
      this.thirdCategoryFixed = this.thirdCategoryFixed
        .replaceAll(",", "и")
        .replaceAll("&", "Ñ");

      this.searchParams.boardCategoryThird = this.thirdCategoryFixed;
    }

    if (
      this.fourthCategoryFixed != null &&
      this.fourthCategoryFixed.length > 0
    ) {
      var dataDeliverThis = "";

      for (var i = 0; i < this.fourthCategoryFixed.length; i++) {
        this.fourthCategoryFixed[i].id = this.fourthCategoryFixed[i].id
          .replace(",", "и")
          .replace("&", "Ñ");
        if (i == this.fourthCategoryFixed.length - 1)
          dataDeliverThis += this.fourthCategoryFixed[i].id;
        else dataDeliverThis += this.fourthCategoryFixed[i].id + ",";
      }

      //console.log(dataDeliverThis);
      this.searchParams.boardCategoryFourth = dataDeliverThis;
    }
    //when from School search (School -> School Board) works ENDS

    this.page.size = this.pageSize;
    
    // 1008 search url add
    //console.log('noga9:',this.searchParams.boardHead);
    //console.log('noga9:',this.route.snapshot.queryParamMap.get('boardHead') );
    //console.log('noga9:',this.isResetSearchboard );
    
    //1009 search fixed
    if(this.route.snapshot.queryParamMap.get('boardHead') && !this.isResetSearchboard) {
      this.searchParams.boardHead = this.route.snapshot.queryParamMap.get('boardHead')
      console.log('noga9 if:',this.searchParams.boardHead);
    }
    this.isResetSearchboard = false;
    //console.log('noga9 after:',this.isResetSearchboard );

    console.log("search this.searchParams : " + JSON.stringify(this.searchParams, null, 2));
    this.informationBoardService
      .searchInformationBoard(this.searchParams, this.page)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.content : " + data.content.length);

          this.rows = new Array<InformationBoard>();
          data.content.forEach((board) => this.rows.push(board));

          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  ngOnChanges(changes: any) {
    if (
      this.thirdCategoryFixed != null &&
      this.codeCommon &&
      this.codeCommon.informationCategoryList
    ) {
      for (var i = 0; i < this.codeCommon.informationCategoryList.length; i++) {
        if (this.codeCommon.informationCategoryList[i].code == "School") {
          this.codeCommon.informationCategoryList[i].checked = true;
        }
      }

      this.setSearchParams(null);
      this.searchInformationBoard(0);
    }
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(obj) {
    if (obj.type === "click") {
      this.selectedRow = obj.row;
    } else if (obj.type === "dblclick") {
      if (this.thirdCategoryFixed == null) {
        this.codeService.keepSearchParams("5", this.searchParams);
        this.router.navigate(["/informationBoardDetail", obj.row.id]);
      } else {
        //const url = this.router.serializeUrl(this.router.createUrlTree(['/informationBoardDetail'], { queryParams: { id: obj.row.id } }));
        //window.open(url, '_blank');

        window.open("/#/informationBoardDetail/" + obj.row.id, "_blank");
      }
    }
  }

  onChangeDailyReportChecker() {
    this.dailyReportChecker = !this.dailyReportChecker;

    if (this.dailyReportChecker == true) {
      for (var i = 0; i < this.codeCommon.informationCategoryList.length; i++) {
        if (this.codeCommon.informationCategoryList[i].code == "Daily Report") {
          this.codeCommon.informationCategoryList[i].checked = true;
        } else {
          this.codeCommon.informationCategoryList[i].checked = false;
        }
      }

      this.searchInformationBoard(0);
    } else {
      this.resetInformationBoard();
    }
  }

  onChangeBoardCategory() {
    //console.log("start onChangeBoardCategory : " + this.searchParams.boardCategory);
    //console.log(this.informationCategoryList.some(x => x.code === this.searchParams.boardCategory));
    //console.log(this.informationCategoryList.find(x => x.code === this.searchParams.boardCategory));

    if (this.searchParams.boardCategory == "") {
      this.informationCategory2List = [];
    } else {
      if (this.searchParams.boardCategory == "Daily Report") {
        if (this.authority == "Manager") {
          this.informationCategory2List = this.codeCommon.epList;
        } else {
          this.informationCategory2List = this.codeCommon.epList.filter(
            (x) => x.code == this.name
          );
        }
      } else {
        this.informationCategory2List = this.informationCategoryList.find(
          (x) => x.code === this.searchParams.boardCategory
        ).children;
      }
    }

    this.searchParams.boardCategoryMiddle = "";
    this.searchParams.boardCategoryThird = "";
    this.searchParams.boardCategoryFourth = "";

    this.searchInformationBoard(0);
  }

  onChangeBoardCategoryMiddle() {
    if (this.searchParams.boardCategoryMiddle == "") {
      this.informationCategory3List = [];
    } else {
      if (this.searchParams.boardCategory.includes("School")) {
        this.informationCategory3List = this.codeCommon.schoolNameList.filter(
          (code) => {
            //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
            if (code.value4) {
              var codes = code.value4.split(", ");
              for (var i = 0; i < codes.length; i++) {
                if (codes[i] == this.searchParams.boardCategoryMiddle)
                  return true;
              }
            }
          }
        );
      } else {
        this.informationCategory3List = this.informationCategory2List.find(
          (x) => x.code === this.searchParams.boardCategoryMiddle
        ).children;
      }
    }

    this.searchParams.boardCategoryThird = "";
    this.searchParams.boardCategoryFourth = "";

    this.searchInformationBoard(0);
  }

  onChangeBoardCategoryThird() {
    if (this.searchParams.boardCategoryThird == "") {
      this.informationCategory4List = [];
    } else {
      if (this.searchParams.boardCategory.includes("School")) {
        this.informationCategory4List = this.informationCategory2List.find(
          (code) => {
            //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
            if (code.value4) {
              var codes = code.value4.split(", ");
              for (var i = 0; i < codes.length; i++) {
                if (codes[i] == this.searchParams.boardCategoryMiddle)
                  return true;
              }
            }
          }
        ).children;
      } else {
        this.informationCategory4List = this.informationCategory3List.find(
          (x) => x.code === this.searchParams.boardCategoryThird
        ).children;
      }
    }

    this.searchParams.boardCategoryFourth = "";

    this.searchInformationBoard(0);
  }

  onChangeBoardCategoryFourth() {
    this.searchInformationBoard(0);
  }

  addInformationBoard() {
    this.router.navigate(["/informationBoardDetail"]);
  }

  onDelete() {
    if (this.selected.length == 0) return;

    let pgNum = 0;
    if (this.rows.length == this.selected.length) pgNum = 1;

    this.idList = [];

    this.selected.forEach((x) => {
      this.idList.push(x.id);
    });

    this.selected = [];

    this.informationBoardService
      .deleteByList({ idList: this.idList })
      .subscribe(
        (data) => {
          //console.log("data" + JSON.stringify(data))
          this.searchInformationBoard(this.page.pageNumber - pgNum);
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  resetInformationBoard() {
   
    //this.router.navigate(['/informationBoard']);
    //1009 search fixed
    this.isResetSearchboard =  true;
    //console.log('noga15 param reset:',this.route.snapshot.queryParamMap.get('boardHead') );
    //this.router.navigate(['/informationBoard']);
    //console.log('noga15 reset:',this.searchParams.boardHead);
    this.searchParams.boardHead = '';
    this.searchParams.pageNumber = 0;
    
    this.searchParams = new InformationBoardSearchParams();
    this.codeCommon = this.codeService.getOrg();

    this.filterList = [];

    this.searchParams.boardCategory = "";
    this.searchParams.boardCategoryMiddle = "";
    this.searchParams.boardCategoryThird = "";
    this.searchParams.boardCategoryFourth = "";

    this.informationCategoryListChecked = false;
    this.informationCategory2ListChecked = false;
    this.informationCategory3ListChecked = false;
    this.informationCategory4ListChecked = false;

    for (var i = 0; i < this.codeCommon.informationCategoryList.length; i++) {
      this.codeCommon.informationCategoryList[i].checked = false;
    }

    for (var i = 0; i < this.informationCategory2List.length; i++) {
      this.informationCategory2List[i].checked = false;
    }

    for (var i = 0; i < this.informationCategory3List.length; i++) {
      this.informationCategory3List[i].checked = false;
    }

    for (var i = 0; i < this.informationCategory4List.length; i++) {
      this.informationCategory4List[i].checked = false;
    }

    this.informationCategory2List = [];
    this.informationCategory3List = [];
    this.informationCategory4List = [];
    

    /*
    for(var i = 0; i < this.informationCategory2List.length; i++) {
      this.informationCategory2List[i].checked = false;
    }
    for(var i = 0; i < this.informationCategory3List.length; i++) {
      this.informationCategory3List[i].checked = false;
    }
    for(var i = 0; i < this.informationCategory4List.length; i++) {
      this.informationCategory4List[i].checked = false;
    }
    */

    this.searchInformationBoard(0);
  }

  detailInformationBoard() {
    console.log('noga15:',this.searchParams.boardHead);

    if (this.selectedRow == undefined || this.selectedRow.id == undefined)
      return;

    this.codeService.keepSearchParams("5", this.searchParams);
    this.router.navigate(["/informationBoardDetail", this.selectedRow.id]);
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    console.log('noga16:',this.searchParams.boardHead);

    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "First Category") {
      //if NOT a manager.
      if (this.authority != "manager") {
        this.dailyReportChecker = false;
        for (
          var i = 0;
          i < this.codeCommon.informationCategoryList.length;
          i++
        ) {
          if (
            this.codeCommon.informationCategoryList[i].code == "Daily Report"
          ) {
            this.codeCommon.informationCategoryList[i].checked = false;
          }
        }
      } else {
        this.codeCommon.informationCategoryList = this.filterList;
      }

      this.informationCategoryListChecked = true;
      this.informationCategory2ListChecked = false;
      this.informationCategory3ListChecked = false;
      this.informationCategory4ListChecked = false;

      this.informationCategory2List = [];
      this.informationCategory3List = [];
      this.informationCategory4List = [];
    } else if (this.selectedColumn == "Second Category") {
      this.informationCategory2List = [];
      this.informationCategory3List = [];
      this.informationCategory4List = [];

      for (var i = 0; i < this.filterList.length; i++) {
        if (this.filterList[i].checked == true) {
          this.informationCategory2List.push(this.filterList[i]);
        }
      }

      this.informationCategoryListChecked = true;
      this.informationCategory2ListChecked = true;
      this.informationCategory3ListChecked = false;
      this.informationCategory4ListChecked = false;
    } else if (this.selectedColumn == "Third Category") {
      this.informationCategory3List = [];
      this.informationCategory4List = [];

      for (var i = 0; i < this.filterList.length; i++) {
        if (this.filterList[i].checked == true) {
          this.informationCategory3List.push(this.filterList[i]);
        }
      }

      this.informationCategoryListChecked = true;
      this.informationCategory2ListChecked = true;
      this.informationCategory3ListChecked = true;
      this.informationCategory4ListChecked = false;
    } else if (this.selectedColumn == "Fourth Category") {
      this.informationCategory4List = [];

      for (var i = 0; i < this.filterList.length; i++) {
        if (this.filterList[i].checked == true) {
          this.informationCategory4List.push(this.filterList[i]);
        }
      }

      this.informationCategoryListChecked = true;
      this.informationCategory2ListChecked = true;
      this.informationCategory3ListChecked = true;
      this.informationCategory4ListChecked = true;
    }

    this.filterModal.hide();
    this.search();
  }

  onFilter(column) {
    this.selectedColumn = column;
    if (column == "First Category") {
      this.filterList = this.codeCommon.informationCategoryList;
    } else if (column == "Second Category") {
      //allow multiple selecting option
      var temp: any[] = this.searchParams.boardCategory.split(",");

      for (var i = 0; i < temp.length; i++) {
        temp[i] = temp[i].replaceAll("и", ",").replaceAll("Ñ", "&");

        if (i == 0) {
          this.filterList = [];
        }

        if (temp[i] == "Daily Report") {
          var filter: any[] = [];

          if (this.authority == "manager") {
            filter = this.codeCommon.epList;
          } else {
            filter = this.codeCommon.epList.filter((x) => x.code == this.name);
          }
        } else {
          var filter: any[] = [];
          filter = this.informationCategoryList.find(
            (x) => x.code == temp[i]
          ).children;
        }

        for (var j = 0; j < filter.length; j++) {
          this.filterList.push(filter[j]);
        }

        if (this.filterList.length < 1) {
          this.filterList = [];
        }
      }
    } else if (column == "Third Category") {
      //allow multiple selecting option
      var temp: any[] = this.searchParams.boardCategoryMiddle.split(",");

      for (var i = 0; i < temp.length; i++) {
        temp[i] = temp[i].replaceAll("и", ",").replaceAll("Ñ", "&");

        if (i == 0) {
          this.filterList = [];
        }

        if (this.searchParams.boardCategory.includes("School")) {
          var filter: any[] = [];

          filter = this.codeCommon.schoolNameList.filter((code) => {
            //(code.value4 ? code.value4.split(",")[0] : "No") == this.informationBoard.boardCategoryMiddle)
            if (code.value4) {
              var codes = code.value4.split(", ");
              for (var j = 0; j < codes.length; j++) {
                if (codes[j] == temp[i]) return true;
              }
            }
          });
        } else {
          var filter: any[] = [];
          filter = this.informationCategory2List.find(
            (x) => x.code == temp[i]
          ).children;
        }

        for (var j = 0; j < filter.length; j++) {
          this.filterList.push(filter[j]);
        }

        if (this.filterList.length < 1) {
          this.filterList = [];
        }
      }

      //this.filterList = this.informationCategory2List.find(x => x.code == this.searchParams.boardCategoryMiddle.split(",")[0]).children;
    } else if (column == "Fourth Category") {
      if (this.searchParams.boardCategory.includes("School")) {
        var temp: any[] = this.searchParams.boardCategoryMiddle.split(",");

        for (var k = 0; k < temp.length; k++) {
          if (k == 0) {
            this.filterList = [];
          }

          temp[k] = temp[k].replaceAll("и", ",").replaceAll("Ñ", "&");

          var filter: any[] = [];
          filter = this.informationCategory2List.find(
            (x) => x.code == temp[k]
          ).children;

          for (var j = 0; j < filter.length; j++) {
            this.filterList.push(filter[j]);
          }
        }

        if (this.filterList.length < 1) {
          this.filterList = [];
        }
      } else {
        var temp: any[] = this.searchParams.boardCategoryThird.split(",");

        for (var k = 0; k < temp.length; k++) {
          if (k == 0) {
            this.filterList = [];
          }

          temp[k] = temp[k].replaceAll("и", ",").replaceAll("Ñ", "&");

          var filter: any[] = [];
          filter = this.informationCategory3List.find(
            (x) => x.code == temp[k]
          ).children;

          for (var j = 0; j < filter.length; j++) {
            this.filterList.push(filter[j]);
          }
        }

        if (this.filterList.length < 1) {
          this.filterList = [];
        }
      }

      /*
      //allow multiple selecting option
      var temp: any[] = this.searchParams.boardCategoryThird.split(",");
      
      for(var i = 0; i < temp.length; i++)
      {
        temp[i] = temp[i].replaceAll('и',',').replaceAll("Ñ", "&");

        if(i == 0)
        {
          this.filterList = [];
        }
        
        if(this.searchParams.boardCategory.includes("School")) {
        
          //allow multiple selecting option
          var temp2: any[] = this.searchParams.boardCategoryMiddle.split(",");
          
          for(var k = 0; k < temp2.length; k++)
          {
            temp2[k] = temp2[k].replaceAll('и',',').replaceAll("Ñ", "&");
            
            var filter: any[] = [];
            filter = this.informationCategory2List.find(x => x.code == temp2[k]);

            for(var j = 0; j < filter.length; j++) {
              this.filterList.push(filter[j]);
            }
          }
        }
        else {

          var filter: any[] = [];
          filter = this.informationCategory3List.find(x => x.code == temp[i]).children;
          
          for(var j = 0; j < filter.length; j++) {
            this.filterList.push(filter[j]);
          }
        }

        if(this.filterList.length < 1) {
          this.filterList = [];
        }
      }
      */

      //this.filterList = this.informationCategory3List.find(x => x.code == this.searchParams.boardCategoryThird.split(",")[0]).children;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }
}
