import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AccountingParams } from "../model/accounting";
import { InvoiceParams } from "../model/invoice";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/accounting`;

@Injectable()
export class AccountingService {
  constructor(private http: JsonHttp) {}

  searchAccounting(searchParams, page) {
    /* 
    let queryString = Object.keys(searchParams).map(key => {
      if (searchParams[key] && (_.isString(searchParams[key]) || _.isArray(searchParams[key]))) {
        return `${key}=${_.isString(searchParams[key]) ? searchParams[key] : searchParams[key].join(',')}`
      }
    }).filter(Boolean).join('&');
    return this.http.get(`${url}?${queryString}&page=${page.pageNumber}&size=${page.size}`); 
    */

    let params: string = `${url}?searchKey=${searchParams.searchKey}&searchValue=${searchParams.searchValue}&fromLapsedDate=${searchParams.fromLapsedDate}&toLapsedDate=${searchParams.toLapsedDate}&fromAppliedDate=${searchParams.fromAppliedDate}&toAppliedDate=${searchParams.toAppliedDate}&fromIntakeDate=${searchParams.fromIntakeDate}&toIntakeDate=${searchParams.toIntakeDate}&fromCompletedDate=${searchParams.fromCompletedDate}&toCompletedDate=${searchParams.toCompletedDate}&appliedMonth=${searchParams.appliedMonth}&intakeMonth=${searchParams.intakeMonth}&specialMode=${searchParams.specialMode}&page=${page.pageNumber}&size=${page.size}`;

    searchParams.ciMemberCategory.forEach(
      (code) => (params = params + "&ciMemberCategory=" + code)
    );
    searchParams.schoolType.forEach(
      (code) => (params = params + "&schoolType=" + code)
    );
    searchParams.cpEp.forEach((code) => (params = params + "&cpEp=" + code));
    searchParams.school.forEach(
      (code) => (params = params + "&school=" + code)
    );
    searchParams.baseCourse1.forEach(
      (code) => (params = params + "&baseCourse1=" + code)
    );
    searchParams.cpSubAgent.forEach(
      (code) => (params = params + "&cpSubAgent=" + code)
    );
    searchParams.adminAgent.forEach(
      (code) => (params = params + "&adminAgent=" + code)
    );
    searchParams.accountStatus.forEach(
      (code) => (params = params + "&accountStatus=" + code)
    );

    //console.log(params);
    return this.http.get(params);
  }

  getList(id: number) {
    return this.http.get(`${url}/basic/${id}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: AccountingParams) {
    return this.http.post(url, params);
  }

  update(params: AccountingParams) {
    return this.http.put(url, params);
  }

  updateStatus(status) {
    return this.http.put(`${url}/accountStatus`, status);
  }

  updateStatusByList(status) {
    return this.http.put(`${url}/accountStatus2`, status);
  }

  divide(status) {
    return this.http.put(`${url}/divide`, status);
  }

  delete(params: AccountingParams) {
    return this.http.delete(url, params);
  }

  getInvoiceNo() {
    return this.http.get(`${url}/invoiceNo`);
  }

  createInvoice(params: InvoiceParams[]) {
    return this.http.post(`${url}/invoice`, params);
  }
}
