import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  Inject
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FileUploader } from "ng2-file-upload";
import * as _ from "lodash";
import { Subject } from "rxjs";
import * as SockJS from "sockjs-client";
import * as Stomp from "stompjs";
import { environment } from "../../../environments/environment";
import { Thread } from "../../model/thread";
import { JsonHttp } from "../../services/json-http";
import * as moment from 'moment';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { PersonalFiles, PersonalFilesParams } from "../../model/personal-files";
import { PersonalFilesService } from "../../services/personal-files.service";
import { BasicService } from "../../services/basic.service";
import { Basic } from "../../model/basic";
import * as FileSaver from "file-saver";


@Component({
  selector: "app-chat-modal",
  providers: [BasicService, PersonalFilesService],
  templateUrl: "./chatmodal.component.html",
  styleUrls: ["./chatmodal.component.css"],
})
export class ChatModalComponent implements OnInit, OnDestroy {



  @ViewChildren("chatbox") chatbox: QueryList<any>;
  @ViewChild("content") content: ElementRef;
  @ViewChild("fileInput") fileInput: ElementRef;

  selectedThread: Thread;
  thread: Subject<Thread> = new Subject<Thread>();
  //thread: Subject<Thread>;
  messages: any[] = [];
  disabled = true;
  newmessage: string;
  private stompClient = null;


  basicId:number;
  isLast: boolean;
  pageNumber: number;
  cnt: number;

  isLoadedMore: boolean;
  public _moment = moment;

  public uploader: FileUploader = new FileUploader({ queueLimit: 999 });
  personalFilesParams = new PersonalFilesParams();
  basic = new Basic();


  constructor(
    private personalFilesService: PersonalFilesService,
    private basicService: BasicService,
    private http: JsonHttp,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChatModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:Thread
  ) {
    this.selectedThread = data;
    //this.thread.next();
  }


  ngAfterViewInit() {
    this.scrollToBottom();
    this.chatbox.changes.subscribe((data) => {
      if (!this.isLoadedMore) {
        this.scrollToBottom();
      } else {
        this.isLoadedMore = false;
      }
    });
  }
  ngOnInit(): void {
    //console.log("##### chat check point :", JSON.stringify(this.thread));
    this.pageNumber = 0;
    this.cnt = 50;
    this.thread.subscribe((thread) => {
      //this.selectedThread = thread;
      //console.log("#### selectedThread user check :", JSON.stringify(this.selectedThread.user));
      this.http
        .get(
          `${environment.api_url}/api/messages?page=${this.pageNumber}&size=${this.cnt}&threadId=${this.selectedThread.customer.id}`
        )
        .subscribe((data: any) => {
          //console.log("### thread user data check :", JSON.stringify(data.content));
          if (data.content.length > 0) {
            this.messages = _.reverse(data.content);
          }
          this.isLast = data.last;
        });
      this.connect();
    });
    this.thread.next(this.selectedThread);

    this.http
      .get(
        `${environment.api_url}/api/basic?searchKey=customer_id&searchValue=${this.selectedThread.customer.id}&page=0&size=1`
      )
      .subscribe(
        (basic)=>{
          this.basicId = basic.content[0].id;
        },
        (error)=>{console.log(error)}
      );





  }

  closeDialog(){
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  }

  scrollToBottom = () => {
    try {
      this.content.nativeElement.scrollTop =
        this.content.nativeElement.scrollHeight;
    } catch (err) {}
  };

  setConnected(connected: boolean) {
    this.disabled = !connected;
    if (connected) {
      this.messages = [];
    }
  }

  connect() {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const socket = new SockJS(`${environment.api_url}/endpoint`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      this.stompClient = Stomp.over(socket);
      this.stompClient.debug = false;
      const _this = this;
      this.stompClient.connect(
        { Authorization: `Bearer ${accessToken}` },
        function (frame) {
        //console.log("Connected: " + frame);
          _this.stompClient.subscribe(
            `/thread/${_this.selectedThread.customer.id}`,
            function (data) {
              _this.showMessage(JSON.parse(data.body));
            }
          );
        },
        function(error){
          console.log("##### STOMCLIENT connect ERROR....:",error);
          _this.connect();
          //setTimeout(this.connect(), 10000);
        }
      );
    }
  }
  sendMessage(fileChecker, fileId) {

    let accessToken = localStorage.getItem("accessToken");
    let sendMsg = {};
    sendMsg["messageText"] = this.newmessage;
    sendMsg["messageIsFile"] = fileChecker;
    sendMsg["messageFileId"] = fileId;

/*
    this.stompClient.send(
      `/chat/message/${this.selectedThread.customer.id}`,
      { Authorization: `Bearer ${accessToken}` },
      this.newmessage
    );
  */

    this.stompClient.send(
      `/chat/message/${this.selectedThread.customer.id}`,
      { Authorization: `Bearer ${accessToken}` },
      JSON.stringify(sendMsg)
    );


    this.newmessage = "";
  }

  showMessage(message) {
    this.messages.push(message);
  }



  downloadFile(id) {
    //id = 23870;
    //console.log("download File :", id);

    this.http.get(`${environment.api_url}/api/personalFiles/${id}`)
        .subscribe((data) => {
            var byteContents = window.atob(data.attachFile);
            var byteNumbers = new Array(byteContents.length);
            for (var i = 0; i < byteContents.length; i++) {
              byteNumbers[i] = byteContents.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray]);

            FileSaver.saveAs(blob, data.filename);
          },(error) => {
            console.log("error : ", error);
          }
        )



  }

  loadMore() {
    this.pageNumber++;

    this.http
      .get(
        `${environment.api_url}/api/messages?page=${this.pageNumber}&size=10&threadId=${this.selectedThread.customer.id}`
      )
      .subscribe((data: any) => {
        if (data.content.length > 0) {
          this.isLoadedMore = true;
          this.messages = [..._.reverse(data.content), ...this.messages];

          //console.log(this.messages);
        }
        this.isLast = data.last;
      });
  }

  triggerFunction(event) {
    if (event.ctrlKey && event.key === "Enter") {
      this.newmessage += "\n";
    } else if (event.key === "Enter") {
      if(event.preventDefault){
        event.preventDefault();
     }
      this.sendMessage(false,0);
    }
  }

  loadFile(){
    this.fileInput.nativeElement.click();
  }


  uploadFile(event:Event){
     const element = event.currentTarget as HTMLInputElement;
     let fileList: FileList | null = element.files;
     if (fileList && fileList.length >0) {
       let f = fileList[0];
       this.newmessage = f.name;
       let fileReader = new FileReader();
       var that = this;
       fileReader.onload = function () {
         that.addFile(
           window.btoa(fileReader.result as string),
           f.name,
           f.size
         );
       };
       fileReader.readAsBinaryString(f);
     }
  }

  addFile(result, filename, filesize) {
    let personalFile = new PersonalFiles();
    personalFile.filename = filename;
    personalFile.attachFile = result;
    personalFile.mode = "I";
    personalFile.kind = "M";
    this.personalFilesParams.basicId = this.basicId;
    this.personalFilesParams.personalFilesList.push(personalFile);
    this.personalFilesService.create(this.personalFilesParams).subscribe(
      (data) => {
          let fileId = data.filesList[0].id;
          this.sendMessage(true, fileId);
      },
      (error) => {
        console.log(error);
      }
    );

  }



  clickImportant() {
    //console.log(this.selectedThread);
    this.selectedThread.isImportant = !this.selectedThread.isImportant;

    this.http
      .put(`${environment.api_url}/api/threads`, this.selectedThread)
      .subscribe((data) => {
        //console.log(data);
      });
  }
}
