import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SchoolGdlinksParams } from "../model/school-gdlinks";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/schoolgdlinks`;

@Injectable()
export class SchoolGdlinksService {
  constructor(private http: JsonHttp) {}

  getList(id: number) {
    let params: string = `${url}/school/${id}`;
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: SchoolGdlinksParams) {
    return this.http.post(url, params);
  }

  update(params: SchoolGdlinksParams) {
    return this.http.put(url, params);
  }

  delete(params: SchoolGdlinksParams) {
    return this.http.delete(url, params);
  }
}
