import { InformationFiles     } from './information-files';
import { Comment } from './comment';

export class InformationBoardSearchParams {
    public boardCategory?       :string = "";
    public boardCategoryMiddle? :string = "";  
    public boardCategoryThird?  :string = "";
    public boardCategoryFourth? :string = "";    
    public boardHead?           :string = "";
    public pageNumber?          :number = 0;
}

export class InformationBoard {
    id?:number;
    boardCategory?:string;
    boardCategoryName?:string;
    boardCategoryMiddle?:string;    
    boardCategoryMiddleName?:string;        
    boardCategoryThird?:string;
    boardCategoryThirdName?:string ;  
    boardCategoryFourth?:string;
    boardCategoryFourthName?:string ;      
    boardCategoryFullName?:string;
    boardHead?:string;
    boardBody?:string;
    boardAttachId?:number;
    boardAnswerStatus?:string;
    boardAnswerHead?:string;
    boardAnswerBody?:string;
    bunryu?:string;
    boardType?:string;
    boardAnswerAttachId?:number;
    createUser?:string;   
    saveUser?:string;      
    answerUser?:string;
    createDate?:string;
    saveDate?:string;
    readed?:number;
    replies?:number;
    fileCount?:number;
    scheduleDate?:string;
    scheduleEndDate?:string;
    scheduleStH?:string;
    scheduleStM?:string;
    scheduleEtH?:string;
    scheduleEtM?:string;
    colorZone?:string;
    epInfor?:string;
    epInfor2?:string;
    epInfor3?:string;
    epInfor4?:string;
    epInfor5?:string;
    epInfor6?:string;
    epInfor7?:string;
    epInfor8?:string;
    epInfor9?:string;
    epInfor10?:string;
    epInfor11?:string;
    epInfor12?:string;
    epInfor13?:string;
    epInfor14?:string;
    epInfor15?:string;
    notice?:string;
    scheduledDate?:string;
    idx?:number;
    alarmTimer?:string;
    sent?:string;
    afterAlarm?:string;
    afterAlarmDate?:string;
    frequency?:string;    
    frequencyName?:string;
    inCharge?:string;
    emailAlarm?:string;
    
    dueDate?:string;
    boardHead1?:string;
    viewReport?:string;
    
    sendToManager?:string;
    
    informationFilesParams = new Array<InformationFiles>();

    commentList?: Comment[];

    commentNumbers?: string;
}

export class InformationBoardParams extends InformationBoard {
}