export class TertiaryApplicant {
    id: string | number;
    event?: string;
    counsellor?: string;
    firstName?: string;
    lastName?: string;
    dob?: string;
    gender?: string;
    countryCode?: string;
    mobile?: string;
    email?: string;
    nationality?: string;
    visaType?: string;
    visaExpiryDate?: string;

    nzAddress?: string;
    overseasAddress?: string;
    highSchool?: string;
    highStartDate?: string;
    highEndDate?: string;
    highCountry?: string;
    entranceExam?: string;
    nsnNo?: string;
    nsnEmail?: string;
    nsnPassword?: string;
    grade?: string;
    tertiarySchool1?: string;
    tertiaryQualification1?: string;
    tertiaryMajor1?: string;
    tertiaryStartDate1?: string;
    tertiaryEndDate1?: string;
    tertiaryStatus1?: string;
    tertiarySchool2?: string;
    tertiaryQualification2?: string;
    tertiaryMajor2?: string;
    tertiaryStartDate2?: string;
    tertiaryEndDate2?: string;
    tertiaryStatus2?: string;
    tertiarySchool3?: string;
    tertiaryQualification3?: string;
    tertiaryMajor3?: string;
    tertiaryStartDate3?: string;
    tertiaryEndDate3?: string;
    tertiaryStatus3?: string;
    tertiarySchool4?: string;
    tertiaryQualification4?: string;
    tertiaryMajor4?: string;
    tertiaryStartDate4?: string;
    tertiaryEndDate4?: string;
    tertiaryStatus4?: string;
    tertiarySchool5?: string;
    tertiaryQualification5?: string;
    tertiaryMajor5?: string;
    tertiaryStartDate5?: string;
    tertiaryEndDate5?: string;
    tertiaryStatus5?: string;
    proposedSchool1?: string;
    proposedQualification1?: string;
    proposedMajor1?: string;
    proposedStartDate1?: string;
    proposedSchool2?: string;
    proposedQualification2?: string;
    proposedMajor2?: string;
    proposedStartDate2?: string;
    proposedSchool3?: string;
    proposedQualification3?: string;
    proposedMajor3?: string;
    proposedStartDate3?: string;
    proposedSchool4?: string;
    proposedQualification4?: string;
    proposedMajor4?: string;
    proposedStartDate4?: string;
    proposedSchool5?: string;
    proposedQualification5?: string;
    proposedMajor5?: string;
    proposedStartDate5?: string;

    note?: string;
    realType?: string;
    realTypeName?: string;
    createDate?: string;
    saveDate?: string;
    createUser?: string;
    saveUser?: string;
    processing?: string;
    basicId?: number;

    emergencycountryCode?: string;
    emergencyfirstName?: string;
    emergencylastName?: string;
    emergencyRelation?: string;
    emergencyemail?: string;
    emergencymobile?: string;

    findus?: string;
}

export class TertiaryApplicantParams extends TertiaryApplicant {
}


