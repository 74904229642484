export class InformationFilesSearchParams {
    public realType?  :string;
    public kname?     :string;
    public email?     :string;    
}

export class InformationFiles {
    id:string|number;   
    informId?:number;
    filename?:string;
    filesize?:string;
    file?:any[];
    crmId?:string;
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
    mode?:string;
}

export class InformationFilesParams extends InformationFiles {
}


