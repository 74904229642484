export class SchoolFilesSearchParams {
    public realType?  :string;
    public kname?     :string;
    public email?     :string;    
}

export class SchoolFiles {
    id:string|number;   
    schoolId?:number;
    fileCategory?:number;
    filename?:string;
    filesize?:string;
    file?:any[];
    crmId?:string;
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
    mode?:string;
}

export class SchoolFilesParams extends SchoolFiles {
}


