import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DatatableComponent, TableColumn } from "@swimlane/ngx-datatable";
import { Angular2Csv } from "angular2-csv";
import { Profile } from "app/model/profile";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import * as Collections from "typescript-collections";
import { Accounting, AccountingSearchParams } from "../../model/accounting";
import { CodeCommon } from "../../model/code-common";
import { Page } from "../../model/page";
import { AccountingService } from "../../services/accounting.service";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';



const fixedSize = 10000;

@Component({
  selector: "app-accounting",
  providers: [AccountingService, DatePipe],
  templateUrl: "./accounting.component.html",
})
export class AccountingComponent implements OnInit {
  public page = new Page();
  public rows = new Array<Accounting>();
  public userId: number = 0;

  public searchParams = new AccountingSearchParams();
  public setByLink: boolean = false;

  public selectedRow = new Accounting();

  codeCommon: CodeCommon;
  filterList: any[] = [];

  selected = [];
  selectedInvoices = [];
  showInvoice: boolean;

  selectedAll: boolean = false;
  selectedColumn: string;
  @ViewChild("filterModal") public filterModal: ModalDirective;

  @ViewChild("dataTable") public dataTable: DatatableComponent;

  authority: string;
  name: string;
  company: string;
  isSearchEp: boolean = true;

  disabledInvoice: boolean = true;
  inoviceCnt: number = 0;

  isInvoiced: boolean = false;
  isCompleted: boolean = false;
  disabledInvoiced: boolean = true;
  disabledCompleted: boolean = true;

  totalDisplayweeks: number = 0;
  totalDisplayfreeWeeks: number = 0;
  totalDisplaySchoolFee: number = 0;
  totalDisplayCommission: number = 0;
  totalDisplaySubCommission: number = 0;
  totalDisplayDc: number = 0;
  totalDisplayProfit: number = 0;

  action: string;
  idx: string;
  selectedEp: string;

  loadingIndicator: boolean = false;
  timeout: any;

  mySet = new Collections.Set<number>();
  studentCount: number = 0;
  studentAverage: number = 0;
  totalAverage: number = 0;

  displaySearchValue: string;

  feeMatchedMissingOnly: boolean = false;
  feeMatchedOnly: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private codeService: CodeService,
    private authenticationService: AuthenticationService,
    private accountingService: AccountingService
  ) {
    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
    this.searchParams.searchKey = "Name";
    this.searchParams.searchValue = "";

    if (this.authority == "accountant" || this.authority == "manager") {
      this.isSearchEp = true;
    } else {
      this.isSearchEp = false;
    }

    this.route.queryParams.subscribe((params) => {
      this.action = params["action"];
      this.idx = params["idx"];
      this.selectedEp = params["ep"];

      this.searchParams.cpEp = params.cpEp ? [params.cpEp] : [];
      this.setByLink = this.searchParams.cpEp.length > 0;
      this.searchParams.fromIntakeDate = params.fromIntakeDate ?? "";
      if (this.searchParams.fromIntakeDate != "") {
        this.searchParams.fromIntakeDate1 = {
          year: +this.searchParams.fromIntakeDate.split("-")[0],
          month: +this.searchParams.fromIntakeDate.split("-")[1],
          day: +this.searchParams.fromIntakeDate.split("-")[2],
        };
      }
      this.searchParams.toIntakeDate = params.toIntakeDate ?? "";
      if (this.searchParams.toIntakeDate != "") {
        this.searchParams.toIntakeDate1 = {
          year: +this.searchParams.toIntakeDate.split("-")[0],
          month: +this.searchParams.toIntakeDate.split("-")[1],
          day: +this.searchParams.toIntakeDate.split("-")[2],
        };
      }
      this.searchParams.fromAppliedDate = params.fromAppliedDate ?? "";
      if (this.searchParams.fromAppliedDate != "") {
        this.searchParams.fromAppliedDate1 = {
          year: +this.searchParams.fromAppliedDate.split("-")[0],
          month: +this.searchParams.fromAppliedDate.split("-")[1],
          day: +this.searchParams.fromAppliedDate.split("-")[2],
        };
      }
      this.searchParams.toAppliedDate = params.toAppliedDate ?? "";
      if (this.searchParams.toAppliedDate != "") {
        this.searchParams.toAppliedDate1 = {
          year: +this.searchParams.toAppliedDate.split("-")[0],
          month: +this.searchParams.toAppliedDate.split("-")[1],
          day: +this.searchParams.toAppliedDate.split("-")[2],
        };
      }
      this.searchParams.accountStatus = [
        "Holding",
        "Estimated",
        "Pending",
        "OnGoing",
        "Received",
      ];

      //codeService.getCommon(this);
      codeService.getEachCommon(this, "4");
    });
  }

  feeMatchedMissingOnlySelected() {
    if (this.feeMatchedMissingOnly == false) {
      this.feeMatchedOnly = false;
    }
  }

  feeMatchedOnlySelected() {
    if (this.feeMatchedOnly == false) {
      this.feeMatchedMissingOnly = false;
    }
  }

  setSearchParams(searchParams) {
    if (searchParams != undefined) {
      this.searchParams = searchParams;
    }
  }

  setCodeList(codeCommon: CodeCommon) {
        if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    
    if (this.searchParams.fromIntakeDate1 == undefined)
      {
        //240425 accounting from home eplist with searchparam
        //240403 henry init add after reset
         this.searchParams = new AccountingSearchParams();
         this.codeCommon = this.codeService.getOrg();  
      }
      else
      {
        this.codeCommon = codeCommon; 
      }
   
    //this.codeCommon = codeCommon; //240403 henry init add after reset
    //console.log("fromIntakeDate1 : " + this.searchParams.fromIntakeDate1);

    //console.log("toIntakeDate1 : " + this.searchParams.toIntakeDate1);
    
    this.feeMatchedOnly = false;
    this.feeMatchedMissingOnly = false;

    if (this.action == "home") {
      if (this.idx !== undefined && this.idx != "") {
        this.searchParams.fromIntakeDate1 = undefined;

        this.codeCommon.accountStatusList.forEach((x) => {
          if (x.code == "Holding") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }

      if (this.selectedEp != undefined) {
        this.codeCommon.epList.forEach((x) => {
          if (x.code == this.selectedEp) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });
      }
    } else if (this.action == "statistics") {
      //
    } else {
      if (!this.codeCommon.accountStatusList.some((x) => x.checked == true)) {
        this.setDefaultFilter();
      }
    }

    //-- From dashboard starts

    if (this.action == "home") {
      var selectedEps = this.selectedEp.split(",");

      this.codeCommon.epList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.epList.forEach((x) => {
        selectedEps.forEach(function (value) {
          if (x.code == value) {
            x.checked = true;
          }
        });
      });

      this.codeCommon.accountStatusList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.schoolTypeList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.memberCategoryList.forEach((x) => {
        x.checked = false;
      });

      this.searchParams.fromLapsedDate1 = undefined;
      this.searchParams.toLapsedDate1 = undefined;

      this.searchParams.fromIntakeDate1 = undefined;
      this.searchParams.toIntakeDate1 = undefined;

      if (this.idx == "enrolled0") {
        this.codeCommon.accountStatusList.forEach((x) => {
          if (
            x.code == "Received" ||
            x.code == "OnGoing" ||
            x.code == "Estimated" ||
            x.code == "Holding" ||
            x.code == "Pending"
          ) {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        this.searchParams.fromLapsedDate1 = undefined;
        this.searchParams.toLapsedDate1 = Utils.formatStrDate("today");

        //0217 noga fixed add intakes limit year
        const todayStruct = Utils.formatStrDate("today") as NgbDateStruct; // 오늘 날짜를 NgbDateStruct로 가져옴
        const currentYear = todayStruct.year; // 올해 연도 추출

        this.searchParams.fromIntakeDate1 = Utils.formatStrDate(`${currentYear}-01-01`); //올해의 시작 (1월 1일)
        this.searchParams.toIntakeDate1 = Utils.formatStrDate(`${currentYear}-12-31`); // 올해의 마지막 (12월 31일)

      }

      if (
        this.idx == "holdingpending0" ||
        this.idx == "holdingpending1" ||
        this.idx == "holdingpending2" ||
        this.idx == "holdingpending3"
      ) {
        this.codeCommon.accountStatusList.forEach((x) => {
          if (x.code == "Estimated" || x.code == "Holding") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "holdingpending0") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-30);
          this.searchParams.toLapsedDate1 = Utils.formatStrDate("today");
        } else if (this.idx == "holdingpending1") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-60);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-31);
        } else if (this.idx == "holdingpending2") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-90);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-61);
        } else if (this.idx == "holdingpending3") {
          this.searchParams.fromLapsedDate1 = undefined;
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-91);
        }
      }
      //0121 invoiced1+invoiced2+invoiced3
      if (
        decodeURIComponent(this.idx) == "invoiced1+invoiced2+invoiced3" ){
          console.log("start idx : " +decodeURIComponent(this.idx));
          this.codeCommon.accountStatusList.forEach((x) => {
            if (x.code == "Pending") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          this.searchParams.fromLapsedDate1 = undefined//Utils.getNgbDate(-90);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-30);//Utils.formatStrDate("today");
      }

      if (
        this.idx == "invoiced0" ||
        this.idx == "invoiced1" ||
        this.idx == "invoiced2" ||
        this.idx == "invoiced3"
      ) {
        this.codeCommon.accountStatusList.forEach((x) => {
          if (x.code == "Pending") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "invoiced0") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-30);
          this.searchParams.toLapsedDate1 = Utils.formatStrDate("today");
        } else if (this.idx == "invoiced1") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-60);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-31);
        } else if (this.idx == "invoiced2") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-90);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-61);
        } else if (this.idx == "invoiced3") {
          this.searchParams.fromLapsedDate1 = undefined;
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-91);
        }
      }

      if (
        this.idx == "subUnpaid0" ||
        this.idx == "subUnpaid1" ||
        this.idx == "subUnpaid2" ||
        this.idx == "subUnpaid3"
      ) {
        this.codeCommon.accountStatusList.forEach((x) => {
          if (x.code == "OnGoing") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "subUnpaid0") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-30);
          this.searchParams.toLapsedDate1 = Utils.formatStrDate("today");
        } else if (this.idx == "subUnpaid1") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-60);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-31);
        } else if (this.idx == "subUnpaid2") {
          this.searchParams.fromLapsedDate1 = Utils.getNgbDate(-90);
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-61);
        } else if (this.idx == "subUnpaid3") {
          this.searchParams.fromLapsedDate1 = undefined;
          this.searchParams.toLapsedDate1 = Utils.getNgbDate(-91);
        }
      }
      //0121 case0+case1+case2 
      console.log("start idx : " +decodeURIComponent(this.idx));
      if (
        decodeURIComponent(this.idx) == "case0+case1+case2" ){
          this.codeCommon.accountStatusList.forEach((x) => {
            if (x.code == "Estimated" || x.code == "Holding") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "ELICOS") {
              x.checked = true;
              this.searchParams.fromIntakeDate1 = undefined;
              this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
            } else if (x.code == "Secondary") {
              x.checked = true;
              this.searchParams.fromIntakeDate1 = undefined;
              this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
            } else if (x.code == "NZ PTE") {
              x.checked = true;
              this.searchParams.fromIntakeDate1 = undefined;
              this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
            } else {
              x.checked = false;
            }
          });     
      }
      //0121 case4+case5
      if (
        decodeURIComponent(this.idx) == "case4+case5" ){
          this.codeCommon.accountStatusList.forEach((x) => {
            if (x.code == "Estimated" || x.code == "Holding") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "AU PTE") {
              x.checked = true;
              this.searchParams.fromIntakeDate1 = undefined;
              this.searchParams.toIntakeDate1 = Utils.getNgbDate(-84);
            } else if (x.code == "AU Secondary") {
              x.checked = true;
              this.searchParams.fromIntakeDate1 = undefined;
              this.searchParams.toIntakeDate1 = Utils.getNgbDate(-84);
            } else {
              x.checked = false;
            }
          });     
      }

      if (
        this.idx == "case0" ||
        this.idx == "case1" ||
        this.idx == "case2" ||
        this.idx == "case3" ||
        this.idx == "case4" ||
        this.idx == "case5" ||
        this.idx == "case6"
      ) {
        this.codeCommon.accountStatusList.forEach((x) => {
          if (x.code == "Estimated" || x.code == "Holding") {
            x.checked = true;
          } else {
            x.checked = false;
          }
        });

        if (this.idx == "case0") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "ELICOS") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-20);
        } else if (this.idx == "case1") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "Secondary") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
        } else if (this.idx == "case2") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "NZ PTE") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
        } else if (this.idx == "case3") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "NZ Uni/Poly") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-56);
        } else if (this.idx == "case4") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "AU PTE") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
        } else if (this.idx == "case5") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "AU Secondary") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-28);
        } else if (this.idx == "case6") {
          this.codeCommon.schoolTypeList.forEach((x) => {
            if (x.code == "AU Uni" || x.code == "AU PTE") {
              x.checked = true;
            } else {
              x.checked = false;
            }
          });

          this.searchParams.fromIntakeDate1 = undefined;
          this.searchParams.toIntakeDate1 = Utils.getNgbDate(-84);
        }
      }

      if (this.idx == "feematchedmissingonly") {
        this.feeMatchedMissingOnly = true;
        this.feeMatchedOnly = false;
      } else if (this.idx == "feematchedonly") {
        this.feeMatchedOnly = true;
        this.feeMatchedMissingOnly = false;
      }
    }

    //-- From dashboard ends

    //atarget
    //atargetDirect
    //atargetIndirect
    //myAtargetDirect
    //myAtargetDirectDirect
    //myAtargetDirectIndirect
    //atargetLastyear
    //myAtargetLastyear
    //atarget2yearsAgo
    //myAtarget2yearAgo
    //Utils.formatStrDate("today");

    if (this.action == "year") {
      this.searchParams.fromIntakeDate1 = Utils.formatStrDate("2022-01-01");
      this.searchParams.toIntakeDate1 = Utils.formatStrDate("2022-12-31");

      if (this.idx == "atargetLastyear" || this.idx == "myAtargetLastyear") {
        this.searchParams.fromIntakeDate1 = Utils.formatStrDate("2021-01-01");
        this.searchParams.toIntakeDate1 = Utils.formatStrDate("2021-12-31");
      }

      if (this.idx == "atarget2yearsAgo" || this.idx == "myAtarget2yearAgo") {
        this.searchParams.fromIntakeDate1 = Utils.formatStrDate("2020-01-01");
        this.searchParams.toIntakeDate1 = Utils.formatStrDate("2020-12-31");
      }
    } else if (this.action == "month") {
      this.searchParams.fromIntakeDate1 = Utils.formatStrDate("2022-01-01");
      this.searchParams.toIntakeDate1 = Utils.formatStrDate("2022-12-31");

      this.searchParams.fromAppliedDate1 = Utils.formatStrDate("2022-01-01");
      this.searchParams.toAppliedDate1 = Utils.formatStrDate("2022-01-31");
    }

    if (
      this.idx == "atarget" ||
      this.idx == "myAtarget" ||
      this.idx == "atargetDirect" ||
      this.idx == "atargetIndirect" ||
      this.idx == "myAtargetDirect" ||
      this.idx == "myAtargetIndirect" ||
      this.idx == "atargetLastyear" ||
      this.idx == "atarget2yearsAgo" ||
      this.idx == "myAtargetLastyear" ||
      this.idx == "myAtarget2yearAgo"
    ) {
      var selectedEps = this.selectedEp.split(",");

      this.codeCommon.epList.forEach((x) => {
        x.checked = false;
      });

      this.codeCommon.epList.forEach((x) => {
        selectedEps.forEach(function (value) {
          if (x.code == value) {
            x.checked = true;
          }
        });
      });

      this.codeCommon.accountStatusList.forEach((x) => {
        if (
          x.code == "Estimated" ||
          x.code == "Holding" ||
          x.code == "Pending" ||
          x.code == "OnGoing" ||
          x.code == "Received"
        ) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });

      this.codeCommon.subAgentList.forEach((x) => {
        x.checked = false;
      });

      /*
      if(this.idx == "atargetDirect" || this.idx == "myAtargetDirect") {

        this.codeCommon.subAgentList.forEach(x => {
            x.checked = false;
        });

      }
      else if(this.idx == "atargetIndirect" || this.idx == "myAtargetIndirect") {

        this.codeCommon.subAgentList.forEach(x => {
          x.checked = true;
        });
      }
      */
    }

    /*
    if(this.idx == "companyMonthlyProfit" || this.idx == "companyWeeklyProfit" ||
       this.idx == "myMonthlyProfit" || this.idx == "myWeeklyProfit") {
        this.searchParams.fromIntakeDate1 = undefined;
        this.searchParams.toIntakeDate1 = undefined;
        
        this.searchParams.fromAppliedDate1 = Utils.formatStrDate("2021-01-01");
        this.searchParams.toAppliedDate1 = Utils.formatStrDate("2021-12-31");
    }
    */

    this.searchAccounting();
  }

  setDefaultFilter() {
    this.codeCommon.accountStatusList.forEach((x) => {
      if (
        x.code == "Enrolled" ||
        x.code == "Holding" ||
        x.code == "Estimated" ||
        x.code == "Pending"
      ) {        
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  ngOnInit() {
    //this.setPage({ offset: 0 });
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.rows = new Array<Accounting>();
    this.page = new Page();
    this.page.pageNumber = pageInfo.offset;
    this.page.size = fixedSize;

    this.search();    
  }

  searchAccounting() {
    this.disabledInvoice = true;
    this.disabledInvoiced = true;
    this.disabledCompleted = true;

    this.rows = new Array<Accounting>();
    this.page = new Page();

    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    if (this.searchParams.fromAppliedDate1 != undefined)
      this.searchParams.fromAppliedDate = Utils.formatDateStr(
        this.searchParams.fromAppliedDate1
      );
    //else this.searchParams.fromAppliedDate = "";
    if (this.searchParams.toAppliedDate1 != undefined)
      this.searchParams.toAppliedDate = Utils.formatDateStr(
        this.searchParams.toAppliedDate1
      );
    //else this.searchParams.toAppliedDate = "";
    if (this.searchParams.fromIntakeDate1 != undefined)
      this.searchParams.fromIntakeDate = Utils.formatDateStr(
        this.searchParams.fromIntakeDate1
      );
    //else this.searchParams.fromIntakeDate = "";
    if (this.searchParams.toIntakeDate1 != undefined)
      this.searchParams.toIntakeDate = Utils.formatDateStr(
        this.searchParams.toIntakeDate1
      );
    //else this.searchParams.toIntakeDate = "";
    if (this.searchParams.fromCompletedDate1 != undefined)
      this.searchParams.fromCompletedDate = Utils.formatDateStr(
        this.searchParams.fromCompletedDate1
      );
    else this.searchParams.fromCompletedDate = "";
    if (this.searchParams.toCompletedDate1 != undefined)
      this.searchParams.toCompletedDate = Utils.formatDateStr(
        this.searchParams.toCompletedDate1
      );
    else this.searchParams.toCompletedDate = "";
    if (this.searchParams.fromLapsedDate1 != undefined)
      this.searchParams.fromLapsedDate = Utils.formatDateStr(
        this.searchParams.fromLapsedDate1
      );
    else this.searchParams.fromLapsedDate = "";
    if (this.searchParams.toLapsedDate1 != undefined)
      this.searchParams.toLapsedDate = Utils.formatDateStr(
        this.searchParams.toLapsedDate1
      );
    else this.searchParams.toLapsedDate = "";

    this.selected = [];

    this.search();
  }

  search() {
        this.searchParams.ciMemberCategory = this.codeCommon.memberCategoryList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.schoolType = this.codeCommon.schoolTypeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.school = this.codeCommon.schoolNameListIncFalse
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.baseCourse1 = this.codeCommon.courseList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.cpSubAgent = this.codeCommon.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.adminAgent = this.codeCommon.adminAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
          
    if (!this.setByLink) {
      this.searchParams.accountStatus = this.codeCommon.accountStatusList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    }

    if (this.feeMatchedMissingOnly == true) {
      this.searchParams.specialMode = "1";
    } else if (this.feeMatchedOnly == true) {
      this.searchParams.specialMode = "2";
    } else {
      this.searchParams.specialMode = "";
    }

    this.displaySearchValue = "";
    if (this.searchParams.ciMemberCategory.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " M Category in " +
        JSON.stringify(
          this.codeCommon.memberCategoryList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.schoolType.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School Type in " +
        JSON.stringify(
          this.codeCommon.schoolTypeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.school.length > 0) {        
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School in " +
        JSON.stringify(
          this.codeCommon.schoolNameListIncFalse
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
            }
    if (this.searchParams.baseCourse1.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Course in " +
        JSON.stringify(
          this.codeCommon.courseList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.cpSubAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Sub Agent in " +
        JSON.stringify(
          this.codeCommon.subAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.adminAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Admin Agent in " +
        JSON.stringify(
          this.codeCommon.adminAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.accountStatus.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Status in " +
        JSON.stringify(
          this.codeCommon.accountStatusList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    if (!this.setByLink) {
      if (this.authority == "accountant" || this.authority == "manager") {
        this.searchParams.cpEp = this.codeCommon.epList
          .filter((opt) => opt.checked)
          .map((opt) => opt.code);
      } else {
        this.searchParams.cpEp = this.codeCommon.epList
          .filter((opt) => opt.value == this.name)
          .map((opt) => opt.code);
      }
    }
    if (this.searchParams.cpEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Acc EP in " +
        JSON.stringify(
          this.codeCommon.epList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    this.loadingIndicator = true;
    this.studentCount = 0;
    this.studentAverage = 0;
    this.totalAverage = 0;
    console.log("start accountingService : " +JSON.stringify(this.searchParams));
    this.accountingService
      .searchAccounting(this.searchParams, this.page)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.content : " + data.content.length);

          this.totalDisplayweeks = 0;
          this.totalDisplayfreeWeeks = 0;
          this.totalDisplaySchoolFee = 0;
          this.totalDisplayCommission = 0;
          this.totalDisplaySubCommission = 0;
          this.totalDisplayDc = 0;
          this.totalDisplayProfit = 0;

          this.rows = new Array<Accounting>();

          this.mySet = new Collections.Set<number>();
          //console.log("selected:", JSON.stringify(this.selected));
          data.content.forEach((accounting) => {
            if (
              this.selected == undefined ||
              this.selected.length == 0 ||
              this.selected.some((x) => x.id == accounting.id)
            ) {
              
              this.rows.push(accounting);
              //console.log("this.rows:", JSON.stringify(this.rows));

              if (accounting.superId == 1) {
                this.totalDisplayweeks =
                  this.totalDisplayweeks + +accounting.weeks;
                this.totalDisplayfreeWeeks =
                  this.totalDisplayfreeWeeks + +accounting.freeWeeks;
              }
              this.totalDisplaySchoolFee =
                this.totalDisplaySchoolFee + accounting.displaySchoolFee;
              this.totalDisplayCommission =
                this.totalDisplayCommission + accounting.displayCommission;
              this.totalDisplaySubCommission =
                this.totalDisplaySubCommission +
                accounting.displaySubCommission;
              this.totalDisplayDc = this.totalDisplayDc + accounting.displayDc;
              this.totalDisplayProfit =
                this.totalDisplayProfit + accounting.displayProfit;

              this.mySet.add(accounting.basicId);
            }
            
          });
          console.log("this.rows:", JSON.stringify(this.rows));
          console.log("selected:", JSON.stringify(this.selected));
          //this.loadingIndicator   = false;
          setTimeout(() => {
            this.loadingIndicator = false;
          }, 1500);
          this.studentCount = this.mySet.size();
          if (data.totalElements > 0 && this.studentCount > 0) {
            this.totalAverage = this.totalDisplayProfit / data.totalElements;
            this.studentAverage = this.totalDisplayProfit / this.studentCount;
          }

          let addLine = 0;
          if (this.rows.length > 0) {
            let acc = new Accounting();
            acc.weeks = "" + this.totalDisplayweeks;
            acc.freeWeeks = "" + this.totalDisplayfreeWeeks;
            acc.displaySchoolFee = this.totalDisplaySchoolFee;
            acc.displayCommission = this.totalDisplayCommission;
            acc.displaySubCommission = this.totalDisplaySubCommission;
            acc.displayDc = this.totalDisplayDc;
            acc.displayProfit = this.totalDisplayProfit;
            this.rows.push(acc);
            addLine = 1;
          }

          this.page.totalElements = data.totalElements + addLine;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;

          this.selected = [];
        },
        (error) => {
          console.log("error : ", error);
          this.loadingIndicator = false;
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
  }

  onUpdateStatus(accountStatus: string) {
    let idList = this.selected.map((opt) => opt.id);

    this.accountingService
      .updateStatusByList({ idList: idList, accountStatus: accountStatus })
      .subscribe(
        (data) => {
          let isSubunpaid = false;
          if (
            this.filterList.find(
              (x) => x.code == "OnGoing" && x.checked == true
            ) &&
            !this.filterList.find(
              (x) => x.code != "OnGoing" && x.checked == true
            )
          ) {
            isSubunpaid = true;
          }

          this.codeCommon.accountStatusList.forEach((x) => {
            if (accountStatus == "Pending") {
              if (x.code == "Pending") {
                x.checked = true;
              } else {
                x.checked = false;
              }
            } else if (accountStatus == "Received") {
              if (isSubunpaid) {
                if (x.code == "Received") {
                  x.checked = true;
                } else {
                  x.checked = false;
                }
              } else {
                if (x.code == "Received" || x.code == "OnGoing") {
                  x.checked = true;
                } else {
                  x.checked = false;
                }
              }
            }
          });

          this.rows = new Array<Accounting>();
          this.page = new Page();

          this.page.pageNumber = 0;
          this.page.size = fixedSize;

          this.search();

          if (accountStatus == "Pending") {
            this.isInvoiced = false;
            this.isCompleted = true;
          } else {
            this.isInvoiced = false;
            this.isCompleted = false;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

    this.inoviceCnt = 0;
    this.selected.forEach((x) => {
      if (x.accountStatus == "Pending") {
        this.inoviceCnt = this.inoviceCnt + 1;
      }
    });

    if (this.inoviceCnt > 0 && this.inoviceCnt == this.selected.length) {
      this.disabledInvoice = false;
    } else {
      this.disabledInvoice = true;
    }

    if (this.isInvoiced || this.isCompleted) {
      if (this.selected.length > 0) {
        this.disabledInvoiced = false;
        this.disabledCompleted = false;
      } else {
        this.disabledInvoiced = true;
        this.disabledCompleted = true;
      }
    } else {
      this.disabledInvoiced = true;
      this.disabledCompleted = true;
    }
  }

  onActivate(obj) {
    if (obj.type === "click") {
      if (obj.event.altKey) {
        window.open(
          `${window.location.origin}/accountingDetail/${obj.row.basicId}`,
          "_blank"
        );
      } else {
        this.selectedRow = obj.row;
      }
    } else if (obj.type === "dblclick") {
      this.codeService.keepSearchParams("4", this.searchParams);
      this.router.navigate(["/accountingDetail", obj.row.basicId, obj.row.id]);
    }
  }

  onFilter(column) {
    //if(1==1) return;

    this.selectedColumn = column;
    if (column == "M Category") {
      this.filterList = this.codeCommon.memberCategoryList;
    } else if (column == "School Type") {
      this.filterList = this.codeCommon.schoolTypeList;
    } else if (column == "Acc EP") {
      this.filterList = this.codeCommon.epList;
    } else if (column == "School") {
      this.filterList =
        this.codeService.codeCommon.schoolNameListIncFalse.filter((code) =>
          this.rows.some((x) => x.school == code.code)
        );
    } else if (column == "Course") {
      //this.filterList = this.codeCommon.courseList;

      this.filterList = this.codeService.codeCommon.courseList.filter((code) =>
        this.rows.some((x) => x.baseCourse1 == code.code)
      );
    } else if (column == "Sub Agent") {
      //this.filterList = this.codeCommon.subAgentList;

      this.filterList = this.codeService.codeCommon.subAgentList.filter(
        (code) => this.rows.some((x) => x.cpSubAgent == code.code)
      );
    } else if (column == "Admin Agent") {
      this.filterList = this.codeCommon.adminAgentList;
    } else if (column == "Status") {
      this.filterList = this.codeCommon.accountStatusList;
    } else {
      this.filterList = [];
    }

    if (
      this.filterList.some((x) => x.checked == undefined || x.checked == false)
    ) {
      this.selectedAll = false;
    } else {
      this.selectedAll = true;
    }

    this.filterModal.show();
  }

  selectAll() {
    //console.log("selecteAll : " + this.selectedAll);
    for (var i = 0; i < this.filterList.length; i++) {
      this.filterList[i].checked = this.selectedAll;
      //console.log("selecteAll " + i + " : " + this.filterList[i].checked);
    }
  }

  checkIfAllSelected() {
    this.selectedAll = this.filterList.every(function (item: any) {
      return item.checked == true;
    });
  }

  onCloseFilter() {
    this.filterModal.hide();
  }

  onOkFilter() {
    if (this.selectedColumn == "M Category") {
      this.codeCommon.memberCategoryList = this.filterList;
    } else if (this.selectedColumn == "SCHOOL TYPE") {
      this.codeCommon.schoolTypeList = this.filterList;
    } else if (this.selectedColumn == "Acc EP") {
      this.codeCommon.epList = this.filterList;
    } else if (this.selectedColumn == "School") {
      //this.codeCommon.schoolNameList = this.filterList;

      this.codeCommon.schoolNameListIncFalse.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "Course") {
      //this.codeCommon.courseList = this.filterList;
      this.codeCommon.courseList.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "Sub Agent") {
      //this.codeCommon.subAgentList = this.filterList;

      this.codeCommon.subAgentList.forEach((z) => {
        let code = this.filterList.find((x) => x.code == z.code);
        if (code != undefined) {
          z.checked = code.checked;
        }
      });
    } else if (this.selectedColumn == "Admin Agent") {
      this.codeCommon.adminAgentList = this.filterList;
    } else if (this.selectedColumn == "Status") {
      this.codeCommon.accountStatusList = this.filterList;

      if (
        this.filterList.find(
          (x) => x.code == "Estimated" && x.checked == true
        ) &&
        !this.filterList.find((x) => x.code != "Estimated" && x.checked == true)
      ) {
        this.isInvoiced = true;
      } else {
        this.isInvoiced = false;
      }

      if (
        this.filterList.find((x) => x.code == "Pending" && x.checked == true) &&
        !this.filterList.find((x) => x.code != "Pending" && x.checked == true)
      ) {
        this.isCompleted = true;
      } else {
        if (
          this.filterList.find(
            (x) => x.code == "OnGoing" && x.checked == true
          ) &&
          !this.filterList.find((x) => x.code != "OnGoing" && x.checked == true)
        ) {
          this.isCompleted = true;
        } else {
          this.isCompleted = false;
        }
      }
    }

    this.filterModal.hide();
    this.searchAccounting();
  }

  deselectInvoice() {
    this.selected = [];
  }

  onOpenInvoice() {
    this.showInvoice = true;
    this.selected.forEach((x) => this.selectedInvoices.push(x));
    this.selectedInvoices = JSON.parse(JSON.stringify(this.selectedInvoices));
  }

  onClosedInvoice(showInvoice: boolean) {
    this.showInvoice = showInvoice;
    this.selectedInvoices.length = 0;
  }

  onPage(event) {}

  setDefaultParams() {
        this.feeMatchedMissingOnly = false;
    this.feeMatchedOnly = false;

    this.searchParams = new AccountingSearchParams();
    this.codeCommon = this.codeService.getOrg();
    this.setDefaultFilter();
    this.searchAccounting();
  }
  
  exportAsCSV() {
    const columns: TableColumn[] = this.dataTable._internalColumns;
    const headers = columns
      .map((column: TableColumn) => column.name)
      .filter((e) => e); // remove column without name (i.e. falsy value)

    const rows: any[] = this.dataTable.rows.map((row) => {
      let r = {};
      columns.forEach((column) => {
        if (!column.name) {
          return;
        } // ignore column without name
        if (column.prop) {
          let prop = column.prop;
          r[prop] =
            typeof row[prop] === "boolean"
              ? row[prop]
                ? "Yes"
                : "No"
              : row[prop] == null
              ? ""
              : row[prop];
        } else {
          // special cases handled here
        }
      });
      return r;
    });

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      headers: headers,
      showTitle: false,
      title: "accounting",
      useBom: true,
    };
    return new Angular2Csv(rows, "accounting", options); //_BB
  }

  toggleExpandRow(row) {
    row.expanded = !row.expanded;
    this.dataTable.rowDetail.toggleExpandRow(row);
  }
}
