import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { ApplicationParams } from "../model/application";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/application`;

@Injectable()
export class ApplicationService {
  constructor(private http: JsonHttp) {}

  search(searchParams, page) {
    let params: string = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;

    ////console.log(params);

    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: ApplicationParams) {
    return this.http.post(url, params);
  }

  update(params: ApplicationParams) {
    return this.http.put(url, params);
  }

  delete(params: ApplicationParams) {
    return this.http.delete(url, params);
  }
}
