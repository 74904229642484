import { DatePipe, Location } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Profile } from "app/model/profile";
import * as _ from "lodash";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter, switchMap } from "rxjs/operators";
import { AdminHistoryComment } from "../../model/admin-history-comment";
import { AdminMemo } from "../../model/admin-memo";
import { Admission } from "../../model/admission";
import { Basic } from "../../model/basic";
import { CodeCommon } from "../../model/code-common";
import { AdminHistoryCommentService } from "../../services/admin-history-comment.service";
import { AdminMemoService } from "../../services/admin-memo.service";
import { AdmissionService } from "../../services/admission.service";
import { AuthenticationService } from "../../services/authentication.service";
import { BasicService } from "../../services/basic.service";
import { CodeService } from "../../services/code.service";
import Utils from "../../shared/utils";
import { AgentService } from "../../services/agent.service"; //1003 extra email send
import { Agent } from "../../model/agent"; //1003 extra email send
// 1024 파일첨부
import { PersonalFilesService } from '../../services/personal-files.service';
import { PersonalFiles, PersonalFilesParams } from "../../model/personal-files";
import * as FileSaver from "file-saver";


import { forkJoin, Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

//1205 extra Email send
import { SchoolService } from "../../services/school.service";

@Component({
  selector: "app-admission-detail",
  providers: [
    PersonalFilesService, // 1024 파일첨부
    SchoolService,  //1205 extra Email send
    AgentService,
    AdmissionService,
    AdminMemoService,
    AdminHistoryCommentService,
    BasicService,
    DatePipe,
  ],
  templateUrl: "./admission-detail.component.html",
  styles: [
    `
      .line-breaker {
        white-space: pre-line;
      }
    `,
  ],
})
export class AdmissionDetailComponent implements OnInit {
  basicDSCdisabledUpdate: boolean = true;

  public admissionRows = new Array<Admission>();
  public adminMemoRows = new Array<AdminMemo>();

  public adminHistoryCommentList = new Array<AdminHistoryComment>();
  keyNote: string;

  public selectedAdmissionRow = new Admission();
  public selectedAdminMemoRow = new AdminMemo();

  @ViewChild("admissionModal") public admissionModal: ModalDirective;
  @ViewChild("adminMemoModal") public adminMemoModal: ModalDirective;
  @ViewChild("childModal") public childModal: ModalDirective;

  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  searchTerm: string;

  selectedBasicId: number;
  basic = new Basic();

  selectedAdminId: number;

  schoolTypeList: any[] = [];
  schoolNameList: any[] = [];
  courseList: any[] = [];
  subAgentList: any[] = [];
  adminAgentList: any[] = [];
  epList: any[] = [];
  
  //noga 1203 memo template
  selectedTemplate: number | null = null;
  selectedTemplateContent: string = '';
  templateList = [
    {
      id: 1,
      name: 'Inquiry About Space for International Student',
      content: `Subject: Inquiry About Space for International Student
Dear [School Contact Name or "Admissions Team"],
  
I hope this email finds you well.
  
I am writing to inquire about the availability of placements for international students at [School Name] for [specific year/term, e.g., "Term 1, 2025"]. Below are the details:
  
Name:   
Date of Birth:   
Nationality:   
Year Level:   
Duration:  
Start Date:  
  
If there are spots available, please let me know, and we will provide the enrollment application form along with the required documents shortly.
  
Thank you for your time and assistance. I look forward to your reply.
  
Kind regards,
      `
    },
    {
      id: 2,
      name: 'Student Visa and Insurance Attached',
      content: `Subject: Student Visa and Insurance Attached
Dear [School Contact Name or "Admissions Team"],
  
I hope this email finds you well.
  
I am pleased to inform you that [Student's Name] has received their student visa. Please find attached the insurance certificate and visa copy for the student listed below:
  
Student Name:   
Date of Birth:   
Year Level:  
  
If you require any additional information or documents, please don’t hesitate to contact me.
  
Thank you for your assistance.
  
Kind regards,
      `
    },
    {
      id: 3,
      name: 'Request for Confirmation of Tuition Fee Payment',
      content: `Subject: Request for Confirmation of Tuition Fee Payment
Hi Team,
  
I hope this email finds you well.
  
I would like to inform you that XXX’s parents have made the tuition fee payment. Could you please confirm this with your finance team and provide us with the official receipt for the student’s visa application?
  
Thank you for your assistance. Please feel free to reach out if you need any additional information.
  
Kind regards,
      `
    }
  ];

  //noga 1203 allfiles
  allFiles: any[] = []; // 모든 파일 리스트
  // 1024 파일첨부
  // 샘플 파일 목록
  //uploadfileList = [
  //  { id: '  2024-09-11 18:04:21', name: '여권샘플.jpeg' },
  //  { id: '  2023-11-22 09:50:01', name: 'sample.png' },
  //  { id: '  2024-09-11 12:31:56', name: 'Presentation.pptx' },
  //  { id: '  2024-09-11 13:03:21', name: '명함.txt' },
  //];
  public fileList: { [key: string]: PersonalFiles[] } = {};
  //public uploaderList: { [key: string]: FileUploader } = {};
  //uploadfileList: PersonalFiles[] = []; // 파일 목록 저장
  selectedFiles: Set<string> = new Set(); // 선택된 파일 ID 저장
  selectedFilesString: string = '';
  personalFilesParams = new PersonalFilesParams();
  public folderKeys = [
    "Personal Documents/ID (Passport etc)",
    "Personal Documents/Qualification (School Report)",
    "Personal Documents/Visa and Insurance",
    "Personal Documents/English Test (IELTS, PTE etc)",
    "Personal Documents/Signed Doc From Clients",
    "Personal Documents/Others",
    "Pending Documents/Received Files",
  ];
  //selectedFiles: Set<string> = new Set(); // 선택된 파일 ID 저장
  public enrolledSchoolKeys: string[] = [];
  private enrolledSchool: string = "Enrolled School";
  public appliedSchoolKeys: string[] = [];
  private appliedSchool: string = "Applied School";


  memoTypeList: any[] = [];
  adminStatusList: any[] = [];
  emailEpList: any[] = [];
  msgTo: any[] = [];
  emailTo: any[] = [];

  isBonusPayment: boolean = false;

  schoolTypeListForVisa: any[] = [
    { code: "Accreditation", value: "Accreditation" },
    { code: "Job Check", value: "Job Check" },
    { code: "Section 61", value: "Section 61" },
    { code: "Student Visa", value: "Student Visa" },
    { code: "Visitor Visa - ", value: "Visitor Visa - " },
    { code: "Visitor Visa - Guardian", value: "Visitor Visa - Guardian" },
    { code: "Visitor Visa - Partnership", value: "Visitor Visa - Partnership" },
    { code: "Work Visa - AEWV", value: "Work Visa - AEWV" },
    { code: "Work Visa - Essential Skill", value: "Work Visa - Essential Skill",},
    { code: "Work Visa - Graduate Visa", value: "Work Visa - Graduate Visa" },
    { code: "Work Visa - Partnership", value: "Work Visa - Partnership" },
    { code: "Working Holiday Visa", value: "Working Holiday Visa" },
  ];

  schoolTypeListForInsurance: any[] = [
    {
      code: "Done By School(Paid to KOKOS)",
      value: "Done By School(Paid to KOKOS)",
    },
    { code: "Student Lite(Orbit)", value: "Student Lite(Orbit)" },
    { code: "Student Prime(Orbit)", value: "Student Prime(Orbit)" },
    { code: "Done By Subagent", value: "Done By Subagent" },
    { code: "Family Lite(Orbit)", value: "Family Lite(Orbit)" },
    { code: "Individual Prime(Orbit)", value: "Individual Prime(Orbit)" },
    { code: "Individual Lite(Orbit)", value: "Individual Lite(Orbit)" },
    { code: "Working Holiday(Orbit)", value: "Working Holiday(Orbit)" },
  ];

  schoolTypeListForOtherServices: any[] = [
    { code: "Admin Service (Refundable)", value: "Admin Service (Refundable)" },
    { code: "Admin Service (No-Retund)", value: "Admin Service (No-Retund)" },
    { code: "Visa Service Fee", value: "Visa Service Fee" },
    { code: "Consulting Service (Edu)", value: "Consulting Service (Edu)" },
    { code: "Consulting Service (Visa)", value: "Consulting Service (Visa)" },
    {
      code: "School Viewing/Tour Service",
      value: "School Viewing/Tour Service",
    },
    { code: "Airport Pick Up Service", value: "Airport Pick Up Service" },
    { code: "Airport Drop Service", value: "Airport Drop Service" },
    { code: "Settlement Service", value: "Settlement Service" },
    { code: "Guardianship Service", value: "Guardianship Service" },
    { code: "Other Service", value: "Other Service" },
  ];
  //{ code: 'Bonus Commission from School', value: 'Bonus Commission from School' },

  //for course setting
  secondary: any[] = [
    "Year 1",
    "YR 10",
    "YR 11",
    "YR 12",
    "YR 13",
    "YR 2",
    "YR 3",
    "YR 4",
    "YR 5",
    "YR 6",
    "YR 7",
    "YR 8",
    "YR 9",
  ];
  auSecondary: any[] = [
    "Year 1",
    "YR 10",
    "YR 11",
    "YR 12",
    "YR 13",
    "YR 2",
    "YR 3",
    "YR 4",
    "YR 5",
    "YR 6",
    "YR 7",
    "YR 8",
    "YR 9",
  ];
  elicos: any[] = ["GE", "1", "2", "TECSOL", "CFZ", "BST", "NZCEL", "EAP"];
  pteUni: any[] = [
    "7",
    "5",
    "3",
    "Graduate Diploma",
    "Master",
    "DT",
    "GCZ",
    "PDP",
    "PCT",
    "6",
  ];

  //1206 manual email send
  selectedManualEmail: boolean = false;  
  inputManualEmail: string;

  //1205 extra Email send
  schoolEmail: string; 
  selectedSchoolEmail: boolean = false; 

  //1003 extra Email send
  selectedStudentEmail: boolean = false;
  subagentEmail: string; 
  selectedSubAgentEmail: boolean = false; 
 
  basicSubagent:string;
  Emailagentlist: any[] = [];  
  agent = new Agent(); //1003 extra email send


  displayMessage: string;

  


  validStatusList: any[] = [
    { code: "Enrolled", value: ["Enrolled", "wd", "an", "Admin Holding"] },
    {
      code: "1_RO",
      value: ["2_AS", "wd", "dr", "ADM EP Change Req", "an", "Admin Holding"],
    },
    {
      code: "2_AS",
      value: [
        "2_AS",
        "3_PS",
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "an",
        "Admin Holding"
      ],
    },
    {
      code: "3_PS",
      value: [
        "2_AS",
        "3_PS",
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding"
      ],
    },
    {
      code: "4_CO",
      value: [
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding"
      ],
    },
    {
      code: "5_FS",
      value: [
        "5_FS",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding"
      ],
    },
    {
      code: "6_UO",
      value: [
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "Defer",
        "an",
        "Admin Holding"
      ],
    },
    {
      code: "wd",
      value: ["wd", "an", "2_AS", "4_CO", "6_UO", "Admin Holding"],
    },
    {
      code: "Defer",
      value: [
        "Defer",
        "3_PS",
        "4_CO",
        "6_UO",
        "wd",
        "Rejected",
        "ADM EP Change Req",
        "dr",
        "an",
        "Admin Holding",
      ],
    },
    { code: "dr", value: ["dr", "an"] },
    { code: "OnGoing", value: ["2_AS", "OnGoing", "4_CO", "6_UO", "wd", "an"] },
    {
      code: "Admin Holding",
      value: ["2_AS", "wd", "dr", "ADM EP Change Req", "an", "Admin Holding"],
    },
    { code: "Cancelled", value: ["an", "6_UO"] },
  ];

  intake: NgbDateStruct;
  intakeDate: NgbDateStruct;
  startingDate: NgbDateStruct;
  endDate: NgbDateStruct;

  mmAlarmDate: NgbDateStruct;
  adminHoldingDate: NgbDateStruct;
  visaAppliedDate: NgbDateStruct;
  visaCreditPurchaseDate: NgbDateStruct;

  insStartDate: NgbDateStruct;
  insEndDate: NgbDateStruct;
  insPurchaseDate: NgbDateStruct;

  osEndDate: NgbDateStruct;
  osStartDate: NgbDateStruct;

  epChangedList: any[] = [];
  selected: any[] = [];

  disabledRequest: boolean = true;
  disabledStuId: boolean = false;
  disabledStuIdPwd: boolean = false;

  isOnGoing: boolean = false;
  isSendToAc: boolean = false;
  isUnconoffer: boolean = false; //noga 1126 no show send to ac_AM

  adminMessage: string;

  authority: string;
  name: string;
  company: string;

  onUpdateStatusStr: string;
  onDeleteHistoryCommentMsgStr: string;

  constructor(
    private personalFilesService: PersonalFilesService, // 1024 파일첨부
    private viewContainerRef: ViewContainerRef,
    private admissionService: AdmissionService,
    private adminMemoService: AdminMemoService,
    private schoolService: SchoolService, //1205 extra Email send
    private adminHistoryCommentService: AdminHistoryCommentService,
    private authenticationService: AuthenticationService,
    private basicService: BasicService,
    private codeService: CodeService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private agentService: AgentService, //1003 extra email send
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.schoolTypeList = codeCommon.schoolTypeList;
    this.schoolNameList = codeCommon.schoolNameList;
    this.courseList = codeCommon.courseList;
    this.subAgentList = codeCommon.subAgentList.filter((x) => x.code != "self");
    this.adminAgentList = codeCommon.adminAgentList;
    this.epList = JSON.parse(JSON.stringify(codeCommon.epList));

    this.memoTypeList = JSON.parse(JSON.stringify(codeCommon.memoTypeList));
    //this.adminStatusList= codeCommon.adminStatusList;
    //console.log('codeCommon.adminStatusList:', JSON.stringify(codeCommon.adminStatusList));
    console.log('codeCommon.adminStatusList:', codeCommon.adminStatusList.map(status => status.value).join(', '));
    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));

    this.setInit();
  }

  ngOnInit() {}

  // 1024 파일첨부 불러온 파일 목록을 출력 및 선택

  openFile(id: any, fileName: string): void {
    // 파일 확장자를 기반으로 MIME 타입 결정
    //console.log('fileContent:', fileContent);
    this.personalFilesService.get(id).subscribe(
      (data) => {
        const fileName = data.filename?.trim(); // 파일명 앞뒤 공백 제거
        console.log('1fileName:', fileName); // 디버깅용 로그
  
        if (!fileName) {
          console.error('Invalid file name:', fileName);
          return; // 파일 이름이 없을 경우 종료
        }

        console.log('2fileName:', fileName);
        const extension = fileName.split('.').pop().toLowerCase();
        let mimeType = 'application/octet-stream'; // 기본값
        switch (extension) {
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            case 'png':
                mimeType = 'image/png';
                break;
            case 'jpg':
            case 'jpeg':
                mimeType = 'image/jpeg';
                break;
            case 'gif':
                mimeType = 'image/gif';
                break;
            // 여기에 필요한 다른 파일 형식을 추가할 수 있습니다.
            }
    
        console.log('mimeType:', mimeType);
    
        this.personalFilesService.get(id).subscribe(
          (data) => {
            var byteContents = window.atob(data.attachFile);
            var byteNumbers = new Array(byteContents.length);
    
            for (var i = 0; i < byteContents.length; i++) {
              byteNumbers[i] = byteContents.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
    
            //let blob = new Blob([byteArray]);
            let blob = new Blob([byteArray], { type: mimeType });
    
            // Blob URL 생성
            const blobUrl = URL.createObjectURL(blob);
            console.log('Url:', blobUrl);
            // 파일을 브라우저로 열기 위한 링크 생성
            const link = document.createElement('a');
            link.href = blobUrl;
            link.target = '_blank'; // 새 창에서 열도록 설정
            document.body.appendChild(link); // DOM에 링크 요소 추가
            link.click();
            document.body.removeChild(link); // 사용 후 링크 요소 제거
          },
          (error) => {
            console.log("error : ", error);
          }
        );
      }
    )


  
  }
  onSaveFile(fileContent, fileName) {
    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }

  onSaveFileAfterGetting(id) {
    this.personalFilesService.get(id).subscribe(
      (data) => {
        var byteContents = window.atob(data.attachFile);
        var byteNumbers = new Array(byteContents.length);

        for (var i = 0; i < byteContents.length; i++) {
          byteNumbers[i] = byteContents.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);

        let blob = new Blob([byteArray]);
        FileSaver.saveAs(blob, data.filename);
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }
  // 1024 파일첨부 불러온 파일 목록을 출력 및 선택
  // 파일 목록 로드 함수

  // 파일 선택/해제 처리
  onFileSelect(file: { id: string }) {
    if (this.selectedFiles.has(file.id)) {
      this.selectedFiles.delete(file.id); // 선택 해제
    } else {
      this.selectedFiles.add(file.id); // 선택 추가
    }
   
    // 선택된 파일 ID를 문자열로 변환하여 저장
    this.selectedFilesString = Array.from(this.selectedFiles).join(',');
    console.log('Selected Files:', Array.from(this.selectedFiles));
    console.log('Selected Files:', this.selectedFilesString);
  }

  // 파일이 선택되었는지 확인
  isSelected(file: { id: string }): boolean {
    return this.selectedFiles.has(file.id);
  }

  selectSchool(val: any) {
    this.selectedAdmissionRow.school = val;
    this.onChangeSchool();
  }
  // 1024 파일첨부 불러온 파일 목록을 출력 및 선택

  setInit() {
    var admId = null;

    this.route.params
      .pipe(
        switchMap((params: Params) => {
          this.selectedBasicId = +params["id"];
          admId = +params["admid"];
          //console.log('noga Params:', params); // params 디버깅
          return this.admissionService.getList(+params["id"]);
        })
      )
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.length : " + data.length);

          this.admissionRows = new Array<Admission>();

          let admin = new Admission();
          admin.baseCourse1Name = "click here to start";
          this.admissionRows.push(admin);

          data.forEach((admission) => this.admissionRows.push(admission));

          if (admId != null) {
            let account = this.admissionRows.find((x) => x.id === admId);
            //1211 fixed exception
            if (account) {
              this.selected = [account];
              this.onSelected(account);
            }
          }

          //console.log("this.admissionRows : " + JSON.stringify(this.admissionRows));
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );

    /*
    
    ).add(() => {

      console.log("a");
      if(admId != null) {
        console.log("b");
        console.log(this.admissionRows.length);
 
        //this.onSelected(this.admissionRows[0]);
        //this.selectedAdminId = admId;
        //console.log(admId);
      }
      else
      {
        console.log("c");
        //console.log(admId);
      }
 });
 */
  // 1024 파일첨부
    if (this.selectedBasicId !== undefined) {
      this.personalFilesService.getList(this.selectedBasicId).subscribe(
        (data) => {
          //console.log('API Data:', data); // API 데이터 확인
          const personalFilesList = data.filesList; // 전체 파일 목록

          //noga 1203 모든 파일 저장
          this.allFiles = personalFilesList;

          // 폴더별로 파일을 분류하여 저장
          _.each(this.folderKeys, (key) => {
            this.fileList[key] = personalFilesList.filter(
              (file) => file.bucket === key
            );
            //console.log('this.fileList[key]:', JSON.stringify(this.fileList[key], null, 2));
          });
        },
        (error) => {
          console.log('Error loading files:', error);
        }
      );
    }
    // 1024 파일첨부

    this.basicService.get(this.selectedBasicId).subscribe((basic) => {
      this.basic = basic;

      //console.log("basicService ciSubAgent : " + this.basic.ciSubAgent);
      this.basicSubagent = this.basic.ciSubAgent;


      //1003 extra Email send
      //console.log("start agentservice: " + JSON.stringify(this.basic, null, 2));
      this.agentService.getAll().subscribe((data) => {
  
        this.Emailagentlist = data.content;     
        
        const Subagent = this.Emailagentlist.find((x) => x.name === this.basicSubagent);
        
        if (Subagent) {        
          this.subagentEmail = Subagent.email;  // SubAgent의 value4 값을 설정        
        } else {
          //console.log("agentService Subagent not found");
          this.subagentEmail = '';  // SubAgent가 없을 때 처리
        }
        
        //console.log("Subagent : " + JSON.stringify(Subagent, null, 2));
        //console.log("agentService ciSubAgent : " + this.basicSubagent);
        //console.log("agentService subagentEmail : " + this.subagentEmail);
      }  ,
      (error) => {
        //console.log("basic error : " + JSON.stringify(error));
      }
      );

      //console.log("subagentEmail : " + this.subagentEmail);

    });
  }

  find() {
    this.admissionService.getList(this.selectedBasicId).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        //console.log("data.length : " + data.length);

        this.admissionRows = new Array<Admission>();

        let admin = new Admission();
        admin.baseCourse1Name = "click here to start";
        this.admissionRows.push(admin);

        data.forEach((admission) => this.admissionRows.push(admission));

        //console.log("this.admissionRows : " + JSON.stringify(this.admissionRows));

        //console.log("this.selectedAdminId : " + this.selectedAdminId);
        let account = this.admissionRows.find(
          (x) => x.id === this.selectedAdminId
        );

        //console.log("account : " + JSON.stringify(this.selectedAdminId));
        this.selected = [account];

        this.onSelected(account);
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  onSelectAdmission({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAdmission(obj) {
    this.adminMessage = "";

    if (obj.type === "click") {
      this.onSelected(obj.row);
    } else if (obj.type === "dblclick") {
      if (obj.row.id == undefined) return;

      this.searchTerm = "";
      this.selectedAdmissionRow = obj.row;
      this.onChangeSchool();
      this.onChangeSchoolType();
      this.convStrToDate();

      if (
        this.authority === "manager" ||
        _.includes(
          [undefined, "UnApplied", "Holding", "Estimated"],
          this.selectedAdmissionRow.accountStatus
        )
      ) {
        this.disabledStuId = false; //this.selectedAdmissionRow.appId != undefined ? true : false;;
        this.disabledStuIdPwd = false; //this.selectedAdmissionRow.stuIdPwd != undefined ? true : false;

        this.admissionModal.show();
      } else {
        this.disabledStuId = true;
        this.disabledStuIdPwd = true;
      }
    }
  }

  onSelected(row) {
    console.log("onSelected() called with row:", row);

    // row와 id 유효성 검사 //1213 reuest버튼이 활성화되지 못해서 주석처리
    //if (!row?.id) {
    //  console.error("Invalid row object or row.id is undefined:", row);
    //  return;
    //}
  
    console.log("onSelected() row.id:", row.id);

    if (row.id != undefined){//(row && row.id) {
      //this.selectedRow = JSON.parse(JSON.stringify(obj.row));
      console.log("selectedRow.schoolName :",row.admissionSchoolType);
      
      this.schoolService.getAll().subscribe((data) => {
        // content 배열에서 selectedRow.school와 code가 일치하는 항목을 찾음
        const selectedSchool = data.content.find((x) => x.code === row.school.trim());
        console.log("selectedSchool :", JSON.stringify(selectedSchool) );
        if (selectedSchool) {
          this.schoolEmail = selectedSchool.accountEmail;  // 해당 학교의 이메일을 할당
          console.log("schoolEmail :", this.schoolEmail );
        } else {
          this.schoolEmail = "";
          console.error('Selected school not found');
        }
      });   
    }
    //console.log('noga row : ', row.adminStatusName);
    //noga 1126 no show send to ac_AM
    if (row.adminStatusName == "Uncon offer") {
      this.isUnconoffer = true;
    } else {
      this.isUnconoffer = false;
    }
    //console.log('noga this.isSendToAc && !this.isUnconoffer : ', this.isSendToAc && !this.isUnconoffer);
    //noga 1126 no show send to ac_AM

    if (row.id === undefined) {
      this.selectedAdmissionRow = new Admission();
      this.adminMemoRows = new Array<AdminMemo>();
      this.adminHistoryCommentList = new Array<AdminHistoryComment>();
      this.isSendToAc = false;

      if (this.basic.fileCount == 0) {
        this.disabledRequest = true;
        console.error('disabled Request:fileCount:0');
        this.isOnGoing = false;
        this.adminMessage =
          "There is no file. Please upload a file to request.";
      } else {
        this.disabledRequest = false;

        if (this.admissionRows.some((x) => x.adminStatus == "Enrolled")) {
          this.isOnGoing = true;
        } else {
          this.isOnGoing = false;
        }
      }
    } else {
      this.selectedAdmissionRow = row;
      this.disabledRequest = true;
      this.findMemo();

      //console.log("this.selectedAdmissionRow.adminStatus : " + this.selectedAdmissionRow.adminStatus + " this.selectedAdmissionRow.admissionSchoolType : " + this.selectedAdmissionRow.admissionSchoolType);
      if (
        this.selectedAdmissionRow.adminStatus == "6_UO" ||
        ((this.selectedAdmissionRow.admissionSchoolType == "ELICOS" ||
          this.selectedAdmissionRow.admissionSchoolType == "Others" ||
          this.selectedAdmissionRow.admissionSchoolType == "Service" ||
          this.selectedAdmissionRow.admissionSchoolType == "Immigration") &&
          this.selectedAdmissionRow.adminStatus != "Enrolled" &&
          this.selectedAdmissionRow.adminStatus != "wd" &&
          this.selectedAdmissionRow.adminStatus != "Cancelled")
      ) {
        this.isSendToAc = true;
      } else {
        this.isSendToAc = false;
      }

      this.isOnGoing = false;

      this.selectedAdminId = this.selectedAdmissionRow.id;

      this.emailEpList.forEach((x) => {
        if (
          this.name != this.selectedAdmissionRow.adminEp &&
          this.selectedAdmissionRow.adminEp == x.code
        ) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
    }
  }

  onChangeSchoolType() {
    //this.schoolNameList = this.codeService.codeCommon.schoolNameList.filter(code => code.value3 === this.selectedAdmissionRow.admissionSchoolType);

    //this.selectedAdmissionRow.school = '';

    if (this.selectedAdmissionRow.admissionSchoolType == "Secondary") {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.secondary.some((x) => x === code.code)
      );
      this.displayMessage =
        "Passport, E-signature, Acommodation Request, Parent Name Contact Number";
    } else if (
      this.selectedAdmissionRow.admissionSchoolType == "AU Secondary"
    ) {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.auSecondary.some((x) => x === code.code)
      );
      this.displayMessage =
        "Passport, E-signature, Acommodation Request, Parent Name Contact Number";
    } else if (this.selectedAdmissionRow.admissionSchoolType == "ELICOS") {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.elicos.some((x) => x === code.code)
      );
      this.displayMessage = "Passport, E-signature, Acommodation Request";
    } else if (
      ["NZ PTE", "NZ Uni/Poly", "AU Uni", "AU PTE", "AU PTE"].some(
        (tp) => tp === this.selectedAdmissionRow.admissionSchoolType
      )
    ) {
      this.courseList = this.codeService.codeCommon.courseList.filter((code) =>
        this.pteUni.some((x) => x === code.code)
      );
      this.displayMessage = "Passport, E-signature";
    } else {
      this.courseList = this.codeService.codeCommon.courseList;
      this.displayMessage = "Passport, E-signature";
    }

    //this.selectedAdmissionRow.baseCourse1 = '';
    this.calculateEndDate();
  }

  onChangeSchool() {
    //School Type organised by School Name.
    for (var val of this.codeService.codeCommon.schoolNewList) {
      if (
        this.selectedAdmissionRow.school === val.code ||
        this.selectedAdmissionRow.school == val.code
      ) {
        //after comma, it always have a space so needs to split by ", " not ",".
        var val2 = val.courseTypes.split(", ");

        this.schoolTypeList = this.codeService.codeCommon.schoolTypeList.filter(
          function (item) {
            if (val2.includes(item.code)) {
              return item;
            }
          }
        );

        //If School Type is only one detected select it automatically.
        if (this.schoolTypeList.length == 1) {
          this.selectedAdmissionRow.admissionSchoolType =
            this.schoolTypeList[0].code;
          this.onChangeSchoolType();
        }

        break;
      }
    }

    let that = this;
    this.schoolNameList.forEach(function (element) {
      if (element.code == that.selectedAdmissionRow.school) {
        that.selectedAdmissionRow.auNzCheck = element.value2;
        return;
      }
    });
  }

  onChangeCourse() {
    this.selectedAdmissionRow.baseCourse2 = "";
  }

  showAdmissionModal(status): void {
    this.isBonusPayment = false;

    this.selected.splice(0, this.selected.length);
    this.adminMemoRows = new Array<AdminMemo>();

    var onGoingSchool = this.selectedAdmissionRow.school;
    var onGoingSchoolType = this.selectedAdmissionRow.admissionSchoolType;
    var onGoingAdminEp = this.selectedAdmissionRow.adminEp;
    var onGoingAdminAgent = this.selectedAdmissionRow.adminAgent;

    this.convStrToDate();

    var bonusBaseCourse1 = this.selectedAdmissionRow.baseCourse1;
    var bonusMajor = this.selectedAdmissionRow.major;
    var bonusIntake = this.intake;
    var bonusEndDate = this.endDate;
    var bonusStuId = this.selectedAdmissionRow.stuId;
    var bonusStuIdPwd = this.selectedAdmissionRow.stuIdPwd;
    var bonusCpSubAgent = this.selectedAdmissionRow.cpSubAgent;
    var bonusAdminAgent = this.selectedAdmissionRow.adminAgent;
    var bonusAdminEp = this.selectedAdmissionRow.adminEp;

    this.selectedAdmissionRow = new Admission();

    //this.schoolNameList = [];
    this.schoolTypeList = [];

    this.courseList = [];
    this.intake = undefined;
    this.startingDate = undefined;
    this.adminHoldingDate = undefined;
    this.endDate = undefined;
    this.visaAppliedDate = undefined;
    this.visaCreditPurchaseDate = undefined;

    this.insStartDate = undefined;
    this.insEndDate = undefined;
    this.insPurchaseDate = undefined;

    this.osStartDate = undefined;
    this.osEndDate = undefined;

    this.displayMessage = "";

    this.selectedAdmissionRow.admissionSchoolType = undefined;
    this.selectedAdmissionRow.school = undefined;
    this.selectedAdmissionRow.baseCourse1 = undefined;
    //this.selectedAdmissionRow.baseCourse2         = undefined;
    this.selectedAdmissionRow.cpSubAgent = undefined;

    if (status == "OnGoing") {
      /*
      for (var idx = 0; idx < this.admissionRows.length; idx++) {
        if (this.admissionRows[idx].adminStatus == "Enrolled") {
          this.selectedAdmissionRow.admissionSchoolType = this.admissionRows[idx].admissionSchoolType;
          this.selectedAdmissionRow.school = this.admissionRows[idx].school;
          this.selectedAdmissionRow.adminEp = this.admissionRows[idx].adminEp;
          this.selectedAdmissionRow.adminAgent = this.admissionRows[idx].adminAgent;

          this.onChangeSchool();
          this.onChangeSchoolType();
          break;
        }
      }
      */

      this.selectedAdmissionRow.admissionSchoolType = onGoingSchoolType;
      this.selectedAdmissionRow.school = onGoingSchool;
      this.selectedAdmissionRow.adminEp = onGoingAdminEp;
      this.selectedAdmissionRow.adminAgent = onGoingAdminAgent;

      this.onChangeSchool();
      this.onChangeSchoolType();

      this.selectedAdmissionRow.adminStatus = "OnGoing";
    } else if (status == "Bonus") {
      this.isBonusPayment = true;

      this.selectedAdmissionRow.admissionSchoolType = onGoingSchoolType;
      this.selectedAdmissionRow.school = onGoingSchool;
      this.selectedAdmissionRow.adminEp = onGoingAdminEp;
      this.selectedAdmissionRow.adminAgent = onGoingAdminAgent;

      this.selectedAdmissionRow.baseCourse1 = bonusBaseCourse1;
      this.selectedAdmissionRow.major = bonusMajor;

      this.intake = bonusIntake;
      this.endDate = bonusEndDate;

      this.selectedAdmissionRow.stuId = bonusStuId;
      this.selectedAdmissionRow.stuIdPwd = bonusStuIdPwd;
      this.selectedAdmissionRow.cpSubAgent = bonusCpSubAgent;
      this.selectedAdmissionRow.adminAgent = bonusAdminAgent;
      this.selectedAdmissionRow.adminEp = bonusAdminEp;

      this.onChangeSchool();
      this.onChangeSchoolType();

      this.selectedAdmissionRow.adminStatus = "Enrolled";
    } else {
      this.onSetDefault();
    }

    this.admissionModal.show();
  }

  onCancelAdmission() {
    this.admissionModal.hide();
    //this.childModal.show();
  }

  onValidateAdmission() {
    if (this.selectedAdmissionRow.school == "- Visa Only") {
      this.selectedAdmissionRow.major = "- Visa Only";
      this.selectedAdmissionRow.admissionSchoolType = "- Visa Only";

      if (this.selectedAdmissionRow.adminStatus == undefined) {
        this.selectedAdmissionRow.adminStatus = "Enrolled";
      }
    }

    if (this.selectedAdmissionRow.school == "- Insurance Only") {
      this.selectedAdmissionRow.major = "- Insurance Only";
      this.selectedAdmissionRow.admissionSchoolType = "- Insurance Only";
      //this.selectedAdmissionRow.major = "Insurance Only";

      if (this.selectedAdmissionRow.adminStatus == undefined) {
        this.selectedAdmissionRow.adminStatus = "Enrolled";
      }
    }

    if (this.selectedAdmissionRow.school == "- Other Services Only") {
      this.selectedAdmissionRow.major = "- Other Services Only";
      this.selectedAdmissionRow.admissionSchoolType = "- Other Services Only";

      if (this.selectedAdmissionRow.adminStatus == undefined) {
        this.selectedAdmissionRow.adminStatus = "Enrolled";
      }
    }

    this.popupMessage = "";

    if (this.selectedAdmissionRow.adminStatus == "OnGoing") {
      if (
        this.selectedAdmissionRow.admissionSchoolType == undefined ||
        this.selectedAdmissionRow.admissionSchoolType == ""
      ) {
        this.popupMessage = "School Type is required";
      } else if (
        this.selectedAdmissionRow.school == undefined ||
        this.selectedAdmissionRow.school == ""
      ) {
        this.popupMessage = "School Name is required";
      } else if (
        this.selectedAdmissionRow.adminAgent == undefined ||
        this.selectedAdmissionRow.adminAgent == ""
      ) {
        this.popupMessage = "Admin Agent is required";
      } else if (
        this.selectedAdmissionRow.adminEp == undefined ||
        this.selectedAdmissionRow.adminEp == ""
      ) {
        this.popupMessage = "Admin EP is required";
      } else if (
        this.selectedAdmissionRow.school != "- Other Services Only" &&
        this.selectedAdmissionRow.school != "- Visa Only" &&
        this.selectedAdmissionRow.school != "- Insurance Only" &&
        (this.selectedAdmissionRow.intake == undefined ||
          this.selectedAdmissionRow.intake == "")
      ) {
        this.popupMessage = "Intake is required";
      }
    } else {
      if (
        this.selectedAdmissionRow.admissionSchoolType == undefined ||
        this.selectedAdmissionRow.admissionSchoolType == ""
      ) {
        this.popupMessage = "School Type is required";
      } else if (
        this.selectedAdmissionRow.school == undefined ||
        this.selectedAdmissionRow.school == ""
      ) {
        this.popupMessage = "School Name is required";
      } else if (
        this.selectedAdmissionRow.baseCourse1 == undefined ||
        this.selectedAdmissionRow.baseCourse1 == ""
      ) {
        this.popupMessage = "Course is required";
      } else if (
        this.selectedAdmissionRow.major == undefined ||
        this.selectedAdmissionRow.major == ""
      ) {
        if (
          this.selectedAdmissionRow.admissionSchoolType != "ELICOS" &&
          this.selectedAdmissionRow.admissionSchoolType != "Secondary" &&
          this.selectedAdmissionRow.admissionSchoolType != "AU Secondary"
        ) {
          this.popupMessage = "Major is required";
        }
      } else if (
        this.selectedAdmissionRow.school != "- Other Services Only" &&
        this.selectedAdmissionRow.school != "- Visa Only" &&
        this.selectedAdmissionRow.school != "- Insurance Only" &&
        (this.selectedAdmissionRow.intake == undefined ||
          this.selectedAdmissionRow.intake == "")
      ) {
        this.popupMessage = "Intake is required";
      } else if (
        this.selectedAdmissionRow.adminAgent == undefined ||
        this.selectedAdmissionRow.adminAgent == ""
      ) {
        this.popupMessage = "Admin Agent is required";
      } else if (
        this.selectedAdmissionRow.adminEp == undefined ||
        this.selectedAdmissionRow.adminEp == ""
      ) {
        this.popupMessage = "Admin EP is required";
      }

      if (
        this.selectedAdmissionRow.admissionSchoolType == "ELICOS" &&
        (this.selectedAdmissionRow.weeks == null ||
          this.selectedAdmissionRow.weeks == "0")
      ) {
        this.popupMessage = "Weeks is required";
      }
    }

    if (this.selectedAdmissionRow.school == "- Insurance Only") {
      /*
      if(this.selectedAdmissionRow.insPolicyNumber == undefined || this.selectedAdmissionRow.insPolicyNumber == '') {
        this.popupMessage = "Policy No is required";
      }
      else if(this.selectedAdmissionRow.insStartDate == undefined || this.selectedAdmissionRow.insStartDate == '') {
        this.popupMessage = "Start Date is required";
      }
      else if(this.selectedAdmissionRow.insEndDate == undefined || this.selectedAdmissionRow.insEndDate == '') {
        this.popupMessage = "End Date is required";
      }
      else if(this.selectedAdmissionRow.insPurchaseDate == undefined || this.selectedAdmissionRow.insPurchaseDate == '') {
        this.popupMessage = "Purchase Date is required";
      }
      */
    }

    if (this.selectedAdmissionRow.school == "- Visa Only") {
      /*
      if(this.selectedAdmissionRow.visaCreditPurchaseDate == undefined || this.selectedAdmissionRow.visaCreditPurchaseDate == '') {
        this.popupMessage = "Purchase Date is required";
      }
      */
    }

    if (this.selectedAdmissionRow.school == "- Other Services Only") {
      //if(this.selectedAdmissionRow.osStartDate == undefined || this.selectedAdmissionRow.osStartDate == '') {
      //  this.popupMessage = "Start Date is required";
      //}
    }

    if (
      this.selectedAdmissionRow.baseCourse1 === undefined ||
      this.selectedAdmissionRow.baseCourse1 === ""
    ) {
      if (this.selectedAdmissionRow.school == "- Insurance Only") {
        this.popupMessage = "Insurance Type is required";
      } else if (this.selectedAdmissionRow.school == "- Visa Only") {
        this.popupMessage = "Visa Type is required";
      } else if (this.selectedAdmissionRow.school == "- Other Services Only") {
        this.popupMessage = "Service Type is required";
      } else {
        this.popupMessage = "Course is required";
      }
    }

    if (this.popupMessage != "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onDeleteHistoryCommentMsg(id) {
    this.onDeleteHistoryCommentMsgStr = id;
    this.popupMessage = "Would you like to delete selected admission key memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateDSCmsg() {
    this.popupMessage =
      "Would you like to update 'Desired School and Course by Priority?'";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateAdmissionMsg() {
    this.popupMessage = "Would you like to create this admission?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateDSC() {
    this.basicDSCdisabledUpdate = true;

    //업데이트 기능 추가 예정.
    this.basicService.update(this.basic).subscribe(
      (data) => {
        console.log("update successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);
        this.basicDSCdisabledUpdate = false;
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red", // red, blue....
          widthProsentage: 20, // The with of the popou measured by browser width
          animationDuration: 0, // in seconds, 0 = no animation
          showButtons: true, // You can hide this in case you want to use custom buttons
          confirmBtnContent: "OK", // The text on your confirm button
          cancleBtnContent: "Cancel", // the text on your cancel button
          confirmBtnClass: "btn btn-default", // your class for styling the confirm button
          cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
          //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
        };

        this.popup1.show(this.popup1.options);
        this.basicDSCdisabledUpdate = false;
      }
    );
  }

  onCreateAdmission() {
    this.selectedAdmissionRow.basicId = this.selectedBasicId;

    this.convDateToStr();

    if (!this.onValidateAdmission()) return;

    if (this.isBonusPayment == true)
      this.selectedAdmissionRow.isBonusPayment = true;

    this.admissionService.create(this.selectedAdmissionRow).subscribe(
      (data) => {
        this.admissionModal.hide();
        console.log("create successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.selectedAdminId = data.id;

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onUpdateAdmission() {
    this.convDateToStr();

    if (!this.onValidateAdmission()) return;

    this.admissionService.update(this.selectedAdmissionRow).subscribe(
      (data) => {
        this.admissionModal.hide();
        console.log("update successful");
        this.popupMessage = "Succeed in updating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.selectedAdminId = data.id;

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.admissionModal.hide();
        this.popupMessage = "Fail to update";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteAdmission() {
    this.popupMessage = "Would you like to delete selected admission?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDeleteAdmissionMemo() {
    this.popupMessage = "Would you like to delete selected admission memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  deleteAdminMemo() {
    this.adminMemoService.delete(this.selectedAdminMemoRow).subscribe(
      (data) => {
        console.log("delete successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  deleteAdmin() {
    this.admissionService.delete(this.selectedAdmissionRow).subscribe(
      (data) => {
        console.log("delete successful");
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.adminMemoRows = new Array<AdminMemo>();
        this.epChangedList = [];
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  hideAdmissionModal(): void {
    this.admissionModal.hide();
  }

  onUpdateStatusMsg(adminStatus) {
    this.onUpdateStatusStr = adminStatus;

    this.popupMessage = "Would you like to send to Accounting?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onUpdateStatus(adminStatus) {
    if (this.basic.fileCount == 0) {
      this.popupMessage = "There is no file. please upload a file.";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 30,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);

      return;
    }

    this.admissionService
      .updateStatus({
        id: this.selectedAdmissionRow.id,
        adminStatus: adminStatus,
      })
      .subscribe(
        (data) => {
          console.log("update successful");
          //staticModal.show();
          //alert("update is successful");
          this.popupMessage = "Succeed to request";

          this.popup1.options = {
            header: "Information",
            color: "#5cb85c",
            widthProsentage: 20,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);

          this.adminMemoRows = new Array<AdminMemo>();
          this.epChangedList = [];

          this.isSendToAc = false;

          this.selectedAdminId = data.id;
        },
        (error) => {
          console.log(error);
          this.popupMessage = "Fail to update";

          this.popup1.options = {
            header: "Error",
            color: "red",
            widthProsentage: 20,
            animationDuration: 0,
            showButtons: true,
            confirmBtnContent: "OK",
            cancleBtnContent: "Cancel",
            confirmBtnClass: "btn btn-default",
            cancleBtnClass: "one-button-hidden",
          };

          this.popup1.show(this.popup1.options);
        }
      );
  }

  convStrToDate() {
    if (
      this.selectedAdmissionRow.intake != undefined &&
      this.selectedAdmissionRow.intake.length == 10
    )
      this.intake = Utils.formatStrDate(this.selectedAdmissionRow.intake);
    else this.intake = undefined;
    if (
      this.selectedAdmissionRow.startingDate != undefined &&
      this.selectedAdmissionRow.startingDate.length == 10
    )
      this.startingDate = Utils.formatStrDate(
        this.selectedAdmissionRow.startingDate
      );
    else this.startingDate = undefined;
    if (
      this.selectedAdmissionRow.endDate != undefined &&
      this.selectedAdmissionRow.endDate.length == 10
    )
      this.endDate = Utils.formatStrDate(this.selectedAdmissionRow.endDate);
    else this.endDate = undefined;
    if (
      this.selectedAdmissionRow.adminHoldingDate != undefined &&
      this.selectedAdmissionRow.adminHoldingDate.length == 10
    )
      this.adminHoldingDate = Utils.formatStrDate(
        this.selectedAdmissionRow.adminHoldingDate
      );
    else this.adminHoldingDate = undefined;

    if (
      this.selectedAdmissionRow.visaAppliedDate != undefined &&
      this.selectedAdmissionRow.visaAppliedDate.length == 10
    )
      this.visaAppliedDate = Utils.formatStrDate(
        this.selectedAdmissionRow.visaAppliedDate
      );
    else this.visaAppliedDate = undefined;

    if (
      this.selectedAdmissionRow.visaCreditPurchaseDate != undefined &&
      this.selectedAdmissionRow.visaCreditPurchaseDate.length == 10
    )
      this.visaCreditPurchaseDate = Utils.formatStrDate(
        this.selectedAdmissionRow.visaCreditPurchaseDate
      );
    else this.visaCreditPurchaseDate = undefined;

    if (
      this.selectedAdmissionRow.insStartDate != undefined &&
      this.selectedAdmissionRow.insStartDate.length == 10
    )
      this.insStartDate = Utils.formatStrDate(
        this.selectedAdmissionRow.insStartDate
      );
    else this.insStartDate = undefined;

    if (
      this.selectedAdmissionRow.insEndDate != undefined &&
      this.selectedAdmissionRow.insEndDate.length == 10
    )
      this.insEndDate = Utils.formatStrDate(
        this.selectedAdmissionRow.insEndDate
      );
    else this.insEndDate = undefined;

    if (
      this.selectedAdmissionRow.insPurchaseDate != undefined &&
      this.selectedAdmissionRow.insPurchaseDate.length == 10
    )
      this.insPurchaseDate = Utils.formatStrDate(
        this.selectedAdmissionRow.insPurchaseDate
      );
    else this.insPurchaseDate = undefined;

    if (
      this.selectedAdmissionRow.osStartDate != undefined &&
      this.selectedAdmissionRow.osStartDate.length == 10
    )
      this.osStartDate = Utils.formatStrDate(
        this.selectedAdmissionRow.osStartDate
      );
    else this.osStartDate = undefined;

    if (
      this.selectedAdmissionRow.osEndDate != undefined &&
      this.selectedAdmissionRow.osEndDate.length == 10
    )
      this.osEndDate = Utils.formatStrDate(this.selectedAdmissionRow.osEndDate);
    else this.osEndDate = undefined;
  }

  convDateToStr() {
    if (this.intake != undefined)
      this.selectedAdmissionRow.intake = Utils.formatDateStr(this.intake);
    else this.selectedAdmissionRow.intake = undefined;
    if (this.startingDate != undefined)
      this.selectedAdmissionRow.startingDate = Utils.formatDateStr(
        this.startingDate
      );
    else this.selectedAdmissionRow.startingDate = undefined;
    if (this.endDate != undefined)
      this.selectedAdmissionRow.endDate = Utils.formatDateStr(this.endDate);
    else this.selectedAdmissionRow.endDate = undefined;
    if (this.adminHoldingDate != undefined)
      this.selectedAdmissionRow.adminHoldingDate = Utils.formatDateStr(
        this.adminHoldingDate
      );
    else this.selectedAdmissionRow.adminHoldingDate = undefined;

    if (this.visaAppliedDate != undefined)
      this.selectedAdmissionRow.visaAppliedDate = Utils.formatDateStr(
        this.visaAppliedDate
      );
    else this.selectedAdmissionRow.visaAppliedDate = undefined;

    if (this.visaCreditPurchaseDate != undefined)
      this.selectedAdmissionRow.visaCreditPurchaseDate = Utils.formatDateStr(
        this.visaCreditPurchaseDate
      );
    else this.selectedAdmissionRow.visaCreditPurchaseDate = undefined;

    if (this.insStartDate != undefined)
      this.selectedAdmissionRow.insStartDate = Utils.formatDateStr(
        this.insStartDate
      );
    else this.selectedAdmissionRow.insStartDate = undefined;

    if (this.insEndDate != undefined)
      this.selectedAdmissionRow.insEndDate = Utils.formatDateStr(
        this.insEndDate
      );
    else this.selectedAdmissionRow.insEndDate = undefined;

    if (this.insPurchaseDate != undefined)
      this.selectedAdmissionRow.insPurchaseDate = Utils.formatDateStr(
        this.insPurchaseDate
      );
    else this.selectedAdmissionRow.insPurchaseDate = undefined;

    if (this.osStartDate != undefined)
      this.selectedAdmissionRow.osStartDate = Utils.formatDateStr(
        this.osStartDate
      );
    else this.selectedAdmissionRow.osStartDate = undefined;

    if (this.osEndDate != undefined)
      this.selectedAdmissionRow.osEndDate = Utils.formatDateStr(this.osEndDate);
    else this.selectedAdmissionRow.osEndDate = undefined;
  }

  clickOKButton() {
    this.popup1.hide();

    if (
      this.popupMessage === "Succeed in creating" ||
      this.popupMessage === "Succeed in updating" ||
      this.popupMessage === "Succeed in deleting" ||
      this.popupMessage === "Succeed to request"
    ) {
      //this.onClear();
      this.find();

      this.basicService.get(this.selectedBasicId).subscribe((basic) => {
        this.basic = basic;
      });
    } else if (this.popupMessage === "Succeed in creating a memo") {
      this.find();
      this.findMemo();
    } else if (
      this.popupMessage === "Would you like to delete selected admission?"
    ) {
      this.deleteAdmin();
    } else if (
      this.popupMessage === "Would you like to delete selected admission memo?"
    ) {
      this.deleteAdminMemo();
    } else if (
      this.popupMessage === "Would you like to create this admission?"
    ) {
      this.onCreateAdmission();
    } else if (
      this.popupMessage === "Would you like to create this admission memo?"
    ) {
      this.onCreateAdminMemo();
    } else if (
      this.popupMessage === "Would you like to create this admission key memo?"
    ) {
      this.onCreateKey();
    } else if (this.popupMessage === "Would you like to send to Accounting?") {
      this.onUpdateStatus(this.onUpdateStatusStr);
    } else if (
      this.popupMessage ===
      "Would you like to delete selected admission key memo?"
    ) {
      this.onDeleteHistoryComment(this.onDeleteHistoryCommentMsgStr);
    } else if (
      this.popupMessage ==
      "Would you like to update 'Desired School and Course by Priority?'"
    ) {
      this.onUpdateDSC();
    }
  }

  clickCancleButton() {}

  showAdminMemoModal(): void {
    //1211 init selectedTemplate
    this.selectedTemplate = 0;
    if (
      this.selectedAdmissionRow === undefined ||
      this.selectedAdmissionRow.id === undefined
    ) {
      this.popupMessage = "Please click an admission above to add new memo ";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 55,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.selectedAdminMemoRow = new AdminMemo();
      this.mmAlarmDate = null;
      this.adminHoldingDate = null;

      this.resetCheckedEmail();

      //this.informationCategory2List = this.informationCategoryList.find(x => x.code === this.informationBoard.boardCategory).children;
      //validStatusList
      if (
        this.validStatusList.find(
          (y) => y.code === this.selectedAdmissionRow.adminStatus
        ) !== undefined
      ) {
        this.adminStatusList =
          this.codeService.codeCommon.adminStatusList.filter((x) =>
            this.validStatusList
              .find((y) => y.code === this.selectedAdmissionRow.adminStatus)
              .value.some((z) => z === x.code)
          );
          console.log('this.selectedAdmissionRow.adminStatus:', this.selectedAdmissionRow.adminStatus);
          //console.log('this.adminStatusList:', JSON.stringify(this.adminStatusList));
          console.log('adminStatusList:', this.adminStatusList.map(status => status.value).join(', '));

          //console.log('this.validStatusList:', JSON.stringify(this.validStatusList));
          console.log('codeCommon.adminStatusList:', this.codeService.codeCommon.adminStatusList.map(status => status.value).join(', '));
      }

      this.adminMemoModal.show();
    }
  }

  hideAdminMemoModal(): void {
    this.adminMemoModal.hide();
  }

  onSelectAdminMemo({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAdminMemo(obj) {
    if (obj.type === "click") {
      this.selectedAdminMemoRow = obj.row;
    } else if (obj.type === "dblclick") {
      this.isBonusPayment = false;

      this.selectedAdminMemoRow = obj.row;
      if (
        this.selectedAdminMemoRow.mmAlarmDate != undefined &&
        this.selectedAdminMemoRow.mmAlarmDate !== "" &&
        this.selectedAdminMemoRow.mmAlarmDate.length == 10
      ) {
        this.mmAlarmDate = Utils.formatStrDate(
          this.selectedAdminMemoRow.mmAlarmDate
        );
      }

      if (
        this.selectedAdminMemoRow.adminHoldingDate != undefined &&
        this.selectedAdminMemoRow.adminHoldingDate !== "" &&
        this.selectedAdminMemoRow.adminHoldingDate.length == 10
      ) {
        this.adminHoldingDate = Utils.formatStrDate(
          this.selectedAdminMemoRow.adminHoldingDate
        );
      }
      console.log('noga emailAlarm : ', this.selectedAdminMemoRow.emailAlarm);
      if (
        this.selectedAdminMemoRow.emailAlarm != undefined &&
        this.selectedAdminMemoRow.emailAlarm !== ""
      ) {
        this.emailTo = this.selectedAdminMemoRow.emailAlarm
          .replace(" ", "")
          .split(",");
        this.emailEpList.forEach((ep) => {
          ep.checked = this.isCheckedEmail(ep.code);
        });
      } else {
        this.resetCheckedEmail();
      }

      //this.showAdminMemoModal();
    }
  }

  onValidateAdminMemo() {
    this.popupMessage = "";
    console.log('noga selectedAdminMemoRow.crmType : ', this.selectedAdminMemoRow.crmType);
    if (
      this.selectedAdminMemoRow.crmType === undefined ||
      this.selectedAdminMemoRow.crmType === ""
    ) {
      this.popupMessage = "Admin Status is required";
    } else if (
      this.selectedAdminMemoRow.crmType == "Admin Holding" &&
      (this.adminHoldingDate == null || this.adminHoldingDate === null)
    ) {
      this.popupMessage = "Admin Hoding date is required";
    } else if (
      this.selectedAdminMemoRow.crmType == "Defer" &&
      (this.intakeDate == null || this.intakeDate === null)
    ) {
      this.popupMessage = "Intake date is required";
    } else if (
      this.selectedAdminMemoRow.crmType == "Defer" &&
      Utils.compareStrDate(
        Utils.formatDateStr(this.intakeDate),
        Utils.formatDateStr(Utils.formatStrDate("today"))
      ) > 0
    ) {
      //console.log("yes " + Utils.compareStrDate(Utils.formatDateStr(this.intakeDate), Utils.formatDateStr(Utils.formatStrDate("today"))));
      this.popupMessage = "Intake date must be over today";
    }

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreateAdminMemoMsg() {
    this.popupMessage = "Would you like to create this admission memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateAdminMemo() {
    if (!this.onValidateAdminMemo()) return;

    // 1024 파일첨부: 기존 메모 내용에 파일 ID를 HTML 주석으로 추가
    if (this.selectedFilesString !== null && this.selectedAdminMemoRow.memo !== null && this.selectedAdminMemoRow.memo !== undefined) {
      this.selectedAdminMemoRow.memo = `<!-- attachfile-id: ${this.selectedFilesString} --> ${this.selectedAdminMemoRow.memo}`;
      console.log("File attatch crmType : " + this.selectedAdminMemoRow.crmType);
      console.log("File attatch selectedFilesString : " + this.selectedFilesString);
      console.log("File attatch memo : " + this.selectedAdminMemoRow.memo);

    } else {
      console.log("crmType : " + this.selectedAdminMemoRow.crmType);
      console.log("selectedFilesString : " + this.selectedFilesString);
      console.log("memo : " + this.selectedAdminMemoRow.memo);
    }

    this.selectedAdminMemoRow.basicId = this.selectedBasicId;
    this.selectedAdminMemoRow.adminId = this.selectedAdmissionRow.id;
    this.selectedAdminMemoRow.ep = this.selectedAdmissionRow.cpEp;
    this.selectedAdminMemoRow.adminEp = this.selectedAdmissionRow.adminEp;

    this.selectedAdminMemoRow.emailAlarm = this.selectedEmailEp.toString();

    this.selectedAdminMemoRow.adminStatus = this.selectedAdminMemoRow.crmType;

    this.selectedAdminMemoRow.adminHoldingDate = Utils.formatDateStr(
      this.adminHoldingDate
    );

    if (this.selectedAdminMemoRow.adminStatus == "Defer") {
      this.selectedAdminMemoRow.intake = Utils.formatDateStr(this.intakeDate);

      if (
        this.selectedAdmissionRow.admissionSchoolType === "ELICOS" &&
        this.intakeDate !== undefined
      ) {
        let date = new Date();
        let weeks =
          this.selectedAdmissionRow.weeks === undefined ||
          this.selectedAdmissionRow.weeks === ""
            ? 0
            : this.selectedAdmissionRow.weeks;
        let freeWeeks =
          this.selectedAdmissionRow.freeWeeks === undefined ||
          this.selectedAdmissionRow.freeWeeks === ""
            ? 0
            : this.selectedAdmissionRow.freeWeeks;

        let intake = new Date(Utils.formatDateStr(this.intakeDate));
        let endDate = this.datePipe.transform(
          new Date(
            date.setTime(
              intake.getTime() + (+weeks + +freeWeeks) * 86400000 * 7
            ) -
              86400000 * 3
          ),
          "yyyy-MM-dd"
        );
        this.selectedAdminMemoRow.endDate = endDate;
      }

      this.intake = null;
    }

    if (this.name == "Kokos") {
      // console.log("crmType : " + this.selectedAdminMemoRow.crmType);
      // console.log("admissionSchoolType : " + this.selectedAdmissionRow.admissionSchoolType);
      // console.log("school : " + this.selectedAdmissionRow.school);
      // console.log("baseCourse1 : " + this.selectedAdmissionRow.baseCourse1);
      // console.log("adminStatusList : " + JSON.stringify(this.adminStatusList));
      // console.log("schoolTypeList : " + JSON.stringify(this.schoolTypeList));
      // console.log("schoolNameList : " + JSON.stringify(this.schoolNameList));
      // console.log("courseList : " + JSON.stringify(this.courseList));
    }

    this.selectedAdminMemoRow.crmType = this.adminStatusList.find(
      (x) => x.code === this.selectedAdminMemoRow.crmType
    ).value;
    console.log("noga crmType : " + JSON.stringify(this.selectedAdminMemoRow.crmType));

    let admissionSchoolType = this.schoolTypeList.find(
      (x) => x.code === this.selectedAdmissionRow.admissionSchoolType
    );
    if (admissionSchoolType != undefined) {
      this.selectedAdminMemoRow.recSchoolType = admissionSchoolType.value;
    }

    let school = this.schoolNameList.find(
      (x) => x.code === this.selectedAdmissionRow.school
    );
    if (school != undefined) {
      this.selectedAdminMemoRow.recSchool = school.value;
    }

    let course = this.courseList.find(
      (x) => x.code === this.selectedAdmissionRow.baseCourse1
    );
    if (course != undefined) {
      this.selectedAdminMemoRow.recCourse = course.value;
    }

    this.selectedAdminMemoRow.recMajor = this.selectedAdmissionRow.major;
    //this.selectedAdminMemoRow.recStatus     =

    if (this.mmAlarmDate != undefined)
      this.selectedAdminMemoRow.mmAlarmDate = Utils.formatDateStr(
        this.mmAlarmDate
      );

    if (this.adminHoldingDate != undefined)
      this.selectedAdminMemoRow.adminHoldingDate = Utils.formatDateStr(
        this.adminHoldingDate
      );

    console.log(this.selectedAdminMemoRow);
    //console.log(this.selectedAdminMemoRow);
    
    //1003 extra Email send   
    if (this.selectedStudentEmail && this.basic.mdEMail) {
      if (this.selectedAdminMemoRow.emailAlarm) {
        this.selectedAdminMemoRow.emailAlarm = this.selectedAdminMemoRow.emailAlarm + ',';
      }
      this.selectedAdminMemoRow.emailAlarm = this.selectedAdminMemoRow.emailAlarm + this.basic.mdEMail;
    }
    
    if (this.selectedSubAgentEmail && this.subagentEmail) {
      if (this.selectedAdminMemoRow.emailAlarm) {
        this.selectedAdminMemoRow.emailAlarm = this.selectedAdminMemoRow.emailAlarm + ',';
      }
      this.selectedAdminMemoRow.emailAlarm = this.selectedAdminMemoRow.emailAlarm + this.subagentEmail;
    }
    //1206 manual email send
    if (this.selectedManualEmail && this.inputManualEmail) {
      if (this.selectedAdminMemoRow.emailAlarm) {
        this.selectedAdminMemoRow.emailAlarm = this.selectedAdminMemoRow.emailAlarm + ',';
      }
      this.selectedAdminMemoRow.emailAlarm = this.selectedAdminMemoRow.emailAlarm + this.inputManualEmail;
    }

    console.log("selectedAdminMemoRow emailAlarm:",this.selectedAdminMemoRow.emailAlarm);

    this.adminMemoService.create(this.selectedAdminMemoRow).subscribe(
      (data) => {
        this.adminMemoModal.hide();
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating a memo";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 30,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.selectedAdmissionRow.accountStatus =
          this.selectedAdminMemoRow.adminStatus;

        if (this.selectedAdminMemoRow.adminStatus == "Defer") {
          this.selectedAdmissionRow.intake = this.selectedAdminMemoRow.intake;
        }

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);

        this.selectedAdminId = data.adminId;

        this.selectedAdminMemoRow = {};
      },
      (error) => {
        console.log(error);
        this.adminMemoModal.hide();

        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  findMemo() {
    //noga 1204 show filename history
    this.adminMemoService.getList(this.selectedAdmissionRow.id).subscribe(
      (data) => {
        const rowsWithFileData: Array<AdminMemo> = [];
        const observables = data.map((adminMemo) => {
          //1211 fixed exception  adminMemo.memo가 null 또는 undefined인지 확인
          const memoContent = adminMemo.memo || ""; // null 또는 undefined일 경우 빈 문자열로 대체
          const match = memoContent.match(/<!-- attachfile-id: ([\d,]+) -->/);
          //1211 fixed exception  adminMemo.memo가 null 또는 undefined인지 확인

          const fileIds = match && match[1] ? match[1].split(',') : [];
  
          if (fileIds.length > 0) {
            // 파일 이름 가져오기 비동기 작업
            return this.getFilenamesHistory(fileIds).pipe(
              map((fileNames) => ({
                ...adminMemo,
                _fileCount: fileIds.length,
                _fileNames: fileNames.join(', '),
              }))
            );
          } else {
            // 파일 없는 경우 바로 결과 반환
            return of({
              ...adminMemo,
              _fileCount: 0,
              _fileNames: '',
            });
          }
        });
  
        // 모든 비동기 작업 완료 후 데이터 처리
        
        forkJoin(observables).subscribe((results: AdminMemo[]) => {
          this.adminMemoRows = results.sort((a, b) =>
            new Date(b.createDate || '').getTime() - new Date(a.createDate || '').getTime()
          );
          this.getEpChanged(); // 정렬 후 추가 작업
        });
      },
      (error) => {
        console.error('Error fetching memo list:', error);
      }
    );    
    //noga 1204 show filename history
    this.onSelectHistoryComment();
  }

  onSelectHistoryComment() {
    this.adminHistoryCommentService
      .getList(this.selectedAdmissionRow.id)
      .subscribe(
        (data) => {
          console.log("adminHistoryComment data : " + JSON.stringify(data));
          console.log("adminHistoryComment data.length : " + data.length);

          this.adminHistoryCommentList = new Array<AdminHistoryComment>();
          data.forEach((comment) => this.adminHistoryCommentList.push(comment));
        },
        (error) => {
          console.log("error : ", error);
        }
      );
  }

  onCreateKeyMsg() {
    this.popupMessage = "Would you like to create this admission key memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onCreateKey() {
    if (this.keyNote == undefined) return;

    let adminHistoryComment = new AdminHistoryComment();

    adminHistoryComment.adminId = this.selectedAdmissionRow.id;
    adminHistoryComment.commentBody = this.keyNote;

    this.adminHistoryCommentService.create(adminHistoryComment).subscribe(
      (data) => {
        console.log("create successful");
        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.keyNote = "";
        this.onSelectHistoryComment();
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onDeleteHistoryComment(id) {
    let comment = new AdminHistoryComment();
    comment.id = id;
    this.adminHistoryCommentService.delete(comment).subscribe(
      (data) => {
        //console.log("onDeleteHistoryComment data : " + JSON.stringify(data));
        this.onSelectHistoryComment();
      },
      (error) => {
        console.log("onDeleteHistoryComment error : " + JSON.stringify(error));
      }
    );
  }

  //1206 Manual Email send
  onManualEmailChange() {
    this.selectedManualEmail = !this.selectedManualEmail;
    console.log("onManualEmailChange:"+ this.selectedManualEmail);
    console.log("onManualEmailChange:"+ this.inputManualEmail);
  }
  //1003 extra Email send
  onStudentEmailChange() {
    this.selectedStudentEmail = !this.selectedStudentEmail;
    console.log("onStudentEmailChange:"+ this.selectedStudentEmail);
  }
  onSubAgentEmailChange() {
    this.selectedSubAgentEmail = !this.selectedSubAgentEmail;
    console.log("onSubAgentEmailChange:"+this.selectedSubAgentEmail);
  }

  isCheckedMessage(code: string) {
    return this.msgTo.some((x) => x === code);
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  isCheckedEmail(code: string) {
    return this.emailTo.some((x) => x === code);
  }

  resetCheckedEmail() {
    this.emailEpList.forEach((x) => {
      if (
        (this.name != this.selectedAdmissionRow.adminEp &&
          this.selectedAdmissionRow.adminEp == x.code) ||
        (this.name != this.selectedAdmissionRow.cpEp &&
          this.selectedAdmissionRow.cpEp == x.code)
      ) {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });
  }

  onSelectedMenu(menu) {
    this.router.navigate(["/" + menu, this.selectedBasicId]);
  }

  getEpChanged() {
    this.epChangedList = [];
    //console.log("adminMemoRows : " + this.adminMemoRows.length);

    let temp = "";
    this.adminMemoRows.reverse().forEach((memo) => {
      if (temp !== memo.adminEpName) {
        this.epChangedList.push(
          "[" + memo.createDate + "]   Admin EP : " + memo.adminEpName
        );
      }
      temp = memo.adminEpName;
    });

    this.adminMemoRows.reverse();
    this.epChangedList = this.epChangedList.reverse();
  }

  calculateEndDate() {
    if (
      this.selectedAdmissionRow.admissionSchoolType === "ELICOS" &&
      this.intake !== undefined
    ) {
      let date = new Date();
      let weeks =
        this.selectedAdmissionRow.weeks === undefined ||
        this.selectedAdmissionRow.weeks === ""
          ? 0
          : this.selectedAdmissionRow.weeks;
      let freeWeeks =
        this.selectedAdmissionRow.freeWeeks === undefined ||
        this.selectedAdmissionRow.freeWeeks === ""
          ? 0
          : this.selectedAdmissionRow.freeWeeks;

      let intake = new Date(Utils.formatDateStr(this.intake));
      let endDate = this.datePipe.transform(
        new Date(
          date.setTime(
            intake.getTime() + (+weeks + +freeWeeks) * 86400000 * 7
          ) -
            86400000 * 3
        ),
        "yyyy-MM-dd"
      );
      this.endDate = Utils.formatStrDate(endDate);
    }
  }

  changedOnIntakeDate() {
    this.selectedAdminMemoRow.memo = `Defer: intake date is changed. [ ${
      this.selectedAdmissionRow.intake
    } -> ${Utils.formatDateStr(this.intakeDate)} ]`;
  }

  onSetDefault() {
    this.selectedAdmissionRow.isSendToAdminByEmail = true;

    //console.log("this.selectedAdmissionRow.adminEp : " + this.selectedAdmissionRow.adminEp);
    if (
      this.selectedAdmissionRow.adminEp === undefined ||
      this.selectedAdmissionRow.adminEp == null
    ) {
      this.selectedAdmissionRow.adminEp = this.name;
    }
    //console.log("this.selectedAdmissionRow.adminEp : " + this.selectedAdmissionRow.adminEp);
    if (
      this.selectedAdmissionRow.adminAgent == undefined ||
      this.selectedAdmissionRow.adminAgent == null
    ) {
      this.codeService.codeCommon.adminAgentList.filter(
        (x) => x.code == "KOKOS" || x.code == "KOKOS NZ"
      );
      if (
        this.codeService.codeCommon.adminAgentList.some(
          (x) => x.code == "SOLNZ"
        )
      ) {
        this.selectedAdmissionRow.adminAgent = "SOLNZ";
      } 
      else if (
        this.codeService.codeCommon.adminAgentList.some(
          (x) => x.code == "KOKOS"
        )
      ) {
        this.selectedAdmissionRow.adminAgent = "KOKOS";
      } 
      else if (
        this.codeService.codeCommon.adminAgentList.some((x) => x.code == "Hana")
      ) {
        this.selectedAdmissionRow.adminAgent = "Hana";
      }
      
    }
    if (
      this.selectedAdmissionRow.cpSubAgent === undefined ||
      this.selectedAdmissionRow.cpSubAgent == null
    ) {
      this.selectedAdmissionRow.cpSubAgent = this.basic.ciSubAgent;
    }
  }

  goBack(): void {
    this.location.back();
  }
  //noga 1203 memo template
  onTemplateChange() {
    const selected = this.templateList.find(template => template.id === +this.selectedTemplate);
    //this.selectedTemplateContent = selected ? selected.content : '';
    this.selectedAdminMemoRow.memo = selected ? selected.content : '';
  }

  //noga 1204 remove attachfile-id  
  removeAttachFileIds(content: string): string {
    if (!content) return '';
    // 정규식을 사용하여 attachfile-id 주석 제거
    return content.replace(/<!-- attachfile-id:.*?-->/g, '');
  }
  
  //noga 1204 show filename history
  getFilenamesHistory(fileIds: string[]): Observable<string[]> {
    console.log("noga fileIds: " + fileIds.join(',')); // 파일 ID 로그 출력
  
    if (!fileIds || fileIds.length === 0) {
      return of([]); // 파일 ID가 없을 경우 빈 배열 반환
    }
  
    // 각 ID에 대해 API 요청 생성
    const requests = fileIds.map((id) =>
      this.personalFilesService.get(+id).pipe(
        map((data: any) => data.filename?.trim() || ''), // 파일 이름 가져오기
        catchError(() => of('')) // 에러 발생 시 빈 문자열 반환
      )
    );
  
    // 모든 요청 병렬 실행 후 결과 반환
    return forkJoin(requests).pipe(
      map((fileNames) => fileNames.filter((name) => name)) // 빈 값 제거
    );
  }
  /*
  getFilenamesHistory(fileIds: string[]): void {
    console.log("noga fileIds: " + fileIds.join(',')); // 파일 ID 로그 출력
  
    if (!fileIds || fileIds.length === 0) {
      //console.log("noga2 fileNames: []"); // 파일 ID가 없을 경우 빈 배열 로그
      return;
    }
  
    // 각 ID에 대해 API 요청 생성
    const requests = fileIds.map((id) =>
      this.personalFilesService.get(+id).pipe(
        map((data: any) => data.filename?.trim() || ''), // 파일 이름 가져오기
        catchError(() => of('')) // 에러 발생 시 빈 문자열 반환
      )
    );
  
    // 모든 요청 병렬 실행 후 결과를 하나의 문자열로 연결
    forkJoin(requests).subscribe((fileNames) => {
      const filteredNames = fileNames.filter((name) => name); // 빈 값 제거
      const joinedNames = filteredNames.join(', '); // 쉼표로 연결
      console.log("noga3 fileNames: " + joinedNames); // 최종 파일 이름 로그 출력
    });
  }*/
  
  /*
  getFilenamesHistory(fileIds: string[]): string {
    console.log("noga fileIds: " + fileIds.join(',')); // 디버깅용 로그
  
    let filenames: string = ''; // 파일명들을 저장할 문자열
  
    fileIds.forEach((id) => {
      this.personalFilesService.get(+id).subscribe(
        (data: any) => {
          const fileName = data.filename?.trim(); // 파일명 앞뒤 공백 제거
          console.log('1fileName:', fileName); // 디버깅용 로그
          if (fileName) {
            filenames += fileName + ', '; // 쉼표로 구분하여 추가
          }
        },
        (error) => {
          console.error(`Failed to fetch filename for id: ${id}`, error); // 에러 로그
        }
      );
    });
  
    // 최종 쉼표 제거 및 반환
    return filenames.endsWith(', ') ? filenames.slice(0, -2) : filenames;
  }*/
  
  
}
