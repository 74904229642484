import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { PageRequest } from "../model/dto";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/customer`;
const defaultPageRequest: PageRequest = { page: 1, size: 5 };
const basicUrl = `${environment.api_url}/api/basic?searchKey=customer_id&searchValue=`;
@Injectable()
export class CustomerService {
  constructor(private http: JsonHttp) {}

  getAll(searchParams, page) {


    let params: string = `${url}?page=${page.pageNumber}&size=${page.size}`;
    if(searchParams.searchKey){
      if(searchParams.searchValue){
        params += `&${searchParams.searchKey}=${searchParams.searchValue}`;
      }
    }
    return this.http.get(params);
  }


  getBasicInfo(customerId){
    let params: string = `${basicUrl}` + customerId;
    return this.http.get(params);
  }
}
