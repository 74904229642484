import { Component, OnInit }      from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, 
         FormControl, 
         Validators,
         ValidatorFn  }           from '@angular/forms';
     
import { AuthenticationService }  from '../../services/authentication.service';
import { CodeService           }  from '../../services/code.service';
import  * as _                    from 'lodash';
import { FlashMessagesService }   from 'angular2-flash-messages';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    returnUrl: string = '/';
    loading = false;

    loginForm           : FormGroup;
    email               : FormControl;
    password            : FormControl;
    
    constructor(
                private route : ActivatedRoute,
                private router: Router,
                private codeService: CodeService,
                private authenticationService: AuthenticationService,
                private _flashMessagesService: FlashMessagesService) { }
    
    ngOnInit() {
        //this.authenticationService.logout();
        //this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
      
      this.initForm();      
    }
        
    onSubmit(loginParams) {
      
      _.values(this.loginForm.controls).forEach(c => c.markAsTouched());
      if (!this.loginForm.valid) return;

      this.loading = true;
      this.authenticationService.login(this.email.value, this.password.value)
          .subscribe(
              data  => {
                  
                  console.log("login ok"); 
                  console.log("data : " + data);            
                  
                  this.codeService.resetCommon();
                  
                  this.router.navigate([this.returnUrl])
              },
              error => {
      
                  //this._flashMessagesService.show('Login fail => status : ' + error.status + ' , statusText : ' + error.statusText, { cssClass: 'alert-danger', timeout: 3000 });
                  this._flashMessagesService.show('Login failed. Please check your username or password again (' + error.statusText + ')', { cssClass: 'alert-danger', timeout: 3000 });                
                  
                  console.log("error : " + error);          
                  console.log("error1 : " + JSON.stringify(error)); 
                 
                  this.loading = false;
              }
          );
    }
    
    private initForm() {
      this.email                = new FormControl('', Validators.compose( [
                                                                            Validators.required,
                                                                            Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")
                                                                          ]));
      this.password             = new FormControl('', Validators.compose( [
                                                                            Validators.required,
                                                                            Validators.minLength(4)
                                                                          ]));
      this.loginForm = new FormGroup({
        email                 : this.email,
        password              : this.password,
      });
    }    
}
