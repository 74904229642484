import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Profile } from "app/model/profile";
import { filter } from "rxjs/operators";
import { Basic } from "../../model/basic";
import { AuthenticationService } from "../../services/authentication.service";
import { MatDialog,MatDialogConfig ,MatDialogRef} from '@angular/material/dialog';
import {ChatModalComponent} from '../thread/chatmodal.component';
import { Thread } from "../../model/thread";
import { environment } from "../../../environments/environment";
import { JsonHttp } from "../../services/json-http";

@Component({
  selector: "app-detail-menu",
  templateUrl: "./detail-menu.component.html",
})
export class DetailMenuComponent {
  @Input()
  menu: string;

  @Input()
  basic: Basic;

  @Output()
  selectedMenu: EventEmitter<any>;

  name: string;
  authority: string;
  company: string;
  dialogRef: MatDialogRef<ChatModalComponent>;

  constructor(
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private http: JsonHttp
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.selectedMenu = new EventEmitter<any>();
  }

  clickMenu(value) {
    this.selectedMenu.emit(value);
  }

  openChat(id){
    this.http.get(`${environment.api_url}/api/threads?type=all&searchKey=basicId&searchValue=${id}`).subscribe(
      (result) => {
        //console.log("#### result check :",result.content[0]);
        if(result.content.length > 0){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = '900px';
          dialogConfig.position  = {
            'top':'80px',
          };
          dialogConfig.data = result.content[0];
          this.dialogRef = this.dialog.open(ChatModalComponent,dialogConfig);
          //this.selectedThread.next(thread);
        }else{
          alert("No chatting");
        }
      }
    );
  }

  possible() {
    //console.log("this.authority : " + this.authority + " this.name : " + this.name + " this.basic.ciEp : " + this.basic.ciEp);
    if (this.authority == "accountant" || this.authority == "manager") {
      return true;
    }

    if (this.menu == "admissionDetail") {
      if (this.authority == "admin") {
        return true;
      }

      if (this.name == this.basic.ciEp) {
        return true;
      }
    }

    if (this.menu == "accountingDetail") {
      if (this.name == this.basic.ciEp) {
        return true;
      }
    }

    return false;
  }
}
