import { ElementRef, HostListener, Directive } from "@angular/core";
import { NgControl } from "@angular/forms";
import   Utils       from '../utils'; 

@Directive({
  selector: '[ngModel][decimal]'
})
export class DecimalMask {
  constructor(private el: ElementRef, public model: NgControl) {}

  @HostListener('input',['$event']) onEvent($event){
    //console.log("keypress: " + $event.keypress);

    //console.log(this.model);
    //console.log(newVal);
    // set the new value
    this.model.control.setValue(Utils.addComma(this.el.nativeElement.value));
    //this.model.valueAccessor.writeValue(newVal);
  }
}