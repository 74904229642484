import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CurrencyMaskModule } from 'ng2-currency-mask';

import { PopupModule } from 'ng2-opd-popup';
//import { PdfmakeModule } from 'ng-pdf-make';
import { DetailMenuModule } from '../detail/detail-menu.module';

import { AccountingComponent } from './accounting.component';
import { AccountingDetailComponent } from './accounting-detail.component';
import { AccountingPrintComponent } from './accounting-print.component';
import { RestrictInputDirectveModule } from '../../shared/directive/restrict-input-directive.module';
//import { DecimalMask              } from '../../shared/directive/decimal-mask.directive'; 
import { DirectiveCommonModule } from '../../shared/directive/directive-common.module';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,

    MatDatepickerModule,
    NgxDatatableModule,
    ModalModule.forRoot(),
    TabsModule,
    CurrencyMaskModule,

    NgbModule,
    PopupModule.forRoot(),
    DetailMenuModule,
    //PdfmakeModule,
    RestrictInputDirectveModule,
    DirectiveCommonModule,
    MultiselectDropdownModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  declarations: [
    AccountingComponent,
    AccountingDetailComponent,
    AccountingPrintComponent
  ],
  exports: [
    AccountingComponent,
    AccountingDetailComponent,
    AccountingPrintComponent
  ]
})
export class AccountingModule {
}
