import { NgbDateStruct          } from '@ng-bootstrap/ng-bootstrap';

export class StatisticsSearchParams {
  
  public searchKey?         :string   = "A";
  public searchDate?        :string   = "AD";
  public holding?           :boolean  = false;
  
  public fromAppliedDate?   :string   = "";    
  public toAppliedDate?     :string   = "";  
  public fromCompletedDate? :string   = "";  
  public toCompletedDate?   :string   = "";  
  public fromIntakeDate?    :string   = "";  
  public toIntakeDate?      :string   = "";     

  
  public ciSubAgent         :any[];
  public ciMemberField      :any[];
  public ciMemberCategory   :any[];
  public ciEp               :any[];
  public ciStage            :any[];
  public mmAlarmLevel       :any[];
  public school             :any[];
  
  public fromAppliedDate1?  :NgbDateStruct;    
  public toAppliedDate1?    :NgbDateStruct;  
  public fromCompletedDate1?:NgbDateStruct;  
  public toCompletedDate1?  :NgbDateStruct;  
  public fromIntakeDate1?   :NgbDateStruct;  
  public toIntakeDate1?     :NgbDateStruct;    
}

export class Statistics {
    username?:string;
    ciMemberCategory?:string;    
    ciMemberCategoryName?:string;      
    ciMemberField?:string;        
    school?:string;
    schoolName?:string;    
    schoolType?:string;    
    schoolTypeName?:string;    
    cpSubAgent?:string;    
    cpSubAgentName?:string;    
    ciStage?:string;    
    count?:number;   
    profit1?:number;    
    profit2?:number;    
    profit3?:number;    
    profit4?:number;    
    profit5?:number;    
    profit6?:number;    
    profit7?:number;    
    profit8?:number;    
    profit9?:number;    
    profit10?:number;    
    profit11?:number;    
    profit12?:number;    
    profit13?:number;    
    profit14?:number;    
    profit15?:number;   
    profit16?:number;    
    profit17?:number;    
    profit18?:number;   
    profit19?:number;    
    profit20?:number;    
    profit21?:number;    
    profit22?:number;    
    profit23?:number;    
    profit24?:number;    
    profit25?:number;    
    profit26?:number;    
    profit27?:number;    
    profit28?:number;    
    profit29?:number;    
    profit30?:number;
    profit31?:number;    
    profit32?:number;    
    profit33?:number;    
    profit34?:number;    
    profit35?:number;    
    profit36?:number;    
    profit37?:number;    
    profit38?:number;    
    profit39?:number;    
    profit40?:number;
    profit41?:number;    
    profit42?:number;    
    profit43?:number;    
    profit44?:number;    
    profit45?:number;    
    profit46?:number;    
    profit47?:number;    
    profit48?:number;    
    profit49?:number;    
    profit50?:number;
    profit51?:number;    
    profit52?:number;    
    profit53?:number;    
    profit54?:number;    
    profit55?:number;    
    profit56?:number;    
    profit57?:number;    
    profit58?:number;    
    profit59?:number;    
    profit60?:number;
    profit61?:number;    
    profit62?:number;    
    profit63?:number;    
    profit64?:number;    
    profit65?:number;    
    profit66?:number;    
    profit67?:number;    
    profit68?:number;    
    profit69?:number;    
    profit70?:number;    
    total?:number;    
    schoolFee?:number;   
    commission?:number;
    subCommission?:number;
    dc?:number;    
    profit?:number;   
    average?:number;      
    
    count1?:number = 0;    
    count2?:number = 0;    
    count3?:number = 0;    
    count4?:number = 0;    
    count5?:number = 0;    
    count6?:number = 0;    
    count7?:number = 0;    
    count8?:number = 0;    
    count9?:number = 0;    
    count10?:number = 0;    
    count11?:number = 0;    
    count12?:number = 0;    
    count13?:number = 0;    
    count14?:number = 0;    
    count15?:number = 0; 
    count16?:number = 0;    
    count17?:number = 0;    
    count18?:number = 0; 
    count19?:number = 0;    
    count20?:number = 0;  
    count21?:number = 0;    
    count22?:number = 0;    
    count23?:number = 0;    
    count24?:number = 0;    
    count25?:number = 0;    
    count26?:number = 0;    
    count27?:number = 0;    
    count28?:number = 0;    
    count29?:number = 0;    
    count30?:number = 0; 
    count31?:number = 0;    
    count32?:number = 0;    
    count33?:number = 0;    
    count34?:number = 0;    
    count35?:number = 0;    
    count36?:number = 0;    
    count37?:number = 0;    
    count38?:number = 0;    
    count39?:number = 0;    
    count40?:number = 0; 
    count41?:number = 0;    
    count42?:number = 0;    
    count43?:number = 0;    
    count44?:number = 0;    
    count45?:number = 0;    
    count46?:number = 0;    
    count47?:number = 0;    
    count48?:number = 0;    
    count49?:number = 0;    
    count50?:number = 0; 
    count51?:number = 0;    
    count52?:number = 0;    
    count53?:number = 0;    
    count54?:number = 0;    
    count55?:number = 0;    
    count56?:number = 0;    
    count57?:number = 0;    
    count58?:number = 0;    
    count59?:number = 0;    
    count60?:number = 0; 
    count61?:number = 0;    
    count62?:number = 0;    
    count63?:number = 0;    
    count64?:number = 0;    
    count65?:number = 0;    
    count66?:number = 0;    
    count67?:number = 0;    
    count68?:number = 0;    
    count69?:number = 0;    
    count70?:number = 0;     
    
    countTotal?:number = 0;        
}

export class StatisticsParams extends Statistics {
}


