export class UserSearchParams {
    public isActive       :any[];
    searchKey           ?:string;
    searchValue           ?:string;
}

export class User {
    id              ?:string|number;
    email           ?:string;
    name            ?:string;
    canLogin        ?:boolean;
    isActive        ?:boolean;
    lastLogedOnDate ?: string;
    phoneNumber     ?: string;
    authority       ?: string;
    smtpServer      ?: string;
    smtpPort        ?: string;
    isSsl           ?:boolean;
    createdBy       ?: string;
    createdOnDate   ?: string;
    updatedBy       ?: string;
    updatedOnDate   ?: string;
    password        ?: string;
    company         ?: string;
    initial         ?: string;

    targetOfLastYear ?:number;
    targetOfThisYear ?:number;
    targetOfNextYear ?:number;
}

export class UserParams extends User {
}

export class UserView {
  normalMessageCnt            ?:number  = 0;
  adminMessageCnt             ?:number  = 0;
  todayBirthdayCnt            ?:number  = 0;
  tomorrowBirthdayCnt         ?:number  = 0;

  newMember1                  ?:number  = 0;
  newMember2                  ?:number  = 0;
  newMember3                  ?:number  = 0;
  newMember4                  ?:number  = 0;

  counsellingMember1          ?:number  = 0;
  counsellingMember2          ?:number  = 0;
  counsellingMember3          ?:number  = 0;
  counsellingMember4          ?:number  = 0;

  enrolledMember1             ?:number  = 0;
  enrolledMember2             ?:number  = 0;
  enrolledMember3             ?:number  = 0;
  enrolledMember4             ?:number  = 0;

  arrivingMember1             ?:number  = 0;
  arrivingMember2             ?:number  = 0;
  arrivingMember3             ?:number  = 0;
  arrivingMember4             ?:number  = 0;

  onlineApplication1          ?:number  = 0;
  onlineApplication2          ?:number  = 0;
  onlineApplication3          ?:number  = 0;
  onlineApplication4          ?:number  = 0;
  onlineApplication5          ?:number  = 0;
  onlineApplication6          ?:number  = 0;
  onlineApplication7          ?:number  = 0;

  accounting1                 ?:number  = 0;

  companyTargetOfThisYear     ?:number  = 0;
  companyProfitOfThisYear     ?:number  = 0;
  companyProfitOfThisMonth    ?:number  = 0;

  myTargetOfThisYear          ?:number  = 0;
  myProfitOfThisYear          ?:number  = 0;
  myProfitOfThisMonth         ?:number  = 0;

  myTargetOfThisTerm          ?:number  = 0;
  myProfitOfThisTerm          ?:number  = 0;

  companyTargetOfNextYear     ?:number  = 0;
  companyProfitOfNextYear     ?:number  = 0;

  companyTargetOfLastYear     ?:number  = 0;
  myProfitOfLastYear          ?:number  = 0;

  today                       ?:string;
  dayOfWeek                   ?:string;
  dayOfFortnight              ?:string;
  dayOfMonth                  ?:string;
}

export class MasterCode {
  name?           : string;
  value?          : string;
}

export class Code {
  id?             : string|number;
  masterCode?     : string;
  code?           : string;
  value?          : string;
  value2?         : string;
  value3?         : string;
  value4?         : string;
  value5?         : string;
  value6?         : string;
  orderIdx?       : string|number;
  parentId?       : string|number;
  parentCode?     : string;
  isActive?       : boolean;
  createdBy?      : string;
  createdOnDate?  : string;
  createdOnTime?  : string;
  updatedBy?      : string;
  updatedOnDate?  : string;
  updatedOnTime?  : string;
}

export class CodeParams extends Code {
}

export class Page<T> {
  content      : T[];
  totalPages   : number;
  totalElements: number;
}

export class PageRequest {
  page: number;
  size: number;
}
