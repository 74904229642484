import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FlashMessagesService } from "angular2-flash-messages";
import { Profile } from "app/model/profile";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { DashboardSearchParams } from "../../model/dashboard";
import { UserView } from "../../model/dto";
import {
  InformationBoard,
  InformationBoardSearchParams,
} from "../../model/information-board";
import { Page } from "../../model/page";
import { Schedule } from "../../model/schedule";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { DashboardService } from "../../services/dashboard.service";
import { InformationBoardService } from "../../services/information-board.service";
import { ScheduleService } from "../../services/schedule.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-top",
  providers: [
    DatePipe,
    InformationBoardService,
    ScheduleService,
    DashboardService,
  ],
  templateUrl: "./top.component.html",
  styleUrls: ["./top.component.css"],
})
export class TopComponent implements OnInit {
  //plannedCRM: number[] = new Array(3).fill(0);
  totalNumber: number[] = new Array(6).fill(0);
  myNumber: number[] = new Array(6).fill(0);
  lapsedNM: number[] = new Array(4).fill(0);
  lapsedCM: number[] = new Array(4).fill(0);
  currentCM: number[] = new Array(5).fill(0);
  arrivingM: number[] = new Array(2).fill(0);

  public searchParamsDashboard = new DashboardSearchParams();

  reset() {
    //this.plannedCRM.forEach((n) => (n = 0));
    this.totalNumber.forEach((n) => (n = 0));
    this.myNumber.forEach((n) => (n = 0));
    this.lapsedNM.forEach((n) => (n = 0));
    this.lapsedCM.forEach((n) => (n = 0));
    this.currentCM.forEach((n) => (n = 0));
    this.arrivingM.forEach((n) => (n = 0));

    this.searchParamsDashboard.searchEP = this.selectedEp;
  }

  search() {
    this.reset();

    this.dashboardService.getEPs(this.searchParamsDashboard).subscribe(
      (data) => {
        console.log("top getEPs success: " );
        data.forEach((eps) => {
          /*
            this.plannedCRM[0] += +eps.plannedcrm0;
            this.plannedCRM[1] += +eps.plannedcrm1;
            this.plannedCRM[2] += +eps.plannedcrm2;
            */

          this.totalNumber[0] += +eps.totalnumber0;
          this.totalNumber[1] += +eps.totalnumber1;
          this.totalNumber[2] += +eps.totalnumber2;
          this.totalNumber[3] += +eps.totalnumber3;
          this.totalNumber[4] += +eps.totalnumber4;
          this.totalNumber[5] += +eps.totalnumber5;

          this.myNumber[0] += +eps.mynumber0;
          this.myNumber[1] += +eps.mynumber1;
          this.myNumber[2] += +eps.mynumber2;
          this.myNumber[3] += +eps.mynumber3;
          this.myNumber[4] += +eps.mynumber4;
          this.myNumber[5] += +eps.mynumber5;

          this.lapsedNM[0] += +eps.lapsednm0;
          this.lapsedNM[1] += +eps.lapsednm1;
          this.lapsedNM[2] += +eps.lapsednm2;
          this.lapsedNM[3] += +eps.lapsednm3;

          this.lapsedCM[0] += +eps.lapsedcm0;
          this.lapsedCM[1] += +eps.lapsedcm1;
          this.lapsedCM[2] += +eps.lapsedcm2;
          this.lapsedCM[3] += +eps.lapsedcm3;

          this.currentCM[0] += +eps.currentcm0;
          this.currentCM[1] += +eps.currentcm1;
          this.currentCM[2] += +eps.currentcm2;
          this.currentCM[3] += +eps.currentcm3;
          this.currentCM[4] += +eps.currentcm4;

          this.arrivingM[0] += +eps.arrivingm0;
          this.arrivingM[1] += +eps.arrivingm1;
        });
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  isSignedIn: boolean = false;

  public page = new Page();
  public rows1 = new Array<InformationBoard>();
  public rows2 = new Array<InformationBoard>();
  public rows3 = new Array<Schedule>();

  public searchParams = new InformationBoardSearchParams();

  public userView = new UserView();

  authority: string;
  name: string;
  company: string;
  epAll: string;

  lastYear: number = new Date().getFullYear() - 1;
  thisYear: number = new Date().getFullYear();
  nextYear: number = new Date().getFullYear() + 1;

  thisMonth: number = new Date().getMonth();
  thisTerm: string;

  epList: any[] = [];
  selectedEp: string = "epAll";
  codeCommon: CodeCommon;

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private codeService: CodeService,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    private informationBoardService: InformationBoardService,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private _flashMessagesService: FlashMessagesService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.isSignedIn = true;
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    if (this.thisMonth >= 0 && this.thisMonth <= 1) this.thisTerm = "Feb";
    else if (this.thisMonth >= 2 && this.thisMonth <= 6) this.thisTerm = "Jul";
    else this.thisTerm = "Dec";

    if (this.codeService.getSelectedEp() != undefined) {
      this.selectedEp = this.codeService.getSelectedEp();
    } else {
      this.selectedEp = this.name;
    }

    if (this.company == undefined || this.company == "k") {
      this.epAll = "kokos";
    } else {
      this.epAll = "hana";
    }

    this.page.pageNumber = 0;
    this.page.size = 15;

    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    this.epList = codeCommon.epList;
    //console.log(this.epList);

    for (var i = 0; i < this.epList.length; i++) {
      //|| this.epList[i].value == 'Shirley' || this.epList[i].value == 'Charles'

      if (
        this.epList[i].value == "Lucia" ||
        this.epList[i].value == "Vanessa" ||
        this.epList[i].value == "Anna"
      ) {
        this.epList.splice(i, 1);
        i--;
      }
    }
  }

  ngOnInit() {
    if (this.isSignedIn) {
      // 1st parameter is a flash message text
      // 2nd parameter is optional. You can pass object with options.
      //this._flashMessagesService.grayOut(true);
      //this._flashMessagesService.show('We are in about component!', { cssClass: 'alert-success', timeout: 3000 });
      this.getUserView();
      this.getHomeList1();
      this.getHomeList3();
    }
  }

  getUserView() {
    if (this.selectedEp == undefined) {
      this.selectedEp = this.name;
    }

    //console.log("this.selectedEp : " + this.selectedEp);

    this.userService.getView(this.selectedEp).subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        this.userView = data;

        this.getHomeList2();
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/"]);
        }
      }
    );

    this.search();
  }

  getHomeList1() {
    this.informationBoardService.getHomeList1().subscribe(
      (data) => {
        //console.log("getHomeList1 : " + JSON.stringify(data));
        console.log("getHomeList1.content : " + data.length);

        this.rows1 = new Array<InformationBoard>();
        data.forEach((board) => {
          if (board.boardHead != undefined) {
            board.boardHead1 =
              board.boardHead.length > 25
                ? board.boardHead.substring(0, 25) + "..."
                : board.boardHead;
          }

          this.rows1.push(board);
        });

        for (let idx = this.rows1.length; idx < 14; idx++) {
          this.rows1.push(new InformationBoard());
        }
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  getHomeList2() {
    this.informationBoardService.getHomeList2(this.selectedEp).subscribe(
      (data) => {
        //console.log("getHomeList2 : " + JSON.stringify(data));

        this.rows2 = new Array<InformationBoard>();
        data.forEach((board) => {
          if (board.frequency == "Daily") board.dueDate = this.userView.today;
          else if (board.frequency == "Weekly")
            board.dueDate = this.userView.dayOfWeek;
          else if (board.frequency == "Fortnightly")
            board.dueDate = this.userView.dayOfFortnight;
          else if (board.frequency == "Monthly")
            board.dueDate = this.userView.dayOfMonth;

          if (board.boardHead != undefined) {
            board.boardHead1 =
              board.boardHead.length > 25
                ? board.boardHead.substring(0, 25) + "..."
                : board.boardHead;
          }

          this.rows2.push(board);
        });

        for (let idx = this.rows2.length; idx < 14; idx++) {
          this.rows2.push(new InformationBoard());
        }
      },
      (error) => {
        console.log("error : ", error);
        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }

  getHomeList3() {
    this.scheduleService.getHomeList("").subscribe(
      (data) => {
        //console.log("data : " + JSON.stringify(data));
        data.forEach((schedule) => {
          if (this.rows3.length < 14) {
            if (schedule.summary != undefined) {
              schedule.summary1 =
                schedule.summary.length > 22
                  ? schedule.summary.substring(0, 22) + "..."
                  : schedule.summary;
            }
            this.rows3.push(schedule);
          }
        });

        for (let idx = this.rows3.length; idx < 14; idx++) {
          this.rows3.push(new Schedule());
        }
      },
      (error) => {
        console.log("error : ", error);
      }
    );
  }

  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);
  }

  onActivate(obj) {
    if (obj.type === "dblclick") {
      //console.log('Activate Event', obj);
      //console.log('Type', obj.type);
      //console.log('basicId', obj.row.id);
      this.router.navigate(["/manualDetail", obj.row.id]);
    }
  }

  onWriteReport() {
    this.router.navigate(["/manualDetail"], {
      queryParams: { category: "Daily Report" },
    });
  }

  onWriteNew() {
    this.router.navigate(["/manualDetail"]);
  }

  strip_html_tags = (function () {
    "use strict";

    return function (str) {
      // validation
      if (!str || typeof str === "undefined" || str === null) {
        return false;
      }

      var re = new RegExp("(<([^>]+)>)", "ig");
      return str.replace(re, "");
    };
  })();

  navigate(page) {
    this.codeService.setSelectedEp(this.selectedEp);

    if (page == "today") {
      this.router.navigate(["/basic"], {
        queryParams: {
          ep: this.selectedEp,
          dob: this.datePipe.transform(new Date(), "MM-dd"),
        },
      });
    } else if (page == "tomorrow") {
      this.router.navigate(["/basic"], {
        queryParams: {
          ep: this.selectedEp,
          dob: this.datePipe.transform(Date.now() + 86400000, "MM-dd"),
        },
      });
    }
  }

  navigateAction(member) {
    //this.codeService.setSelectedEp(this.selectedEp);
    if (
      member == "NEW MEMBER" ||
      member == "COUNSELLING MEMBER" ||
      member == "ENROLLED MEMBER" ||
      member == "ARRIVING MEMBER" ||
      member == "4all"
    ) {
      this.router.navigate(["/basic"], {
        queryParams: { action: "home", stage: member },
      });
    }
  }

  navigateActionWithEP(member) {
    this.codeService.setSelectedEp(this.selectedEp);
    if (
      member == "NEW MEMBER" ||
      member == "COUNSELLING MEMBER" ||
      member == "ENROLLED MEMBER" ||
      member == "ARRIVING MEMBER" ||
      member == "4all"
    ) {
      this.router.navigate(["/basic"], {
        queryParams: { action: "home", stage: member, ep: this.selectedEp },
      });
    }
  }

  navigateActionWithOnlyIdx(idx) {
    this.codeService.setSelectedEp(this.selectedEp);

    this.router.navigate(["/basic"], {
      queryParams: { action: "home", idx: idx, ep: this.selectedEp },
    });
  }

  navigateMember(member, idx) {
    this.codeService.setSelectedEp(this.selectedEp);

    if (
      member == "NEW MEMBER" ||
      member == "COUNSELLING MEMBER" ||
      member == "ENROLLED MEMBER" ||
      member == "ARRIVING MEMBER"
    ) {
      this.router.navigate(["/basic"], {
        queryParams: {
          action: "home",
          stage: member,
          idx: idx,
          ep: this.selectedEp,
        },
      });
    } else if (member == "Online Application") {
      if (idx == "1") {
        this.router.navigate(["/application"], {
          queryParams: { action: "home", idx: idx, ep: this.selectedEp },
        });
      } else {
        this.router.navigate(["/admission"], {
          queryParams: { action: "home", idx: idx, ep: this.selectedEp },
        });
      }
    } else if (member == "Accounting") {
      this.router.navigate(["/accounting"], {
        queryParams: { action: "home", idx: idx, ep: this.selectedEp },
      });
    }
  }

  navigateReport() {
    this.router.navigate(["/informationBoardDetail"], {
      queryParams: { action: "home" },
    });
  }

  getDate(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10);
    } else {
      return "";
    }
  }

  getDateTime(datetime) {
    if (datetime != undefined && datetime != null) {
      return datetime.substring(0, 10) + " " + datetime.substring(11, 16);
    } else {
      return "";
    }
  }

  navigateReportView(id) {
    this.router.navigate(["/informationBoardDetail", id]);
  }
}
