export class AdminHistoryComment {
    id?:string|number;   
    adminId?:string|number;
    commentBody?:string;
    createDate?:string;
    saveDate?:string;
    createUser?:string;
    saveUser?:string;
}

export class AdminHistoryCommentParams extends AdminHistoryComment {
}
