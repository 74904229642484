import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FlashMessagesService } from "angular2-flash-messages";
import { Profile } from "app/model/profile";
import * as FileSaver from "file-saver";
import * as _ from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { FileUploader } from "ng2-file-upload";
import { Popup } from "ng2-opd-popup";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter } from "rxjs/operators";
import { Agent } from "../../model/agent";
import { AgentFiles } from "../../model/agent-files";
import { AgentMemo } from "../../model/agent-memo";
import { CodeCommon } from "../../model/code-common";
import { AgentFilesService } from "../../services/agent-files.service";
import { AgentMemoService } from "../../services/agent-memo.service";
import { AgentService } from "../../services/agent.service";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
/*
import { Page } from "../../model/page";
import { AdmissionService } from "../../services/admission.service";
import { Admission, AdmissionSearchParams } from "../../model/admission";
import * as Collections from "typescript-collections";
import Utils from "../../shared/utils";
*/

import { Accounting, AccountingSearchParams } from "../../model/accounting";
import { Page } from "../../model/page";
import { AccountingService } from "../../services/accounting.service";
import Utils from "../../shared/utils";
import * as Collections from "typescript-collections";
const fixedSize = 100;
@Component({
  selector: "app-agent",
  providers: [AgentService, AgentMemoService, AgentFilesService, AccountingService],
  templateUrl: "./agent.component.html",
  styleUrls: ["./agent.component.css"],
})
export class AgentComponent implements OnInit {
  @ViewChild("popup1") popup1: Popup;
  popupMessage: string;

  agentNoteList: any[] = [
    { code: "Phone", value: "Phone" },
    { code: "Face", value: "Face" },
    { code: "Online", value: "Online" },
  ];

  public uploader: FileUploader = new FileUploader({ queueLimit: 999 });

  public agentFiles = new Array<AgentFiles>();

  createUpdateButtonDisabled: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "code",
    textField: "value",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 2,
    allowSearchFilter: true,
  };

  /*
  public page = new Page();
  public rows = new Array<Admission>();
  public userId: number = 0;
  public searchParams = new AdmissionSearchParams();
  codeCommon: CodeCommon;
  mySet = new Collections.Set<number>();
  mySet2 = new Collections.Set<string>();
  loadingIndicator: boolean = false;
  displaySearchValue: string;
  studentCount: number = 0;
  schoolCount: number = 0;
  selected = [];
*/
public searchParams = new AccountingSearchParams();
codeCommon: CodeCommon;
displaySearchValue: string;
public page = new Page();
public rows = new Array<Accounting>();
public userId: number = 0;
public setByLink: boolean = false;
public selectedRow = new Accounting();
filterList: any[] = [];
selected = [];
selectedInvoices = [];
showInvoice: boolean;
selectedAll: boolean = false;
selectedColumn: string;
totalDisplayweeks: number = 0;
totalDisplayfreeWeeks: number = 0;
totalDisplaySchoolFee: number = 0;
totalDisplayCommission: number = 0;
totalDisplaySubCommission: number = 0;
totalDisplayDc: number = 0;
totalDisplayProfit: number = 0;
action: string;
idx: string;
selectedEp: string;
loadingIndicator: boolean = false;
timeout: any;
mySet = new Collections.Set<number>();
studentCount: number = 0;
studentAverage: number = 0;
totalAverage: number = 0;
feeMatchedMissingOnly: boolean = false;
feeMatchedOnly: boolean = false;
isSearchEp: boolean = true;
disabledInvoice: boolean = true;
inoviceCnt: number = 0;
isInvoiced: boolean = false;
isCompleted: boolean = false;
disabledInvoiced: boolean = true;
disabledCompleted: boolean = true;
@ViewChild("filterModal") public filterModal: ModalDirective;

  selectedTab: number;
  processedCount = 0;
  totalFiles = 0;

  public searchTerm: string;

  public form: FormGroup;

  authority: string;
  name: string;
  company: string;

  public agents: Agent[] = [];
  public selectedAgent: Agent;

  epList: any[] = [];
  nationalityList: any[] = [];

  addNew = new Agent();

  emailEpList: any[] = [];
  disabledRequest: boolean = true;
  
  //1113 loading add
  isLoading : boolean  = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private agentFilesService: AgentFilesService,
    formBuilder: FormBuilder,
    private agentService: AgentService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private codeService: CodeService,
    private agentMemoService: AgentMemoService,
    private route: ActivatedRoute,
    private location: Location,
    private _flashMessagesService: FlashMessagesService,
    private accountingService: AccountingService
    //private admissionService: AdmissionService
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });

    this.form = formBuilder.group({
      id: formBuilder.control(null, []),
      name: formBuilder.control(null, [Validators.required]),
      phone: formBuilder.control(null, []),
      marketCountry: formBuilder.control(null, []),
      basedCountry: formBuilder.control(null, []),
      basedCity: formBuilder.control(null, []),
      representative: formBuilder.control(null, []),
      email: formBuilder.control(null, []),
      managingEP: formBuilder.control(null, []),
      active: formBuilder.control(true, []),
      memo: formBuilder.control(null, []),
      agentCode: formBuilder.control(null, []),
      commissionRateNZ: formBuilder.control(null, []),
      commissionRateAU: formBuilder.control(null, []),
    });

    codeService.getCommon(this);
  }

  onFileWithoutDeleting(agentFiles: AgentFiles[]): AgentFiles[] {
    let result: AgentFiles[] = [];

    for (var i = 0; agentFiles != undefined && i < agentFiles.length; i++) {
      if (agentFiles[i].mode === undefined || agentFiles[i].mode == "") {
        result.push(agentFiles[i]);
      }
    }
    return result;
  }

  readQueue(action) {
    for (var idx = 0; idx < this.uploader.queue.length; idx++) {
      this.readFile(action, this.uploader.queue[idx]._file);
    }
  }

  readFile(action, f) {
    var fileReader = new FileReader();

    var that = this;
    fileReader.onload = function () {
      //console.log("content   : " + window.btoa(fileReader.result));
      that.addFile(
        action,
        window.btoa(fileReader.result as string),
        f.name,
        f.size
      );
    };

    fileReader.readAsBinaryString(f);
  }

  addFile(action, result, filename, filesize) {
    let informationFile = new AgentFiles();

    informationFile.filesize = filesize;
    informationFile.filename = filename;
    informationFile.file = result;
    informationFile.mode = "I";

    this.agentFiles.push(informationFile);
    this.processedCount++;

    this.agentFiles.forEach(function (elem) {
      //console.log("File Name : " + elem.filename);
    });

    if (this.processedCount == this.totalFiles) {
      this.onSubmitWroker(action);
    }
  }

  selectTab(idx: number) {
    this.selectedTab = idx;
  }

  clickOKButton() {
    if (this.popupMessage === "Would you like to create this agent memo?") {
      this.onCreateAgentMemo();
    } else if (
      this.popupMessage === "Would you like to delete selected accounting memo?"
    ) {
      this.deleteAgentMemo();
    }

    this.popup1.hide();
  }

  setCodeList(codeCommon: CodeCommon) {   
    if (codeCommon == undefined) {
    this.router.navigate(["/logout"]);
    }
  
    if (this.searchParams.fromIntakeDate1 == undefined)
    {
      //240425 accounting from home eplist with searchparam
      //240403 henry init add after reset
        this.searchParams = new AccountingSearchParams();
        this.codeCommon = this.codeService.getOrg();  
    }
    else
    {
      this.codeCommon = codeCommon; 
    } 

    this.feeMatchedOnly = false;
    this.feeMatchedMissingOnly = false;    
    this.searchParams.fromIntakeDate1 = undefined;

    this.codeCommon.accountStatusList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.epList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.schoolTypeList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.memberCategoryList.forEach((x) => {
      x.checked = false;
    });
    this.searchParams.fromLapsedDate1 = undefined;
    this.searchParams.toLapsedDate1 = undefined;

    this.searchParams.fromIntakeDate1 = undefined;
    this.searchParams.toIntakeDate1 = undefined;

    //0319 dropdown menu 3ea fixed
    this.epList = codeCommon.epList;
    console.log("0319 codeCommon.epList update:", JSON.stringify(codeCommon.epList));
    this.nationalityList = codeCommon.nationalityList;
    console.log("0319 codeCommon.nationalityList update:", JSON.stringify(codeCommon.nationalityList));

    //this.searchAccounting();
    if(this.selectedAgent != undefined && this.selectedAgent.name.length>0) {
      console.log("0130 selectedAgent update:", this.selectedAgent.name);
      this.codeCommon.subAgentList.forEach((x) => {        
        if (x.value == this.selectedAgent.name) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
      this.codeCommon.adminStatusList.forEach((x) => {
        x.checked = false;
      });
      this.searchAccounting();
    } 
  }

  searchAccounting() {
    this.disabledInvoice = true;
    this.disabledInvoiced = true;
    this.disabledCompleted = true;

    this.rows = new Array<Accounting>();
    this.page = new Page();

    this.page.pageNumber = 0;
    this.page.size = fixedSize;

    if (this.searchParams.fromAppliedDate1 != undefined)
      this.searchParams.fromAppliedDate = Utils.formatDateStr(
        this.searchParams.fromAppliedDate1
      );
    //else this.searchParams.fromAppliedDate = "";
    if (this.searchParams.toAppliedDate1 != undefined)
      this.searchParams.toAppliedDate = Utils.formatDateStr(
        this.searchParams.toAppliedDate1
      );
    //else this.searchParams.toAppliedDate = "";
    if (this.searchParams.fromIntakeDate1 != undefined)
      this.searchParams.fromIntakeDate = Utils.formatDateStr(
        this.searchParams.fromIntakeDate1
      );
    //else this.searchParams.fromIntakeDate = "";
    if (this.searchParams.toIntakeDate1 != undefined)
      this.searchParams.toIntakeDate = Utils.formatDateStr(
        this.searchParams.toIntakeDate1
      );
    //else this.searchParams.toIntakeDate = "";
    if (this.searchParams.fromCompletedDate1 != undefined)
      this.searchParams.fromCompletedDate = Utils.formatDateStr(
        this.searchParams.fromCompletedDate1
      );
    else this.searchParams.fromCompletedDate = "";
    if (this.searchParams.toCompletedDate1 != undefined)
      this.searchParams.toCompletedDate = Utils.formatDateStr(
        this.searchParams.toCompletedDate1
      );
    else this.searchParams.toCompletedDate = "";
    if (this.searchParams.fromLapsedDate1 != undefined)
      this.searchParams.fromLapsedDate = Utils.formatDateStr(
        this.searchParams.fromLapsedDate1
      );
    else this.searchParams.fromLapsedDate = "";
    if (this.searchParams.toLapsedDate1 != undefined)
      this.searchParams.toLapsedDate = Utils.formatDateStr(
        this.searchParams.toLapsedDate1
      );
    else this.searchParams.toLapsedDate = "";

    this.selected = [];

    this.search();
  }
      
  search() {
        this.searchParams.ciMemberCategory = this.codeCommon.memberCategoryList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.schoolType = this.codeCommon.schoolTypeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.school = this.codeCommon.schoolNameListIncFalse
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.baseCourse1 = this.codeCommon.courseList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.cpSubAgent = this.codeCommon.subAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.adminAgent = this.codeCommon.adminAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
       
    if (!this.setByLink) {
      this.searchParams.accountStatus = this.codeCommon.accountStatusList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    }

    if (this.feeMatchedMissingOnly == true) {
      this.searchParams.specialMode = "1";
    } else if (this.feeMatchedOnly == true) {
      this.searchParams.specialMode = "2";
    } else {
      this.searchParams.specialMode = "";
    }

    this.displaySearchValue = "";
    if (this.searchParams.ciMemberCategory.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " M Category in " +
        JSON.stringify(
          this.codeCommon.memberCategoryList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.schoolType.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School Type in " +
        JSON.stringify(
          this.codeCommon.schoolTypeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.school.length > 0) {        
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School in " +
        JSON.stringify(
          this.codeCommon.schoolNameListIncFalse
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
            }
    if (this.searchParams.baseCourse1.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Course in " +
        JSON.stringify(
          this.codeCommon.courseList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.cpSubAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Sub Agent in " +
        JSON.stringify(
          this.codeCommon.subAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.adminAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Admin Agent in " +
        JSON.stringify(
          this.codeCommon.adminAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.accountStatus.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Status in " +
        JSON.stringify(
          this.codeCommon.accountStatusList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    //0205 delete manager limited
    this.searchParams.cpEp = this.codeCommon.epList
          .filter((opt) => opt.checked)
          .map((opt) => opt.code);
    /*
    if (!this.setByLink) {
      if (this.authority == "accountant" || this.authority == "manager") {
        this.searchParams.cpEp = this.codeCommon.epList
          .filter((opt) => opt.checked)
          .map((opt) => opt.code);
      } else {
        this.searchParams.cpEp = this.codeCommon.epList
          .filter((opt) => opt.value == this.name)
          .map((opt) => opt.code);
      }
    }*/
    if (this.searchParams.cpEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Acc EP in " +
        JSON.stringify(
          this.codeCommon.epList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    this.loadingIndicator = true;
    this.studentCount = 0;
    this.studentAverage = 0;
    this.totalAverage = 0;

    this.accountingService
      .searchAccounting(this.searchParams, this.page)
      .subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.content : " + data.content.length);

          this.totalDisplayweeks = 0;
          this.totalDisplayfreeWeeks = 0;
          this.totalDisplaySchoolFee = 0;
          this.totalDisplayCommission = 0;
          this.totalDisplaySubCommission = 0;
          this.totalDisplayDc = 0;
          this.totalDisplayProfit = 0;

          this.rows = new Array<Accounting>();

          this.mySet = new Collections.Set<number>();

          data.content.forEach((accounting) => {
            if (
              this.selected == undefined ||
              this.selected.length == 0 ||
              this.selected.some((x) => x.id == accounting.id)
            ) {
              this.rows.push(accounting);

              if (accounting.superId == 1) {
                this.totalDisplayweeks =
                  this.totalDisplayweeks + +accounting.weeks;
                this.totalDisplayfreeWeeks =
                  this.totalDisplayfreeWeeks + +accounting.freeWeeks;
              }
              this.totalDisplaySchoolFee =
                this.totalDisplaySchoolFee + accounting.displaySchoolFee;
              this.totalDisplayCommission =
                this.totalDisplayCommission + accounting.displayCommission;
              this.totalDisplaySubCommission =
                this.totalDisplaySubCommission +
                accounting.displaySubCommission;
              this.totalDisplayDc = this.totalDisplayDc + accounting.displayDc;
              this.totalDisplayProfit =
                this.totalDisplayProfit + accounting.displayProfit;

              this.mySet.add(accounting.basicId);
            }
          });

          //this.loadingIndicator   = false;
          setTimeout(() => {
            this.loadingIndicator = false;
          }, 1500);
          this.studentCount = this.mySet.size();
          if (data.totalElements > 0 && this.studentCount > 0) {
            this.totalAverage = this.totalDisplayProfit / data.totalElements;
            this.studentAverage = this.totalDisplayProfit / this.studentCount;
          }

          let addLine = 0;
          if (this.rows.length > 0) {
            let acc = new Accounting();
            acc.weeks = "" + this.totalDisplayweeks;
            acc.freeWeeks = "" + this.totalDisplayfreeWeeks;
            acc.displaySchoolFee = this.totalDisplaySchoolFee;
            acc.displayCommission = this.totalDisplayCommission;
            acc.displaySubCommission = this.totalDisplaySubCommission;
            acc.displayDc = this.totalDisplayDc;
            acc.displayProfit = this.totalDisplayProfit;
            this.rows.push(acc);
            addLine = 1;
          }

          this.page.totalElements = data.totalElements + addLine;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;

          this.selected = [];
        },
        (error) => {
          console.log("error : ", error);
          this.loadingIndicator = false;
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );    
  }
    onFilter(column) {
      //if(1==1) return;
  
      this.selectedColumn = column;
      if (column == "M Category") {
        this.filterList = this.codeCommon.memberCategoryList;
      } else if (column == "School Type") {
        this.filterList = this.codeCommon.schoolTypeList;
      } else if (column == "Acc EP") {
        this.filterList = this.codeCommon.epList;
      } else if (column == "School") {
        this.filterList =
          this.codeService.codeCommon.schoolNameListIncFalse.filter((code) =>
            this.rows.some((x) => x.school == code.code)
          );
      } else if (column == "Course") {
        //this.filterList = this.codeCommon.courseList;
  
        this.filterList = this.codeService.codeCommon.courseList.filter((code) =>
          this.rows.some((x) => x.baseCourse1 == code.code)
        );
      } else if (column == "Sub Agent") {
        //this.filterList = this.codeCommon.subAgentList;
  
        this.filterList = this.codeService.codeCommon.subAgentList.filter(
          (code) => this.rows.some((x) => x.cpSubAgent == code.code)
        );
      } else if (column == "Admin Agent") {
        this.filterList = this.codeCommon.adminAgentList;
      } else if (column == "Status") {
        this.filterList = this.codeCommon.accountStatusList;
      } else {
        this.filterList = [];
      }
  
      if (
        this.filterList.some((x) => x.checked == undefined || x.checked == false)
      ) {
        this.selectedAll = false;
      } else {
        this.selectedAll = true;
      }
  
      this.filterModal.show();
    }
  onPage(event) {}
  onSelect({ selected }) {
    //console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);

    this.inoviceCnt = 0;
    this.selected.forEach((x) => {
      if (x.accountStatus == "Pending") {
        this.inoviceCnt = this.inoviceCnt + 1;
      }
    });

    if (this.inoviceCnt > 0 && this.inoviceCnt == this.selected.length) {
      this.disabledInvoice = false;
    } else {
      this.disabledInvoice = true;
    }

    if (this.isInvoiced || this.isCompleted) {
      if (this.selected.length > 0) {
        this.disabledInvoiced = false;
        this.disabledCompleted = false;
      } else {
        this.disabledInvoiced = true;
        this.disabledCompleted = true;
      }
    } else {
      this.disabledInvoiced = true;
      this.disabledCompleted = true;
    }
  }

  onActivate(obj) {
    if (obj.type === "click") {
      if (obj.event.altKey) {
        window.open(
          `${window.location.origin}/accountingDetail/${obj.row.basicId}`,
          "_blank"
        );
      } else {
        this.selectedRow = obj.row;
      }
    } else if (obj.type === "dblclick") {
      this.codeService.keepSearchParams("4", this.searchParams);
      this.router.navigate(["/accountingDetail", obj.row.basicId, obj.row.id]);
    }
  }
  

/*
  setCodeList(codeCommon: CodeCommon) {
    
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }

    this.codeCommon = codeCommon; //0129

    this.epList = codeCommon.epList;


    this.nationalityList = codeCommon.nationalityList;

    this.emailEpList = JSON.parse(JSON.stringify(codeCommon.epList));

    
    this.codeCommon.memberCategoryList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.stageList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.epList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.adminEpList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.adminAgentList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.schoolTypeList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.schoolNewList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.courseList.forEach((x) => {
      x.checked = false;
    });

    this.codeCommon.adminStatusList.forEach((x) => {
      x.checked = false;
    });
    this.codeCommon.subAgentList.forEach((x) => {
      x.checked = false;
    });

    this.searchParams.fromLastCrm1 = undefined;
    this.searchParams.toLastCrm1 = undefined;

    this.searchParams.fromIntakeDate1 = undefined;
    this.searchParams.toIntakeDate1 = undefined;

    this.searchParams.fromCourseEndDate1 = undefined;
    this.searchParams.toCourseEndDate1 = undefined;

    this.searchParams.fromNextCrm1 = undefined;
    this.searchParams.toNextCrm1 = undefined;

    this.searchParams.fromHoldingDate1 = undefined;
    this.searchParams.toHoldingDate1 = undefined;

    this.codeCommon.epList.forEach((x) => {
      x.checked = false;
    });
    
    this.codeCommon.adminEpList.forEach((x) => {
      x.checked = false;
    });   0131*/ 
    /*
    this.codeCommon.adminStatusList.forEach((x) => {
      if (
        x.code == "2_AS" ||
        x.code == "3_PS" ||
        x.code == "4_CO" ||
        x.code == "6_UO"
      ) {
        x.checked = true;
      } else {
        x.checked = false;
      }
    });   
   */ 
  /*        
    console.log("0129 selectedAgent update:", this.selectedAgent);
    if(this.selectedAgent != undefined && this.selectedAgent.name.length>0) {
      console.log("0130 selectedAgent update:", this.selectedAgent.name);
      this.codeCommon.subAgentList.forEach((x) => {        
        if (x.value == this.selectedAgent.name) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
      this.codeCommon.adminStatusList.forEach((x) => {
        x.checked = false;
      });
      this.searchAdmission();
    } 
    //console.log("Total Records Count (Before subagentlist):", this.codeCommon.adminStatusList);
    //console.log("0129! setCodeList cpSubAgent : " + JSON.stringify(this.codeCommon.subAgentList.filter(x => x.checked)));
    //this.searchAdmission();
  }*/
/*
  searchAdmission() {
    
    this.rows = new Array<Admission>();
    this.page = new Page();

    this.page.pageNumber = 0;
    this.page.size = 30;  
    
    if (this.searchParams.fromNextCrm1 != undefined)
      this.searchParams.fromNextCrm = Utils.formatDateStr(
        this.searchParams.fromNextCrm1
      );
    else this.searchParams.fromNextCrm = "";
    if (this.searchParams.toNextCrm1 != undefined)
      this.searchParams.toNextCrm = Utils.formatDateStr(
        this.searchParams.toNextCrm1
      );
    else this.searchParams.toNextCrm = "";
    if (this.searchParams.fromIntakeDate1 != undefined)
      this.searchParams.fromIntakeDate = Utils.formatDateStr(
        this.searchParams.fromIntakeDate1
      );
    else this.searchParams.fromIntakeDate = "";
    if (this.searchParams.toIntakeDate1 != undefined)
      this.searchParams.toIntakeDate = Utils.formatDateStr(
        this.searchParams.toIntakeDate1
      );
    else this.searchParams.toIntakeDate = "";
    if (this.searchParams.fromLastCrm1 != undefined)
      this.searchParams.fromLastCrm = Utils.formatDateStr(
        this.searchParams.fromLastCrm1
      );
    else this.searchParams.fromLastCrm = "";
    if (this.searchParams.toLastCrm1 != undefined)
      this.searchParams.toLastCrm = Utils.formatDateStr(
        this.searchParams.toLastCrm1
      );
    else this.searchParams.toLastCrm = "";
    if (this.searchParams.fromCourseEndDate1 != undefined)
      this.searchParams.fromCourseEndDate = Utils.formatDateStr(
        this.searchParams.fromCourseEndDate1
      );
    else this.searchParams.fromCourseEndDate = "";
    if (this.searchParams.toCourseEndDate1 != undefined)
      this.searchParams.toCourseEndDate = Utils.formatDateStr(
        this.searchParams.toCourseEndDate1
      );
    else this.searchParams.toCourseEndDate = "";
    if (this.searchParams.fromHoldingDate1 != undefined)
      this.searchParams.fromHoldingDate = Utils.formatDateStr(
        this.searchParams.fromHoldingDate1
      );
    else this.searchParams.fromHoldingDate = "";
    if (this.searchParams.toHoldingDate1 != undefined)
      this.searchParams.toHoldingDate = Utils.formatDateStr(
        this.searchParams.toHoldingDate1
      );
    else this.searchParams.toHoldingDate = "";
    this.search();
  }

  search() {  
     
    this.searchParams.ciMemberCategory = this.codeCommon.memberCategoryList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.ciStage = this.codeCommon.stageList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);

      //console.log("0129! setCodeList cpSubAgent : " + JSON.stringify(this.codeCommon.subAgentList));
      //console.log("0129 searchAdmission selectAgent name : " + (this.selectedAgent ? this.selectedAgent.name : 'selectedAgent is null'));

    this.searchParams.cpSubAgent = (this.codeCommon.subAgentList || [])
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    //this.searchParams.cpEp                = this.epList.filter(opt => opt.checked).map(opt => opt.code);
    
    this.searchParams.adminEp = (this.codeCommon.adminEpList || [])
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);  
    this.searchParams.adminAgent = this.codeCommon.adminAgentList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.schoolType = this.codeCommon.schoolTypeList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.school = this.codeCommon.schoolNameListIncFalse
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);
    this.searchParams.baseCourse1 = this.codeCommon.courseList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);     
    this.searchParams.adminStatus = this.codeCommon.adminStatusList
      .filter((opt) => opt.checked)
      .map((opt) => opt.code);    

    this.displaySearchValue = "";
    if (this.searchParams.ciMemberCategory.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " M Category in " +
        JSON.stringify(
          this.codeCommon.memberCategoryList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.ciStage.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Stage in " +
        JSON.stringify(
          this.codeCommon.stageList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.adminEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Admin EP in " +
        JSON.stringify(
          this.codeCommon.adminEpList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.adminAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Admin Agent in " +
        JSON.stringify(
          this.codeCommon.adminAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.schoolType.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School Type in " +
        JSON.stringify(
          this.codeCommon.schoolTypeList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.school.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " School in " +
        JSON.stringify(
          this.codeCommon.schoolNameListIncFalse
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.baseCourse1.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Course in " +
        JSON.stringify(
          this.codeCommon.courseList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }
    if (this.searchParams.cpSubAgent.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Sub Agent in " +
        JSON.stringify(
          this.codeCommon.subAgentList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    if (this.searchParams.adminStatus.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " Status in " +
        JSON.stringify(
          this.codeCommon.adminStatusList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    if (
      this.authority == "admin" ||
      this.authority == "accountant" ||
      this.authority == "manager"
    ) {
      this.searchParams.cpEp = this.codeCommon.epList
        .filter((opt) => opt.checked)
        .map((opt) => opt.code);
    } else {
      this.searchParams.cpEp = this.codeCommon.epList
        .filter((opt) => opt.value == this.name)
        .map((opt) => opt.code);
    }
    
    if (this.searchParams.cpEp.length > 0) {
      if (this.displaySearchValue != "")
        this.displaySearchValue = this.displaySearchValue + ",";
      this.displaySearchValue =
        this.displaySearchValue +
        " EP in " +
        JSON.stringify(
          this.codeCommon.epList
            .filter((opt) => opt.checked)
            .map((opt) => opt.value)
        );
    }

    this.loadingIndicator = true;
    this.studentCount = 0;
    this.schoolCount = 0;
    this.selected = [];
    //console.log("0129 before service this.searchParams : ", JSON.stringify(this.searchParams));
    this.admissionService
      .searchAdmission(this.searchParams, this.page)
      .subscribe(
        (data) => {
          //console.log("0129 admissionService success!! : ");
          this.mySet = new Collections.Set<number>();
          this.mySet2 = new Collections.Set<string>();
          // this.mySet3             = new Collections.Set<number>();
                    
          data.content.forEach((admission) => {
            //if(!this.mySet3.contains(admission.id)) {
            this.rows.push(admission);
            //}
            this.mySet.add(admission.basicId);
            this.mySet2.add(admission.basicId + "|" + admission.school);

            //this.mySet3.add(admission.id);
          });

          this.page.totalElements = data.totalElements;
          this.page.totalPages = data.totalPages;
          this.page.pageNumber = data.number;

          this.rows = [...this.rows];          //this.loadingIndicator   = false;
          setTimeout(() => {
            this.loadingIndicator = false;
          }, 1500);
          this.studentCount = this.mySet.size();
          this.schoolCount = this.mySet2.size();
        },
        (error) => {
          //console.log("0129 admissionService fail!! : ");
          console.log("error : ", error);

          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          } else {
            setTimeout(() => {
              this.loadingIndicator = false;
            }, 1500);
          }
        }
      );
  }
  onPage(event) {}

  onActivate(obj) {
    //console.log("0129 onActivate : ");
    if (obj.type === "click" && obj.event.altKey) {
      window.open(
        `${window.location.origin}/admissionDetail/${obj.row.basicId}`,
        "_blank"
      );
    } else if (obj.type === "dblclick") {
      //console.log('Activate Event', obj);
      //console.log('Type', obj.type);
      //console.log('basicId', obj.row.basicId);
      this.codeService.keepSearchParams("3", this.searchParams);
      this.router.navigate(["/admissionDetail", obj.row.basicId, obj.row.id]);
    }
  }
*/
  onSaveFile(fileContent, fileName) {
    var byteContents = window.atob(fileContent);
    var byteNumbers = new Array(byteContents.length);

    for (var i = 0; i < byteContents.length; i++) {
      byteNumbers[i] = byteContents.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray]);
    FileSaver.saveAs(blob, fileName);
  }
  //1113 preview
  openFile(fileContent: string, fileName: string): void {
    console.log('fileContent:', fileContent);
    console.log('fileName:', fileName);
    // 파일 확장자를 기반으로 MIME 타입 결정
    const extension = fileName.split('.').pop().toLowerCase();
    let mimeType = 'application/octet-stream'; // 기본값
    switch (extension) {
        case 'pdf':
            mimeType = 'application/pdf';
            break;
        case 'png':
            mimeType = 'image/png';
            break;
        case 'jpg':
        case 'jpeg':
            mimeType = 'image/jpeg';
            break;
        case 'gif':
            mimeType = 'image/gif';
            break;
        // 여기에 필요한 다른 파일 형식을 추가할 수 있습니다.
    }
    console.log('mimeType:', mimeType);
    // fileContent를 Blob으로 변환
    const byteCharacters = atob(fileContent);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Blob URL 생성
    const blobUrl = URL.createObjectURL(blob);
    console.log('Url:', blobUrl);
    // 파일을 브라우저로 열기 위한 링크 생성
    const link = document.createElement('a');
    link.href = blobUrl;
    link.target = '_blank'; // 새 창에서 열도록 설정
    document.body.appendChild(link); // DOM에 링크 요소 추가
    link.click();
    document.body.removeChild(link); // 사용 후 링크 요소 제거
  }

  setInit() {
    this.selectedTab = 0;

    if (
      this.selectedAgent != null &&
      this.selectedAgent.id != null &&
      this.selectedAgent.id != undefined
    ) {
      this.agentMemoService.getList(this.selectedAgent.id).subscribe(
        (data) => {
          this.selectedAgentMemoRow = new AgentMemo();
          this.agentMemoRows = new Array<AgentMemo>();
          data.forEach((agentMemo) => this.agentMemoRows.push(agentMemo));

          this.getFiles();
        },
        (error) => {
          console.log("error : ", error);
          if (error.status == "401") {
            this.router.navigate(["/logout"]);
          }
        }
      );
    }
  }

  onDeleteFile(fileName) {
    this.agentFiles.find(function (item) {
      return item.filename === fileName;
    }).mode = "D";
  }

  getFiles() {
    if (this.selectedAgent.id !== undefined) {
      this.isLoading = true;
      this.agentFilesService.getList(this.selectedAgent.id).subscribe(
        (data) => {
          //console.log("data : " + JSON.stringify(data));
          //console.log("data.length : " + data.length);

          this.agentFiles = new Array<AgentFiles>();
          data.forEach((file) => this.agentFiles.push(file));
          this.isLoading = false;
        },
        (error) => {
          console.log("error : ", error);
        }
      );
    }
  }

  ngOnInit() {
    this.agentService.getAll().subscribe((data) => {
      this.agents = data.content;
      this.agents = this.agents.sort((n1, n2) => {
        if (n1.active > n2.active) {
          return -1;
        }

        if (n1.active < n2.active) {
          return 1;
        }

        return 0;
      });
    });

    this.addNew.name = "[Add New]";
    this.addNew.id = null;
    this.addNew.active = true;

    this.agents.push(this.addNew);

    this.setInit();
  }

  onSubmit(agent: Agent) {
    this.createUpdateButtonDisabled = true;

    if (agent.active == null || agent.active == undefined) agent.active = true;

    this.totalFiles = this.uploader.queue.length;

    if (this.totalFiles > 0) {
      this.readQueue(agent);
    } else {
      this.onSubmitWroker(agent);
    }
  }

  onSubmitWroker(agent: Agent) {
    agent.agentFilesParams = new Array<AgentFiles>();

    for (var i = 0; i < this.agentFiles.length; i++) {
      agent.agentFilesParams.push(this.agentFiles[i]);
    }

    agent.marketCountry =
      _.size(agent.marketCountry) > 0
        ? _.map(agent.marketCountry, (r) => r.code).join(", ")
        : "";

    if (agent.id) {
      this.agentService.update(agent).subscribe((data) => {
        this.agents = _.map(this.agents, (agent) => {
          return agent.id === data.id ? data : agent;
        });

        this.uploader = new FileUploader({ queueLimit: 999 });
        this.processedCount = 0;
        this.totalFiles = 0;
        this.createUpdateButtonDisabled = false;
      });

      this.popupMessage = "Succeed in updating";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c", // red, blue....
        widthProsentage: 20, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.agentService.create(agent).subscribe((data) => {
        this.agents = [data, ...this.agents];

        this.uploader = new FileUploader({ queueLimit: 999 });
        this.processedCount = 0;
        this.totalFiles = 0;
        this.createUpdateButtonDisabled = false;
      });

      this.popupMessage = "Succeed in creating";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c", // red, blue....
        widthProsentage: 20, // The with of the popou measured by browser width
        animationDuration: 0, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "OK", // The text on your confirm button
        cancleBtnContent: "Cancel", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "one-button-hidden", // you class for styling the cancel button
        //animation: "fadeInDown" // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      };

      this.popup1.show(this.popup1.options);
    }
  }

  selectAgent(agent: Agent) {
    
    this.selectedAgent = agent;
    //console.log("0129 selectAgent : ",JSON.stringify(this.selectedAgent));
    if (agent) {
      this.form.patchValue({
        id: agent.id,
        name: agent.name,
        phone: agent.phone,
        marketCountry: _.isArray(agent.marketCountry)
          ? agent.marketCountry
          : agent.marketCountry && agent.marketCountry.length > 0
          ? (<string>agent.marketCountry)
              .split(", ")
              .map((item) =>
              _.find(this.nationalityList, (c) => c && c.code === item) || null)
              .filter((c) => c !== null) // 0129 fixed undefined 값 제거
          : [],

        basedCountry: agent.basedCountry,
        basedCity: agent.basedCity,
        representative: agent.representative,
        email: agent.email,
        managingEP: agent.managingEP,
        active: agent.active,
        memo: agent.memo,
        agentCode: agent.agentCode,
        commissionRateNZ: agent.commissionRateNZ,
        commissionRateAU: agent.commissionRateAU,
      });
    } else {
      agent = new Agent();
      this.selectedAgent = agent;

      this.form.patchValue({
        id: agent.id,
        name: agent.name,
        phone: agent.phone,
        marketCountry: agent.marketCountry,
        basedCountry: agent.basedCountry,
        basedCity: agent.basedCity,
        representative: agent.representative,
        email: agent.email,
        managingEP: agent.managingEP,
        active: agent.active,
        memo: agent.memo,
        agentCode: agent.agentCode,
        commissionRateNZ: agent.commissionRateNZ,
        commissionRateAU: agent.commissionRateAU,
      });
    }
    
    this.setInit();
    
    //console.log("0129 searchAdmission selectAgent name : " + (this.codeCommon.subAgentList ? this.codeCommon.subAgentList : 'selectedAgent is null'));
    
    if(this.selectedAgent != undefined && this.codeCommon != undefined){
      this.codeCommon.subAgentList.forEach((x) => {      
        if (x.value == this.selectedAgent.name) {
          x.checked = true;
        } else {
          x.checked = false;
        }
      });
      this.codeCommon.adminStatusList.forEach((x) => {
        x.checked = false;
      });
      this.searchAccounting();

    }
    //console.log("0129!!!! setInit cpSubAgent : " + JSON.stringify(this.codeCommon.subAgentList));
    //console.log("0131 selectAgent codeCommon.subAgentList : " + JSON.stringify(this.codeCommon.subAgentList.filter(x => x.checked)));
    //console.log("0131 selectAgent searchParams.cpSubAgent : " + this.searchParams.cpSubAgent);    
    //0129
    
  }
    
  /* memo part */

  public agentMemoRows = new Array<AgentMemo>();
  public selectedAgentMemoRow = new AgentMemo();
  @ViewChild("agentMemoModal") public agentMemoModal: ModalDirective;

  onCreateAgentMemoMsg() {
    this.popupMessage = "Would you like to create this agent memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  onDeleteAgentMemo() {
    this.popupMessage = "Would you like to delete selected accounting memo?";

    this.popup1.options = {
      header: "Information",
      color: "#5cb85c",
      widthProsentage: 50,
      animationDuration: 0,
      showButtons: true,
      confirmBtnContent: "OK",
      cancleBtnContent: "Cancel",
      confirmBtnClass: "btn btn-default",
      cancleBtnClass: "btn btn-default",
    };

    this.popup1.show(this.popup1.options);
  }

  deleteAgentMemo() {
    this.agentMemoService.delete(this.selectedAgentMemoRow).subscribe(
      (data) => {
        this.popupMessage = "Succeed in deleting";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);

        this.agentMemoRows = new Array<AgentMemo>();
        this.setInit();
      },
      (error) => {
        console.log(error);
        this.popupMessage = "Fail to delete";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  onSelectAgentMemo({ selected }) {
    //console.log('Select Event', selected);
  }

  onActivateAgentMemo(obj) {
    console.log("0131 onActivate : "+obj.type)
    if (obj.type === "click") {
      //console.log("clicked");

      this.agentFiles = new Array<AgentFiles>();

      this.uploader = new FileUploader({ queueLimit: 999 });
      this.processedCount = 0;
      this.totalFiles = 0;

      this.selectedAgentMemoRow = obj.row;
    }
  }

  onValidateAgentMemo() {
    this.popupMessage = "";

    //if (this.selectedAgentMemoRow.agentNote === undefined || this.selectedAgentMemoRow.agentNote === '') {
    //  this.popupMessage = "Agent Note is required";
    //}

    if (this.popupMessage !== "") {
      this.popup1.options = {
        header: "Error",
        color: "red",
        widthProsentage: 40,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
      return false;
    }

    return true;
  }

  onCreateAgentMemo() {
    if (!this.onValidateAgentMemo()) return;

    this.selectedAgentMemoRow.agentId = this.selectedAgent.id;
    this.selectedAgentMemoRow.ep = this.name;
    this.selectedAgentMemoRow.emailAlarm = this.selectedEmailEp.toString();

    this.selectedAgentMemoRow.agentNote = this.selectedAgentMemoRow.agentNote;

    this.agentMemoService.create(this.selectedAgentMemoRow).subscribe(
      (data) => {
        this.agentMemoModal.hide();

        //staticModal.show();
        //alert("update is successful");
        this.popupMessage = "Succeed in creating a memo";

        this.popup1.options = {
          header: "Information",
          color: "#5cb85c",
          widthProsentage: 30,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
        //setTimeout(()=>{this.popup1.hide()},1000);
        //setTimeout(()=>{this.find(data.id)},1000);

        //console.log("전:" + this.selectedAgent.id);

        this.selectedAgent.id = data.agentId;

        //console.log("후:" + this.selectedAgent.id);

        this.setInit();
      },
      (error) => {
        console.log(error);
        this.agentMemoModal.hide();

        this.popupMessage = "Fail to create";

        this.popup1.options = {
          header: "Error",
          color: "red",
          widthProsentage: 20,
          animationDuration: 0,
          showButtons: true,
          confirmBtnContent: "OK",
          cancleBtnContent: "Cancel",
          confirmBtnClass: "btn btn-default",
          cancleBtnClass: "one-button-hidden",
        };

        this.popup1.show(this.popup1.options);
      }
    );
  }

  get selectedEmailEp() {
    return this.emailEpList
      .filter((opt) => opt.checked)
      .map((opt) => opt.value);
  }

  showAgentMemoModal(): void {
    if (
      this.selectedAgent === undefined ||
      this.selectedAgent.id === undefined
    ) {
      this.popupMessage = "Please click an agent above to add new memo ";

      this.popup1.options = {
        header: "Information",
        color: "#5cb85c",
        widthProsentage: 55,
        animationDuration: 0,
        showButtons: true,
        confirmBtnContent: "OK",
        cancleBtnContent: "Cancel",
        confirmBtnClass: "btn btn-default",
        cancleBtnClass: "one-button-hidden",
      };

      this.popup1.show(this.popup1.options);
    } else {
      this.selectedAgentMemoRow = new AgentMemo();

      //this.resetCheckedEmail();

      this.agentMemoModal.show();
    }
  }

  hideAgentMemoModal(): void {
    this.agentMemoModal.hide();
  }

  goBack(): void {
    this.location.back();
  }
}
