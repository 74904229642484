export class Invoice {
  id: string | number;
  invoiceNo?: string;
  schoolName?: string;
  studentName?: string;
  studentId?: string;
  dob?: string;
  startingDate?: string;
  courseOfStudy?: string;
  courseFees?: number;
  commission?: number;
  adminId?: number;
  bucket?: string;
  invoiceImage?: any[];
}

export class InvoiceParams extends Invoice {
}


