import { NgbDateStruct          } from '@ng-bootstrap/ng-bootstrap';

export class AdmissionSearchParams {
    public searchKey?         :string = "";
    public searchValue?       :string = "";
    public fromNextCrm?       :string = "";  
    public toNextCrm?         :string = "";
    public fromLastCrm?       :string = "";  
    public toLastCrm?         :string = "";
    public fromIntakeDate?    :string = "";    
    public toIntakeDate?      :string = "";  
    public fromCourseEndDate? :string = "";  
    public toCourseEndDate?   :string = "";     
    public fromHoldingDate? :string = "";  
    public toHoldingDate?   :string = "";   
    
    public ciMemberCategory   :any[];
    public ciStage            :any[];
    public cpSubAgent         :any[];
    public cpEp               :any[];
    public adminEp            :any[];
    public adminAgent         :any[];
    public schoolType         :any[];    
    public school             :any[];
    public baseCourse1        :any[];
    public adminStatus        :any[];
    
    public fromNextCrm1?       :NgbDateStruct;  
    public toNextCrm1?         :NgbDateStruct;
    public fromLastCrm1?       :NgbDateStruct;  
    public toLastCrm1?         :NgbDateStruct;
    public fromIntakeDate1?    :NgbDateStruct;    
    public toIntakeDate1?      :NgbDateStruct;  
    public fromCourseEndDate1? :NgbDateStruct;  
    public toCourseEndDate1?   :NgbDateStruct;       
    public fromHoldingDate1?   :NgbDateStruct;  
    public toHoldingDate1?    :NgbDateStruct;
}

export class Admission {
  id?:number;
  basicId?:number;
  name?:string;
  school?:string;
  schoolName?:string;  
  schoolType?:string;
  schoolTypeName?:string;  
  admissionSchoolType?:string;
  course?:string;
  period?:string;
  startingDate?:string;
  endDate?:string;
  paidDate?:string;
  appliedDate?:string;
  tuitionFee?:string;
  weeks?:string;
  freeWeeks?:string;
  dc?:string;
  comB4Dc?:string;
  profit?:string;
  kubun?:string;
  category?:string;
  comments?:string;
  intake?:string;
  major?:string;
  accountStatus?:string;
  baseCourse1?:string;
  baseCourse1Name?:string;  
  baseCourse2?:string;
  gstNo?:string;
  offerOfPlace?:string;
  receipt?:string;
  attendanceReport?:string;
  schoolTuitionCash?:string|number;
  schoolTuitionBank?:string|number;
  schoolTuitionCheque?:string|number;
  schoolRegistrationCash?:string|number;
  schoolRegistrationBank?:string|number;
  schoolRegistrationCheque?:string|number;
  schoolMaterialCash?:string|number;
  schoolMaterialBank?:string|number;
  schoolMaterialCheque?:string|number;
  schoolExamCash?:string|number;
  schoolExamBank?:string|number;
  schoolExamCheque?:string|number;
  etcInsuranceCash?:string|number;
  etcInsuranceBank?:string|number;
  etcInsuranceCheque?:string|number;
  etcVisaCash?:string|number;
  etcVisaBank?:string|number;
  etcVisaCheque?:string|number;
  etcPickupBank?:string|number;
  etcServiceCash?:string|number;
  etcServiceCheque?:string|number;
  homestayWeeksBank?:string|number;
  homestayWeeksCheque?:string|number;
  homestayFindingfeeCash?:string|number;
  homestayFindingfeeBank?:string|number;
  homestayFindingfeeCheque?:string|number;
  subCom?:string|number;
  totalFee?:string|number;
  bankTransferToSchool?:string|number;
  cashDepositToSchool?:string|number;
  cashDepositToIae?:string|number;
  tuitionGross?:string|number;
  tuitionNet?:string|number;
  cpSubAgent?:string;
  cpSubAgentName?:string;
  cpSubCodeName?:string;  
  cpEp?:string;
  cpEpName?:string;  
  createDate?:string;
  createUser?:string;
  saveDate?:string;
  saveUser?:string;
  auNzCheck?:string;
  commissionCash?:string|number;
  commissionBank?:string|number;
  commissionCheque?:string|number;
  iaeTg?:string|number;
  tTn?:string|number;
  sInsuranceCash?:string|number;
  sInsuranceBank?:string|number;
  sInsuranceCheque?:string|number;
  sOthersCash?:string|number;
  sOthersBank?:string|number;
  sOthersCheque?:string|number;
  sNote?:string;
  iaeNote?:string;
  receivedDate?:string;
  visaNo?:string;
  realCom?:string|number;
  schoolInvoiceNo?:string;
  invoicedDate?:string;
  subPaidDate?:string;
  amended?:string;
  divide?:string;
  parentId?:string|number;
  subReal?:string|number;
  otherExCash?:string|number;
  otherExBank?:string|number;
  otherExCheque?:string|number;
  superNumber?:string|number;
  adminStatus?:string;
  adminStatusName?:string;  
  adminNextCrm?:string;
  adminLastCrm?:string;
  adminEp?:string;
  adminEpName?:string;  
  adminBackground?:string;
  urgent?:string;
  receivedCom?:string|number;
  superId?:string|number;
  numb1Tt?:string|number;
  numb2Tt?:string|number;
  numb3Tt?:string|number;
  numb4Tt?:string|number;
  numb1SubTt?:string|number;
  numb2SubTt?:string|number;
  numb3SubTt?:string|number;
  numb4SubTt?:string|number;
  enrolledDate?:string;
  packedId?:string|number;
  insuranceId?:string|number;
  noGst?:string|number;
  noGst2?:string|number;
  chargeEp?:string;
  stuId?:string;
  appId?:string;
  stuIdPwd?:string;
  adminAgent?:string;
  adminAgentName?:string;  
  fromAdmin?:string;
  sunny?:string|number;
  sunnyPaid?:string;
  adminHoldingDate:string;
  
  mdNameOrg?:string;
  mdNameEngFamily?:string;
  mdNameEng?:string;
  ciMemberCategory?:string;
  ciMemberCategoryName?:string;  
  ciStage?:string;
  ciStageName?:string;  
  ciSubAgent?:string;  
  ciSubAgentName?:string;
  ciEpName?:string;
  
  lapsed?:string;
  isSendToAdminByEmail?:boolean;

  visaCreditPurchaseDate?:string;
  visaAppliedDate?:string;

  insPurchaseDate?:string;
  insStartDate?:string;
  insEndDate?:string;
  insPolicyNumber?:string;

  osStartDate?:string;
  osEndDate?:string;

  isBonusPayment?:boolean;
}

export class AdmissionParams extends Admission {
}