import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SchoolUpdateParams } from "../model/school-update";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/school_update`;

@Injectable()
export class SchoolUpdateService {
  constructor(private http: JsonHttp) {}

  getList(id: number) {
    let params: string = `${url}/school/${id}`;
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: SchoolUpdateParams) {
    return this.http.post(url, params);
  }

  update(params: SchoolUpdateParams) {
    return this.http.put(url, params);
  }

  delete(params: SchoolUpdateParams) {
    return this.http.delete(url, params);
  }
}
