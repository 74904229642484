import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import { Profile } from "app/model/profile";
import { cloneDeep } from "lodash";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { filter } from "rxjs/operators";
import { CodeCommon } from "../../model/code-common";
import { DashboardSearchParams } from "../../model/dashboard";
import { AuthenticationService } from "../../services/authentication.service";
import { CodeService } from "../../services/code.service";
import { DashboardService } from "../../services/dashboard.service";

@Component({
  selector: "app-dashboardaccounting",
  providers: [DashboardService],
  templateUrl: "./dashboardaccounting.component.html",
  styleUrls: ["./dashboardaccounting.component.css"],
})
export class DashboardAccountingComponent implements OnInit {
  epList: any[] = [];
  authority: string;
  name: string;
  company: string;

  option1var: any = [];

  serachNotAllowed: boolean = false;

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "name",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };

  epOptions: IMultiSelectOption[] = [];

  onChange1(event) {
    this.searchParams.searchEP = "";
    for (var i = 0; i < this.option1var.length; i++) {
      if (i == this.option1var.length - 1)
        this.searchParams.searchEP += "" + this.option1var[i].id + "";
      else this.searchParams.searchEP += "" + this.option1var[i].id + "" + ",";
    }

    //this.search();
  }

  //1-30, 31-60, 61-90, over 90
  holdingpending: number[] = new Array(4).fill(0);
  invoiced: number[] = new Array(4).fill(0);
  subUnpaid: number[] = new Array(4).fill(0);

  //ready to create invoice list
  case: number[] = new Array(7).fill(0);

  public searchParams = new DashboardSearchParams();

  isReady: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private dashboardService: DashboardService,
    private codeService: CodeService,
    private router: Router
  ) {
    authenticationService.profile$
      .pipe(filter<Profile>(Boolean))
      .subscribe((profile) => {
        this.name = profile.name;
        this.authority = profile.authority;
        this.company = profile.company;
      });
    codeService.getCommon(this);
  }

  setCodeList(codeCommon: CodeCommon) {
    if (codeCommon == undefined) {
      this.router.navigate(["/logout"]);
    }
    this.epList = cloneDeep(codeCommon.epList);

    for (var i = 0; i < this.epList.length; i++) {
      this.epOptions.push({
        name: this.epList[i].value,
        id: this.epList[i].code,
      });

      if (this.name == this.epList[i].code) {
        this.option1var.push(this.epList[i].code);
      } else if (
        this.authority == "manager" ||
        this.authority == "accountant"
      ) {
        this.option1var.push(this.epList[i].code);
      }
    }

    this.isReady = true;
    this.search();

    //console.log(this.company);
    //var temp = {code: "All", value: "All"};
    //this.epList.unshift(temp);
  }

  ngOnInit() {
    /*
    if(this.authority == "manager" || this.authority == "accountant") {
      this.name = "All";
    }
    */
    //this.search();
  }

  navigateActionWithOnlyIdx(idx) {
    console.log("dashboardaccounting idx : "+idx);
    this.codeService.setSelectedEp(this.searchParams.searchEP);

    this.router.navigate(["/accounting"], {
      queryParams: { action: "home", idx: idx, ep: this.searchParams.searchEP },
    });
  }

  reset() {
    this.serachNotAllowed = true;

    this.holdingpending.forEach((n) => (n = 0));
    this.invoiced.forEach((n) => (n = 0));
    this.subUnpaid.forEach((n) => (n = 0));
    this.case.forEach((n) => (n = 0));
  }

  search() {

    this.holdingpending.fill(0);
    this.invoiced.fill(0);
    this.subUnpaid.fill(0);
    this.case.fill(0);

    this.searchParams.searchEP = "";
    for (var i = 0; i < this.option1var.length; i++) {
      if (i == this.option1var.length - 1) {
        if (this.option1var[i].id == undefined) {
          this.searchParams.searchEP += "" + this.option1var[i] + "";
        } else {
          this.searchParams.searchEP += "" + this.option1var[i].id + "";
        }
      } else {
        if (this.option1var[i].id == undefined) {
          this.searchParams.searchEP += "" + this.option1var[i] + "" + ",";
        } else {
          this.searchParams.searchEP += "" + this.option1var[i].id + "" + ",";
        }
      }
    }
    this.searchParams.searchEP;

    this.reset();

    this.dashboardService.getAccounting(this.searchParams).subscribe(
      (data) => {
        data.forEach((accountings) => {
          //console.log("statistics : " + JSON.stringify(statistics));
          this.holdingpending[0] += +accountings.holdingpending0;
          this.holdingpending[1] += +accountings.holdingpending1;
          this.holdingpending[2] += +accountings.holdingpending2;
          this.holdingpending[3] += +accountings.holdingpending3;

          this.invoiced[0] += +accountings.invoiced0;
          this.invoiced[1] += +accountings.invoiced1;
          this.invoiced[2] += +accountings.invoiced2;
          this.invoiced[3] += +accountings.invoiced3;

          this.subUnpaid[0] += +accountings.subUnpaid0;
          this.subUnpaid[1] += +accountings.subUnpaid1;
          this.subUnpaid[2] += +accountings.subUnpaid2;
          this.subUnpaid[3] += +accountings.subUnpaid3;

          this.case[0] += +accountings.case0;
          this.case[1] += +accountings.case1;
          this.case[2] += +accountings.case2;
          this.case[3] += +accountings.case3;
          this.case[4] += +accountings.case4;
          this.case[5] += +accountings.case5;
          this.case[6] += +accountings.case6;
        });
        this.serachNotAllowed = false;
      },
      (error) => {
        console.log("error : ", error);

        if (error.status == "401") {
          this.router.navigate(["/logout"]);
        }
      }
    );
  }
}
