import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { Observable } from "rxjs";




const authenticationToken = (body?:any) => {
  
  const bearerToken = localStorage.getItem('accessToken');

  if(body){
    //console.log('authenticationToken.body', body);
    return (
      {
        body:body, 
        headers: 
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${bearerToken}`,
        }
      }
    );
  }
  else {
    //console.log('authenticationToken.none');

  return (
      {headers: 
        {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${bearerToken}`,
        }
      }
    );
  }
};



@Injectable()
export class JsonHttp {

  constructor(private http: HttpClient) {
  }


  get(url: string): Observable<any> {
  
    //console.log('authenticationToken,localstorage', localStorage.getItem('accessToken'), authenticationToken)
    return this.http.get(url, authenticationToken());
  }

  post(url: string, body: any): Observable<any> {
    //console.log('json-http', url, body);
    return this.http.post(url, body, authenticationToken());
  }

  put(url: string, body: any): Observable<any> {
    return this.http.put(url, body, authenticationToken());
  }

  delete(url: string, body?: any): Observable<any> {
    return this.http.request('delete', url, authenticationToken(body));
    //return this.http.delete(url, authenticationToken());
  }

  patch(url: string, body: any): Observable<any> {
    return this.http.patch(url, body, authenticationToken());
  }

  head(url: string): Observable<any> {
    return this.http.head(url, authenticationToken());
  }

  /*
  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return this.http.get(url, authenticationToken(options));
  }

  post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
    return this.http.post(url, body, authenticationToken(options));
  }

  put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
    return this.http.put(url, body, authenticationToken(options));
  }

  delete(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return this.http.delete(url, authenticationToken(options));
  }

  patch(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
    return this.http.patch(url, body, authenticationToken(options));
  }

  head(url: string, options?: RequestOptionsArgs): Observable<Response> {
    return this.http.head(url, authenticationToken(options));
  }
  */
}