import { Component, EventEmitter, Input, Output  }      from '@angular/core';

export class ComboxValue {
  value:string;
  label:string;

  constructor(value:string,label:string) {
    this.value = value;
    this.label = label;
  }
}

@Component({
  selector: 'app-combox',
  template: `
    <ul>
      <li *ngFor="let value of values" (click)="selectItem(value.value)">{{value.label}}</li>
    </ul>
  `
})
export class ComboxComponent {
  @Input()
  values: ComboxValue[];

  @Output()
  select: EventEmitter<any>;

  constructor() {
    this.select = new EventEmitter<any>();
  }

  selectItem(value) {
    this.select.emit(value);
  }
}