export class SchoolGdlinks {
  id: string | number;
  schoolId?: string | number;
  gdname?: string;
  gdlink?: string;
  createDate?: string;
  createUser?: string;
  updateDate?: string;
  updateUser?: string;
  isDelete?: boolean;
  bucket?: string;
}

export class SchoolGdlinksParams extends SchoolGdlinks {

}