import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { EventParams } from "../model/event";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/event`;

@Injectable()
export class EventService {
  constructor(private http: JsonHttp) {}

  searchEvent(searchParams, page) {
    let params: string = `${url}?searchKey=${searchParams.searchKey}&searchValue=${searchParams.searchValue}&page=${page.pageNumber}&size=${page.size}`;

    searchParams.evEvent.forEach(
      (code) => (params = params + "&evEvent=" + code)
    );
    searchParams.evRoute.forEach(
      (code) => (params = params + "&evRoute=" + code)
    );
    searchParams.ciSubAgent.forEach(
      (code) => (params = params + "&ciSubAgent=" + code)
    );
    searchParams.ciMemberField.forEach(
      (code) => (params = params + "&ciMemberField=" + code)
    );
    searchParams.ciMemberCategory.forEach(
      (code) => (params = params + "&ciMemberCategory=" + code)
    );
    searchParams.ciEp.forEach((code) => (params = params + "&ciEp=" + code));
    searchParams.ciStage.forEach(
      (code) => (params = params + "&ciStage=" + code)
    );
    searchParams.vsCurrentStatus.forEach(
      (code) => (params = params + "&vsCurrentStatus=" + code)
    );
    searchParams.csGrade.forEach(
      (code) => (params = params + "&csGrade=" + code)
    );
    //console.log(params);

    return this.http.get(params);
  }

  getList(id: number) {
    return this.http.get(`${url}/basic/${id}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: EventParams) {
    return this.http.post(url, params);
  }

  update(params: EventParams) {
    return this.http.put(url, params);
  }

  delete(params: EventParams) {
    return this.http.delete(url, params);
  }
}
