import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AgentFilesParams } from "../model/agent-files";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/agentFiles`;

@Injectable()
export class AgentFilesService {
  constructor(private http: JsonHttp) {}

  searchAgentFiles(searchParams, page) {
    let params: string = `${url}?realType=${searchParams.realType}&kname=${searchParams.kname}&email=${searchParams.email}&page=${page.pageNumber}&size=${page.size}`;

    //console.log(params);

    return this.http.get(params);
  }

  getList(agentId: number) {
    return this.http.get(`${url}/agent/${agentId}`);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: AgentFilesParams) {
    return this.http.post(url, params);
  }

  update(params: AgentFilesParams) {
    return this.http.put(url, params);
  }

  delete(params: AgentFilesParams) {
    return this.http.delete(url, params);
  }
}
