import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { BasicParams } from "../model/basic";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/basic`;

@Injectable()
export class BasicService {
  constructor(private http: JsonHttp) {}

  searchBasic(searchParams, page) {
    let params: string = `${url}?searchKey=${searchParams.searchKey}&searchValue=${searchParams.searchValue}&fromRegDate=${searchParams.fromRegDate}&toRegDate=${searchParams.toRegDate}&fromLastCrm=${searchParams.fromLastCrm}&toLastCrm=${searchParams.toLastCrm}&fromNextCrm=${searchParams.fromNextCrm}&toNextCrm=${searchParams.toNextCrm}&fromArrivedDate=${searchParams.fromArrivedDate}&toArrivedDate=${searchParams.toArrivedDate}&fromDob=${searchParams.fromDob}&toDob=${searchParams.toDob}&pickupStatus=${searchParams.pickupStatus}&page=${page.pageNumber}&size=${page.size}`;

    searchParams.ciSubAgent.forEach(
      (code) => (params = params + "&ciSubAgent=" + code)
    );
    searchParams.ciMemberField.forEach(
      (code) => (params = params + "&ciMemberField=" + code)
    );
    searchParams.ciMemberCategory.forEach(
      (code) => (params = params + "&ciMemberCategory=" + code)
    );
    searchParams.ciEp.forEach((code) => (params = params + "&ciEp=" + code));
    searchParams.ciStage.forEach(
      (code) => (params = params + "&ciStage=" + code)
    );
    searchParams.mmAlarmLevel.forEach(
      (code) => (params = params + "&mmAlarmLevel=" + code)
    );
    searchParams.school.forEach(
      (code) => (params = params + "&school=" + code)
    );
    searchParams.csGrade.forEach(
      (code) => (params = params + "&csGrade=" + code)
    );
    searchParams.vsVisaType.forEach(
      (code) => (params = params + "&vsVisaType=" + code)
    );

    //console.log(params);

    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: BasicParams) {
    return this.http.post(url, params);
  }

  update(params: BasicParams) {
    return this.http.put(url, params);
  }

  delete(params: BasicParams) {
    return this.http.delete(url, params);
  }

  updateStatus(params) {
    return this.http.put(`${url}/updateStatus`, params);
  }

  addFamily(params) {
    return this.http.put(`${url}/addFamily`, params);
  }

  searchBasicMapping(searchParams, page) {
    let params: string = `${url}/mapping?mdNameOrg=${searchParams.mdNameOrg}&mdEMail=${searchParams.mdEMail}&mdKorMobile=${searchParams.mdKorMobile}&camKakaoId=${searchParams.camKakaoId}&mdDob=${searchParams.mdDob}&basicId=${searchParams.basicId}&page=${page.pageNumber}&size=${page.size}`;

    //console.log(params);

    return this.http.get(params);
  }
}
