import { NgModule                 } from '@angular/core';
import { BrowserModule            } from '@angular/platform-browser';
import { DetailMenuComponent      } from './detail-menu.component';  


@NgModule({
  imports: [
            BrowserModule
          ],  
  declarations: [
    DetailMenuComponent
  ],
  exports: [
    DetailMenuComponent
  ]
})
export class DetailMenuModule {
}
