export class Counselling {
  id: string | number;
  name: string;
  email: string;
  phone?: string;
  info?: any;
  question?: string;
  answer?: string;
  createDate?: string;
}

export class CounsellingParams extends Counselling {}
