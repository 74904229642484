import {Directive, ElementRef, Renderer2, HostListener} from '@angular/core';

@Directive({
  selector: '[appCountryFlags]'
})
export class CountryFlagDirective{
    private readonly OFFSET = 127397;
    private first = true;

    constructor(private el: ElementRef, private renderer: Renderer2) {
        renderer.addClass(el.nativeElement, 'country-flags');
    }


    @HostListener('window:click', ['$event.target'])
    onClick(targetElement: string) {
        if(this.first) {
            this.first = false;
            this.translateToFlag();
        }
    }
        

    private translateToFlag() {
        const select = this.el.nativeElement as HTMLSelectElement;
        for(let opt of select.options as any) {
            const flag = this.toFlag(opt.value);
            this.renderer.setAttribute(opt, 'data-before', flag);
            opt.innerHTML = `${flag} ${opt.innerHTML}`;
        }
    }

    private toFlag(code: string) {
        let res = '';
        if(code.length == 3) {

            if(code == 'KOR') {
                code = 'KR';
            } else if(code == 'PRK') {
                code = 'KP';
            } else if(code == 'AND') {
                code = 'AD';
            } else if(code == 'DNK') {
                code = 'DK';
            } else if(code == 'ABW') {
                code = 'AW';
            } else if(code == 'FLK') {
                code = 'FK';
            } else if(code == 'JAM') {
                code = 'JM';
            } else if(code == 'KAZ') {
                code = 'KZ';
            } else if(code == 'FSM') {
                code = 'FM';
            } else if(code == 'ANT') {
                code = 'NL';
                //code = 'AN';
            } else if(code == 'PCN') {
                code = 'PN';
            } else if(code == 'POL') {
                code = 'PL';
            } else if(code == 'SPM') {
                code = 'PM';
            } else if(code == 'SUR') {
                code = 'SR';
            } else if(code == 'SWZ') {
                code = 'SZ';
            } else if(code == 'SWE') {
                code = 'SE';
            } else if(code == 'TUN') {
                code = 'TN';
            } else if(code == 'TUR') {
                code = 'TR';
            } else if(code == 'TUV') {
                code = 'TV';
            } else if(code == 'UKR') {
                code = 'UA';
            } else if(code == 'URY') {
                code = 'UY';
            } else if(code == 'WLF') {
                code = 'WF';
            } else if(code == 'TPE') {
                code = 'TW';
            } else {
                code = code.substr(0, 2);
            }
        
            const base = 127462 - 65;
            const cc = code.toUpperCase();
            res = String.fromCodePoint(...cc.split('').map(c => base + c.charCodeAt(0)));
        }
    
        return res;
    }
}