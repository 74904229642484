import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AgentMemoParams } from "../model/agent-memo";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/agentMemo`;

@Injectable()
export class AgentMemoService {
  constructor(private http: JsonHttp) {}

  getList(id: number) {
    let params: string = `${url}/agent/${id}`;
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: AgentMemoParams) {
    return this.http.post(url, params);
  }

  update(params: AgentMemoParams) {
    return this.http.put(url, params);
  }

  delete(params: AgentMemoParams) {
    return this.http.delete(url, params);
  }
}
