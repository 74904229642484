import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AdminMemoParams } from "../model/admin-memo";
import { JsonHttp } from "./json-http";

const url = `${environment.api_url}/api/adminMemo`;

@Injectable()
export class AdminMemoService {
  constructor(private http: JsonHttp) {}

  getList(id: number) {
    let params: string = `${url}/admission/${id}`;
    //console.log(params);
    return this.http.get(params);
  }

  get(id: number) {
    return this.http.get(`${url}/${id}`);
  }

  create(params: AdminMemoParams) {
    return this.http.post(url, params);
  }

  update(params: AdminMemoParams) {
    return this.http.put(url, params);
  }

  delete(params: AdminMemoParams) {
    return this.http.delete(url, params);
  }
}
